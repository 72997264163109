'use strict';

/* globals StarWebPrintBuilder */
var starPrinter = function ({receiptSize = 2} = {}) {
    var builder = new StarWebPrintBuilder();
    const DEFAULT_RECEIPT_SIZE = 43;

    var setReceiptSize = function (receiptWidth) {
        return receiptWidth == 2 ? 32 : DEFAULT_RECEIPT_SIZE;
    };

    var paperWidth = setReceiptSize(receiptSize);
    var request = '';

    var printer = {
        initialize: function () {
            request = builder.createInitializationElement();

            return printer;
        },
        cut: function () {
            request += builder.createCutPaperElement();

            return printer;
        },
        section: function () {
            // do nothing
            return printer;
        },
        align: function (alignment) {
            alignment = alignment || 'left';
            request += builder.createAlignmentElement({position: alignment});

            return printer;
        },
        title: function (elements, emphasis, styles) {
            var data = '';
            _.each(elements, function (element) {
                data += (element + '');
            });
            data += '\n';

            styles = styles || {};
            styles.width = styles.width || 1;
            styles.height = styles.height || 2;
            styles.invert = styles.invert || false;

            // TODO: not setting width at the moment because there
            // seems to be a bug setting both width and height
            request += builder.createTextElement({
                data: data,
                emphasis: !!emphasis,
                width: styles.width,
                height: styles.height,
                invert: styles.invert
            });

            return printer;
        },
        titleJustified: function (elements, emphasis, styles) {
            // TODO: add support for more than two elements?

            // var elementCharCount = 0;
            // _.each(elements, function (element) {
            //     var elem = element + '';
            //     elementCharCount += elem.length;
            // });

            var leftString = elements[0] + '';
            var rightString = elements[1] + '';

            styles = styles || {};
            var textWidth = styles.width || 1;

            var contentCharacterCount = (leftString.length + rightString.length);
            var contentWidth = contentCharacterCount * textWidth;
            var lines = Math.ceil(contentWidth / (paperWidth - 1));
            var spacing = Math.floor(((lines * paperWidth) - contentWidth) / textWidth);

            var newElements = [];
            _.each(elements, function (element, index) {
                var elem = element + '';
                newElements.push(elem);

                if (index < elements.length - 1) {
                    newElements.push(Array(spacing).join(' '));
                }
            });

            printer.title(newElements, emphasis, styles);

            return printer;
        },
        text: function (elements, emphasis, styles) {
            if (!Array.isArray(elements)) {
                elements = [elements];
            }

            var data = '';
            _.each(elements, function (element) {
                data += (element + '');
            });
            data += '\n';

            styles = styles || {};
            styles.width = styles.width || 1;
            styles.height = styles.height || 1;
            styles.invert = styles.invert || false;

            request += builder.createTextElement({
                data: data,
                emphasis: !!emphasis,
                width: styles.width,
                height: styles.height,
                invert: false
            });

            return printer;
        },
        textJustified: function (elements, emphasis, styles, stylesRight, filler) {
            // TODO: add support for more than two elements?

            // var elementCharCount = 0;
            // _.each(elements, function (element) {
            //     var elem = element + '';
            //     elementCharCount += elem.length;
            // });

            filler = filler || ' ';

            var leftString = elements[0] + '';
            var rightString = elements[1] + '';

            var lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            var spacing = (lines * paperWidth) - leftString.length - rightString.length;

            var newElements = [];
            _.each(elements, function (element, index) {
                var elem = element + '';
                newElements.push(elem);

                if (index < elements.length - 1) {
                    newElements.push(Array(spacing).join(filler));
                }
            });

            printer.text(newElements, emphasis, styles, filler);

            return printer;
        },
        textJustifiedAndWrapped: function (elements, emphasis, styles, stylesRight, filler) {
            // TODO: add support for more than two elements?

            // var elementCharCount = 0;
            // _.each(elements, function (element) {
            //     var elem = element + '';
            //     elementCharCount += elem.length;
            // });

            filler = filler || ' ';

            var leftString = elements[0] + '';
            var rightString = elements[1] + '';
            var tmpLeftString = leftString;

            var remainingStringLength = paperWidth - rightString.length;

            var leftStringArr = leftString.split(' ');
            var quantityLength = leftStringArr[0].length;
            var stringToAppendOnLeft = '';
            for (var spaceIncrement = 0; spaceIncrement < quantityLength; spaceIncrement++) {
                stringToAppendOnLeft += ' ';
            }
            stringToAppendOnLeft += '   ';

            var stringToAppendOnRight = '';
            for (var spaceIncrementsa = 0; spaceIncrementsa < rightString.length; spaceIncrementsa++) {
                stringToAppendOnRight += ' ';
            }
            stringToAppendOnRight = filler + stringToAppendOnRight;


            var lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            var spacing = (lines * paperWidth) - leftString.length - rightString.length;


            var newLeftString = '';
            if (leftString.length > remainingStringLength) {
                var counter = 0;
                while ((tmpLeftString.length + stringToAppendOnLeft.length + filler.length)
                    > remainingStringLength) {
                    if (counter > 0) {
                        newLeftString += stringToAppendOnLeft;
                        newLeftString += tmpLeftString.substr(0, (remainingStringLength
                            - stringToAppendOnLeft.length - filler.length));
                        newLeftString += stringToAppendOnRight + '\n';
                        tmpLeftString = tmpLeftString.substr(remainingStringLength
                            - stringToAppendOnLeft.length - filler.length);
                    } else {
                        newLeftString += tmpLeftString.substr(0, (remainingStringLength - 1));
                        newLeftString += filler + rightString + '\n';
                        tmpLeftString = tmpLeftString.substr(remainingStringLength - 1);
                    }
                    counter++;
                }

                if (counter > 0) {
                    newLeftString += stringToAppendOnLeft;
                }
                newLeftString += tmpLeftString;
                /* var newSpacing = remainingStringLength - tmpLeftString.length - stringToAppendOnLeft.length + 1;

                var adsdsd = Array(newSpacing).join(filler);*/
                // var newRightString = adsdsd + rightString;
                leftString = newLeftString;
                rightString = '';
                elements[0] = leftString;
                elements[1] = rightString;
            }


            /* var lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            // var spacing = (lines * paperWidth) - leftString.length - rightString.length;*/

            var newElements = [];
            _.each(elements, function (element, index) {
                var elem = element + '';
                newElements.push(elem);

                if (leftString.length <= remainingStringLength) {
                    if (index < elements.length - 1) {
                        newElements.push(Array(spacing).join(filler));
                    }
                }
            });

            printer.text(newElements, emphasis, styles, filler);

            return printer;
        },
        newLine: function (count) {
            count = count || 1;

            var data = Array(count).join('\n');
            request += builder.createTextElement({data: data});

            return printer;
        },
        divider: function (thin) {
            if (thin) {
                printer.newLine(1);
                var data = Array(paperWidth).join(' ') + '\n';
                request += builder.createTextElement({data: data, underline: true, emphasis: false, height: 1});
                request += builder.createTextElement({data: '', underline: false});
                printer.newLine(2);
            } else {
                printer.newLine(1);
                request += builder.createRuledLineElement({thickness: 'medium', width: 600});
                printer.newLine(1);
            }

            return printer;
        },
        output: function () {
            return request;
        },
        createQrCodeElement: function (data, cell = 6) {
            request += builder.createQrCodeElement({model: 'model2', level: 'level_l', cell: cell, data: data});

            return printer;
        },
        createBarcodeElement: function (data, height) {
            request += builder.createBarcodeElement({symbology: 'Code128', width: 'width2', height: 40, hri: true, data: data});

            return printer;
        }
    };

    return printer;
};

module.exports = starPrinter;
