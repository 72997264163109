'use strict';

/**
*** Commented By Akash Mehta on September 8 2020
*** This service is a pure javascript service, whose pupose is to hold all functions required to build a cart
*** which can be used by any client for without internally maintaining any states. We were dire need of such a service and
*** the quickCharge feature increased the need tenfold. This service now not only allows us to create carts from any part of the POS
*** but also from other external services running transactions (like the nown calculation server) and also helps in maintaing a unified
*** and common cart building workflow.
***/
(function () {
    var CartBuilderService = (function () {
        var CartBuilderService = function () {};

        const NownCalculationService = require('./pos.meal-plan-calculations.js');
        const TransactionTenderService = require('./transaction-tender-service.js');
        const Pure = require('./pure.js');
        const Controller = new (require('./pos.controller.js'))();
        const Decimal = require('decimal.js').default;
        const SharedDataService = require('./pos.data-service.js');
        const LoyaltyRedemption = require('./loyalty/redemption.js');
        const _ = require('underscore');

        CartBuilderService.prototype.AUTOCALCULATE = 'AUTOCALCULATE';
        CartBuilderService.prototype.MODIFY = 'MODIFY';

        /** Commented By Akash Mehta on September 8 2020
        *** This function is a partial implementation of adding an item to the nestedCartItems object. The current fucntion that handles
        *** this functionality still resides in the controller and only a part of the function has been moved here. Currently this functions
        *** can only add a new item to the nestedCartItems object, but once we have moved the function from the controller, this function will
        *** be able to manage all items in the cart.
        ***/
        CartBuilderService.prototype.addItemToCart = function (nestedCartItems, inputItem, upc = false) {
            if (!nestedCartItems || !Array.isArray(nestedCartItems)) {
                return null;
            }

            if (!inputItem || (!upc && !inputItem.locationServicePeriodMenuId)) {
                return null;
            }

            // var itemCopy = lodash.cloneDeep(inputItem);
            var itemCopy = inputItem;
            /* if (shouldAdd) {
                var existingItem = _.find(nestedCartItems, function (receiptItem) {
                    if (angular.isDefined(receiptItem.upc) && upc) {
                        return Controller.areSameUPC(receiptItem, item);
                    } else if (angular.isDefined(receiptItem.locationServicePeriodMenuId) && angular.isDefined(item.locationServicePeriodMenuId)) {
                        return Controller.areSameMenuItems(receiptItem, item);
                    }
                    return false;
                });

                if (existingItem && toMergeReceiptItems) {
                    existingItem.quantity += 1;
                    existingItem._timestamp = Date.now();
                } else {*/
                    if (!upc) {
                        var taxAmount = 0;
                        var itemTaxAmount = 0;
                        if (itemCopy.taxRate) {
                            taxAmount = itemCopy.price * itemCopy.taxRate;
                            itemTaxAmount = itemCopy.price * itemCopy.taxRate;
                        }

                        itemCopy.originalPrice = itemCopy.originalPrice || itemCopy.price;
                        itemCopy.quantity = itemCopy.quantity || 1;
                        itemCopy.upc = itemCopy.upc;
                        itemCopy.taxAmount = taxAmount;
                        itemCopy.itemPrice = itemCopy.price;
                        itemCopy.total = itemCopy.price + taxAmount;
                        itemCopy.itemTaxAmount = itemTaxAmount;
                        itemCopy.mealPlanAmount = itemCopy.mealPlanCount;
                        itemCopy.itemTotalPrice = itemCopy.price + itemTaxAmount;
                        // item.parent = parent;
                        itemCopy.notes = itemCopy.notes || '';
                        itemCopy.visualDiscount = itemCopy.visualDiscount || {};
                        itemCopy.children = itemCopy.children || [];
                    }

                    if (!itemCopy._uuid) {
                        itemCopy._uuid = Pure.generateUuid();
                    }
                    itemCopy._timestamp = Date.now();

                    nestedCartItems.push(itemCopy);
                    itemCopy.index = itemCopy.index || nestedCartItems.length - 1;
                // }

            // Commented By Akash Mehta on Sept 9 2020
            // I am returning the nestedCartItems rather than true as they would both behave the same for truthy/falsy check.
            // Ideally no one would need to store this response if they have passed all parameters correctly.
            return nestedCartItems;
        };

        CartBuilderService.prototype.generateCartForPreorder = function (preorderItemsArr) {
            /**
            *** Commented By Akash Mehta on Apr 1 2020
            *** This is an extra step added to bridge the gap between the flat Item List sent by the Nown Backend
            *** (which inturn receives the same input from the payment server)
            *** where the receipt list is missing the total price of an item. This is required for the recalcuation
            *** of the Nown transaction to get the correct payload.
            ***/
            return Controller.rebuildReceiptForPreorder(preorderItemsArr);
        };

        var _generateNestedCartForPreorder = function (flatCartItems) {
            var nestedCartItems = [];
            let dummyModifierCategory = {
                locationServicePeriodMenuId: -90000,
                name: 'Dummy Modifier Category',
                children: [],
                type: 'modifier'
            };

            flatCartItems.sort(function (a, b) {
                return (a.index == b.index) ? (a.level - b.level) : (a.index - b.index);
            });

            flatCartItems.forEach((item) => {
                var nestedItem = {
                    quantity: item.quantity,
                    locationServicePeriodMenuId: item.locationServicePeriodMenuId,
                    name: item.name,
                    upc: item.upc,
                    taxRate: item.taxRate,
                    taxRateId: item.taxRateId,
                    price: new Decimal(item.price).div(item.quantity).toNearest(SharedDataService.baseDollar).toNumber(),
                    mealPlanAmount: item.mealPlanAmount || 0,
                    mealPlanEligible: false,
                    mealPlanCount: item.mealPlanCount || 0,
                    index: item.index,
                    level: item.level,
                    taxRules: item.taxRules,
                    type: item.type,
                    subtype: item.subtype,
                    notes: item.notes,
                    printoutType: item.printoutType,
                    children: item.children || [],
                    loyaltyEnabled: item.loyaltyEnabled || false
                };

                if (nestedItem.level == 0) {
                    nestedCartItems.push(nestedItem);
                } else {
                    nestedItem.selected = true;
                    let parentItem = nestedCartItems.find((t) => t.index == nestedItem.index && t.level == 0);

                    parentItem.children = parentItem.children || [];
                    if (parentItem.children.length == 0) {
                        // We need to deep clone this modifier category so that its original reference does not get updated
                        parentItem.children.push(JSON.parse(JSON.stringify(dummyModifierCategory)));
                    }

                    let modifierCategory = parentItem.children[0];
                    modifierCategory.children = modifierCategory.children || [];
                    modifierCategory.children.push(nestedItem);
                }
            });

            return nestedCartItems;
        };

        var _calculateAndApplyLoyaltyDiscount = function (thisInstance, nestedCartItems, flatCartItems, adjustments, patronId, nownLocationId,
            {available = [], organizationSettings = {}, company, location, runMock = false}) {
            let tempNestedCartItems = nestedCartItems;
            let tempFlatCartItems = flatCartItems;
            let tempAdjustments = adjustments;
            let tempAvailable = available;
            let tempOrganizationSettings = organizationSettings;
            let tempCompany = company;
            let tempLocation = location;

            if (runMock) {
                tempNestedCartItems = JSON.parse(JSON.stringify(nestedCartItems));
                tempFlatCartItems = JSON.parse(JSON.stringify(flatCartItems));
                tempAdjustments = JSON.parse(JSON.stringify(adjustments));
                tempAvailable = JSON.parse(JSON.stringify(available));
                tempOrganizationSettings = JSON.parse(JSON.stringify(organizationSettings));
                tempCompany = JSON.parse(JSON.stringify(company));
                tempLocation = JSON.parse(JSON.stringify(location));

                // Because there isn't a way to pass whether loyalty is used into the tender screen without
                // introducing more parameters, use `SmbPosService.order` to carry the value forward to
                // perform a calculation right when the tender screen is initialized.
                tempAdjustments.mealEquivalencyAdjustment = runMock;
            }


            var loyaltyDiscountPercentage = LoyaltyRedemption.calculateLoyaltyDiscountPercentage(tempFlatCartItems,
                tempAdjustments, tempAvailable, tempOrganizationSettings);

            var percentageValue = new Decimal(loyaltyDiscountPercentage).dividedBy(100).toDecimalPlaces(2).toNumber();
            thisInstance.applyTransactionPercentageDiscount(percentageValue, tempNestedCartItems, tempFlatCartItems, tempAdjustments, true);

            tempFlatCartItems = Controller.rebuildReceipt(tempNestedCartItems);
            let nownPosData = NownCalculationService.createPosData(tempFlatCartItems, undefined, true, nownLocationId, {},
                organizationSettings, {
                    company: tempCompany,
                    location: tempLocation
                });
            let nownCalculatedObj = NownCalculationService.calculateBalances(tempFlatCartItems, tempAvailable, tempAdjustments, nownPosData);

            let nownTransactionObj = nownCalculatedObj.tenderAmounts;
            if (loyaltyDiscountPercentage) {
                nownTransactionObj.patronId = patronId;
            }

            return nownTransactionObj;
        };

        CartBuilderService.prototype.processCartForPreOrder = function (originalTransactionPayload,
            processedFlatCartItems, nownLocationId, adjustments, {available = [], organizationSettings = {}, menuPeriodId, allTenders = [],
            fiitPatronId, company, location, useLoyalty = false, availableOffers, fiitMockResponse}) {
            let thisInstance = this;
            let nestedCartItems = _generateNestedCartForPreorder(processedFlatCartItems);
            var updatedFlatCartItems = Controller.rebuildReceipt(nestedCartItems);


            var nownPosData = NownCalculationService.createPosData(processedFlatCartItems, undefined, true, nownLocationId, {},
                organizationSettings, {
                    company: company,
                    location: location
                });

            // todo: Implement optional param has been added to calculateBalances called 'patron'
            let patron = null;
            if (availableOffers) {
                patron = {
                    offers: availableOffers
                };
            }

            if (fiitMockResponse) {
                TransactionTenderService.updateNownCartFromFiitTransactionMock(updatedFlatCartItems, fiitMockResponse, adjustments);
            }

            var nownCalculatedObj = NownCalculationService.calculateBalances(updatedFlatCartItems, available, adjustments, nownPosData, patron);

            var nownTransactionObj = nownCalculatedObj.tenderAmounts;
            var loyaltyMealPlan = available.find((plan) => plan.name == 'Loyalty');
            if (loyaltyMealPlan) {
                let mockTransactionObj = _calculateAndApplyLoyaltyDiscount(thisInstance, nestedCartItems, updatedFlatCartItems, adjustments, loyaltyMealPlan.patronId,
                    nownLocationId, {
                    available: available,
                    organizationSettings: organizationSettings,
                    company: company,
                    location: location,
                    runMock: true
                });

                // Because there isn't a way to pass whether loyalty is used into the tender screen without
                // introducing more parameters, use `SmbPosService.order` to carry the value forward to
                // perform a calculation right when the tender screen is initialized.
                adjustments.mealEquivalencyAdjustment = useLoyalty;
                if (useLoyalty) {
                    nownTransactionObj = _calculateAndApplyLoyaltyDiscount(thisInstance, nestedCartItems, updatedFlatCartItems, adjustments, loyaltyMealPlan.patronId,
                        nownLocationId, {
                        available: available,
                        organizationSettings: organizationSettings,
                        company: company,
                        location: location
                    });
                } else {
                    nownTransactionObj.loyaltyPointsRedeemable = mockTransactionObj.mealPlanCount;
                    nownTransactionObj.loyaltyDollarValueRedeemable = mockTransactionObj.mealEqAmount;
                }
            }

            nownTransactionObj.transactionUuid = originalTransactionPayload.transactionUuid;
            nownTransactionObj.lucovaUser = originalTransactionPayload.lucovaUser;
            nownTransactionObj.isMobileOrder = true;
            nownTransactionObj.autoCreateShift = true;
            nownTransactionObj.posStationId = originalTransactionPayload.posStationId || 0;
            nownTransactionObj.deliveryAddress = originalTransactionPayload.deliveryAddress;

            var fiitTransactionDetails = {};
            fiitTransactionDetails.menuPeriodId = menuPeriodId;
            fiitTransactionDetails.fiitPatronId = fiitPatronId;

            return TransactionTenderService.generateNownTransactionPayload(nownTransactionObj, allTenders, {
                fiitTransactionDetails: fiitTransactionDetails,
            });
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function is a implementation of the process of converting the nestedCartItems into a flat list of items.
        *** This processedFlatCartItems is further used in the POS to process the calculations and submit transactions.
        ***/
        CartBuilderService.prototype.processCartForPos = function (nestedCartItems, processedFlatCartItems, adjustments, available, manualCoupon,
            posData, patron, currentOrderBalance = {}) {
            if (!Array.isArray(nestedCartItems)
                || !Array.isArray(processedFlatCartItems)
                || !posData
                || !patron
                || !currentOrderBalance) {
                return null;
            }

            this.applyTaxChanges(nestedCartItems, adjustments);
            processedFlatCartItems.length = 0;
            var updatedFlatCartItems = Controller.rebuildReceipt(nestedCartItems);
            processedFlatCartItems.push(...updatedFlatCartItems);
            var calculated = NownCalculationService.calculateBalances(processedFlatCartItems, available, adjustments, posData, patron);
            Controller.copyCalculations(calculated.payments, currentOrderBalance);

            return NownCalculationService.populateTenderAmounts(calculated.tenderAmounts, manualCoupon, patron);
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function updates the cart based on the processedFlatCartItems passed in the input. This is necessary in the
        *** case of Nown - FIIT where the tax applied on the item can get updated based on the tender used.
        ***/
        CartBuilderService.prototype.updateCart = function (processedFlatCartItems, adjustments, available, manualCoupon,
            posData, patron, currentOrderBalance = {}) {
            if (!Array.isArray(processedFlatCartItems)
                || !posData
                || !patron
                || !currentOrderBalance) {
                return null;
            }

            var calculated = NownCalculationService.calculateBalances(processedFlatCartItems, available, adjustments, posData);
            Controller.copyCalculations(calculated.payments, currentOrderBalance);

            return NownCalculationService.populateTenderAmounts(calculated.tenderAmounts, manualCoupon, patron);
        };

        /** Commented By Akash Mehta on September 8 2020
        *** A child function of the function `applyTaxChanges`. It tries to verify that there was an original tax applied or not.
        *** If there is a tax selected to override the transaction, we apply the new tax if the original item had tax else we ignore
        *** the item.
        *** If no tax is selected, we restore the original tax rate applied against the item.
        *** NOTE : There is a better way of writing this code. We should refactor/rewrite it in a better way to ensure the code's
        ***        stability.
        ***/
        CartBuilderService.prototype.applyTaxToItem = function applyTaxToItem (item, selectedTaxObjectArr) {
            if (selectedTaxObjectArr && selectedTaxObjectArr.length) {
                var newTaxRate = selectedTaxObjectArr[0];
                if (!item._data ||
                    (item._data && !item._data.originalTax)) {

                    item._data = item._data || {};
                    item._data.originalTax = {
                        rate: item.taxRate,
                        id: item.taxRateId,
                        fid: item.taxRateFid
                    };
                }

                if (item._data.originalTax.rate) {
                    item.taxRate = newTaxRate.taxRate;
                    item.taxRateId = newTaxRate.taxRateId;
                }
            } else {
                if (item._data && item._data.originalTax) {
                    item.taxRate = item._data.originalTax.rate;
                    item.taxRateId = item._data.originalTax.id;
                    item.taxRateFid = item._data.originalTax.fid;
                }
            }
        };

        /** Commented By Akash Mehta on September 8 2020
        *** A dependency for the function `processCartForPos`, we used this to update the tax rate applied
        *** on all items present in the basket. This is done for overriding the tax rate applied on the basket.
        *** Note this takes effect only on taxable items
        ***/
        CartBuilderService.prototype.applyTaxChanges = function applyTaxChanges (cartItems, adjustments) {
            var thisInstance = this;
            if (adjustments && adjustments.selectedTaxRates) {
                cartItems.forEach(function (cartItem) {
                    thisInstance.applyTaxToItem(cartItem, adjustments.selectedTaxRates);
                });
            }
        };

        /**
        ** The case takes in consideration that the price in the details object is the new price being set
        ** and hence calculates the % off differently.
        ** For the new modal , a new case is introduced as price_discount, which considers that the price being passed is being
        ** given off (rather than it being the new price like the case above).
        **/
        var calculateDiscount = function (flatCartItem, field) {
            switch (field) {
                case 'percentage':
                    flatCartItem.detail.discount.price =
                        parseFloat(flatCartItem.data.originalBundlePrice * (1 - flatCartItem.detail.discount.percentage))
                        .toPrecision(12);
                    break;
                case 'price':
                    flatCartItem.detail.discount.percentage = (
                        parseFloat(1 - flatCartItem.detail.discount.price / flatCartItem.data.originalBundlePrice))
                        .toPrecision(12);
                    break;
                case 'price_discount':
                    flatCartItem.detail.discount.percentage = (
                        parseFloat((flatCartItem.detail.discount.price /flatCartItem.detail.quantity) / flatCartItem.data.originalBundlePrice))
                        .toPrecision(12);
                    break;
            }
        };

        /**
        **  THe parameter modifyingItem is being added since we now manipulate price as well of the receiptItem.
        **  So when applyReceiptItemChange is called only for discount, the receiptItem price is changed as per the modifier rules
        **  So if it is only for discount, then making changes of item price is useless and modifies the receipt itself. Hence the
        **  new parameter.
        **/
        CartBuilderService.prototype.applyReceiptItemChange = function (nestedCartItems, receiptItem, adjustments,
            {skipReceiptRebuild = false, buildForModal = false, modifyingItem = true, isPGCDiscountable = false, rebuildReceiptFn, removeReceiptItemFn} = {}) {
            let thisInstance = this;
            // Delete if new quantity is 0
            var allItemsToDiscount = [];
            const canApplyDiscounts = (receiptItem.subtype && receiptItem.subtype.includes('giftcard')) ? isPGCDiscountable : true;
            const isLoyaltyStepItem = !!receiptItem.loyaltyStepCost;
            const discountLoyaltyStepModifiers = SharedDataService.company.attributes
                && SharedDataService.company.attributes.discount_loyalty_step_modifiers === 'true';

            if (!buildForModal) {
                receiptItem.detail.quantity = parseFloat(receiptItem.detail.quantity);
                if (receiptItem.detail.quantity === 0) {
                    if (removeReceiptItemFn) {
                        removeReceiptItemFn(receiptItem);
                    }
                    return;
                }
            }

            var percentageToDiscount = 0.00;
            if (isLoyaltyStepItem) {
                percentageToDiscount = 1;
                if (buildForModal) {
                    receiptItem.discount.percentage = percentageToDiscount;
                }
            } if (adjustments.percentDiscount || adjustments.dollarDiscount) {
                percentageToDiscount = adjustments.percentDiscount;

                if (buildForModal) {
                    receiptItem.discount.percentage = percentageToDiscount;
                    receiptItem.discount.visualVal = 0;
                }
            } else if (buildForModal) {
                if (receiptItem.discount.type == 'price') {
                    receiptItem.discount.percentage = percentageToDiscount = new Decimal(receiptItem.discount.price || 0)
                        .dividedBy(new Decimal(receiptItem.totalIndividualPrice))
                        .toDecimalPlaces(12)
                        .toNumber();
                } else {
                    percentageToDiscount = receiptItem.discount.percentage || 0;
                }
            } else {
                percentageToDiscount = receiptItem.detail.discount.percentage || 0;
            }

            var fullReceiptItem = (buildForModal) ? receiptItem : nestedCartItems[receiptItem.index];

            // Assuming being used by Pos modify Modal only
            if (!buildForModal) {
                fullReceiptItem.quantity = receiptItem.detail.quantity;
                fullReceiptItem.detail = receiptItem.detail;
                if (modifyingItem) {
                    fullReceiptItem.price = receiptItem.price;
                }
            }
            fullReceiptItem.visualDiscount = receiptItem.visualDiscount || {};
            fullReceiptItem.visualDiscount.price = receiptItem.visualDiscount.price || new Decimal(0.00);
            var modifiersReplacingPrice = -1;
            if (fullReceiptItem.priceReplacedBy) {
                modifiersReplacingPrice = fullReceiptItem.priceReplacedBy.locationServicePeriodMenuId || -1;
            }
            let children = fullReceiptItem.children || [];
            var isPriceBeingReplaced = false;
            var modifierAttachedPrice = new Decimal(0);
            children.forEach((modifierCategory) => {
                // Safety Check: 'fullReceipt' (from pos.order.parent.ctrl) might get 'undefined' when receipt change is triggered from ng-switch/ng-if
                if (!modifierCategory || modifierCategory.subtype === 'discount' || modifierCategory.subtype === 'loyalty') {
                    return;
                }

                var isModifierAttached = modifierCategory.nameAttachedToParent || false;

                for (var modifierIndex = 0; modifierIndex < modifierCategory.children.length; modifierIndex++) {
                    var modifier = modifierCategory.children[modifierIndex];
                    if ((modifier.quantity > 0 || modifier.multiplier == 0) && modifier.price > 0.00) {
                        modifier.quantity = (isModifierAttached) ? fullReceiptItem.quantity : modifier.quantity;
                        // modifier.originalPrice = modifier.originalPrice || modifier.price;
                        // modifier.price = modifier.originalPrice;
                        thisInstance.applyTaxToItem(modifier, adjustments.selectedTaxRates);
                        if (modifierCategory.locationServicePeriodMenuId === modifiersReplacingPrice) {
                            isPriceBeingReplaced = true;
                        }
                        /**
                         * When a modifier is attached to a parent, the discount calculation
                         * should also be included on the parent and not the modifier itself
                         */
                        if (!isModifierAttached) {
                            // normally, all modifiers gets affected by item discounts
                            // we can prevent that (and charge if needed) for a loyaltyStepItem

                            if (!isLoyaltyStepItem || discountLoyaltyStepModifiers) {
                                allItemsToDiscount.push(modifier);
                            }
                        } else {
                            modifierAttachedPrice = modifierAttachedPrice.plus(new Decimal(modifier.price));
                        }
                    }
                }
            });

            /**
             *** When a modifier is replacing the price of its parent,
             *** this discount calculation function discards any discounts
             *** associated with the parent and calculates any discount on
             *** the modifier. In the case that a modifier both replaces the
             *** price of the parent and also attaches its name to the parent,
             *** this function leaves the discount associated with the parent.
            ***/

            if ((!isPriceBeingReplaced && canApplyDiscounts)
                || (isPriceBeingReplaced && modifierAttachedPrice.greaterThan(0))
                || isLoyaltyStepItem) {
                allItemsToDiscount.push(fullReceiptItem);
            }

            if (receiptItem.notes) {
                fullReceiptItem.notes = receiptItem.notes;
            }

            _.each(allItemsToDiscount, function (item) {
                if (!item.children) {
                    return;
                }
                var discountModifier = _.findWhere(item.children, {subtype: 'discount'});
                if (!discountModifier) {
                    discountModifier = {
                        active: true,
                        type: 'modifier',
                        locationId: receiptItem.locationId,
                        id: -2,
                        subtype: 'discount',
                        minSelection: -1,
                        maxSelection: -1,
                        mealEquivalencyEnabled: false,
                        loyaltyEnabled: false
                    };
                    item.children.push(discountModifier);
                }
                discountModifier.children = [];

                var price = new Decimal(item.price);
                if (item.locationServicePeriodMenuId == fullReceiptItem.locationServicePeriodMenuId && modifierAttachedPrice.greaterThan(0)) {
                    if (isPriceBeingReplaced) {
                        price = modifierAttachedPrice;
                    } else {
                        price = price.plus(modifierAttachedPrice);
                    }
                }
                var amountToDiscount = price.times(percentageToDiscount);

                var discount;
                var discountName;

                var discountSubtype = (adjustments.isLoyaltyDiscount || isLoyaltyStepItem) ? 'loyalty' : 'discount';
                var discountTaxRules = (adjustments.isLoyaltyDiscount || isLoyaltyStepItem) ? {} : item.taxRules;
                if (!item.included && amountToDiscount.toNumber() > 0) {
                    var discountDetail = fullReceiptItem.discount || fullReceiptItem.detail.discount;
                    if (discountDetail && discountDetail.subtype === 'promo') {
                        if (fullReceiptItem === item) {
                            /* discountName = $translate.instant('smb.pos.receipt.item.discount.promo.label', {
                                itemName: item.name
                            });*/
                            discountName = `[Promo] ${ item.name }`;
                            amountToDiscount = new Decimal(fullReceiptItem.price);

                            discount = {
                                active: true,
                                selected: true,
                                workTime: 0,
                                type: 'discount',
                                inventoryId: -1,
                                locationServicePeriodMenuId: -2, // -2 means discount?
                                locationId: receiptItem.locationId,
                                price: -amountToDiscount.toNearest(SharedDataService.baseDollar).toNumber(),
                                taxRate: item.taxRate,
                                taxRules: discountTaxRules,
                                taxRateId: item.taxRateId,
                                name: discountName,
                                subtype: discountSubtype,
                                quantity: item.quantity,
                                mealEquivalencyEnabled: false,
                                parentItemId: item.locationServicePeriodMenuId,
                                labelledDiscount: {
                                    id: undefined,
                                    discountName: 'Promo',
                                    discountPercentage: 0,
                                    transactionLevel: true,
                                    itemLevel: true,
                                    pinRequired: false,
                                    isPromo: true
                                },
                                loyaltyEnabled: false
                            };
                            discountModifier.children.push(discount);
                        }
                    } else {
                        var displayPercentage = new Decimal(percentageToDiscount).times(100).toNearest(SharedDataService.baseDollar)
                            .toDecimalPlaces(2).toNumber();
                        if (displayPercentage % 1 == 0) {
                            displayPercentage = new Decimal(displayPercentage).toDecimalPlaces(0).toNumber();
                        }

                        var labelledDiscountToApply = (fullReceiptItem.detail && fullReceiptItem.detail.discount && fullReceiptItem.detail.discount.labelledDiscount)
                            ? fullReceiptItem.detail.discount.labelledDiscount
                            : ((adjustments.labelledDiscounts && adjustments.labelledDiscounts.length) ? adjustments.labelledDiscounts[0]
                                : adjustments.labelledDiscounts);

                        var percentageString = labelledDiscountToApply ? labelledDiscountToApply.discountName + ' : ' : '';
                        percentageString = percentageString + displayPercentage;

                        if (amountToDiscount.toNumber() > 0) {
                            /* discountName = $translate.instant('smb.pos.receipt.item.discount.percentage.label', {
                                itemName: item.name,
                                discountPercentage: percentageString
                            });*/
                            discountName = `(${ percentageString }% off) ${ item.name }`;
                        } else {
                            discountName = `(Price adjustment) ${ item.name }`;
                        }

                        discount = {
                            active: true,
                            selected: true,
                            workTime: 0,
                            type: 'discount',
                            inventoryId: -1,
                            locationServicePeriodMenuId: -2, // -2 means discount?
                            locationId: receiptItem.locationId,
                            price: -amountToDiscount.toNearest(SharedDataService.baseDollar).toNumber(),
                            taxRate: item.taxRate,
                            taxRules: discountTaxRules,
                            taxRateId: item.taxRateId,
                            name: discountName,
                            subtype: discountSubtype,
                            quantity: item.quantity,
                            mealEquivalencyEnabled: false,
                            loyaltyEnabled: false,
                            parentItemId: item.locationServicePeriodMenuId
                        };

                        if (fullReceiptItem.detail && fullReceiptItem.detail.discount && fullReceiptItem.detail.discount.labelledDiscount) {
                            discount.labelledDiscount = fullReceiptItem.detail.discount.labelledDiscount;
                        }

                        discountModifier.children.push(discount);
                    }
                } else {
                    var discountModifierIndex = item.children.indexOf(discountModifier);

                    if (discountModifierIndex > -1) {
                        item.children.splice(discountModifierIndex, 1);
                    }
                }
            });

            if (isPriceBeingReplaced && !modifierAttachedPrice.greaterThan(0)) {
                var discountModifier = _.findWhere(fullReceiptItem.children, {subtype: 'discount'});
                if (discountModifier) {
                    var discountModifierIndex = fullReceiptItem.children.indexOf(discountModifier);

                    if (discountModifierIndex > -1) {
                        fullReceiptItem.children.splice(discountModifierIndex, 1);
                    }
                }
            }

            if (!skipReceiptRebuild && rebuildReceiptFn) {
                rebuildReceiptFn();
            }
        };


        CartBuilderService.prototype.applyTransactionPercentageDiscount = function (percentage, nestedCartItems,
            flatCartItems, adjustments, isLoyalty, isPGCDiscountable, rebuildReceiptFn) {
            let thisInstance = this;
            adjustments.percentDiscount = percentage;
            adjustments.isLoyaltyDiscount = isLoyalty;

            var mainReceiptItems = _.where(flatCartItems, {level: 0});
            _.each(mainReceiptItems, function (receiptItem) {
                // Note: this also ensures that an item's modifiers
                // loyalty eligibility is inherited from the item
                if (isLoyalty && !receiptItem.loyaltyEnabled) {
                    var fullReceiptItem = nestedCartItems[receiptItem.index];
                    var discountModifier = _.findWhere(fullReceiptItem.children, {subtype: 'discount'});
                    if (discountModifier) {
                        discountModifier.children = [];
                    }

                    return;
                }

                receiptItem.detail = receiptItem.detail || {};
                receiptItem.detail.quantity = receiptItem.quantity;

                receiptItem.detail.discount = receiptItem.detail.discount || {};
                receiptItem.detail.discount.percentage = (receiptItem.subtype && receiptItem.subtype.includes('giftcard') && !isPGCDiscountable) ? 0 : percentage;
                receiptItem.detail.discount.type = 'transaction';

                calculateDiscount(receiptItem, 'percentage');

                if (percentage === 0) {
                    receiptItem.visualDiscount = {};
                }


                thisInstance.applyReceiptItemChange(nestedCartItems, receiptItem, adjustments, {
                    skipReceiptRebuild: true,
                    buildForModal: (!!receiptItem.discount && receiptItem.discount.totalAmount < 0),
                    modifyingItem: false,
                    isPGCDiscountable: isPGCDiscountable
                });
            });

            if (rebuildReceiptFn) {
                rebuildReceiptFn();
            }
        };


        return CartBuilderService;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new CartBuilderService();
    } else {
        window.CartBuilderService = new CartBuilderService();
    }
})();
