'use strict';

const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('MobileOrderPatronModalCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$log',
        '$rootScope',
        '$translate',
        '$timeout',
        'CurrentSession',
        'Lucova',
        'LucovaWebSocket',
        'SmbPosService',
        'KdsService',
        'Lookup',
        'Security',
        'Pure',
        'PosStatusService',
        'PosAlertService',
        'Patrons',
        'mobileOrderPatrons',
        'modalType',
        'activeIndex',
        'PREORDER_STATUS',
        'CompanyAttributesService',
        'PreorderService',
        'activeUserPreorderId',
        function (
            $scope,
            $modal,
            $modalInstance,
            $log,
            $rootScope,
            $translate,
            $timeout,
            CurrentSession,
            Lucova,
            LucovaWebSocket,
            SmbPosService,
            KdsService,
            Lookup,
            Security,
            Pure,
            PosStatusService,
            PosAlertService,
            Patrons,
            mobileOrderPatrons,
            modalType,
            activeIndex,
            PREORDER_STATUS,
            CompanyAttributesService,
            PreorderService,
            activeUserPreorderId) {

            $scope.modalType = modalType;
            $scope.patrons = mobileOrderPatrons;
            $scope.activeIndex = activeIndex;

            $scope.countdownEstimate = function (preorder, modalType) {
                var target = preorder.estimated_completion;
                var now = moment();

                var diff = moment.duration(moment.unix(target).diff(now));
                var duration = moment.duration(diff, 'milliseconds');

                if (duration.asSeconds() > 0) {
                    return $translate.instant('mobile.order.modal.btn.countdown', {
                        countdown: moment.utc(duration.asMilliseconds()).format('mm:ss')
                    });
                } else {
                    return $translate.instant('mobile.order.modal.btn.countdown.due');
                }
            };

            // custom order message for 'completed' orders
            // fulfilled orders are seperate from status
            $scope.getStatusMessage = function (order) {
                if (order.fulfilled) {
                    return $translate.instant('mobile.order.modal.status.fulfilled');
                } else if (order.status === 'completed') {
                    return $translate.instant('mobile.order.modal.status.completed', {
                        isDelivery: order.is_delivery
                    });
                } else if (order.status === 'out_for_delivery') {
                    // exact status string needs to be verified
                    return $translate.instant('mobile.order.modal.status.out.for.delivery');
                } else {
                    return $translate.instant('mobile.order.modal.status.other', {
                        status: order.status
                    });
                }
            };

            // Using ng-repeat with $last adds an extra space after the word (e.g. 'Mango Chunks ,')
            $scope.getModifierList = function (modifierGroupList) {
                var nameArr = [];
                _.each(modifierGroupList, (modifierGroup) => {
                    _.each(modifierGroup.children, (modifier) => {
                        nameArr.push(modifier.name);
                    });
                });
                return nameArr.join(', ');
            };

            $scope.getEstimatedTime = function (preorder, modalType) {
                if (modalType === 'NEW') {
                    var estimatedTime = moment().add(preorder.prep_time_sec, 'seconds');
                    return estimatedTime.format('hh:mm a');
                } else {
                    return moment.unix(preorder.estimated_completion).format('hh:mm a');
                }
            };

            $scope.dismiss = function () {
                $modalInstance.dismiss();
            };

            var getLoyaltyPoints = (patron) => {
                if (!patron || !patron.lucovaUser) {
                    return;
                }

                if (!patron.lucovaUser.loyaltyPoints) {
                    Patrons.byPatronKey({'patronKey': patron.lucovaUser.user_name})
                        .$promise.then((response) => {
                            if (response.mealPlans.length) {
                                _.each(response.mealPlans, (mealPlan) => {
                                    if (mealPlan.name === 'loyalty') {
                                        patron.lucovaUser.loyaltyPoints = mealPlan.currentMealPlanBalance;
                                    }
                                });
                            } else {
                                patron.lucovaUser.loyaltyPoints = 0;
                            }
                        }).catch((error) => {
                            patron.lucovaUser.loyaltyPoints = 0;
                        });
                }
            };

            $scope.printMobileOrderReceipt = (preorder) => {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.print.options.tpl.html',
                    controller: 'SmbPosPrintOptionsCtrl',
                    windowClass: 'smb-pos smb-pos-popup',
                    animation: false,
                    backdrop: true,
                    keyboard: false,
                    resolve: {
                        tenderData: function () {
                            return;
                        },
                        tenderResponse: function () {
                            return;
                        },
                        receipt: function () {
                            return;
                        },
                        preorderDetails: function () {
                            return {
                                isPreorder: true,
                                preorder: preorder,
                            };
                        },
                    }
                });
            };

            $scope.toggleChatPanel = function () {
                $scope.settings.chatPanel.shown = !$scope.settings.chatPanel.shown;
            };

            $scope.getReadReceipt = (chatChannel) => {
                if (chatChannel) {
                    // if user_unread === 0, this suggests the user has read the latest message sent.
                    if (chatChannel.user_unread === 0) {
                        var userTimestamp = chatChannel.user_last_read_timestamp;
                        return $translate.instant('mobile.order.modal.chat.read.receipt', {
                            messageRead: true,
                            timeRead: moment.unix(userTimestamp).format('h:mm a')
                        });
                    } else {
                        return $translate.instant('mobile.order.modal.chat.read.receipt', {
                            messageRead: false,
                            timeRead: 0
                        });
                    }
                }
            };

            $scope.showOrderList = false;
            $scope.activeOrderId = activeUserPreorderId;
            $scope.toggleOrderListScreen = function (userPreorderId = '') {
                if (userPreorderId && userPreorderId.length) {
                    $scope.activeOrderId = userPreorderId;
                }

                $scope.showOrderList = !$scope.showOrderList;
            };

            $scope.init = function () {
                if ($scope.activeIndex > $scope.patrons.length) {
                    $log.error('array index out of bounds.');
                    $modalInstance.close();
                } else {
                    var activePatron = $scope.patrons[$scope.activeIndex];

                    getLoyaltyPoints(activePatron);
                    setDeliveryAddress(activePatron);

                    if (activePatron.lucovaUser.preorder.length) {
                        _.each(activePatron.lucovaUser.preorder, (preorder) => {
                            removeExpiredMapImages();

                            if (preorder.chat_enabled) {
                                getPatronChat(preorder);
                                updateReadReceipt(preorder);
                            }
                        });
                    }
                }
            };

            // removes images that are stored for 3 or more hours.
            var removeExpiredMapImages = () => {
                for (var k in localStorage) {
                    if (k.includes('map_')) {
                        var cachedImage = localStorage.getItem(k);
                        var dateStored = moment(JSON.parse(cachedImage).dateStored);
                        var now = moment();
                        // add 3 hours to the dateStored
                        dateStored.add(3, 'h');
                        if (now.isSameOrAfter(dateStored)) {
                            localStorage.removeItem(k);
                        }
                    }
                }
            };

            var updateReadReceipt = (preorder) => {
                if (!preorder || !preorder._id) {
                    return;
                }

                var preorderId = preorder._id;
                Lucova.manager().updateChatAsRead({
                    preorder_id: preorderId
                }, function (response) {
                    getPatronChat(preorder);
                }, function (error) {
                    $log.error(error);
                });

            };

            $scope.getPrintPendingOrders = function () {
                return SmbPosService.getPrintPendingOrders();
            };

            $scope.clearPreorder = function () {
                SmbPosService.clearPreorder();
            };

            $scope.hasSelectedPreorder = function () {
                return SmbPosService.hasSelectedPreorder();
            };

            $scope.hasStatus = function (statuses, preorder) {
                var states = _.filter(statuses, function (status) {
                    if (preorder) {
                        return preorder.status === status;
                    }
                });

                return states.length > 0;
            };

            $scope.hasActiveRequest = false;
            var timeout;

            function acquireLock () {
                if ($scope.hasActiveRequest) {
                    return false;
                }
                $scope.hasActiveRequest = true;
                timeout = setTimeout(function () {
                    timeout = undefined;
                    $scope.hasActiveRequest = false;
                }, 5000);

                return true;
            }

            function unlockButtons () {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = undefined;
                }
                $scope.hasActiveRequest = false;
            }

            $scope.declineOrder = function (declinedOrder) {
                var id = declinedOrder._id;
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }

                Lucova.manager().cancelMobileOrder({}, {
                    preorder_id: id
                }, function (response) {
                    $modalInstance.close();
                }, function (error) {
                    $modalInstance.close();
                });
            };

            $scope.acknowledgeOrder = function (user, acknowledgedOrder) {
                /*
                    user object is used to grab name and photo_url.
                    reason why we are passing in the acknowledgedOrder seperately is because
                    the user object contains a list of preorders and may cause issues in the future
                    if we loop through the list of preorders, even though the preorder
                    list has been spliced to only contain 1 preorder.
                */

                acknowledgedOrder.isAccepted = false;
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }

                LucovaWebSocket.acceptPreorder(acknowledgedOrder, function (response) {
                    acknowledgedOrder.isAccepted = true;
                    $scope.clearPreorder();
                    if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                        var nextPatron = $scope.patrons[$scope.activeIndex + 1];
                        var continueToNextPatron = false;

                        // only continue to next patron if next patron is pending acceptance
                        // this is only applicable to preorder dashboard,
                        // where all order types are mixed/combined based on selected sort column

                        _.each(nextPatron.preorder, (preorder) => {
                            if (preorder.status === 'print_pending') {
                                continueToNextPatron = true;
                            }
                        });

                        if (continueToNextPatron) {
                            $scope.slide('right', true);
                        } else {
                            $modalInstance.close();
                        }

                    } else {
                        $modalInstance.close();
                    }
                }, function (err) {
                    // Commented By Akash Mehta on July 12 2021
                    // We need to deleted the isAccepted field to reset the state of the loading/progress indicator on the UI
                    // which pops up if the value of the field is set to false. If we don't delete the field, the value remains
                    // as false and the UI keeps on showing a spinning indicator even though nothing is being processed

                    delete acknowledgedOrder.isAccepted;
                    if (err && err.code === 100) {
                        PosAlertService.showAlertByName('general', {
                            title: 'mobile.order.printer.error.ttl',
                            message: 'mobile.order.printer.error.noprinter'
                        });
                    } else {
                        $log.error('Failed to accept preorder', err);
                        $modalInstance.close();
                    }
                });

                var currentCompany = CurrentSession.getCompany();
                if (!currentCompany) {
                    $log.error('Failed to get company from CurrentSession when accepting order.');
                    return;
                }

                KdsService.sendPreorderToKds(user, acknowledgedOrder);
            };

            $scope.cancelMobileOrder = function (cancelledOrder, patron) {
                if (!acquireLock()) {
                    return;
                }

                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }

                PosAlertService.showAlertByName('general-alert', {
                    'title': 'general.confirmCancelMobileOrder.ttl',
                    'message': 'general.confirmCancelMobileOrder.msg',
                    'buttonType': 'okcancel',
                    'buttonTitleOk': 'general.yes',
                    'buttonTitleCancel': 'general.no',
                    'keyboard': false,
                    'modalCallback': function () {
                        Lucova.manager().cancelMobileOrder({}, {
                            preorder_id: cancelledOrder._id
                        }, function (response) {
                            unlockButtons();
                            var preorderIndex = SmbPosService.preorders.indexOf(patron);
                            if (preorderIndex > -1) {
                                SmbPosService.preorders.splice(preorderIndex, 1);
                            }
                            cancelledOrder.status = PREORDER_STATUS.CANCELLED;
                            $scope.clearPreorder();
                            SmbPosService.loadLucovaUsers();
                            $scope.printMobileOrderReceipt(cancelledOrder);
                            $modalInstance.close();
                        }, function (error) {
                            unlockButtons();
                        });
                    },
                    'dismissModalCallback': function () {
                        return;
                    }
                });
            };

            $scope.completeMobileOrder = function (completedOrder) {
                completedOrder.isCompleted = false;

                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().completeMobileOrder({}, {
                    preorder_id: completedOrder._id
                }, function (response) {
                    completedOrder.isCompleted = true;
                    SmbPosService.loadLucovaUsers();
                    if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                        $scope.slide('right', true);
                    } else {
                        $modalInstance.close();
                    }
                }, function (error) {
                    $modalInstance.close();
                    $log.error(error);
                });
            };

            // this function is not fully tested at the moment due to status being unknown
            $scope.completeDeliveryOrder = function (deliveryOrder) {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().completeDeliveryOrder({}, {
                    preorder_id: deliveryOrder._id
                }, function (response) {
                    if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                        $scope.slide('right');
                    } else {
                        $modalInstance.close();
                    }
                    $scope.clearPreorder();
                    SmbPosService.loadLucovaUsers();
                }, function (error) {
                    $modalInstance.close();
                    console.log(error);
                });
            };

            $scope.failDeliveryOrder = function (deliveryOrder) {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().failDeliveryOrder({}, {
                    preorder_id: deliveryOrder._id
                }, function (response) {
                    if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                        $scope.slide('right');
                    } else {
                        $modalInstance.close();
                    }
                    $scope.clearPreorder();
                    SmbPosService.loadLucovaUsers();
                }, function (error) {
                    $modalInstance.close();
                    console.log(error);
                });
            };

            $scope.fulfillMobileOrder = function (fulfilledOrder) {
                fulfilledOrder.isFulfilled = false;

                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.mobile.order.offline.description'
                    });
                    return;
                }
                if (!acquireLock()) {
                    return;
                }
                Lucova.manager().fulfillMobileOrder({}, {
                    preorder_id: fulfilledOrder._id
                }, function (response) {
                    fulfilledOrder.isFulfilled = true;

                    if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                        $scope.slide('right', true);
                    } else {
                        $modalInstance.close();
                    }

                    $scope.clearPreorder();
                    SmbPosService.loadLucovaUsers();
                }, function (error) {
                    $modalInstance.close();
                    $log.error(error);
                });
            };

            $scope.showAutoAcceptModal = () => {
                // if user has already indicated to stop showing auto accept modal
                // for the session. this expires after the current session ends (e.g. log out, refresh)
                if (CurrentSession.isNeverShowAutoAcceptModal()) {
                    return;
                }

                // we don't show the auto accept alert modal if:
                // - does not have autoconfirm as a company attribute
                // or
                // - has autoconfirm as a company attribute & the session-based auto confirm flag has also been set to true
                const hasAutoConfirmAttribute = CompanyAttributesService.hasPreorderAutoConfirm();
                const hasAutoConfirmSession = CurrentSession.isAutoAcceptPreorders();
                if (!hasAutoConfirmAttribute || (hasAutoConfirmAttribute && hasAutoConfirmSession)) {
                    return;
                }

                PosAlertService.showAlertByName('general-alert-never', {
                    'title': 'general.autoAcceptPreorders.ttl',
                    'message': 'general.autoAcceptPreorders.msg',
                    'buttonType': 'okCancelNever',
                    'buttonTitleOk': 'general.yes',
                    'buttonTitleCancel': 'general.no',
                    'buttonTitleNever': 'general.no.neverAskAgain',
                    'keyboard': false,
                    'modalCallback': () => {
                        // expires after a session ends (e.g. log out, refresh)
                        CurrentSession.toggleSessionSettings('preorderAutoConfirm', true);
                    },
                    'neverModalCallback': () => {
                        // expires after a session ends (e.g. log out, refresh)
                        CurrentSession.toggleSessionSettings('preorderAutoConfirmNever', true);
                    },
                    'dismissModalCallback': () => {
                        return;
                    }
                });
            };

            const setMapUrl = (preorder) => {
                // do not continue if no delivery details have been set.
                if (!preorder.deliveryDetails && !preorder.deliveryDetails.address) {
                    return;
                }

                $scope.isLoadingMap = true;

                var obj = preorder.deliveryDetails.address;

                let address = obj.address_line_one || '';
                if (preorder.deliveryDetails.isThirdParty) {
                    address += ',' + obj.city;
                } else if (obj.address_line_two) {
                    address += ',' + obj.address_line_two;
                }

                // if an address was not created for whatever reason
                // do not continue.
                if (!address) {
                    $scope.isLoadingMap = false;
                    return;
                }

                /*
                commented by chris july 27th 2020
                due to cost of google static map api as of this date,
                (0.2 cents per image load),
                we store the image in localStorage to only load the image once.
                we store the time it was stored as an expiry check,
                and remove the image data from local storage after approx 3 hours.
                */

                var user = $scope.patrons[$scope.activeIndex].lucovaUser;
                var cachedImage = localStorage.getItem('map_' + user.user_name);

                if (!cachedImage) {
                    Lookup.staticMap({address: address}).$promise.then(function (response) {
                        var image = 'data:image/png;base64,' + response.imageString;
                        var obj = {
                            dateStored: moment(),
                            imageData: image
                        };

                        localStorage.setItem('map_' + user.user_name, JSON.stringify(obj));
                        preorder.deliveryDetails.mapUrl = obj.imageData;
                        $scope.isLoadingMap = false;
                    }).catch((err) => {
                        $scope.isLoadingMap = false;
                    });
                } else {
                    preorder.deliveryDetails.mapUrl = JSON.parse(cachedImage).imageData;
                    $scope.isLoadingMap = false;
                }
            };

            const setDeliveryAddress = (patron) => {
                if (patron && patron.lucovaUser && patron.lucovaUser.preorder && patron.lucovaUser.preorder.length) {
                    patron.lucovaUser.preorder.forEach((preorder) => {
                        // set/clear deliveryDetails
                        preorder.deliveryDetails = {
                            address: null,
                            mapUrl: null
                        };

                        if (preorder.is_delivery) {
                            preorder.deliveryDetails.isThirdParty = !!preorder.is_third_party_preorder;

                            if (preorder.preorder_details) {
                                preorder.deliveryDetails.address = preorder.preorder_details;
                                // set map URL
                                setMapUrl(preorder);
                            }
                        } else {
                            // remove cached details if same patron places another preorder without delivery.
                            const cachedDeliveryDetails = localStorage.getItem('map_' + patron.lucovaUser.user_name);
                            if (cachedDeliveryDetails) {
                                localStorage.removeItem('map_' + patron.lucovaUser.user_name);
                            }
                        }
                    });
                }
            };

            $scope.getPatronPhoto = function (photoUrl) {
                return SmbPosService.getPatronPhoto(photoUrl);
            };

            $scope.getLogoUrl = () => {
                var company = Security.getUser().company;
                if (company.logoUrl) {
                    return 'url(' + Security.getUser().company.logoUrl + ')';
                } else if (company.companyLogoUrl) {
                    return 'url(' + Security.getUser().company.companyLogoUrl + ')';
                } else {
                    // default to nown logo if no logos available.
                    return 'url(' + '../../images/nown-circle.png' + ')';
                }
            };

            var refreshPatron = function (patron) {
                var foundUser = _.find(LucovaWebSocket.getMobileOrderUsers(), function (user) {
                    return user.user_name === patron.lucovaUser.user_name;
                });

                if (foundUser) {
                    // replaces current order with the updated order.
                    patron.lucovaUser = foundUser;
                }
            };

            $scope.slide = function (dir, refresh) {
                if (refresh) {
                    refreshPatron($scope.patrons[$scope.activeIndex]);
                }

                if (dir === 'left') {
                    $scope.activeIndex--;
                } else {
                    $scope.activeIndex++;
                }

                if ($scope.activeIndex > $scope.patrons.length) {
                    $log.error('array index out of bounds.');
                    $modalInstance.close();
                } else {
                    var activePatron = $scope.patrons[$scope.activeIndex];
                    if (activePatron) {
                        // set loyalty points for next/previous patron
                        getLoyaltyPoints(activePatron);
                        setDeliveryAddress(activePatron);

                        if (activePatron.lucovaUser.preorder.length) {
                            _.each(activePatron.lucovaUser.preorder, (preorder) => {

                                if (preorder.chat_enabled) {
                                    updateReadReceipt(preorder);
                                }
                            });
                        }
                    }
                }
            };

            $scope.chatInput = {
                height: 48,
                display: false,
                focus: false,
                text: ''
            };
            var focusOnInput = function () {
                $timeout(function () {
                    // scroll input box into view & focus
                    var inputBar = document.getElementById('chatInputBar');

                    if (inputBar) {
                        inputBar.scrollIntoView({
                            behavior: 'smooth'
                        });
                        inputBar.focus();
                        $scope.chatInput.focus = true;
                    }

                }, 0);
            };

            $scope.toggleChatInput = (boolean) => {
                // set display to param OR if param not passed in, set to opposite.
                $scope.chatInput.display = boolean != null ? boolean : !$scope.chatInput.display;
                if ($scope.chatInput.display) {
                    focusOnInput();
                } else {
                    $scope.chatInput.focus = false;
                }
            };

            // this function is used to auto resize the textarea element for chat input.
            $scope.autoSize = (elementId) => {
                var field = document.getElementById(elementId);
                field.style.height = 'inherit';

                $scope.chatInput.height = field.scrollHeight - 20;
                field.style.height = $scope.chatInput.height + 'px';
            };

            var getPatronChat = (preorder) => {
                if (preorder._id) {
                    // getting chat for current patron
                    var preorderId = preorder._id;
                    Lucova.manager().getPreorderChatById({
                        preorder_id: preorderId
                    }, function (response) {
                        // setting chat channel
                        if (response.chat_channel) {
                            preorder.chat_channel = response.chat_channel;
                        }
                    }, function (error) {
                        $log.error(error);
                    });
                } else {
                    $log.error('preorder does not contain an id.');
                }
            };

            // this evaluates which button to show sending/loading indicator
            $scope.isSending = {
                yes: false,
                no: false,
                custom: false
            };

            $scope.sendMessage = (preorder, message) => {
                if (message != 'custom') {
                    // preset message contains yes or no, this grabs the translation file's
                    // message for the respective message.
                    $scope.chatInput.text = $translate.instant('mobile.order.modal.response.default.' + message);
                }

                // only send if text isn't empty
                if ($scope.chatInput.text) {
                    // this evaluates which button to show sending/loading indicator
                    if (message === 'custom') {
                        $scope.isSending.custom = true;
                    } else {
                        $scope.isSending[message] = true;
                    }

                    // sending chatInput.text across and
                    // clearing it after message is sent.
                    Lucova.manager().sendPreorderChat({
                        preorder_id: preorder._id,
                        message: $scope.chatInput.text
                    }, function (response) {
                        if (message === 'custom') {
                            $scope.isSending.custom = false;
                        } else {
                            $scope.isSending[message] = false;
                        }

                        // hides chat input bar after sending message.
                        $scope.toggleChatInput(false);
                        getPatronChat(preorder);
                        $scope.chatInput.text = '';
                    }, function (error) {
                        $log.error(error);
                    });
                }
            };

            var onMobileOrderCancelled = function (event, payload) {
                // payload = { earliest_ready_time, _id, short_id, status }
                var mobileOrderUser = _.find(SmbPosService.preorders, (patron) => {
                    _.filter(patron.lucovaUser.preorder, (preorder) => {
                        return preorder._id === payload._id;
                    });
                });

                $scope.clearPreorder();
                SmbPosService.loadLucovaUsers();

                if ($scope.activeIndex > $scope.patrons.length) {
                    $log.error('array index out of bounds.');
                    $modalInstance.close();
                } else {
                    var activePatron = $scope.patrons[$scope.activeIndex];
                    if (activePatron
                        && activePatron.lucovaUser
                        && mobileOrderUser
                        && (activePatron.lucovaUser.user_name === mobileOrderUser.lucovaUser.user_name)) {
                        if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                            $scope.slide('right', true);
                        } else {
                            $modalInstance.close();
                        }
                    }
                }
            };

            var onMobileOrderCompleted = function (event, payload) {
                if ($scope.activeIndex > $scope.patrons.length) {
                    $log.error('array index out of bounds.');
                    $modalInstance.close();
                } else {
                    var activePatron = $scope.patrons[$scope.activeIndex];
                    if (activePatron) {
                        _.each(activePatron.lucovaUser.preorder, (preorder) => {
                            if (preorder._id === payload._id) {
                                if ($scope.activeIndex != ($scope.patrons.length - 1)) {
                                    $scope.slide('right', true);
                                } else {
                                    $modalInstance.close();
                                }
                            }
                            $scope.clearPreorder();
                            SmbPosService.loadLucovaUsers();
                        });
                    }
                }
            };

            var onChatUpdate = function (event, payload) {
                if ($scope.activeIndex > $scope.patrons.length) {
                    $log.error('array index out of bounds.');
                    $modalInstance.close();
                } else {
                    var activePatron = $scope.patrons[$scope.activeIndex];
                    if (activePatron) {
                        _.each(activePatron.lucovaUser.preorder, (preorder) => {
                            if (preorder._id === payload.preorder_id) {
                                // refresh chat channel here
                                getPatronChat(preorder);

                                var scrollAnchor = document.getElementById('scrollAnchor');
                                if (scrollAnchor) {
                                    scrollAnchor.scrollIntoView(false);
                                    updateReadReceipt(preorder);
                                }
                            }
                        });
                    }
                }
            };

            var unbindCancelListener = $rootScope.$on('pos.mobileOrder.cancelled', onMobileOrderCancelled);
            var unbindCompleteListener = $rootScope.$on('pos.mobileOrder.completed', onMobileOrderCompleted);
            var unbindChatListener = $rootScope.$on('pos.mobileOrder.modal.chat.update', onChatUpdate);

            $scope.$on('$destroy', function () {
                // Unbind rootScope listeners
                unbindCancelListener();
                unbindCompleteListener();
                unbindChatListener();
            });


            $scope.init();
        }
    ]);
};
