'use strict';

const htmlPrinter = function (printerDetails, screenPrint = false) {
    const DEFAULT_RECEIPT_SIZE = 43;

    var setReceiptSize = function (receiptWidth) {
        return receiptWidth == 2 ? 32 : DEFAULT_RECEIPT_SIZE;
    };

    let paperWidth = setReceiptSize(printerDetails.receiptSize);
    let fontWidth = 9.3333333;

    let content = undefined;
    let pointer = undefined;
    let canvas = undefined;

    let printer = {
        initialize: function () {
            if (content) {
                // Ensure content is removed to prevent memory leak
                content.remove();
            }

            if (canvas) {
                canvas.remove();
            }

            canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = '16px "Roboto Mono", Monaco, "DejaVu Sans Mono", OCR-A, OCR-B, monospace';
            fontWidth = context.measureText('&').width;

            content = document.createElement('div');
            content.style.margin = '0 auto';
            content.style.fontSize = '16px';
            content.style.fontFamily = '"Roboto Mono", Monaco, "DejaVu Sans Mono", OCR-A, OCR-B, monospace';
            content.style.padding = '12px';
            content.style['-webkit-text-size-adjust'] = 'auto'; // somehow by setting this to auto makes the text width much more accurate
            document.getElementsByTagName('body')[0].appendChild(content);

            content.style.width = (paperWidth * fontWidth + 24) + 'px';
            document.getElementsByTagName('body')[0].removeChild(content);

            pointer = content;
            return printer;
        },
        cut: function () {
            // do nothing
            return printer;
        },
        section: function () {
            // do nothing
            return printer;
        },
        align: function (alignment) {
            alignment = alignment || 'left';

            let alignmentDom = document.createElement('div');
            alignmentDom.style.textAlign = alignment;
            content.appendChild(alignmentDom);

            pointer = alignmentDom;

            return printer;
        },
        title: function (elements, emphasis, styles) {
            let data = '';

            elements = elements || '';
            _.each(elements, function (element) {
                data += (element + '');
            });

            data += '\n';
            data = data.replace(/ /g, '&nbsp;');
            data = data.replace(/\n/g, '<br/>');

            styles = styles || {};
            styles.width = styles.width || 1;
            styles.height = styles.height || 2;
            styles.invert = styles.invert || false;

            let dom = document.createElement('div');

            let inner = document.createElement('div');
            inner.innerHTML = data;
            inner.style.display = 'inline-block';
            inner.style.transform = 'scale(' + styles.width + ',' + styles.height + ')';
            inner.style.wordWrap = 'break-word';
            inner.style.width = (100 / styles.width) + '%';

            if (styles.textDecoration) {
                inner.style.textDecoration = styles.textDecoration;
            }

            if (pointer.style.textAlign === 'left') {
                inner.style.transformOrigin = 0;
            }

            inner.style.margin = (styles.height - 1) + 'em 0';

            if (styles.invert) {
                inner.style.backgroundColor = '#000000';
                inner.style.color = '#ffffff';
            }

            if (emphasis) {
                inner.style.fontWeight = '700';
            }

            dom.appendChild(inner);
            pointer.appendChild(dom);

            return printer;
        },
        titleJustified: function (elements, emphasis, styles) {
            // let elementCharCount = 0;
            // _.each(elements, function (element) {
            //     let elem = element + '';
            //     elementCharCount += elem.length;
            // });

            let leftString = elements[0] + '';
            let rightString = elements[1] + '';

            styles = styles || {};
            let textWidth = styles.width || 1;

            let contentCharacterCount = (leftString.length + rightString.length);
            let contentWidth = contentCharacterCount * textWidth;
            let lines = Math.ceil(contentWidth / (paperWidth - 1));
            let spacing = Math.floor(((lines * paperWidth) - contentWidth) / textWidth);

            let newElements = [];
            _.each(elements, function (element, index) {
                let elem = element + '';
                newElements.push(elem);

                if (index < elements.length - 1) {
                    newElements.push(Array(spacing).join(' '));
                }
            });

            printer.title(newElements, emphasis, styles);

            return printer;
        },
        text: function (elements, emphasis, styles) {
            if (!Array.isArray(elements)) {
                elements = [elements];
            }
            elements = elements || '';

            let data = '';
            _.each(elements, function (element) {
                data += (element + '');
            });

            // If text spans more than a line, add line breaks to simulate
            // text wrapping on the receipt;
            if (data.length > paperWidth) {
                let strings = [];
                while (data.length) {
                    strings.push(data.substr(0, paperWidth));
                    data = data.substr(paperWidth);
                }
                data = strings.join('\n');
            }


            data += '\n';
            data = data.replace(/ /g, '&nbsp;');
            data = data.replace(/\n/g, '<br/>');

            styles = styles || {};
            styles.width = styles.width || 1;
            styles.height = styles.height || 1;
            styles.invert = styles.invert || false;

            let dom = document.createElement('div');
            dom.innerHTML = data;
            dom.style.transform = 'scale(' + styles.width + ',' + styles.height + ')';

            if (styles.textDecoration) {
                dom.style.textDecoration = styles.textDecoration;
            }

            if (emphasis) {
                dom.style.fontWeight = '700';
            }

            pointer.appendChild(dom);

            return printer;
        },
        textForNewReciept: function (elements, emphasis, styles) {
            if (!Array.isArray(elements)) {
                elements = [elements];
            }
            elements = elements || '';

            let data = '';
            _.each(elements, function (element) {
                data += (element + '');
            });

            // If text spans more than a line, add line breaks to simulate
            // text wrapping on the receipt;
            if (data.length > paperWidth) {
                let strings = [];
                while (data.length) {
                    strings.push(data.substr(0, paperWidth));
                    data = data.substr(paperWidth);
                }
                data = strings.join('\n');
            }


            data = data.replace(/ /g, '&nbsp;');
            // data = data.replace(/\n/g, '<br/>');
            data += '\n';

            styles = styles || {};
            styles.width = styles.width || 1;
            styles.height = styles.height || 1;
            styles.invert = styles.invert || false;

            let dom = document.createElement('div');
            dom.innerHTML = data;

            if (emphasis) {
                dom.style.fontWeight = '700';
            }

            pointer.appendChild(dom);

            return printer;
        },
        textJustified: function (elements, emphasis, styles, stylesRight, filler) {
            // let elementCharCount = 0;
            // _.each(elements, function (element) {
            //     let elem = element + '';
            //     elementCharCount += elem.length;
            // });
            filler = filler || ' ';

            let leftString = elements[0] + '';
            let rightString = elements[1] + '';

            let lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            let spacing = (lines * paperWidth) - leftString.length - rightString.length;

            let newElements = [];
            _.each(elements, function (element, index) {
                let elem = element + '';
                newElements.push(elem);

                if (index < elements.length - 1) {
                    newElements.push(Array(spacing).join(filler));
                }
            });

            printer.text(newElements, emphasis, styles);

            return printer;
        },
        textJustifiedAndWrapped: function (elements, emphasis, styles, stylesRight, filler) {
            // TODO: add support for more than two elements?

            // let elementCharCount = 0;
            // _.each(elements, function (element) {
            //     let elem = element + '';
            //     elementCharCount += elem.length;
            // });

            filler = filler || ' ';

            let leftString = elements[0] + '';
            let rightString = elements[1] + '';
            let tmpLeftString = leftString;

            let remainingStringLength = paperWidth - rightString.length;

            let leftStringArr = leftString.split(' ');
            let quantityLength = leftStringArr[0].length;
            let stringToAppendOnLeft = '';
            for (let spaceIncrement = 0; spaceIncrement < quantityLength; spaceIncrement++) {
                stringToAppendOnLeft += ' ';
            }
            stringToAppendOnLeft += '   ';

            let stringToAppendOnRight = '';
            for (let spaceIncrementsa = 0; spaceIncrementsa < rightString.length; spaceIncrementsa++) {
                stringToAppendOnRight += ' ';
            }
            stringToAppendOnRight = filler + stringToAppendOnRight;

            let lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            let spacing = (lines * paperWidth) - leftString.length - rightString.length;

            let newLeftString = '';
            if (leftString.length > remainingStringLength) {
                let counter = 0;
                while ((tmpLeftString.length + stringToAppendOnLeft.length + filler.length)
                    > remainingStringLength) {
                    if (counter > 0) {
                        newLeftString += stringToAppendOnLeft;
                        newLeftString += tmpLeftString.substr(0, (remainingStringLength
                            - stringToAppendOnLeft.length - filler.length));
                        newLeftString += stringToAppendOnRight;
                        tmpLeftString = tmpLeftString.substr(remainingStringLength
                            - stringToAppendOnLeft.length - filler.length);
                    } else {
                        newLeftString += tmpLeftString.substr(0, (remainingStringLength - 1));
                        newLeftString += filler + rightString;
                        tmpLeftString = tmpLeftString.substr(remainingStringLength - 1);
                    }
                    counter++;
                }

                if (counter > 0) {
                    newLeftString += stringToAppendOnLeft;
                }
                newLeftString += tmpLeftString;
                /* let newSpacing = remainingStringLength - tmpLeftString.length - stringToAppendOnLeft.length + 1;

                let adsdsd = Array(newSpacing).join(filler);*/
                // let newRightString = adsdsd + rightString;
                leftString = newLeftString;
                rightString = '';
                elements[0] = leftString;
                elements[1] = rightString;
            }


            /* let lines = Math.ceil((leftString.length + rightString.length) / (paperWidth - 1));
            lines = Math.max(1, lines);
            // let spacing = (lines * paperWidth) - leftString.length - rightString.length;*/

            let newElements = [];
            _.each(elements, function (element, index) {
                let elem = element + '';
                newElements.push(elem);

                if (leftString.length <= remainingStringLength) {
                    if (index < elements.length - 1) {
                        newElements.push(Array(spacing).join(filler));
                    }
                }
            });

            printer.textForNewReciept(newElements, emphasis, styles, filler);

            return printer;
        },
        newLine: function (count) {
            count = (count || 1) + 1; // the first <br/> seems to be ignored all the time?

            let data = Array(count).join('<br/>');
            let dom = document.createElement('div');
            dom.innerHTML = data;

            pointer.appendChild(dom);

            return printer;
        },
        divider: function (thin) {
            let borderColor = (thin) ? '#999999' : '#333333';

            let dom = document.createElement('div');
            dom.style.borderBottom = '2px solid ' + borderColor;

            pointer.appendChild(document.createElement('br'));
            pointer.appendChild(dom);
            pointer.appendChild(document.createElement('br'));

            return printer;
        },
        createQrCodeElement: function (data) {
            printer.textJustified(['QR CODE : ', data]);

            return printer;
        },
        createImageElement: function (data) {
            const dom = document.createElement('img');
            dom.src = data;
            pointer.appendChild(dom);

            return printer;
        },
        output: function () {
            if (screenPrint) {
                printer.align('center').text('-------- Printer Config ---------');
                for (const key in printerDetails) {
                    if (Object.prototype.hasOwnProperty.call(printerDetails, key)) {
                        printer.textJustifiedAndWrapped([key + ': ', printerDetails[key]]);
                    }
                }
            }

            return content.outerHTML;
        }
    };

    return printer;
};

const screenPrinter = function (printerDetails) {
    let printer = htmlPrinter(printerDetails, true);
    let htmlPrinterOutput = printer.output;

    printer.output = function () {
        let htmlText = htmlPrinterOutput();
        let newWindow = window.open('', '_blank', 'width=600, height=400');
        let newDocument = newWindow.document;
        newDocument.open();
        newDocument.write(htmlText);
    };

    return printer;
};

module.exports = {
    htmlPrinter: htmlPrinter,
    screenPrinter: screenPrinter
};
