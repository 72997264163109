'use strict';

const convert = require('xml-js');

const BaseCardTerminal = require('./base.cardterminal');
const TerminalFixtures = require('./fixtures/terminal.common');
const Pure = require('../../external/pure');

const CRLF = '\r\n';
const CLIENT_NAME = 'NOWNPOS';
const CLIENT_VERSION = 'V1.0';

const _requestBuilder = function (payload, config) {
    const REQUEST_LINE = `POST ${config.ip}:${config.port} HTTP/1.1${CRLF}`;

    payload = payload.join('');
    let requestHeaders = 'Host: ' + config.ip + CRLF
        + 'Content-Type: text/xml' + CRLF
        + 'Content-Length: ' + payload.length + CRLF;

    return `${REQUEST_LINE}${requestHeaders}${CRLF}${payload}${CRLF}`;
};

const _retriveAllData = function (rawBytes, session, resolve) {
    const data = rawBytes.toString();
    const splitData = data.split(CRLF.repeat(2));
    // parse http response for headers
    if (splitData.length > 1) {
        const headerList = splitData[0].split(CRLF);

        for (const header of headerList) {
            const headerDetails = header.split(':');
            if (headerDetails.length > 1) {
                session.parsedHttpResponse.headers[headerDetails[0]] = headerDetails[1].trim();
            } else {
                session.parsedHttpResponse.headers['Status'] = headerDetails[0].trim();
            }
        }
    }

    session.completeMessage += data;
    const completeResponseBody = session.completeMessage.split(CRLF.repeat(2))[1];
    if (session.parsedHttpResponse.headers['Content-Length'] == completeResponseBody.length) {
        session.parsedHttpResponse.body = completeResponseBody;
        resolve(session);
    }
};

const _transformReceiptText = function (receiptText) {
    let transformedReceiptInfo = [];
    if (!receiptText) {
        return transformedReceiptInfo;
    }

    for (const line of receiptText.split('\n')) {
        const lineData = line.trim().split(':');
        let el = {
            format: ['center'],
            data: ['', '']
        };

        if (lineData.length > 1) {
            el.format[0] = 'justify';
            el.data[0] = lineData[0].trim() + ':';
            el.data[1] = lineData[1].trim();
        } else {
            el.data[0] = lineData[0].trim();
        }

        transformedReceiptInfo.push(el);
    }

    // remove trailing empty arrays, we might need empty entries in between to separate data as needed
    for (let i = transformedReceiptInfo.length - 1; i != 0; i--) {
        if (!transformedReceiptInfo[i].data[0] && !transformedReceiptInfo[i].data[1]) {
            transformedReceiptInfo.pop();
        } else {
            break;
        }
    }

    return transformedReceiptInfo;
};

const FreedomPayMiddleWare = function () {
    const _self = this;
    BaseCardTerminal.call(_self);
    // Freedom Commerce Connect - related info
    const FCC_DATA = {
        REQ_TYPE: {
            SALE: 'Sale',
            VOID: 'Void',
            REFUND: 'Refund',
            CANCEL: 'Cancel',
            REPLAY_STATUS: 'GetReplayStatus',
            HEARTBEAT: 'Heartbeat',
            ABORT: 'Abort'
        },
        ERROR_CODE: {
            LANE_TIMEOUT: ['3001', '3030', '3128', '151', '250'],
            POS_ABORT: '3033',
            OFFLINE_ACCEPTED: '3021'
        },
        DECISION: {
            ACCEPTED: 'A',
            REJECTED: 'R',
            FAILED: 'F',
            ERROR: 'E'
        },
        CARD_ENTRY_MODE: {
            keyed: 'MANUAL',
            swiped: 'SWIPE',
            icc: 'INSERT',
            ricc: 'CONTACTLESS',
            scanned: 'QR_CODE'
        },
        ITEM_LEVEL__SALECODE: {
            SALE: 'S',
            RETURN: 'R'
        },
        messageType: {
            sale: function (config, amountInDollars, {receiptItems = [], transactionUuid = '', totalTaxes = 0} = {}) {
                let items = [];
                for (let item of receiptItems) {
                    let itemName = item.name ? String.prototype.replace.call(item.name, /([^A-z0-9\s])/g, '').slice(0, 35) : ''; // 35 char max limit

                    items.push('<Item>'
                        + '<fp:productSKU>' + item.locationServicePeriodMenuId + '</fp:productSKU>'
                        + '<fp:productName>' + itemName + '</fp:productName>'
                        + '<fp:productDescription>' + itemName + '</fp:productDescription>'
                        + '<fp:quantity>' + item.quantity + '</fp:quantity>'
                        + '<fp:saleCode>' + FCC_DATA.ITEM_LEVEL__SALECODE.SALE + '</fp:saleCode>'
                        + '<fp:unitPrice>' + item.itemPrice + '</fp:unitPrice>'
                        + '<fp:taxAmount>' + item.itemTaxAmount + '</fp:taxAmount>'
                        + '<fp:totalAmount>' + item.total + '</fp:totalAmount>'
                    + '</Item>');
                }

                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.SALE + '</RequestType>',
                        '<StoreId>' + config.merchantId + '</StoreId>',
                        '<TerminalId>' + config.deviceId + '</TerminalId>',
                        '<MerchantReferenceCode>' + transactionUuid + '</MerchantReferenceCode>',
                        '<ClientEnvironment>' + CLIENT_NAME + '-' + CLIENT_VERSION + '</ClientEnvironment>',
                        '<InvoiceNumber>' + Pure.randomStringGenerator(20) + '</InvoiceNumber>',
                        '<ChargeAmount>' + amountInDollars + '</ChargeAmount>',
                        '<TaxAmount>' + totalTaxes + '</TaxAmount>',
                        '<Items>' + items.join('') + '</Items>',
                        '<SellingSystemMiddlewareName>' + CLIENT_NAME + '</SellingSystemMiddlewareName>',
                        '<SellingSystemMiddlewareVersion>' + CLIENT_VERSION + '</SellingSystemMiddlewareVersion>',
                        '<SellingSystemName>' + CLIENT_NAME + '</SellingSystemName>',
                        '<SellingSystemVersion>' + CLIENT_VERSION + '</SellingSystemVersion>',
                    '</POSRequest>'
                ];

                return command;
            },
            void: function (config, terminalTrxId, {transaction, tenderToVoid = {}} = {}) {
                let originalSaleInvoiceNum = '';

                try {
                    const xml2JsonObj = convert.xml2js(JSON.parse(tenderToVoid.terminalResponse), {compact: true});
                    originalSaleInvoiceNum = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Request', 'POSRequest', 'InvoiceNumber'], '_text');
                } catch (err) {
                    console.log('[creditVoid] Error while getting originalSaleInvoiceNum: ', err);
                }

                if (!originalSaleInvoiceNum.length) {
                    originalSaleInvoiceNum = Pure.randomStringGenerator(20);
                }

                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.VOID + '</RequestType>',
                        '<StoreId>' + config.merchantId + '</StoreId>',
                        '<TerminalId>' + config.deviceId + '</TerminalId>',
                        '<MerchantReferenceCode>' + Pure.generateUuid() + '</MerchantReferenceCode>',
                        '<ClientEnvironment>' + CLIENT_NAME + '-' + CLIENT_VERSION + '</ClientEnvironment>',
                        '<InvoiceNumber>' + originalSaleInvoiceNum + '</InvoiceNumber>',
                        '<RequestId>' + terminalTrxId + '</RequestId>',
                        '<ChargeAmount>' + transaction.transactionTotal + '</ChargeAmount>',
                        '<SellingSystemMiddlewareName>' + CLIENT_NAME + '</SellingSystemMiddlewareName>',
                        '<SellingSystemMiddlewareVersion>' + CLIENT_VERSION + '</SellingSystemMiddlewareVersion>',
                        '<SellingSystemName>' + CLIENT_NAME + '</SellingSystemName>',
                        '<SellingSystemVersion>' + CLIENT_VERSION + '</SellingSystemVersion>',
                    '</POSRequest>'
                ];

                return command;
            },
            refund: function (config, amountInDollars, {transaction}) {
                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.REFUND + '</RequestType>',
                        '<StoreId>' + config.merchantId + '</StoreId>',
                        '<TerminalId>' + config.deviceId + '</TerminalId>',
                        '<MerchantReferenceCode>' + transaction.transactionUuid + '</MerchantReferenceCode>',
                        '<ClientEnvironment>' + CLIENT_NAME + '-' + CLIENT_VERSION + '</ClientEnvironment>',
                        '<InvoiceNumber>' + Pure.randomStringGenerator(20) + '</InvoiceNumber>',
                        '<ChargeAmount>' + amountInDollars + '</ChargeAmount>',
                        '<SellingSystemMiddlewareName>' + CLIENT_NAME + '</SellingSystemMiddlewareName>',
                        '<SellingSystemMiddlewareVersion>' + CLIENT_VERSION + '</SellingSystemMiddlewareVersion>',
                        '<SellingSystemName>' + CLIENT_NAME + '</SellingSystemName>',
                        '<SellingSystemVersion>' + CLIENT_VERSION + '</SellingSystemVersion>',
                    '</POSRequest>'
                ];

                return command;
            },
            replayStatus: function (config, lastParsedResponse) {
                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.REPLAY_STATUS + '</RequestType>',
                        '<RequestId>' + lastParsedResponse.transactionId + '</RequestId>',
                    '</POSRequest>'
                ];

                return command;
            },
            cancel: function (payloadToCancel) {
                let payloadToCancelCopy = payloadToCancel.slice();
                for (let i = 0; i < payloadToCancelCopy.length - 1; i++) {
                    const parsedLineData = payloadToCancelCopy[i].split(/<|>|[</]/g).filter((e) => e);
                    if (parsedLineData.length == 3 && parsedLineData[0] == 'RequestType') {
                        // Entire payload will remain same but functionCode changes
                        // replace original command with 'Cancel'
                        payloadToCancelCopy[i] = '<RequestType>' + FCC_DATA.REQ_TYPE.CANCEL + '</RequestType>';
                        break;
                    }
                }

                return payloadToCancelCopy;
            },
            heartBeat: function () {
                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.HEARTBEAT + '</RequestType>',
                    '</POSRequest>'
                ];

                return command;
            },
            abort: function () {
                const command = [
                    '<?xml version="1.0" encoding="utf-8"?>',
                    '<POSRequest xmlns:fp="http://freeway.freedompay.com/">',
                        '<RequestType>' + FCC_DATA.REQ_TYPE.ABORT + '</RequestType>',
                    '</POSRequest>'
                ];

                return command;
            },
        },
    };

    _self.getFCCContext = function () {
        return FCC_DATA;
    };

    let connection = {
        clientSocket: null,
        requestQueue: []
    };
    _self.sendRequest = function (config, payload = [], {onDataCallback = null, onErrorCallback = null} = {}) {
        const httpRequest = _requestBuilder(payload, config);

        return new Promise(function (resolve, reject) {
            let client = connection.clientSocket;
            if (!client) {
                const net = nodeRequire('net');

                client = new net.Socket();
                connection.terminalSession = {requestPayload: '', completeMessage: '', parsedHttpResponse: {headers: {}, body: ''}};

                client.connect({port: config.port, host: config.ip}, function () {
                    console.log('[fcc-win][connect] connection successful!!');
                    client.setTimeout(180000);
                });

                client.on('data', function (rawBytes) {
                    if (onDataCallback) {
                        onDataCallback(rawBytes, connection.terminalSession, resolve);
                    }
                });

                client.on('error', function (err) {
                    console.error('[fcc-win][error] there was an error:', err);
                    if (onErrorCallback) {
                        onErrorCallback();
                    }

                    client = null;
                    reject(err);
                });

                client.on('timeout', function () {
                    if (onErrorCallback) {
                        onErrorCallback();
                    }

                    reject({timedOut: true});
                });

                client.on('end', function () {
                    client = null;
                });

                client.on('close', function (hadErrors) {
                    console.log('[fcc-win][close] had any transmission errors!!', hadErrors);
                    client = null;

                    if (connection.requestQueue.length > 0) {
                        const earliestRequest = connection.requestQueue[0];
                        connection.requestQueue = connection.requestQueue.slice(1);

                        _self.sendRequest(earliestRequest.config, earliestRequest.payload, {onDataCallback: earliestRequest.onDataCallback, onErrorCallback: earliestRequest.onErrorCallback});
                    }
                });

                client.write(Buffer.from(httpRequest));
                connection.terminalSession.requestPayload = payload.join('');
            } else {
                connection.requestQueue.push({
                    config: config,
                    payload: payload,
                    onDataCallback: onDataCallback,
                    onErrorCallback: onErrorCallback
                });
            }
        });
    };

};

// BEGIN: static variables/methods
// angularjs: resolve minify issue by injecting dependency names in order
if (!Object.getOwnPropertyDescriptor(FreedomPayMiddleWare, '$inject')) {
    Object.defineProperty(FreedomPayMiddleWare, 'type', {
        enumerable: true,
        value: []
    });
}
// END: static variables/methods

FreedomPayMiddleWare.prototype.parseResponse = function (rawResponseBody) {
    const FCC_CONTEXT = this.getFCCContext();
    let xml2JsonObj = convert.xml2js(rawResponseBody, {compact: true});
    let baseReponse = Object.assign({}, TerminalFixtures.BASE_RESPONSE);

    // assign or override baseResponse values here
    baseReponse.paymentProcessor = TerminalFixtures.CARD_TERMINALS.FREEDOMPAY_MIDDLEWARE;

    // Transaction Details
    baseReponse.success = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'Decision'], '_text') == FCC_CONTEXT.DECISION.ACCEPTED;
    baseReponse.transactionId = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'RequestId'], '_text') || '';
    baseReponse.referenceNumber = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'TransactionId'], '_text') || '';
    baseReponse.authorizationNumber = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ApprovalCode'], '_text') || '';
    baseReponse.approvedAmount = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ApprovedAmount'], '_text') || '';
    baseReponse.tipAmount = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'TipAmount'], '_text') || baseReponse.tipAmount;
    baseReponse.processorTimeout = FCC_CONTEXT.ERROR_CODE.LANE_TIMEOUT.includes(BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ErrorCode'], '_text')) || false;
    baseReponse.partiallyApproved = (baseReponse.success && baseReponse.approvedAmount != '0' && baseReponse.approvedAmount < BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ChargeAmount'], '_text'));
    baseReponse.errorCode = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ErrorCode'], '_text') || '';

    // Card Details
    baseReponse.cardName = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'NameOnCard'], '_text') || baseReponse.cardName;
    baseReponse.cardType = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'IssuerName']) || baseReponse.cardType;
    baseReponse.maskedCardNumber = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'MaskedCardNumber'], '_text') || baseReponse.maskedCardNumber;
    baseReponse.cardNumber = baseReponse.maskedCardNumber;
    baseReponse.cardEntryMethod = FCC_CONTEXT.CARD_ENTRY_MODE[BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'EntryMode'], '_text')] || baseReponse.cardEntryMethod;
    baseReponse.isCredit = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'CardType'], '_text') == 'credit' || true;
    baseReponse.isDebit = !baseReponse.isCredit;

    // [Additional] Card Holder Action Details
    baseReponse.verifiedByPin = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'PinVerified'], '_text') == true || baseReponse.verifiedByPin;
    baseReponse.showCustomerSignatureLine = BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'SignatureRequired'], '_text') == true || baseReponse.showCustomerSignatureLine;

    // Receipt Details
    baseReponse.customerCopy = _transformReceiptText(BaseCardTerminal.extractXml(xml2JsonObj, ['Log', 'Response', 'POSResponse', 'ReceiptText'], '_text'));
    baseReponse.merchantCopy = baseReponse.customerCopy;

    return baseReponse;
};

FreedomPayMiddleWare.prototype.creditSale = function (amountInDollars = 0, extraInfo = {}) {
    const _self = this;
    const FCC_CONTEXT = _self.getFCCContext();
    const salePayload = FCC_CONTEXT.messageType.sale(_self.config, amountInDollars, extraInfo);

    return new Promise(async function (resolve, reject) {
        _self.sendRequest(_self.config, salePayload, {onDataCallback: _retriveAllData}).then(function (response) {
            const logToSave = '<Log>'
                    + '<Request>' + response.requestPayload + '</Request>'
                    + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                + '</Log>';
            const transformedResponse = _self.parseResponse(logToSave);

            if (transformedResponse.success && transformedResponse.errorCode != FCC_CONTEXT.ERROR_CODE.OFFLINE_ACCEPTED) {
                resolve([transformedResponse, logToSave]);
            } else {
                reject({timedOut: transformedResponse.processorTimeout, data: [transformedResponse, logToSave]});
            }
        }).catch(function (error) {
            if (error.timedOut) {
                // POS connection to terminal timedout so cancel previous request and reject original promise
                // *** NOTE: At this point even if original sale was approved it will be marked for 'REVERSAL' on batch close
                const cancelPayload = FCC_CONTEXT.messageType.cancel(salePayload);
                _self.sendRequest(_self.config, cancelPayload, {onDataCallback: _retriveAllData}).then(function (response) {
                    const logToSave = '<Log>'
                        + '<Request>' + salePayload.join('') + response.requestPayload + '</Request>'
                        + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                    + '</Log>';
                    const transformedResponse = _self.parseResponse(logToSave);
                    reject({data: [transformedResponse, logToSave]});
                }).catch(function (err) {
                    reject({});
                });
            } else {
                console.log('[fcc-win] creditsale error:', error);
                reject({});
            }
        });
    });
};

FreedomPayMiddleWare.prototype.debitSale = function (amountInDollars = 0, extraInfo = {}) {
    return this.creditSale(amountInDollars, extraInfo);
};

FreedomPayMiddleWare.prototype.creditVoid = function (terminalTrxId, transactionObj) {
    const _self = this;
    const FCC_CONTEXT = _self.getFCCContext();
    const voidPayload = FCC_CONTEXT.messageType.void(_self.config, terminalTrxId, transactionObj);

    return new Promise(function (resolve, reject) {
        _self.sendRequest(_self.config, voidPayload, {onDataCallback: _retriveAllData}).then(function (response) {
            const logToSave = '<Log>'
                    + '<Request>' + response.requestPayload + '</Request>'
                    + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                + '</Log>';
            const transformedResponse = _self.parseResponse(logToSave);
            if (transformedResponse.success) {
                resolve([transformedResponse, logToSave]);
            } else {
                reject({timedOut: transformedResponse.processorTimeout, data: [transformedResponse, logToSave]});
            }
        }).catch(function (error) {
            if (error.timedOut) {
                // POS connection to terminal timedout so cancel previous request and reject original promise
                // *** NOTE: At this point even if original sale was approved it will be marked for 'REVERSAL' on batch close
                const cancelPayload = FCC_CONTEXT.messageType.cancel(voidPayload);
                _self.sendRequest(_self.config, cancelPayload, {onDataCallback: _retriveAllData}).then(function (response) {
                    const logToSave = '<Log>'
                        + '<Request>' + voidPayload.join('') + response.requestPayload + '</Request>'
                        + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                    + '</Log>';
                    const transformedResponse = _self.parseResponse(logToSave);
                    reject({data: [transformedResponse, logToSave]});
                }).catch(function (err) {
                    reject({});
                });
            } else {
                console.log('[fcc-win] creditvoid error:', error);
                reject({});
            }
        });
    });
};

FreedomPayMiddleWare.prototype.creditReturn = function (amountInDollars, transactionObj) {
    const _self = this;
    const FCC_CONTEXT = _self.getFCCContext();
    const returnPayload = FCC_CONTEXT.messageType.refund(_self.config, amountInDollars, transactionObj);

    return new Promise(function (resolve, reject) {
        _self.sendRequest(_self.config, returnPayload, {onDataCallback: _retriveAllData}).then(function (response) {
            const logToSave = '<Log>'
                    + '<Request>' + response.requestPayload + '</Request>'
                    + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                + '</Log>';
            const transformedResponse = _self.parseResponse(logToSave);
            if (transformedResponse.success) {
                resolve([transformedResponse, logToSave]);
            } else {
                reject({timedOut: transformedResponse.processorTimeout, data: [transformedResponse, logToSave]});
            }
        }).catch(function (error) {
            if (error.timedOut) {
                // POS connection to terminal timedout so cancel previous request and reject original promise
                // *** NOTE: At this point even if original sale was approved it will be marked for 'REVERSAL' on batch close
                const cancelPayload = FCC_CONTEXT.messageType.cancel(returnPayload);
                _self.sendRequest(_self.config, cancelPayload, {onDataCallback: _retriveAllData}).then(function (response) {
                    const logToSave = '<Log>'
                        + '<Request>' + returnPayload.join('') + response.requestPayload + '</Request>'
                        + '<Response>' + response.parsedHttpResponse.body + '</Response>'
                    + '</Log>';
                    const transformedResponse = _self.parseResponse(logToSave);
                    reject({data: [transformedResponse, logToSave]});
                }).catch(function (err) {
                    console.log('[fcc-win] creditReturn error:', err);
                    reject({});
                });
            } else {
                console.log('[fcc-win] creditreturn error:', error);
                reject({});
            }
        });
    });
};

FreedomPayMiddleWare.prototype.lastTransactionStatus = function (lastTender) {
    const _self = this;
    const FCC_CONTEXT = _self.getFCCContext();
    const lastParsedResponse = lastTender.receiptFields[0];
    const payload = FCC_CONTEXT.messageType.replayStatus(_self.config, lastParsedResponse);

    return new Promise(function (resolve, reject) {
        _self.sendRequest(_self.config, payload, {onDataCallback: _retriveAllData}).then(function (response) {
            const logToSave = '<Log>'
                + '<Request>' + response.requestPayload + '</Request>'
                + '<Response>' + response.parsedHttpResponse.body + '</Response>'
            + '</Log>';
            const transformedResponse = _self.parseResponse(logToSave);
            resolve([transformedResponse, logToSave]);
        }).catch(function (error) {
            console.log('[fcc-win] lastTransactionStatus error:', error);
            reject({});
        });
    });
};

FreedomPayMiddleWare.prototype.isCancellableFromPos = function () {
    return true;
};

FreedomPayMiddleWare.prototype.cancelFromPos = function () {
    const _self = this;
    const FCC_CONTEXT = _self.getFCCContext();
    const payload = FCC_CONTEXT.messageType.abort();

    return new Promise(function (resolve, reject) {
        _self.sendRequest(_self.config, payload, {onDataCallback: _retriveAllData}).then(function (response) {
            const logToSave = '<Log>'
                + '<Request>' + response.requestPayload + '</Request>'
                + '<Response>' + response.parsedHttpResponse.body + '</Response>'
            + '</Log>';
            const transformedResponse = _self.parseResponse(logToSave);
            if (FCC_CONTEXT.ERROR_CODE.POS_ABORT == transformedResponse.errorCode) {
                resolve([transformedResponse, logToSave]);
            } else {
                reject({data: [transformedResponse, logToSave]});
            }
        }).catch(function (error) {
            console.log('[fcc-win] cancelFromPos error:', error);
            reject({});
        });
    });
};

Object.setPrototypeOf(FreedomPayMiddleWare.prototype, BaseCardTerminal.prototype);
Object.freeze(FreedomPayMiddleWare);

module.exports = FreedomPayMiddleWare;
