'use strict';


module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosPrintOptionsCtrl', [
        '$scope',
        'PrintService',
        'PrintType',
        'tenderData',
        'tenderResponse',
        'receipt',
        'GuestLabelService',
        'CurrentSession',
        'SmbPosService',
        'SharedDataService',
        'CompanyAttributesService',
        'Platform',
        'preorderDetails',
        'EventListener',
        function (
            $scope,
            PrintService,
            PrintType,
            tenderData,
            tenderResponse,
            receipt,
            GuestLabelService,
            CurrentSession,
            SmbPosService,
            SharedDataService,
            CompanyAttributesService,
            Platform,
            preorderDetails,
            EventListener) {

            $scope.PrintType = PrintType;
            $scope.canPrintGiftReceipt = CurrentSession.getCompany().attributes['receipt__has_gift_receipt'] === 'true';
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.isPreorder = (preorderDetails.isPreorder === true);

            $scope.showKitchenReceipts = function () {
                var posPrinters = SharedDataService.posStation.posPrinters;
                var kitchenPrinters = _.find(posPrinters, function (posPrinter) {
                    return posPrinter.kitchenPrinter === true;
                });
                return kitchenPrinters && !CompanyAttributesService.hasRetailMenu();
            };

            $scope.printReceipt = function (printType) {
                // Set patron name to guest label if transaction is completed by guest
                if (!tenderResponse.patronName && GuestLabelService.getGuestLabel()) {
                    tenderResponse.patronName = GuestLabelService.getGuestLabel();
                }
                var creditCardTenders = _.where(tenderData.allTenders, {type: 'card'});

                var creditCardResponses = [];
                _.each(creditCardTenders, function (tender) {
                    if (tender.receiptFields && tender.receiptFields.length) {
                        creditCardResponses = creditCardResponses.concat(tender.receiptFields);
                    }
                });

                PrintService.printReceipt(
                    tenderResponse,
                    receipt,
                    creditCardResponses,
                    false,
                    printType
                );
            };

            $scope.printGiftReceiptV2 = function () {
                PrintService.printGiftReceiptV2(
                    tenderResponse,
                    receipt,
                    []
                );
            };

            $scope.printKitchenSheets = function () {
                if ($scope.isPreorder && Object.keys(preorderDetails.preorder).length > 0) {
                    let receiptDetails = EventListener.generatePreorderReceiptData(preorderDetails.preorder);
                    SmbPosService.printKitchenReceipts(receiptDetails.receiptItems, receiptDetails.data, {buzzerCode: null, isPreorder: true});
                } else {
                    var transactionDetail = angular.copy(tenderResponse);
                    SmbPosService.printKitchenReceipts(receipt, transactionDetail);
                }
            };

            $scope.printMobileOrderReceipt = function () {
                if ($scope.isPreorder && Object.keys(preorderDetails.preorder).length > 0) {
                    let receiptDetails = EventListener.generatePreorderReceiptData(preorderDetails.preorder);

                    PrintService.printMobileOrderReceipt(receiptDetails.data, receiptDetails.receiptItems, function (response) {
                        // do nothing
                    }, function (err) {
                        console.error('[printMobileOrderReceipt] Error occured while trying to print mobile receipt', err);
                    });
                }
            };
        }
    ]);
};
