'use strict';


module.exports = function (rules) {

    var rule = {
        name: 'ca_on_prepared_goods_quantity',
        enableTag: 'has_prepared_goods',
        key: 'CA_ON_PREPARED_GOODS_QUANTITY',
        canEnable: function (company, location) {
            return /* company.businessAddress.country === 'Canada' && */ (!!location && (location.province === 'ON' || location.region === 'ON'));
        },
        isEnabled: function (company, location) {
            return this.canEnable(company, location) && (company.attributes[this.enableTag] && company.attributes[this.enableTag] === 'true');
        },
        run: function (company, location, receipt) {
            if (this.isEnabled(company, location)) {
                // Commented By Akash Mehta on November 12 2020
                // All modifiers (eligible for prepared goods or not) are automatically eligible
                // for prepared goods if the main/top/root item is eligible for prepared goods tax and is not fully discounted
                // This list inclueds : modifiers, negative modifiers as well as discounts
                //
                // Commented By Akash Mehta on April 20 2021
                // If the root item is fully discounted (for e.g. when paid by a fahrenheit loyalty plan like an americano loyalty plan)
                // it should no longer be eligible for tax rule redemption
                receipt.forEach(function (listItem) {
                    if (listItem.level > 0) {
                        var rootItem = receipt.find((receiptItem) => receiptItem.index == listItem.index && receiptItem.level == 0);
                        if (rootItem.taxRules && rootItem.taxRules[rule.name]) {
                            listItem.taxRules = listItem.taxRules || {};
                            if (rootItem.isFullyDiscounted) {
                                listItem.taxRules[rule.name] = 'false';
                            } else if (rootItem.taxRules[rule.name] === 'true') {
                                listItem.taxRules[rule.name] = 'true';
                            }
                        }
                    } else if (listItem.level == 0) {
                        if (listItem.taxRules && listItem.taxRules[rule.name] === 'true' && listItem.isFullyDiscounted) {
                            listItem.taxRules[rule.name] = 'false';
                        }
                    }
                });
            }
        }
    };

    rules.CA_ON_PREPARED_GOODS_QUANTITY = rule;
};
