'use strict';

const moment = require('moment');
const messagingJson = require('./data/messaging.json');
const LoyaltyCollection = require('../../external/loyalty/collection.js');
const Decimal = require('decimal.js').default;
const PrintHelper = require('../../electron/printer/print.helper.js');
const Controller = new (require('../../external/pos.controller.js'))();

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.service('SmbPosService', [
        '$filter',
        '$http',
        '$log',
        '$modal',
        '$q',
        '$timeout',
        '$rootScope',
        '$translate',
        'CashierShift',
        'CurrentSession',
        'CompanyAttributesService',
        'CommonOfflineCache',
        'DTO_WHITELIST',
        'EnvConfig',
        'Locations',
        'Lookup',
        'Lucova',
        'LucovaBluetooth',
        'LucovaWebSocket',
        'Patrons',
        'PosStatusService',
        'PosAlertService',
        'PrintService',
        'PrintStatusService',
        'Pure',
        'Settings',
        'Reports',
        'SharedDataService',
        'SharedFunctionService',
        'Users',
        'ReceiptBuilderService',
        'PrintType',
        'PrintReceiptType',
        'OfflineShift',
        'GatewayFiit',
        'CartBuilderService',
        'Menu',
        'Inventory',
        'PRINTOUT_TYPE',
        'Organization',
        'PreorderService',
        function (
            $filter,
            $http,
            $log,
            $modal,
            $q,
            $timeout,
            $rootScope,
            $translate,
            CashierShift,
            CurrentSession,
            CompanyAttributesService,
            CommonOfflineCache,
            DTO_WHITELIST,
            EnvConfig,
            Locations,
            Lookup,
            Lucova,
            LucovaBluetooth,
            LucovaWebSocket,
            Patrons,
            PosStatusService,
            PosAlertService,
            PrintService,
            PrintStatusService,
            Pure,
            Settings,
            Reports,
            SharedDataService,
            SharedFunctionService,
            Users,
            ReceiptBuilderService,
            PrintType,
            PrintReceiptType,
            OfflineShift,
            GatewayFiit,
            CartBuilderService,
            Menu,
            Inventory,
            PRINTOUT_TYPE,
            Organization,
            PreorderService) {

            $rootScope.$on('auth-loginConfirmed', function (event, user) {
                reset();
            });

            var _resetFiitOverviewData = function () {
                data.overviewData = {
                    cashCount: 0,
                    cardCount: 0,
                    totalFiitDcbCount: 0,
                    totalFiitMealUnitCount: 0
                };
                data.fiitTransactionCount = 0;
            };

            var _resetArr = function (array) {
                array.length = 0;
            };
            var _resetObj = function (obj) {
                for (const prop of Object.keys(obj)) {
                    delete obj[prop];
                }
            };
            var reset = function () {
                _companyId = CurrentSession.getCompany() ? CurrentSession.getCompany().companyId : null;
                _resetArr(_companies);
                _resetArr(_posStations);
                _resetArr(_menuPeriods);
                _resetArr(_menus);
                _resetArr(_organizationLanguages);
                _resetArr(_locations);
                _resetArr(_servicePeriods);
                _resetArr(_taxes);
                _resetObj(_servicePeriod);

                _resetObj(shift);

                _resetArr(patrons.checkedIn);
                _resetArr(patrons.recent);
                _resetArr(patrons.favourite);
                _resetArr(patrons.preorder);
                _resetArr(patrons.search);
                _resetArr(patrons.all);

                status.cumulative.totalAmount = 0;
                status.cumulative.totalCount = 0;
                _resetObj(status.printer);
                _resetArr(status.transactions);

                _resetQuickChargeSessionStatus();
                _resetFiitOverviewData();


                unregisterLucovaLoginListener();
            };

            // variables needed from app.js
            var _companies = [];
            var _companyId = CurrentSession.getCompany() ? CurrentSession.getCompany().companyId : null;

            // Bluetooth status for iOS
            window.addEventListener('bluetoothOnline', function () {
                PosStatusService.setIosBluetoothOnline(true);
            });
            window.addEventListener('bluetoothOffline', function () {
                PosStatusService.setIosBluetoothOnline(false);
            });

            var _lookupCompanies = function () {
                Lookup.companies({}, function (response) {
                    _companies = response;
                }, function (error) {
                    if (error.status <= 0) {
                        // TODO: what to do?
                    } else {
                        // _companiesError = error.data.error;
                    }
                });
            };
            var _posStations = [];
            var _menuPeriods = [];
            var _menus = [];
            var _organizationLanguages = [];
            var _locations = [];
            var _servicePeriods = [];
            var _taxes = [];
            var quickChargeSessionStatus = {
                active: false
            };

            var data = {
                locations: _locations,
                posStations: _posStations,
                menuPeriods: _menuPeriods,
                menus: _menus,
                organizationLanguages: _organizationLanguages,
                servicePeriods: _servicePeriods,
                taxes: _taxes,
                orderCounter: 0,
                overviewData: {
                    cashCount: 0,
                    cardCount: 0,
                    totalFiitDcbCount: 0,
                    totalFiitMealUnitCount: 0,
                    totalFiitSavedMealCardCount: 0
                },
                fiitTransactionCount: 0
            };

            var _servicePeriod = {};

            var _resetQuickChargeSessionStatus = function () {
                var quickChargeSession = CommonOfflineCache.getQuickChargeSession();
                if (quickChargeSession && quickChargeSession.quickChargeSessionId) {
                    quickChargeSessionStatus.active = true;
                } else {
                    quickChargeSessionStatus.active = false;
                }
            };

            var loadPOSStations = function (locationId) {
                if (!PosStatusService.isOffline()) {
                    return Locations.getLocationPOSStations({locationId: locationId}, function (response) {
                        // Commented By Akash Mehta on March 31 2021
                        // The call to _locationPOSStationsSuccess modifies the response objects
                        // and somehow we end up saving the modified response object to the offline cache
                        // Hence in offline mode we do not get the expected response from the cache causing
                        // a bug where we cannot open station in offline mode. Hence using angular.copy
                        // to deepclone the response object.
                        CommonOfflineCache.saveLocationPosStations(angular.copy(response));

                        _locationPOSStationsSuccess(response);
                    }, function (error) {
                        _posStations.length = 0;
                        if (error && error.status > 0 && error.data && error.data.error) {
                            shift.error = error.data.error;
                        }
                    }).$promise;
                }
                CommonOfflineCache.getLocationPosStations().then(function (locationPosStations) {
                    _locationPOSStationsSuccess(locationPosStations);
                }, function (error) {
                    _posStations.length = 0;

                    if (error.status <= 0) {
                        // TODO: what to do?
                    } else {
                        shift.error = error.data.error;
                    }
                });
            };

            var loadAllAvailableMenuPeriods = function (companyId) {
                if (!PosStatusService.isOffline()) {
                    return Locations.getMenuPeriods({companyId: companyId}, function (response) {
                        CommonOfflineCache.saveMenuPeriods(response);
                        return response;
                    }, function (error) {
                        if (error && error.status > 0 && error.data && error.data.error) {
                            shift.error = error.data.error;
                        }

                        return [];
                    }).$promise;
                }
                CommonOfflineCache.getMenuPeriods().then(function (menuPeriods) {
                    return menuPeriods;
                }, function (error) {
                    if (error.status <= 0) {
                        // TODO: what to do?
                    } else {
                        shift.error = error.data.error;
                    }

                    return [];
                });
            };

            var loadAllAvailableMenus = function (companyId) {
                const isRootCompany = CurrentSession.isRootCompany();

                if (!PosStatusService.isOffline()) {
                    return Menu.activeMenusForCompany({companyId: companyId, inActive: isRootCompany}, function (response) {
                        CommonOfflineCache.saveMenus(response);
                        return response;
                    }, function (error) {
                        if (error && error.status > 0 && error.data && error.data.error) {
                            shift.errloadAllAvailableMenusor = error.data.error;
                        }

                        return [];
                    }).$promise;
                }

                CommonOfflineCache.getMenus().then(function (menus) {
                    return menus;
                }, function (error) {
                    if (error.status <= 0) {
                        // TODO: what to do?
                    } else {
                        shift.error = error.data.error;
                    }

                    return [];
                });
            };


            var loadAllMenusByOrganization = function () {
                return Locations.getMenusByOrganization({organizationId: CurrentSession.getCompany().organizationId}, function (response) {
                    SharedDataService.setAllMenus(response);
                    return response;
                }, function (error) {
                    if (error && error.status > 0 && error.data && error.data.error) {
                        shift.errloadAllMenus = error.data.error;
                    }

                    return [];
                }).$promise;
            };

            var loadOrganizationLanguages = function () {
                return Organization.getAllLanguagesForOrganization({organizationId: CurrentSession.getCompany().organizationId}, function (response) {
                    _organizationLanguagesSuccess(response);
                }, function (error) {
                    return [];
                }).$promise;
            };

            const loadOrganizationLoyaltySteps = () => {
                return Organization.getLoyaltySteps({organizationId: CurrentSession.getCompany().organizationId},
                    (response) => {
                        // response is sorted by points required
                        SharedDataService.cachedLoyaltySteps = response;
                    }, (error) => {
                        SharedDataService.cachedLoyaltySteps = [];
                    }
                ).$promise;
            };

            var _locationPOSStationsSuccess = function (response) {
                // Previously, POS station info was retrieved from Lookup resource, which returned POS station id and name as `id` and `name`.
                // Now POS station info is requested through Location resource with more info, but with slightly different field names.
                // In order to prevent issues caused by the change in field names, info returned through the new field names are transformed
                // to match old field names.
                SharedDataService.locationPosStations = response.entries;
                _.each(response.entries, function (posStation) {
                    posStation.id = posStation.posStationId;
                    posStation.name = posStation.posStationName;
                    delete posStation.posStationId;
                    delete posStation.posStationName;
                });

                _posStations.length = 0;
                _posStations.push(...response.entries);

                _updateCurrentPosStationInfo();
            };

            var _menuPeriodsSuccess = function (response) {
                _resetArr(_menuPeriods);
                _menuPeriods.push(...response);
            };

            var _menusSuccess = function (response) {
                _resetArr(_menus);
                _menus.push(...response);
            };

            var _organizationLanguagesSuccess = function (response) {
                _resetArr(_organizationLanguages);
                _organizationLanguages.push(...response);
            };

            var _updateCurrentPosStationInfo = function () {
                // check if POS Station allows "No sale"
                if (shift && shift.posStationId && _posStations) {
                    var currentPosStation = _.findWhere(_posStations, {id: shift.posStationId}) || {};
                    currentPosStation.selected = true;
                    shift.posStation = currentPosStation;
                }
            };


            var _loadMenuGrid = function (locationId, languageId, cacheTimestamp = 0) {
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    let menu = CommonOfflineCache.getCurrentMenu();
                    let menuId = menu ? menu.menuId : null;
                    return Menu.menuGridV2({menuId, languageId, cacheTimestamp}).$promise;
                } else {
                    let menuPeriodId;
                    if (CompanyAttributesService.hasMenuPeriodsEnabled()) {
                        const currentMenuPeriod = CommonOfflineCache.getCurrentMenuPeriod() || {};
                        menuPeriodId = currentMenuPeriod.menuPeriodId;
                    }
                    return Locations.menuGridV2({locationId, cacheTimestamp, menuPeriodId}).$promise;
                }
            };

            const _loadLoyaltyStepItems = (languageId, cacheTimestamp = 0) => {
                if (!CompanyAttributesService.loyaltyStepsEnabled()) return Promise.resolve({});

                const menu = CommonOfflineCache.getCurrentMenu();
                if (!menu) return Promise.reject('No Menu Set');
                const menuId = menu.menuId;
                return Menu.getLoyaltyStepItems({menuId, languageId, cacheTimestamp}).$promise;
            };

            var searchItems = function (searchParams) {
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    let menu = CommonOfflineCache.getCurrentMenu();
                    searchParams.menuId = menu ? menu.menuId : undefined;
                    return Menu.itemSearch(searchParams);
                } else {
                    return Locations.itemSearch(searchParams);
                }
            };

            var updateMenuItemAvailability = function (item) {
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    return Menu.updateMenuItemAvailability(item).$promise;
                } else {
                    return Inventory.updateMenuItemAvailability({menuItemId: item.locationServicePeriodMenuId}, item).$promise;
                }
            };

            const updateShiftMenuByLanguageId = (languageId, menu) => {
                if (!languageId || !menu || CompanyAttributesService.hasMenuPeriodsEnabled()) return;

                SharedDataService.cachedShiftMenu[languageId] = menu;
            };

            const updateLoyaltyStepItemsByLanguageId = (languageId, itemMap) => {
                if (!languageId || !Object.entries(itemMap).length || CompanyAttributesService.hasMenuPeriodsEnabled()) return;

                SharedDataService.cachedLoyaltyStepItemsMap[languageId] = itemMap;
            };

            const getShiftMenuByLanguageId = (languageId) => {
                if (!languageId || CompanyAttributesService.hasMenuPeriodsEnabled()) return {};

                return SharedDataService.cachedShiftMenu[languageId] || {};
            };

            var loadShiftMenu = function () {
                var locationId = shift.locationId;
                var servicePeriodId = shift.servicePeriodId;
                let languageId = SharedDataService.preferredLanguage.languageId;

                let validServiceLocation = locationId && servicePeriodId;

                if (!validServiceLocation) {
                    var deferred = $q.defer();
                    deferred.resolve([]);

                    return deferred.promise;
                }

                // cachedShiftMenu is already sorted
                let promiseToReturn;
                let cachedShiftMenu = getShiftMenuByLanguageId(languageId);

                _loadLoyaltyStepItems(languageId, cachedShiftMenu.lastModified || 0)
                .then((itemMap) => {
                    updateLoyaltyStepItemsByLanguageId(languageId, itemMap);
                });

                promiseToReturn = _loadMenuGrid(locationId, languageId, cachedShiftMenu.lastModified || 0)
                .then(function (response) {
                    const entries = response.entries || [];
                    const lastModified = response.lastModified || 0;

                    if (lastModified === cachedShiftMenu.lastModified && shift.menu) {
                        return shift.menu;
                    }

                    const filteredEntries = entries.filter((entry) => {
                        return entry.menuOrderId && entry.menuOrderId > 0;
                    });

                    const sortedEntries = _.sortBy(filteredEntries, ['menuOrderId']);

                    cachedShiftMenu = {entries: sortedEntries, lastModified};
                    updateShiftMenuByLanguageId(languageId, cachedShiftMenu);
                    shift.menu = sortedEntries;

                    return shift.menu;
                }).catch((error) => {
                    if (error && error.status && error.status === 304) {
                        // Not Modified, we should use cache
                        return shift.menu;
                    } else {
                        console.error(error);
                    }
                });

                // we will always use the cachedShiftMenu if it exists, but _loadMenuGrid will still get called and
                // will update the cached grid in the background so that the updated grid is available for next time
                if (cachedShiftMenu && cachedShiftMenu.entries) {
                    shift.menu = cachedShiftMenu.entries;
                    promiseToReturn = Promise.resolve(shift.menu);
                }

                return promiseToReturn;
            };

            // POS Start
            var shift = {};
            var previousEndOfShiftReports = [];

            var loadShift = function () {
                return new Promise(function (resolve, reject) {
                    if (!PosStatusService.isOffline()) {
                        CashierShift.shiftStatus({}, async function (response) {
                            CommonOfflineCache.saveLocationShiftStatus(response);
                            await loadFormattedMenuPeriods();
                            await loadFormattedMenus();
                            await loadOrganizationLanguages();
                            if (CompanyAttributesService.loyaltyStepsEnabled()) {
                                await loadOrganizationLoyaltySteps();
                            }
                            await _shiftStatusSuccess(response, false);
                            await loadQuickChargeSession();
                            loadPOSStations(shift.locationId || CurrentSession.getCompany().locationId);
                            resolve(response);
                        }, function (error) {
                            shift.started = false;

                            if (error.status <= 0) {
                                shift.error = 'Network Connection Error';
                            } else {
                                shift.error = error.data.error;
                            }
                            reject(error);
                        });
                    } else {
                        CommonOfflineCache.getLocationShiftStatus().then(async function (locationShiftStatus) {
                            await loadFormattedMenuPeriods();
                            await loadFormattedMenus();
                            await loadOrganizationLanguages();
                            await _shiftStatusSuccess(locationShiftStatus, false);
                            await loadQuickChargeSession();
                            loadPOSStations(shift.locationId || CurrentSession.getCompany().locationId);
                            resolve(locationShiftStatus);
                        }, function (error) {
                            shift.started = false;

                            if (error.status <= 0) {
                                shift.error = 'Network Connection Error';
                            } else {
                                shift.error = error.data.error;
                            }
                            reject(error);
                        });
                    }
                });
            };

            var startShift = function (newShift) {
                if (!PosStatusService.isOffline()) {
                    return CashierShift.startShift({}, newShift, function (response) {
                        _shiftStatusSuccess(response, true, true);
                    }, function (error) {
                        if (error.status > 0) {
                            if (error.data.exception && error.data.exception.appCode === 402) {
                                _resumeActiveShift();
                            } else {
                                shift.error = error.data.error;
                            }
                        }
                    }).$promise;
                }

                // Get station data such as card terminal address from offline cache when starting a shift in offline mode.
                CommonOfflineCache.getLocationPosStations().then(function (posStations) {
                    var offlinePosStation = posStations.entries.find(function (station) {
                        return station.posStationId == newShift.posStationId;
                    });

                    // Create a cashier shift object to be used in offline mode.
                    // We then send this object to the backend to create an entry in the database.
                    var offlineShift = {
                        autoCreate: false,
                        cashierShiftId: -1,
                        companyId: CurrentSession.getCompany().companyId,
                        locationId: CurrentSession.getCompany().locationId,
                        posStationId: newShift.posStationId,
                        posStation: offlinePosStation,
                        servicePeriodId: parseInt(newShift.servicePeriodId),
                        employeeId: -1,
                        shiftStartTime: moment().valueOf(),
                        shiftStartBalance: newShift.shiftStartBalance,
                        shiftStarted: true,
                        timeZoneString: newShift.timeZoneString,
                        menuId: newShift.menuId
                    };
                    CommonOfflineCache.getLocationShiftStatus().then(function (shift) {
                        if (shift.shiftEndTime || (!shift.shiftStartTime && !shift.shiftEndTime)) {
                            CommonOfflineCache.saveLocationShiftStatus(offlineShift);
                            OfflineShift.open(offlineShift);
                        }
                    });
                    return new Promise(function (resolve, reject) {
                        _shiftStatusSuccess(offlineShift, false, true);
                        resolve();
                    });
                });
            };

            var _resumeActiveShift = function () {
                CashierShift.currentCashierShift({}, function (response) {
                    PosAlertService.showAlertByName('active-shift', {
                        'modalCallback': function () {
                            _shiftStatusSuccess(response);
                        }
                    });
                });
            };
            var endShift = function (shiftEndBalance, requestingUser) {
                var currentShift = angular.copy(shift);
                currentShift.shiftEndBalance = shiftEndBalance || 0;

                if (requestingUser) {
                    currentShift.closedByEmployeeId = requestingUser.userId;
                }

                var cashierShiftDTO = Pure.filterObject(
                    currentShift,
                    DTO_WHITELIST.CASHIER_SHIFT
                );

                return new Promise(function (resolve, reject) {
                    CashierShift.endShift({}, cashierShiftDTO, function (response) {
                        _shiftStatusSuccess(response, false);
                        loadPreviousEndOfShiftReports();
                        resolve(response);
                    }, function (error) {
                        reject(error);
                    });
                });
            };

            var loadPreviousEndOfShiftReports = function () {
                Reports.previousEndOfShiftReports({
                    locationId: shift.locationId
                }).$promise.then(function (response) {
                    previousEndOfShiftReports.length = 0;
                    previousEndOfShiftReports.push(...response);
                });
            };

            var printEndOfShiftReport = function (cashierShiftId, posStation) {
                return new Promise(function (resolve, reject) {
                    CashierShift.shiftReport({'shiftId': cashierShiftId}, function (reportResponse) {
                        PrintService.printEndOfShift(reportResponse, posStation).then(function (response) {
                            PrintService.clearSession();
                            resolve();
                        }, function (error) {
                            reject(error);
                        });
                    }, function (error) {
                        reject(error);

                        if (error.status <= 0) {
                            // TODO: what to do?
                        } else {
                            shift.error = error.data.error;
                        }
                    });
                });
            };

            var emailAndPrintEndOfShiftReport = function (cashierShiftId, posStation) {
                return new Promise(function (resolve, reject) {
                    CashierShift.shiftReport({'shiftId': cashierShiftId}, function (reportResponse) {
                        if (CompanyAttributesService.hasEOSReportPrint()) {
                            PrintService.printEndOfShift(reportResponse, posStation).then(function (response) {
                                PrintService.clearSession();
                                resolve();
                            }, function (error) {
                                reject(error);
                            });
                        }

                        const serviceConfig = CurrentSession.getCompany().serviceConfig;

                        if (serviceConfig.endOfShiftReport && serviceConfig.endOfShiftReport.enabled) {
                            PrintService.previewEndOfShiftReport(reportResponse).then(function (htmlReport) {
                                var payload = new FormData();
                                payload.append('report', htmlReport);
                                CashierShift.emailEndOfShift(payload);
                            });
                        }

                        resolve();
                    }, function (error) {
                        reject(error);

                        if (error.status <= 0) {
                            // TODO: what to do?
                        } else {
                            shift.error = error.data.error;
                        }
                    });
                });
            };

            var reprintGiftReceipt = function (transaction, isDuplicate = false) {
                getTransactionResult(transaction.transactionId, true).then(function (response) {
                    transaction.detail = response;

                    transaction.detail.posPrinters = SharedDataService.posStation.posPrinters;

                    PrintService.printGiftReceiptV2(
                        transaction.detail, // Note: remember to print points used
                        transaction.detail.receipt,
                        [],
                        true,
                        PrintType.CUSTOMER,
                        isDuplicate
                    );
                });
            };

            var reprintReceipt = function (transaction, printReceiptType, isDuplicate = false, printType = PrintType.CUSTOMER) {
                if (printReceiptType == PrintReceiptType.TRANSACTION) {
                    getTransactionResult(transaction.transactionId, true).then(function (response) {
                        transaction.detail = response;

                        var cardReceiptArr = ReceiptBuilderService.buildCardReceipt(
                            transaction.terminalResponses);

                        transaction.detail.posPrinters = SharedDataService.posStation.posPrinters;

                        PrintService.printReceipt(
                            transaction.detail, // Note: remember to print points used
                            transaction.detail.receipt,
                            cardReceiptArr,
                            true,
                            printType,
                            isDuplicate);
                    });
                } else if (printReceiptType == PrintReceiptType.REFUND) {
                    getTransactionResult(transaction.transactionId, true).then(function (response) {
                        var responseTransaction = response;
                        responseTransaction.transactionType = transaction.refundStatus;
                        responseTransaction.refundTransactions = transaction.refundTransactions;

                        var fullNames = [];
                        for (var refundTrans of responseTransaction.refundTransactions) {
                            if (refundTrans.requestingUser) {
                                var fullName = refundTrans.requestingUser.firstname + ' ' + refundTrans.requestingUser.lastname;
                                fullNames.push(fullName);
                            }
                        }

                        responseTransaction.userFullName = fullNames.join(', ');

                        return Locations.getLocationPrinters({'locationId': shift.locationId})
                            .$promise.then(function (locationResponse) {
                                var terminalConfig = locationResponse.posStation.cardTerminalProperties;
                                var terminalType = terminalConfig.type;

                                var refundTerminalResponses = [];

                                for (var refundTrans of responseTransaction.refundTransactions) {
                                    for (var refundTransTenders of refundTrans.tenders) {
                                        if (refundTransTenders.terminalResponse) {
                                            var responseObj = {};
                                            responseObj.cardTerminalResponse = refundTransTenders.terminalResponse;
                                            responseObj.processorType = terminalType;
                                            refundTerminalResponses.push(responseObj);
                                        }
                                    }
                                }
                                var cardReceiptArr = ReceiptBuilderService.buildCardReceipt(refundTerminalResponses);

                                var origCardReceiptArr = ReceiptBuilderService.buildCardReceipt(
                                    transaction.terminalResponses);

                                origCardReceiptArr = origCardReceiptArr.concat(cardReceiptArr);

                                responseTransaction.posPrinters = SharedDataService.posStation.posPrinters;

                                PrintService.printReceipt(
                                    responseTransaction, // Note: remember to print points used
                                    responseTransaction.receipt,
                                    origCardReceiptArr,
                                    true,
                                    printType,
                                    isDuplicate);
                            });


                    });
                }
            };

            var _generateReceiptHierarchy = function (receipt) {
                var receiptItemsRoot = _.filter(receipt, function (receiptItem) {
                    if (receiptItem.receiptItemId) {
                        // receiptItem object returned from backend
                        return receiptItem.index === receiptItem.itemIndex;
                    } else {
                        // receiptItem object constructed in frontend
                        return receiptItem.level === 0;
                    }
                });

                // Build list of children for each item
                for (var i = 0; i < receiptItemsRoot.length; i++) {
                    var receiptChildren = _.filter(receipt, function (receiptItem) {
                        if (receiptItem.receiptItemId) {
                            // receiptItem object returned from backend
                            return receiptItem.index !== receiptItem.itemIndex && receiptItem.index === receiptItemsRoot[i].index;
                        } else {
                            // receiptItem object constructed in frontend
                            return receiptItem.index === receiptItemsRoot[i].index && receiptItem.level === 1;
                        }
                    });

                    if (receiptChildren.length > 0) {
                        receiptItemsRoot[i].children = receiptChildren;
                    }
                }

                return receiptItemsRoot;
            };

            var populateSecondaryPrinters = async function (receipt, posPrinters) {
                // Commented by Akash Mehta on April 27 2021
                // The logic for the default kitchen printer is as follow:
                // 1. We try to find the default kitchen printer
                // 2. If not default kitchen printer found, we try to get the first available kitchen printer
                // 3. If no kitchen printers found at all, we get the first available printer
                var defaultKitchenPrinter = (_.findWhere(posPrinters, {kitchenPrinter: true, defaultKitchenPrinter: true})
                    || _.findWhere(posPrinters, {kitchenPrinter: true})) || posPrinters[0];
                let indicesToRemove = [];

                if (!PosStatusService.isOffline()) {
                    // Look up printers for each item if we are online
                    let servicePeriodMenuIds = receipt.filter((item) => !!item.locationServicePeriodMenuId).map((item) => {
                        return item.locationServicePeriodMenuId;
                    });

                    let responseResults = [];
                    if (servicePeriodMenuIds.length) {
                        let search = {
                            servicePeriodMenuIds: servicePeriodMenuIds.toString(),
                            locationId: shift.locationId,
                            itemsOnly: true
                        };
                        let response = await searchItems(search).$promise;
                        responseResults.length = 0;
                        responseResults.push(...response.posMenuItemEntries[0].entries);
                    }

                    // Commented By Akash Mehta on May 17 2021
                    // Saving lookup time in a for loop by Creating a map in
                    // rather than using array.find().
                    let posPrintersMap = posPrinters.reduce(function (map, obj) {
                        map[obj.posPrinterId] = obj.posPrinterId;
                        return map;
                    }, {});

                    for (let i = 0; i < receipt.length; i++) {
                        let matchingItem = _.findWhere(responseResults, {locationServicePeriodMenuId: receipt[i].locationServicePeriodMenuId});
                        let skipSecondaryPrinting = true;

                        if (matchingItem && matchingItem.printOnKitchenSheet
                            && [PRINTOUT_TYPE.ALL, PRINTOUT_TYPE.KITCHEN_SHEET].includes(receipt[i].printoutType)) {
                            const modifiers = matchingItem.children || [];
                            if (matchingItem.noModifierNoKitchenPrint == true && modifiers.length) {
                                indicesToRemove.push(i);
                                continue;
                            }

                            skipSecondaryPrinting = false;
                            receipt[i].printoutType = matchingItem.printoutType;
                            if (matchingItem.printerId && posPrintersMap[matchingItem.printerId]) {
                                receipt[i].printerId = matchingItem.printerId;
                            }
                        }

                        if (!receipt[i].printerId && !skipSecondaryPrinting && defaultKitchenPrinter) {
                            receipt[i].printerId = defaultKitchenPrinter.posPrinterId;
                        }
                    }
                } else if (defaultKitchenPrinter) {
                    // Just set all items to the first kitchen printer if we are offline
                    for (let i = 0; i < receipt.length; i++) {
                        receipt[i].printerId = defaultKitchenPrinter.posPrinterId;
                    }
                }

                for (var i = indicesToRemove.length - 1; i >= 0; i--) {
                    var index = indicesToRemove[i];
                    receipt.splice(index, 1);
                }
            };

            // TODO: remove
            // TODO: copied over from `app.js` since it's needed for `endShift`, but can't remember
            // what the differences are between `_confirmPrintEndOfShiftReport` and
            // `_confirmReprintEndOfShiftReport`
            var _confirmReprintEndOfShiftReport = function (cashierShiftId) {
                var promise = new Promise(function (resolve, reject) {

                    var modalInstance = $modal.open({
                        templateUrl: 'common/modals/modalConfirmReprintShiftEnd.tpl.html',
                        animation: false,
                        backdrop: 'static'
                    });
                    modalInstance.result.then(function (result) {
                        CashierShift.shiftReport({'shiftId': cashierShiftId}, function (reportResponse) {
                            PrintService.printEndOfShift(reportResponse).then(function (response) {
                                resolve();
                            }, function (error) {
                                reject();
                                setTimeout(_confirmReprintEndOfShiftReport(cashierShiftId), 0);
                            });
                        }, function (error) {
                            reject();
                            setTimeout(_confirmReprintEndOfShiftReport(cashierShiftId), 0);

                            if (error.status <= 0) {
                                // TODO: what to do?
                            } else {
                                shift.error = error.data.error;
                            }
                        });
                    }, function (error) {
                        resolve();
                    });
                });

                return promise;
            };

            var menuTimer;
            var registerMenuTimer = function () {
                if (menuTimer) {
                    $timeout.cancel(menuTimer);
                }

                if (!_menuPeriods || !_menuPeriods.length) {
                    return;
                }

                // Commented By Akash Mehta on November 16 2020
                // Here we need to ignore the seconds and milliseconds
                // to make the logic simpler and cleaner.
                var todayMoment = moment().seconds(0).milliseconds(0);
                var todayDay = todayMoment.format('dddd');
                for (var period of _menuPeriods) {
                    if (!period.days.includes(todayDay.toUpperCase())) {
                        continue;
                    }

                    var startTimeMoment = moment(period.startTimeStr, 'HH:mm').seconds(0).milliseconds(0);

                    if (todayMoment.isAfter(startTimeMoment)) {
                        continue;
                    }

                    var millisTillMenuPeriod = startTimeMoment.subtract(todayMoment.valueOf()).valueOf();
                    if (millisTillMenuPeriod <= 30000 && millisTillMenuPeriod >= -60000) {
                        var cachedMenuToSwitchTo = CommonOfflineCache.getMenuPeriodToSwitchTo();
                        if (cachedMenuToSwitchTo && cachedMenuToSwitchTo.millisTillAlert != undefined) {
                            if (millisTillMenuPeriod <= cachedMenuToSwitchTo.millisTillAlert) {
                                CommonOfflineCache.setMenuPeriodToSwitchTo(millisTillMenuPeriod, period);
                            }
                        } else {
                            CommonOfflineCache.setMenuPeriodToSwitchTo(millisTillMenuPeriod, period);
                        }
                    }
                }


                menuTimer = $timeout(registerMenuTimer, 30000); // reset the timer

            };

            var _setQuickChargeSession = function (quickChargeSession) {
                CommonOfflineCache.unsetQuickChargeSession();
                if (quickChargeSession && quickChargeSession.quickChargeSessionId) {
                    CommonOfflineCache.setQuickChargeSession(quickChargeSession);
                    quickChargeSessionStatus.active = true;
                } else {
                    quickChargeSessionStatus.active = false;
                }
            };

            var loadQuickChargeSession = function () {
                // Commented By Akash Mehta on August 17 2020
                // If we are reloading a shift, quick charge data needs to be
                // removed and reloaded again.
                var menuPeriod = CommonOfflineCache.getCurrentMenuPeriod();

                if (!menuPeriod) {
                    return Promise.resolve();
                }

                var payload = {
                    cashierShiftId: shift.cashierShiftId,
                    menuPeriodId: menuPeriod.menuPeriodId
                };

                CashierShift.getQuickChargeSession(payload, function (response) {
                    _setQuickChargeSession(response);
                    loadStatus();
                    return Promise.resolve();
                }, function (error) {
                    loadStatus();
                    return Promise.resolve([]);
                });

            };

            var switchMenuPeriod = function (newMenuPeriod, toReset, ignoreQuickCharge = false) {
                CommonOfflineCache.setCurrentMenuPeriod(newMenuPeriod, toReset);
                if (!ignoreQuickCharge) {
                    loadQuickChargeSession();
                }
            };

            var loadFormattedMenuPeriods = function () {
                return loadAllAvailableMenuPeriods(CurrentSession.getCompany().companyId).then(function (response) {
                    var menuPeriodsToReturn = response || [];
                    var todayMoment = moment();
                    var todayDay = todayMoment.format('dddd');
                    for (var period of menuPeriodsToReturn) {
                        var textToAppend = '';
                        if (!period.days.includes(todayDay.toUpperCase())) {
                            textToAppend = ' (Not valid for today)';
                        } else {
                            var startTime = $filter('dateTimeFormatter')(period.startTimeStr, 'HH:mm', 'hh:mm A');
                            var endTime = $filter('dateTimeFormatter')(period.endTimeStr, 'HH:mm', 'hh:mm A');
                            textToAppend = ' (' + startTime + ' - ' + endTime + ') ';
                        }

                        period.description += textToAppend;
                    }


                    _menuPeriodsSuccess(menuPeriodsToReturn);
                    return menuPeriodsToReturn;
                });
            };

            var loadFormattedMenus = function ({getAllMenusByOrganization = false} = {}) {
                let menuPromise;
                if (getAllMenusByOrganization) {
                    menuPromise = loadAllMenusByOrganization();
                } else {
                    menuPromise = loadAllAvailableMenus(CurrentSession.getCompany().companyId);
                }

                return menuPromise.then(function (response) {
                    var menusToReturn = response || [];
                    /* var todayMoment = moment();
                    var todayDay = todayMoment.format('dddd');
                    for (var period of menuPeriodsToReturn) {
                        var textToAppend = '';
                        if (!period.days.includes(todayDay.toUpperCase())) {
                            textToAppend = ' (Not valid for today)';
                        } else {
                            var startTime = convertTimestampToTime(period.startTime, true);
                            var endTime = convertTimestampToTime(period.endTime, true);
                            textToAppend = ' (' + startTime + ' - ' + endTime + ') ';
                        }

                        period.description += textToAppend;
                    }*/


                    if (!getAllMenusByOrganization) {
                        _menusSuccess(menusToReturn);
                    }
                    return menusToReturn;
                });
            };

            var updateShiftMenu = function (newMenuId, {toUpdateBackend = true, updateLoyaltyStepItems = true} = {}) {
                CommonOfflineCache.unsetCurrentMenu();

                if (!CompanyAttributesService.hasMenuV2Enabled()) {
                    return;
                }

                var callback = function (shiftMenuId, menusResponse) {
                    let menusArr = menusResponse || _menus;
                    var newMenu = menusArr.find((menu) => menu.menuId == shiftMenuId);
                    CommonOfflineCache.setCurrentMenu(newMenu);

                    if (!updateLoyaltyStepItems) return;

                    _loadLoyaltyStepItems(SharedDataService.preferredLanguage.languageId, 0)
                    .then((itemMap) => {
                        updateLoyaltyStepItemsByLanguageId(SharedDataService.preferredLanguage.languageId, itemMap);
                    });
                };

                if (!newMenuId) {
                    PosAlertService.showAlertByName('general-error', {
                        message: 'general.error.menu.not.available.msg',
                        title: 'general.error.menu.not.available.ttl'
                    });
                }

                var switchMenuPayload = {
                    cashierShiftId: shift.cashierShiftId,
                    menuId: newMenuId
                };
                if (toUpdateBackend) {
                    return CashierShift.switchMenu(switchMenuPayload).$promise.then(async function (response) {
                        let menusResponsePromise = await loadFormattedMenus({getAllMenusByOrganization: true});
                        let menusResponse = await menusResponsePromise;
                        callback(response.menuId, menusResponse);
                    });
                } else {
                    callback(shift.menuId);
                }
            };

            const updateShiftFiitServicePeriod = (fiitServicePeriodId) => {
                const payload = {
                    cashierShiftId: shift.cashierShiftId,
                    fiitServicePeriodId: fiitServicePeriodId,
                };

                return CashierShift.changeFiitServicePeriod(payload).$promise.then((response) => {
                    CommonOfflineCache.unsetCurrentFiitServicePeriodId();
                    CommonOfflineCache.setCurrentFiitServicePeriodId(response.fiitServicePeriodId);
                });
            };

            var lucovaLoginFailures = 0;
            var _shiftStatusSuccess = function (response, clearMenuCache = true, refreshMenuPeriod = false) {
                if (angular.isDefined(response) && angular.isDefined(response.cashierShiftId)) {
                    if (clearMenuCache) {
                        // Clear menu items from the cache
                        SharedDataService.clearMenuCache();
                    }

                    let shiftCompany = CurrentSession.getCompany();
                    SharedDataService.company = shiftCompany;
                    SharedDataService.preferredLanguage.languageId = shiftCompany.languageId;
                    SharedDataService.preferredLanguage.locale = shiftCompany.locale;
                    SharedDataService.minimumDenomination = shiftCompany.minimumDenomination || 1;
                    SharedDataService.setCurrencyCode(shiftCompany.baseCurrencyCode);

                    // The assigned SRM device if available
                    SharedDataService.posSrmDevice = response.posStation.locationSrmDevice;

                    // Check if shift just started
                    if (!PosStatusService.shiftStarted && response.shiftStarted) {
                        CommonOfflineCache.cacheMenuItems(_companyId, response.locationId);
                        CommonOfflineCache.saveLocationId(response.locationId);
                        CommonOfflineCache.savePosStationMenu(response.locationId);

                        // Only connect to payment server if environment is not the testing environment
                        if (EnvConfig.env !== 'test') {
                            if (Lucova.isInitialized()) {
                                var loginResponse = Lucova.getLoginResponse();
                                if (loginResponse && loginResponse.success) {
                                    loadLucovaUsers();
                                } else {
                                    if (lucovaLoginFailures === 0) {
                                        PosAlertService.showAlertByName('lucova-login-failed');
                                        lucovaLoginFailures++;
                                    }
                                }
                            } else {
                                // this listener won't ever get called if Lucova cannot be connected upon login
                                registerLucovaLoginListener(function (response) {
                                    if (response.success) {
                                        loadLucovaUsers();
                                    } else {
                                        if (lucovaLoginFailures === 0) {
                                            PosAlertService.showAlertByName('lucova-login-failed');
                                            lucovaLoginFailures++;
                                        }
                                    }
                                });
                            }
                        }
                    }

                    PosStatusService.shiftStarted = response.shiftStarted;
                    shift.started = response.shiftStarted;

                    if (PosStatusService.shiftStarted) {
                        shift.locationId = response.locationId;
                        shift.posStationId = response.posStationId;
                        shift.cashierShiftId = response.cashierShiftId;
                        shift.servicePeriodId = response.servicePeriodId;
                        shift.shiftStartBalance = response.shiftStartBalance;
                        shift.currentTime = response.currentTime;
                        shift.cashierBreaks = response.cashierBreaks;
                        shift.menuPeriodId = response.menuPeriodId;
                        shift.menuId = response.menuId;


                        if (CompanyAttributesService.hasMevBox()) {
                            // Fetch and save location SRM devices
                            Locations.getLocationSrmDevices({companyId: response.companyId}).$promise
                                .then((list) => {
                                    SharedDataService.locationSrmDevices = list;
                                })
                                .catch((error) => {
                                    $log.error(error);
                                });
                        }


                        var menuPeriod = _menuPeriods.find((period) => period.menuPeriodId == shift.menuPeriodId);

                        if (refreshMenuPeriod) {
                            CommonOfflineCache.unsetCurrentMenuPeriod();
                            CommonOfflineCache.resetMenuPeriodToSwitchTo();
                            switchMenuPeriod(menuPeriod);
                        } else {
                            var currentMenuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                            if (currentMenuPeriod && currentMenuPeriod.menuPeriodId
                                && currentMenuPeriod.companyId !== CurrentSession.getCompany().companyId) {
                                CommonOfflineCache.unsetCurrentMenuPeriod();
                                delete currentMenuPeriod.menuPeriodId;
                            }

                            if (!currentMenuPeriod || !currentMenuPeriod.menuPeriodId) {
                                switchMenuPeriod(menuPeriod, false);
                            }
                        }


                        updateShiftMenu(response.menuId, {
                            toUpdateBackend: false,
                            updateLoyaltyStepItems: false
                        });

                        registerMenuTimer();
                        SharedDataService.posStation = response.posStation;
                        SharedDataService.posStationId = response.posStationId;
                        SharedDataService.locationId = response.locationId;

                        if (!shift.locationId && EnvConfig.env !== 'test') {
                            $log.error({
                                message: 'shift.locationId value not set in function _shiftStatusSuccess : ' + shift.locationId,
                                context: {
                                    user: JSON.stringify(CurrentSession.getUser())
                                }
                            });
                        }

                        Settings.getTaxRates({'companyId': _companyId}, function (response) {
                            _taxes.length = 0;
                            _taxes.push(...response.entries);
                        });

                        _updateCurrentPosStationInfo();

                        loadShiftMenu();
                    } else {
                        // Set locationId here in order to use KDS without having a started shift
                        // The reason this assumption is fine is because for Nown, each company has
                        // exactly one location (1:1 binding)
                        shift.locationId = response.locationId;
                        shift.posStationId = undefined;
                        shift.cashierShiftId = undefined;
                        shift.shiftStartBalance = undefined;
                        shift.shiftEndBalance = undefined;
                        shift.cashierBreaks = undefined;
                        shift.started = false;
                        shift.menuPeriodId = undefined;
                        shift.menuId = undefined;

                        LucovaBluetooth.stop();
                        _lookupCompanies();

                        loadShiftMenu();
                    }
                }

                return new Promise((resolve, reject) => {
                    if (!PosStatusService.isOffline()) {
                        Locations.getLocations({
                            companyId: _companyId
                        }, function (response) {
                            // This is to ensure the locations is in the same structure as the ones
                            // returned by Lookup.companyLocations:
                            // - id: id of the location in String
                            // - name: name of the location
                            CommonOfflineCache.saveCompanyLocations(response);
                            var transformed = _.map(response.entries, function (location) {
                                return {
                                    id: location.locationId + '',
                                    name: location.locationName,
                                    address: {
                                        street: location.street,
                                        city: location.city,
                                        region: location.region
                                    },
                                    nodeId: location.nodeId
                                };
                            });

                            const currentLocation = response.entries.find((location) => location.locationId === shift.locationId);
                            SharedDataService.location = currentLocation;
                            CommonOfflineCache.saveCurrentLocation(currentLocation);

                            _locations.length = 0;
                            _locations.push(...transformed);
                            shift.location = _.findWhere(_locations, {id: shift.locationId + ''});
                            resolve();
                        }, function (error) {
                            reject(error);
                        });
                    } else {
                        CommonOfflineCache.getCompanyLocations().then(function (companyLocations) {
                            var transformed = _.map(companyLocations.entries, function (location) {
                                return {
                                    id: location.locationId + '',
                                    name: location.locationName,
                                    address: {
                                        street: location.street,
                                        city: location.city,
                                        region: location.region
                                    }
                                };
                            });

                            _locations.length = 0;
                            _locations.push(...transformed);
                            shift.location = _.findWhere(_locations, {id: shift.locationId + ''});
                            resolve();
                        }, function (error) {
                            reject(error);
                        });
                    }
                    PrintService.updateSession();
                });
            };

            var findLocation = function (locationId) {
                return _.findWhere(_locations, {id: (locationId + '')});
            };

            var loadServicePeriods = function (locationId) {
                // unfortunately we have to do this check due to multiple states sharing page 1
                if (!locationId) {
                    _servicePeriods.length = 0;
                    return Promise.reject();
                }
                if (!PosStatusService.isOffline()) {
                    return Lookup.locationServicePeriods({'locationId': locationId}, function (response) {
                        CommonOfflineCache.saveLocationServicePeriods(response);
                        response.sort(function (a, b) {
                            return a.id.localeCompare(b.id);
                        });

                        _servicePeriods.length = 0;
                        _servicePeriods.push(...response);
                    }, function (error) {
                        if (error.status <= 0) {
                            // TODO: what to do?
                        } else {
                            shift.error = error.data.error;
                        }
                        _servicePeriods.length = 0;
                    }).$promise;
                }
                return CommonOfflineCache.getLocationServicePeriods().then(function (response) {
                    response.sort(function (a, b) {
                        return a.id.localeCompare(b.id);
                    });

                    _servicePeriods.length = 0;
                    _servicePeriods.push(...response);
                }, function (error) {
                    if (error.status <= 0) {
                        // TODO: what to do?
                    } else {
                        shift.error = error.data.error;
                    }
                    _servicePeriods.length = 0;
                });
            };

            var lucovaLoginListener = null;
            var registerLucovaLoginListener = function (listener) {
                unregisterLucovaLoginListener();

                if (!lucovaLoginListener) {
                    lucovaLoginListener = listener;
                    Lucova.addListener(lucovaLoginListener);
                }
            };
            var unregisterLucovaLoginListener = function () {
                if (lucovaLoginListener) {
                    Lucova.removeListener(lucovaLoginListener);
                    lucovaLoginListener = null;
                }
            };

            var patrons = {
                checkedIn: [],
                recent: [],
                favourite: [],
                preorder: [],
                ungroupedPreorder: [],
                search: [],
                all: []
            };
            var suspend = [];

            var selectedPreorder;

            window.addEventListener('didEnterForeground', function () {
                loadLucovaUsers();

                $translate('translate.testOnly').then(function (result) {
                }, function (error) {
                    $log.error('Translation failed:', error);
                    $translate.refresh();
                });
            });

            // this method always resolves
            const loadPreorderHistory = function (type = 'ALL', offset = 0, limit = 30) {
                return new Promise((resolve, reject) => {
                    const params = {
                        type: type,
                        offset: offset,
                        limit: limit
                    };

                    try {
                        Lucova.manager().getLocationPreorderHistory(params).$promise.then(function (response) {
                            let preorderArr = response.preorders || [];

                            _.each(preorderArr, function (preorderUser) {
                                _.each(preorderUser.preorder, function (preorder) {
                                    // set app icon, formatted estimated time and the delivery service provider (if delivery order)
                                    PreorderService.transformPreorder(preorder);
                                });

                                preorderUser.preorder = _.sortBy(preorderUser.preorder, function (preorder) {
                                    return -preorder.transaction_opened;
                                });

                                var latestPreorder = preorderUser.preorder[0];
                                if (latestPreorder) {
                                    preorderUser.isPreorderCompleted = latestPreorder.status === 'completed';
                                    preorderUser.preorderPickupTime = latestPreorder.estimated_completion;
                                    preorderUser.show = (moment().valueOf() / 1000 - preorderUser.preorderPickupTime) < 15 * 60;
                                }
                            });

                            _transformPreorderLucovaUsers(preorderArr).then(function (result) {
                                refreshLucovaUsers();
                                resolve(result);
                            });
                        }).catch(function (error) {
                            console.warn('Error while getting preorder history!!', error);
                            resolve([]);
                        });
                    } catch (error) {
                        resolve([]);
                    }
                });
            };

            var ungroupedUsers = [];
            var loadLucovaUsers = function () {
                LucovaWebSocket.getUsers()
                    .then(function (response) {

                        // it is possible for a status 200 response to be returned by payments server,
                        // however with success = false.
                        // in this case, response should be returning something similar as followed:
                        // { success, message, error_code }

                        if (response.success) {
                            ungroupedUsers.push(...response.preorder);
                            refreshLucovaUsers();
                        }
                    });

                // Load suspended
                loadSuspend();
            };

            var loadSuspend = function () {
                if (shift.locationId) {
                    Locations.getSuspend({'locationId': shift.locationId})
                        .$promise.then(function (response) {
                            var suspendGrouped = _.groupBy(response.entries, function (suspend) {
                                return moment(suspend.timestamp).startOf('day').valueOf();
                            });

                            var suspendArray = Array.from(
                                Object.keys(suspendGrouped),
                                function (suspendKey) {
                                    return {
                                        date: suspendKey,
                                        suspendList: suspendGrouped[suspendKey]
                                    };
                                });

                            _resetArr(suspend);
                            suspend.push(...suspendArray);
                        });
                }
            };

            var lookupSuspend = function (suspendId) {
                return Locations.lookupSuspend({'suspendId': suspendId}).$promise;
            };

            var getScheduledPreorders = function () {
                return LucovaWebSocket.getUngroupedScheduledPreorders().then(function (response) {
                    return _transformPreorderLucovaUsers(response).then(function (result) {
                        var ungroupedPreorder = [];

                        if (result.length) {
                            Pure.splicePreorderArrayFromPatrons(result);
                        }

                        ungroupedPreorder.push(...result);
                        return ungroupedPreorder;
                    });
                }).catch(function (error) {
                    $log.error({
                        message: 'Unable to fetch scheduled preorders',
                        context: {
                            error: error
                        }
                    });
                    return Promise.reject(error);
                });
            };

            var refreshLucovaUsers = function () {

                var nearbyPromise = _transformNearbyLucovaUsers(LucovaWebSocket.nearbyUsers).then(function (result) {
                    patrons.checkedIn.length = 0;
                    patrons.checkedIn.push(...result);
                    return result;
                });

                var preorderPromise = _transformPreorderLucovaUsers(LucovaWebSocket.mobileOrderUsers).then(function (result) {
                    patrons.preorder.length = 0;

                    // we only require the first preorder (for now) for mobile order
                    // if future release requires or allows multiple preorders per patron, this can be safely removed
                    // with tweaks required in mobile order modal.
                    if (result.length) {
                        Pure.splicePreorderArrayFromPatrons(result);
                    }

                    patrons.preorder.push(...result);

                    _parsePreorders();

                    return result;
                });

                var ungroupedPromise = _transformPreorderLucovaUsers(LucovaWebSocket.ungroupedPreorderUsers).then(function (result) {
                    patrons.ungroupedPreorder.length = 0;

                    if (result.length) {
                        Pure.splicePreorderArrayFromPatrons(result);
                    }

                    patrons.ungroupedPreorder.push(...result);
                    return result;
                });

                return Promise.all([nearbyPromise, preorderPromise, ungroupedPromise]);
            };

            var fetchPatronByPatronKey = function (patronKey) {
                return Patrons.byPatronKey({
                    patronKey: patronKey
                }).$promise.then(function (patron) {
                    var transformed = _transformFiitPatrons([patron]);
                    return transformed[0];
                });
            };

            var fetchAllPatrons = function (query) {
                var params = {
                    companyId: _companyId,
                    limit: 50,
                    offset: 0,
                    reactive: true
                };

                if (query && query !== '') {
                    params['patronSearchFilter'] = query;
                }

                return Patrons.listPatrons(params).$promise.then(function (response) {
                    patrons.all.length = 0;
                    patrons.all.push(..._transformFiitPatrons(response.entries));
                });
            };

            const fetchPatronAvailableGCs = function (patron) {
                let params = {
                    patronId: patron.patronId
                };

                return Patrons.getAllAvailableGCs(params).$promise;
            };

            var lookupEncryptedLucovaUser = function (username, params, iv, onSuccess, onError) {
                Lucova.manager(true).userProximityNotify(
                    {},
                    {
                        user_name: username,
                        params: params,
                        iv: iv
                    }, function (response) {
                        if (response && response.success) {
                            Lucova.manager().getUserInfo({user_name: username}, function (response) {
                                if (response.success) {
                                    var lucovaUser = response['user'];

                                    var nearbyPromise = _transformNearbyLucovaUsers([lucovaUser]).then(function (result) {
                                        onSuccess(result);
                                        return result;
                                    });

                                    return nearbyPromise;
                                } else {
                                    onError(response.message);
                                }
                            }, function (error) {
                                onError('Invalid QR. Please try again');
                            });
                        } else {
                            onError(response.message);
                        }
                    }, function (error) {
                        onError('Invalid QR. Please try again');
                    }
                );
            };

            var lookupByLucovaUser = function (lucovaUsername, onSuccess, onError) {
                Lucova.manager().userProximityNotify(
                    {},
                    {
                        user_name: lucovaUsername,
                        offline_checkin: true
                    }, function (response) {
                        Lucova.manager().getUserInfo({user_name: lucovaUsername}, function (response) {
                            if (response.success) {
                                var lucovaUser = response['user'];

                                var nearbyPromise = _transformNearbyLucovaUsers([lucovaUser]).then(function (result) {
                                    onSuccess(result);
                                    return result;
                                });

                                return nearbyPromise;
                            } else {
                                onError(response.message);
                            }
                        }, function (error) {
                            onError('Invalid QR. Please try again');
                        });
                    },
                    function (error) {
                        onError('Invalid QR. Please try again');
                    }
                );
            };

            var split = function (string, delimiter, maxSplits) {
                if (!string) {
                    return [];
                }

                var arr = string.split(delimiter);
                var result = arr.splice(0, maxSplits);

                if (arr.length > 0) {
                    result.push(arr.join(delimiter));
                }

                return result;
            };

            var fetchLucovaPatronByGiftCard = function (giftCardCode, isFiitGatewayEnabled) {
                return new Promise((resolve, reject) => {
                    try {
                        if (isFiitGatewayEnabled) {
                            reject({'error': 'No need to scan giftcards for FIIT patrons'});
                        }

                        Lucova.manager().getUserInfo({nown_gift_card_num: giftCardCode}, function (response) {
                            if (response.success) {
                                var lucovaUser = response['user'];

                                _transformNearbyLucovaUsers([lucovaUser]).then(function (result) {
                                    resolve(result);
                                });
                            } else {
                                reject(response.message);
                            }
                        }, function (error) {
                            reject(error);
                        });
                    } catch (error) {
                        reject(error);
                    }
                });
            };

            var fetchLucovaPatron = function (qrcode) {
                return new Promise((resolve, reject) => {
                    try {
                        var arr = split(qrcode, ':', 2);
                        if (arr.length == 3) {
                            /**
                            * User entered-code is a QR code for offline mode.
                            * v1 format: l:1:username
                            * v2 format: l:2:unencryptedUserName:encryptedPayload:iv
                            */
                            var qrVersion = arr[1];
                            if (qrVersion === '1') {
                                lookupByLucovaUser(arr[2], resolve, reject);
                            } else if (qrVersion === '2') {
                                // format: unencryptedUserName:encryptedPayload:iv
                                let v2 = split(arr[2], ':', 2);
                                if (v2.length == 3) {
                                    let username = v2[0];
                                    let params = v2[1];
                                    let iv = v2[2];
                                    lookupEncryptedLucovaUser(username, params, iv, resolve, reject);
                                }
                            } else {
                                reject('Invalid QR. Please try again');
                            }
                        } else {
                            reject('Invalid QR. Please try again');
                        }
                    } catch (error) {
                        reject(error);
                    }
                });
            };

            const isServicePlanActive = (servicePlan) => {
                let isAfterStart, isBeforeEnd = true;

                if (servicePlan.startDateTime) {
                    const startTime = moment(servicePlan.startDateTime);
                    isAfterStart = moment().isSameOrAfter(startTime);
                }

                if (servicePlan.endDateTime) {
                    const endTime = moment(servicePlan.endDateTime);
                    isBeforeEnd = moment().isSameOrBefore(endTime);
                }

                return isAfterStart && isBeforeEnd;
            };

            const fetchPatronFromFiitBackend = async function (patronId, patronKey, notFoundError = {}, patronObj = {}) {
                const fiitMealCardRequestObj = {
                    patronKey,
                    patronId
                };

                try {
                    const patron = await GatewayFiit.getAccount(fiitMealCardRequestObj);
                    const mealPlans = patron.mealPlans || [];
                    const mealPlanSummary = {
                        amountCents: 0,
                        amountMeals: 0,
                        totalMealBalance: 0,
                        enabled: false
                    };

                    const fiitMpsCompanyId = parseInt(GatewayFiit.fiitMps.companyId);
                    const patronCompanyId = patron.companyId;
                    const isEligiblePatronCompany = (fiitMpsCompanyId === patronCompanyId);
                    if (!isEligiblePatronCompany) {
                        patron.status = 100;
                    }

                    if (patron.status === 0) {
                        let tempMealPlans = [];
                        const servicePlanMap = GatewayFiit.getServicePlanMap();

                        // Filter patronMealPlans for matching locationActiveMealPlans
                        if (Object.keys(servicePlanMap).length) {
                            mealPlans.forEach((plan) => {
                                plan.unavailablityReasons = {};

                                const servicePlan = servicePlanMap[plan.mealPlanId];
                                if (!servicePlan) {
                                    plan.unavailablityReasons.unavailableAtLocation = true;
                                    return;
                                }

                                const isActive = isServicePlanActive(servicePlan);
                                let isAvailable = isActive && plan.isMealPlanApplicable;
                                const mealPlanType = plan.mealPlanType;

                                const zeroDcb = !plan.currentDcbBalance;
                                const zeroMeals = !plan.currentMealPlanBalance;

                                if (mealPlanType === 'DCB' && zeroDcb) {
                                    isAvailable = false;
                                    plan.unavailablityReasons.noBalance = true;
                                } else if (mealPlanType === 'MEAL' && zeroMeals) {
                                    isAvailable = false;
                                    plan.unavailablityReasons.noBalance = true;
                                }

                                plan.isAvailable = isAvailable && plan.enabled;

                                if (!isAvailable) {
                                    plan.unavailablityReasons.disabled = !plan.enabled;
                                    plan.unavailablityReasons.noPriority = plan.priority <= 0;
                                    plan.unavailablityReasons.expired = !plan.mealPlanRunning;
                                    plan.unavailablityReasons.active = isActive;
                                } else {
                                    tempMealPlans.push(plan);
                                }
                            });
                        } else {
                            tempMealPlans = mealPlans.filter((plan) => plan.isAvailable);
                        }

                        for (const mealPlan of tempMealPlans) {
                            if (mealPlan.priority < -1 || mealPlan.expired) {
                                continue;
                            }

                            switch (mealPlan.mealPlanType) {
                                case 'DCB': {
                                    const dcbBalanceCents = new Decimal(mealPlan.remainingServicePeriodDcb).times(100).toDecimalPlaces(2).toNumber();
                                    mealPlanSummary.amountCents += dcbBalanceCents;
                                    mealPlanSummary.enabled = true;
                                    break;
                                }
                                case 'MEAL': {
                                    mealPlanSummary.amountMeals += mealPlan.remainingServicePeriodMeals;
                                    mealPlanSummary.totalMealBalance += mealPlan.currentMealPlanBalance;
                                    mealPlanSummary.enabled = true;
                                    break;
                                }
                            }
                        }
                    }

                    patron.mealPlanSummary = mealPlanSummary;
                    patronObj.fiitMpsAccount = patron;
                    patronObj.firstName = patronObj.fiitMpsAccount.firstName;
                    patronObj.lastName = patronObj.fiitMpsAccount.lastName;
                    patronObj.fullName = patronObj.fiitMpsAccount.firstName + ' ' + patronObj.fiitMpsAccount.lastName;
                    patronObj.photoUrl = patronObj.fiitMpsAccount.photoUrl;
                    patronObj.fiitMpsAccount.mealPlans = patronObj.fiitMpsAccount.mealPlans || [];
                    return Promise.resolve(patronObj);
                } catch (err) {
                    const {data} = err;
                    const error = data && data.message ? data.message : 'Patron Lookup Failed';
                    const code = data ? data.code : null;

                    patronObj.fiitMpsAccount = {error, code};
                    notFoundError.fiitMps = true;

                    if (GatewayFiit.getUnexpectedErrorCodes().includes(code)) {
                        patronObj.fiitMpsAccount.alertShown = true;
                        PosAlertService.showAlertByName('general-error', {
                            title: 'Card Scan Error',
                            message: error
                        });
                    }

                    return Promise.reject(patronObj);
                }
            };

            var preorders = [];

            var _parsePreorders = function () {
                preorders.length = 0;

                var unsortedPreorders = [];
                _.each(patrons.preorder, function (preorderPatron) {
                    var preorder = preorderPatron.lucovaUser.preorder[0];
                    if (preorder.status === 'print_pending') {
                        var printPendingStatus = _.findWhere(preorder.status_history, {status: 'print_pending'});
                        if (printPendingStatus) {
                            var orderReceivedTimestamp = printPendingStatus.ts;
                            preorderPatron.orderReceived = orderReceivedTimestamp;

                            unsortedPreorders.push(preorderPatron);
                        }
                    }
                });

                var sortedPreorders = _.sortBy(unsortedPreorders, function (unsortedPreorder) {
                    return unsortedPreorder.orderReceived; // ascending order
                });
                preorders.push(...sortedPreorders);
            };

            var getPrintPendingOrders = function () {

                if (selectedPreorder) {
                    return [selectedPreorder];
                }
                return [];
            };

            var selectPreorder = function (preorder) {
                selectedPreorder = preorder;
            };

            var hasSelectedPreorder = function () {
                return selectedPreorder ? true : false;
            };

            var clearPreorder = function () {
                selectedPreorder = undefined;
            };

            var _transformPatron = function (patron) {
                var fiitPatronFullName = (patron.fiitPatron) ? patron.fiitPatron.full_name : '';
                var lucovaUserFullName = (patron.lucovaUser) ? patron.lucovaUser.full_name : '';
                var patronFullName = (fiitPatronFullName || lucovaUserFullName) || 'Customer';

                patron.fullName = patronFullName;

                if (patron.fiitPatron) {
                    patron.patronId = patron.fiitPatron.patronId;
                }

                _parseFiitPatronLoyalty(patron);

                return patron;
            };
            var transformPatron = _transformPatron;

            var _parseFiitPatronLoyalty = function (patron) {
                if (patron.fiitPatron) {
                    var mealPlans = patron.fiitPatron.mealPlans || [];
                    var loyaltyMealPlan = _.findWhere(mealPlans, {name: 'Loyalty'});
                    patron.mealPlans = mealPlans.filter((mp) => mp.name != 'Loyalty');

                    patron.loyalty = {
                        points: 0,
                        level: -1,
                        name: '',
                        totalVisits: 0
                    };

                    if (loyaltyMealPlan) {
                        var loyaltyPoints = loyaltyMealPlan.currentMealPlanBalance;

                        patron.loyalty.points = loyaltyPoints;
                        patron.loyalty.level = LoyaltyCollection.calculateNextLoyaltyLevel(loyaltyMealPlan.loyaltyLevel, patron.loyalty);
                        patron.loyalty.name = LoyaltyCollection.getLevelName(patron.loyalty.level);
                        patron.loyalty.totalVisits = loyaltyMealPlan.totalVisits || 0;
                    }
                }
            };

            var _transformPatrons = function (patrons) {
                _.each(patrons, function (patron) {
                    _transformPatron(patron);
                });

                return patrons;
            };

            var _transformFiitPatrons = function (patrons) {
                var result = _.map(patrons, function (patron) {
                    let fullName = patron.firstName + ' ' + patron.lastName;

                    // Test that fullName is not empty /\S/.test(fullName)
                    patron.full_name = /\S/.test(fullName) ? toShortName(fullName) : patron.phoneNumber;

                    // repurpose lucovaUser so we can reuse the same patron template
                    return {
                        fiitPatron: patron,
                        lucovaUser: null
                    };
                });

                return _transformPatrons(result);
            };

            var _transformNearbyLucovaUsers = function (nearbyLucovaUsers) {
                var results = _.map(nearbyLucovaUsers, function (lucovaUser) {
                    lucovaUser.full_name = toShortName(lucovaUser.full_name);
                    return {
                        fiitPatron: null,
                        lucovaUser: lucovaUser,
                        isNearby: true
                    };
                });

                var nearByLucovaUsernames = _.map(nearbyLucovaUsers, function (lucovaUser) {
                    return lucovaUser.user_name;
                });

                return CommonOfflineCache.getPatrons(nearByLucovaUsernames).then(function (foundFiitPatrons) {
                    _.each(results, function (result) {
                        var username = result.lucovaUser.user_name;
                        if (foundFiitPatrons[username]) {
                            result.fiitPatron = foundFiitPatrons[username];
                        }
                    });

                    return _transformPatrons(results);
                }).catch(function (err) {
                    $log.error('Unable to find Patron!');
                    return [];
                });
            };

            var toShortName = function (fullName) {
                if (fullName) {
                    let tempName = fullName.split(' ');
                    if (tempName.length > 1) {
                        tempName[0] += ' ' + tempName[1].charAt(0);
                    }
                    return tempName[0];
                } else {
                    return null;
                }
            };
            var _transformPreorderLucovaUsers = function (preorderLucovaUsers) {
                var results = _.map(preorderLucovaUsers, function (lucovaUser) {
                    lucovaUser.full_name = toShortName(lucovaUser.full_name);
                    return {
                        fiitPatron: null,
                        lucovaUser: lucovaUser
                    };
                });

                var preorderLucovaUsernames = _.map(preorderLucovaUsers, function (lucovaUser) {
                    return lucovaUser.user_name;
                });

                return CommonOfflineCache.getPatrons(preorderLucovaUsernames).then(function (foundFiitPatrons) {
                    _.each(results, function (result) {
                        var username = result.lucovaUser.user_name;
                        if (foundFiitPatrons[username]) {
                            result.fiitPatron = foundFiitPatrons[username];
                        }
                    });

                    return _transformPatrons(results);
                });
            };

            var linkFiitPatron = function (lucovaUser) {
                var query = {
                    patronKey: lucovaUser.user_name
                };
                return Patrons.byPatronKey(query).$promise;
            };

            var searchPatron = function (searchString, type) {
                var filteredPatrons = _.filter(patrons[type], function (patron) {
                    return patron.lucovaUser.full_name.toLowerCase()
                        .indexOf(searchString.toLowerCase()) != -1;
                });

                patrons.search.length = 0;
                patrons.search.push(...filteredPatrons);
            };

            // Builds the photo URL for a given environment (dev, staging, production)
            var getPatronPhoto = function (photoUrl, isCompleteUrl = false) {
                if (photoUrl) {
                    var urlToReturn = (isCompleteUrl) ? photoUrl : EnvConfig.lucovaHost + photoUrl;
                    return 'url("' + urlToReturn + '")';
                } else {
                    // With `ng-src` or `ng-style` background-image, this ensures
                    // undefined url will not trigger an image fetch request.
                    return 'none';
                }
            };

            var isGuestTransaction = function (user) {
                return user.guest;
            };

            var isMobileUser = function (user) {
                return user && (user.lucovaUser || user.fiitPatron);
            };

            const getMessaging = function () {
                return messagingJson.messaging || [];
            };


            var status = {
                cumulative: {
                    totalAmount: 0,
                    totalCount: 0,
                },
                printer: {},
                transactions: []
            };
            var loadStatus = function () {
                if (shift.cashierShiftId) {
                    CashierShift.recentStats({cashierShiftId: shift.cashierShiftId}).$promise.then(function (response) {
                        status.transactions = response.transactions;
                    }).catch();

                    lookupShiftStatus();
                }
            };
            var lookupShiftStatus = function () {
                if (shift.cashierShiftId) {
                    var shiftReportSearch = {
                        shiftId: shift.cashierShiftId,
                        includeCampusStats: !!GatewayFiit.isEnabled()
                    };


                    var currentMenuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                    if (currentMenuPeriod && currentMenuPeriod.menuPeriodId) {
                        shiftReportSearch.menuPeriodId = currentMenuPeriod.menuPeriodId;
                    }

                    let quickChargeSession = CommonOfflineCache.getQuickChargeSession();
                    if (quickChargeSession && quickChargeSession.quickChargeSessionId) {
                        shiftReportSearch.quickChargeSessionId = quickChargeSession.quickChargeSessionId;
                    }

                    CashierShift.shiftReportLight(shiftReportSearch, function (response) {
                        status.cumulative.cashAmount = response.totalCashIn || 0;
                        status.cumulative.cashCollected = response.totalCashCollected || 0;
                        status.cumulative.chargeAmount = response.totalChargeAmount || 0;
                        status.cumulative.creditCardAmount = response.totalCreditCards || 0;
                        status.cumulative.debitCardAmount = response.totalDebitCards || 0;
                        status.cumulative.dcbAmount = response.totalDCBAmount || 0;
                        status.cumulative.mealPlanCount = response.mealPlanCount || 0;
                        status.cumulative.totalCount = response.transactions || 0;
                        status.cumulative.totalMealEqAmount = response.totalMealEqAmount || 0;
                        status.cumulative.totalPayout = response.totalPayout || 0;
                        status.cumulative.cashAdded = response.totalCashAdded || 0;
                        status.cumulative.totalTip = response.totalTipAmount || 0;

                        status.cumulative.totalAmount =
                            status.cumulative.cashAmount
                            + status.cumulative.chargeAmount
                            + status.cumulative.creditCardAmount
                            + status.cumulative.debitCardAmount
                            + status.cumulative.dcbAmount;

                        status.cumulative.totalQuickChargeAmount = response.totalQuickChargeAmount || 0;
                        if (response.campusStats && response.campusStats.length) {
                            data.overviewData.cashCount = response.campusStats[0].cashCount;
                            data.overviewData.cardCount = response.campusStats[0].cardCount;
                            data.overviewData.totalFiitDcbCount = response.campusStats[0].totalFiitDcbCount;
                            data.overviewData.totalFiitMealUnitCount = response.campusStats[0].totalFiitMealUnitCount;
                            data.overviewData.totalFiitSavedMealCardCount = response.campusStats[0].totalFiitSavedMealCardCount;
                            data.fiitTransactionCount =
                                response.campusStats[0].cashCount
                                + response.campusStats[0].cardCount
                                + response.campusStats[0].totalFiitDcbCount
                                + response.campusStats[0].totalFiitMealUnitCount;
                        }
                    }, function (error) {
                        status.cumulative.cashAmount = 0;
                        status.cumulative.chargeAmount = 0;
                        status.cumulative.creditCardAmount = 0;
                        status.cumulative.debitCardAmount = 0;
                        status.cumulative.dcbAmount = 0;
                        status.cumulative.mealPlanCount = 0;
                        status.cumulative.totalCount = 0;
                        status.cumulative.totalAmount = 0;
                        status.cumulative.totalMealEqAmount = 0;
                    });
                }
            };
            var checkPrinterStatus = function () {
                if (shift.posStation && shift.posStation.posPrinters) {
                    // var currentPosStationUrl = PrintService.buildPrinterObj(shift.posStation.printerIpAddress,
                    //    shift.posStation.printerPort, shift.posStation.bluetoothEnabled);
                    var printUrlArray = PrintService.buildPrinterObjArray(
                        shift.posStation.posPrinters,
                        false);
                    return PrintService
                        .testPrinter(printUrlArray)
                        .then(function () {
                            status.printer.status = 1;
                            delete status.printer.error;
                        }, function (error) {
                            status.printer.status = 0;
                            status.printer.error = error;
                        });
                } else {
                    return Promise.resolve();
                }
            };

            var newOrder = function () {
                // POS Order
                return {
                    patron: {},
                    menuItems: {
                        all: [],
                        recent: [],
                        popular: [],
                        current: []
                    },
                    receipt: [],
                    discount: [],
                    balance: {
                        totalMeals: 0,
                        subTotalAmount: 0,
                        taxAmount: 0,
                        totalDiscount: 0,
                        totalAmount: 0,
                        mealError: false,
                        useLoyalty: false
                    },
                    employee: {}
                };
            };

            var startOrder = function (orderObj) {
                var order = newOrder();
                order.patron = orderObj.patron;
                setAllMenuItems(shift.menu, order);
                setCurrentMenuItems(shift.menu, order);


                if (CompanyAttributesService.hasTicketPrePrint()) {
                    // GET a kitchen receipt counter value for this upcoming transaction
                    _acquireReceiptCounterValue();
                }

                return order;
            };

            var incrementInternalOrderCounter = function () {
                // always increment this because we don't know whether an order will end up using
                // the internal order counter (eg. offline) or not
                data.orderCounter++;
            };

            var loadPatronTopItems = function (patronId) {
                let menu = CommonOfflineCache.getCurrentMenu();
                var query = {
                    startDateTime: moment().subtract(6, 'months').valueOf(),
                    endDateTime: moment().valueOf(),
                    patronId: patronId,
                    menuId: menu ? menu.menuId : null
                };

                return Patrons.getTopItems(query).$promise;
            };

            const loadPatronOffers = (patronId, timezone) => {
                return CashierShift.getPatronOffers({patronId, timezone}).$promise;
            };

            var getPopularItemModifiers = function (itemId, locationId, limit) {
                var query = {
                    itemId: itemId,
                    locationId: locationId,
                    limit: limit
                };

                return CashierShift.getItemTopModifiers(query).$promise;
            };

            var findMenuItem = function (menuItems, locationServicePeriodMenuId, order) {
                menuItems = menuItems || order.menuItems.all;

                var foundMenuItem = undefined;
                _.each(menuItems, function (menuItem) {
                    // explicit null check for empty grid squares
                    if (menuItem === null) {
                        return;
                    }

                    if (menuItem.locationServicePeriodMenuId === locationServicePeriodMenuId) {
                        foundMenuItem = menuItem;
                    } else {
                        if (foundMenuItem === undefined && menuItem.children && menuItem.children.length) {
                            foundMenuItem = findMenuItem(menuItem.children, locationServicePeriodMenuId, order);
                        }
                    }
                });

                return foundMenuItem;
            };

            var reloadOrderMenuItems = function (order) {
                return loadShiftMenu().then(function (response) {
                    setAllMenuItems(shift.menu, order);
                    setCurrentMenuItems(shift.menu, order);
                });
            };

            var setAllMenuItems = function (items = [], order) {
                order.menuItems.all.length = 0;
                order.menuItems.all.push(...items); // ES6, concat onto original array
            };

            var setCurrentMenuItems = function (items = [], order) {
                order.menuItems.current.length = 0;
                order.menuItems.current.push(...items); // ES6, concat onto original array
            };

            var signupNewPatron = function (patron) {
                return CashierShift.signupNewPatron({}, patron).$promise;
            };
            var linkGuestToNewPatron = function (patron, tenderAmounts) {
                tenderAmounts.guestTransaction = false;
                tenderAmounts.patronId = patron.patronId;

                return CashierShift.linkTransactionToPatron({}, tenderAmounts).$promise;
            };
            var linkReceiptToPatron = function (transactionId, patronId) {
                return CashierShift.linkReceiptToPatron({transactionId, patronId}).$promise;
            };
            var emailReceipt = function (tenderAmounts) {
                return CashierShift.emailReceipt({}, tenderAmounts).$promise;
            };

            const linkPatron = async (transaction) => {
                let signupObj, patron;

                signupObj = await $modal.open({
                    templateUrl: 'pos/smb/templates/pos.link.patron.tpl.html',
                    windowClass: 'smb-pos smb-pos-popup',
                    animation: false,
                    backdrop: false,
                    keyboard: false
                }).result.catch(async () => {
                    patron = await $modal.open({
                        templateUrl: 'pos/smb/templates/pos.manual.checkin.tpl.html',
                        animation: true,
                        controller: 'SmbManualCheckinCtrl',
                        windowClass: 'smb-pos__checkin',
                        backdrop: false,
                        resolve: {
                            selectPatron: function () {
                                return () => {};
                            },
                            isGatewayFiitEnabled: function () {
                                return false;
                            },
                            isExistingOrder: function () {
                                return true;
                            },
                            showError: function () {
                                return false;
                            },
                            isQuickChargeEnabled: function () {
                                return false;
                            },
                            autoCloseModalAfterScan: () => true,
                            dismissButtonText: () => {}
                        },
                        keyboard: false
                    }).result;
                });

                if (signupObj || patron) {
                    try {
                        if (patron && patron.fiitPatron) {
                            patron = patron.fiitPatron;
                        } else if (signupObj) {
                            patron = await signupNewPatron(signupObj);
                        } else {
                            return Promise.reject();
                        }

                        await linkReceiptToPatron(transaction.transactionId, patron.patronId);
                        transaction.patron = patron;

                        return patron;
                    } catch (error) {
                        PosAlertService.showAlertByName('general-error', {
                            'title': $translate.instant('smb.pos.patronLink.error')
                        });
                        return Promise.reject(error);
                    }
                } else {
                    return Promise.reject();
                }
            };

            var getTransactionResult = function (transactionId, findAcrossOrganization = false) {
                return CashierShift.transactionResult({
                    transactionId: transactionId,
                    findAcrossOrganization: findAcrossOrganization
                }).$promise;
            };

            const addMissingUpc = (payload) => {
                return CashierShift.addMissingUpc(payload).$promise;
            };

            var openDrawer = function () {
                if (shift.posStation) {
                    var printUrlArray = PrintService.buildPrinterObjArray(
                        shift.posStation.posPrinters,
                        false);

                    PrintService.openCashDrawer(printUrlArray, false, false, function (data, attempt) {
                    }, function (error, attempt) {
                        // If unsuccessful, notify the user the cash drawer/printer has been disconnected
                        PosAlertService.showAlertByName('cash-drawer-uninitialized');
                    });
                } else {
                    // If POS does not exist, notify the user the POS has not been initialized
                    PosAlertService.showAlertByName('cash-drawer-uninitialized');
                }
            };

            var openDrawerAsPromise = function (newShift) {
                var posStationToUse;
                if (newShift) {
                    var filteredPosStation = _posStations.filter(function (stationToFilter) {
                        return stationToFilter.id == newShift.posStationId;
                    });

                    posStationToUse = filteredPosStation[0];
                } else {
                    posStationToUse = shift.posStation;
                }
                return $q(function (resolve, reject) {
                    if (posStationToUse && posStationToUse.posPrinters && posStationToUse.posPrinters.length) {
                        var printUrlArray = PrintService.buildPrinterObjArray(
                            posStationToUse.posPrinters,
                            false);

                        PrintService.openCashDrawer(printUrlArray, false, false, function (data, attempt) {
                            resolve();
                        }, function (error, attempt) {
                            // If unsuccessful, notify the user the cash drawer/printer has been disconnected
                            PosAlertService.showAlertByName('cash-drawer-uninitialized');
                            reject();
                        });
                    } else {
                        // If POS does not exist, notify the user the POS has not been initialized
                        PosAlertService.showAlertByName('cash-drawer-uninitialized');
                        reject();
                    }
                });
            };

            // Request pin from user with permission 'pos:cancelitems'
            // Resolves with pinObj from backend
            var cancelItemsRequestPin = function (scope) {
                return new Promise(function (resolve, reject) {
                    var requestedPermission = 'pos:cancelitems';
                    var params = {
                        callback: function (pinObj) {
                            resolve(pinObj);
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var exception = error.exception || {};
                                if (exception.appCode === 412) {
                                    PosAlertService.showAlertByName('cancelitems-invalid-pin');
                                } else {
                                    PosAlertService.showAlertByName('cancelitems-fail', {
                                        message: exception.message || ''
                                    });
                                }
                            }
                            reject(error);
                        },
                        requestedPermission: requestedPermission,
                        message: 'smb.pos.cancelitems.description'
                    };

                    scope.$emit('PincodeAuthentication:Required', params);
                }, function (error) {
                    return Promise.reject(error);
                });
            };

            // Adds all items currently in receipt into cancelled items
            // Used when user "force cancels" by leaving POS receipt screen
            // with items inside the receipt
            var cancelItemsAddReceipt = function (scope) {
                // Only add level 0 (base) items
                var filteredReceipt = _.filter(scope.receipt, {
                    level: 0
                });

                for (var i = 0; i < filteredReceipt.length; i++) {
                    scope.cancelledItemsCount += filteredReceipt[i].quantity;
                    scope.cancelledItemsAmount +=
                        filteredReceipt[i].quantity * filteredReceipt[i].data.currentBundlePrice;
                }
            };

            // Logs all cancelled items into backend as a cancelled order
            var cancelItemsLogToBackend = function (scope) {
                return new Promise(function (resolve, reject) {
                    var logUserActivityAttributes = {
                        locationId: shift.locationId,
                        shiftId: shift.cashierShiftId,
                        userActivityType: 'POS__CANCEL_TRANSACTION',
                        userActivityDetails: [{
                            attribute: 'count',
                            value: scope.cancelledItemsCount
                        }, {
                            attribute: 'amount',
                            value: scope.cancelledItemsAmount
                        }]
                    };

                    if (scope.cancelledItemsUserId) {
                        logUserActivityAttributes.userId = scope.cancelledItemsUserId;
                    }

                    Users.logUserActivity({}, logUserActivityAttributes,
                        function (response) {
                            resolve(response);
                        }, function (error) {
                            reject(error);
                        }
                    );
                });
            };

            var fetchNonLoyaltyMealPlans = function () {
                return new Promise((resolve, reject) => {
                    Lookup.companyMealPlans({companyId: _companyId}).$promise.then((response) => {
                        let f = response.filter((mealPlan) => {
                            return mealPlan.name !== 'Loyalty' && mealPlan.mealPlanDescription !== 'loyalty';
                        });
                        resolve(f);
                    }).catch((error) => {
                        console.log(error);
                        resolve([]);
                    });
                });
            };


            var getDefaultTaxRate = function () {
                var taxes = _taxes || [];
                var defaultTax = taxes.find((tax) => tax && tax.default);

                if (defaultTax && defaultTax.taxRateId) {
                    return defaultTax.taxRate / 100;
                } else {
                    return 0;
                }
            };


            var addDeliveryFeeToTransaction = function (deliverySettings, adjustments) {
                SharedFunctionService.addDeliveryFeeToTransaction(deliverySettings, getDefaultTaxRate(), adjustments);
            };

            var removeDeliveryFromTransaction = function (adjustments) {
                SharedFunctionService.removeDeliveryFromTransaction(adjustments);
            };


            /**
             * This only populates Transaction `adjustments` based on the basket-level discount to be
             * applied but does not calculate and generate the discounts needed per item. The actual
             * caculation/generation of the discounts is in `applyBasketDiscountAndTaxOverrides`. The reason for
             * doing so is so that if the `fullReceipt` is updated, the algorithm should just
             * re-calculate/generate the discounts based on the existing `adjustments` object.
             */
            var populateAdjustmentDiscount = function (discountObj, adjustments) {
                adjustments.percentDiscount = discountObj.percentDiscount || discountObj.labelledDiscount.value || 0;
                adjustments.dollarDiscount = discountObj.dollarDiscount;

                if (discountObj.labelledDiscount && discountObj.labelledDiscount.selectedDiscounts) {
                    adjustments.labelledDiscounts = discountObj.labelledDiscount.selectedDiscounts;
                } else {
                    delete adjustments.labelledDiscounts;
                }
            };

            /**
             * Creates discount `adjustments` required by the discount selection modal from
             * the Transaction `adjustments`. When a discount is selected, the discount information
             * is transformed and stored into Transaction `adjustments` using
             * `populateAdjustmentDiscount`. This function is a reverse of that process
             * in order to tell the discount selectio modal what discount is being applied.
             */
            var createDiscountAdjustments = function (adjustments) {
                var discountAdjustments = angular.copy(adjustments);

                if (discountAdjustments.labelledDiscounts) {
                    var labelledDiscountsObj = {
                        selectedDiscounts: discountAdjustments.labelledDiscounts,
                        value: discountAdjustments.percentDiscount
                    };
                    discountAdjustments.labelledDiscounts = labelledDiscountsObj;
                    discountAdjustments.percentDiscount = 0;
                }

                return discountAdjustments;
            };

            /**
             * Applies basket-level discounts to items. This is a very dumbed-down discount
             * logic strictly only for exchanges. The reason for doing this is because the
             * current discount logic on POS screen is very convoluted and is very hard to
             * test. This version is only dealing the following cases for now:
             * - Basket-level percentage discount (labelled or not)
             * - Basket-level dollar discount
             * - Items without modifiers
             * This is omitting other use cases such as:
             * - Loyalty discount
             * - Item-level percentage/dollar discounts
             * - Item promo
             */
            var applyBasketDiscountAndTaxOverrides = function (fullReceipt, adjustments) {
                _resetDiscounts(fullReceipt);

                CartBuilderService.applyTaxChanges(fullReceipt, adjustments);
                var totalDiscount = _applyPercentDiscountToItems(fullReceipt, adjustments);

                return totalDiscount;
            };

            var _resetDiscounts = function (fullReceipt) {
                for (var fullReceiptItem of fullReceipt) {
                    fullReceiptItem.children = fullReceiptItem.children || [];

                    var discountModifier = _.findWhere(fullReceiptItem.children, {subtype: 'discount'});
                    if (discountModifier) {
                        var discountModifierIndex = fullReceiptItem.children.indexOf(discountModifier);

                        if (discountModifierIndex > -1) {
                            fullReceiptItem.children.splice(discountModifierIndex, 1);
                        }
                    }
                }
            };
            var _applyPercentDiscountToItems = function (fullReceipt, adjustments) {
                if (!adjustments.percentDiscount) {
                    return 0;
                }

                var decimalTotalDiscount = new Decimal(0);

                for (var fullReceiptItem of fullReceipt) {
                    var baseDollar = SharedDataService.baseDollar || 0.01;

                    var decimalPercentageToDiscount = new Decimal(adjustments.percentDiscount);
                    var decimalItemPrice = new Decimal(fullReceiptItem.price);
                    var decimalAmountToDiscount = decimalItemPrice.times(decimalPercentageToDiscount).toNearest(baseDollar);

                    var discountModifier = {
                        active: true,
                        type: 'modifier',
                        locationId: fullReceiptItem.locationId,
                        id: -2,
                        subtype: 'discount',
                        minSelection: -1,
                        maxSelection: -1,
                        mealEquivalencyEnabled: false,
                        loyaltyEnabled: false,
                        children: []
                    };
                    fullReceiptItem.children.push(discountModifier);

                    var displayPercentage = $filter('number')(adjustments.percentDiscount * 100, 2);
                    if (displayPercentage % 1 == 0) {
                        displayPercentage = $filter('number')(displayPercentage, 0);
                    }

                    var percentageString = adjustments.labelledDiscount ? adjustments.labelledDiscount.discountName + ' : ' : '';
                    percentageString = percentageString + displayPercentage;

                    var discountName = $translate.instant('smb.pos.receipt.item.discount.percentage.label', {
                        itemName: fullReceiptItem.name,
                        discountPercentage: percentageString
                    });

                    decimalTotalDiscount = decimalTotalDiscount
                        .plus(decimalAmountToDiscount.times(new Decimal(fullReceiptItem.quantity)));


                    var discount = {
                        active: true,
                        selected: true,
                        workTime: 0,
                        type: 'discount',
                        inventoryId: -1,
                        locationServicePeriodMenuId: -2, // -2 means discount?
                        locationId: fullReceiptItem.locationId,
                        price: -decimalAmountToDiscount.toNumber(),
                        taxRate: fullReceiptItem.taxRate,
                        taxRules: fullReceiptItem.taxRules,
                        taxRateId: fullReceiptItem.taxRateId,
                        name: discountName,
                        subtype: 'discount',
                        quantity: fullReceiptItem.quantity,
                        mealEquivalencyEnabled: false,
                        loyaltyEnabled: false
                    };

                    discountModifier.children.push(discount);
                }

                return decimalTotalDiscount.toNumber();
            };

            // Only print items added/changed since the last kitchen print
            const printNewItemsToKitchen = (receiptItems, transactionObject, {
                buzzerCode,
                isPreorder = false,
                successCallback = () => { },
                orderName,
            } = {}) => {
                // Limit the list to only new items
                let unprintedItems = [];
                let differenceInQuantity;
                let itemToPrint;
                receiptItems.forEach((item) => {
                    if (item.updatedSinceLastKitchenPrint) {
                        // Only mark base items as updated
                        if (item.createdSinceLastKitchenPrint || !!item.subtype) {
                            itemToPrint = item;
                        } else {
                            // We need to deep copy this to avoid messing up the name of the actual item
                            itemToPrint = angular.copy(item);
                            itemToPrint.name = '(UPDATED) ' + itemToPrint.name;
                        }
                        unprintedItems.push(itemToPrint);
                    } else if (item.quantityPrintedToKitchen < item.quantity) {
                        differenceInQuantity = item.quantity - item.quantityPrintedToKitchen;
                        // We need to deep copy this to avoid messing up the quantity of the actual item
                        itemToPrint = angular.copy(item);
                        itemToPrint.quantity = differenceInQuantity;
                        unprintedItems.push(itemToPrint);
                    }
                });

                printKitchenReceipts(unprintedItems, transactionObject, {
                    buzzerCode: buzzerCode,
                    isPreorder: isPreorder,
                    successCallback: successCallback,
                    orderName: orderName,
                });
            };

            var printKitchenReceipts = async function (receiptItemsOriginal, transactionData, {
                buzzerCode,
                isPreorder = false,
                successCallback = () => { },
                orderName,
            } = {}) {
                let receiptItems = angular.copy(receiptItemsOriginal);

                try {
                    _.each(receiptItems, function (item) {
                        item.amount_cents = item.itemPrice * 100;
                        item.printoutType = PRINTOUT_TYPE.KITCHEN_SHEET;
                    });
                    var locationPrinters = getReachablePrinters();
                    var currentPosPrinters = SharedDataService.posStation.posPrinters || [];

                    // Print to each printer the items assigned to it
                    var receiptItemsFiltered = isPreorder ? receiptItems : _generateReceiptHierarchy(receiptItems);

                    await populateSecondaryPrinters(receiptItemsFiltered, locationPrinters);

                    // Whether to separate each item onto individual kitchen sheets
                    var separateItems = CurrentSession.getCompany().attributes.receipt__separate_items_on_secondary === 'true';

                    var labelPrinter = _.findWhere(currentPosPrinters, {labelPrinter: true}) || currentPosPrinters[0] || {};

                    var updatedReceiptItemsPrinterGrouped = _.groupBy(receiptItemsFiltered, 'printerId');
                    for (var updatedKey of Object.keys(updatedReceiptItemsPrinterGrouped)) {
                        var updatedParsedKey = parseInt(updatedKey);

                        if (isNaN(updatedParsedKey)) {
                            continue;
                        }

                        var updatedPosPrinter = _.filter(locationPrinters, {
                            'posPrinterId': updatedParsedKey,
                        });

                        var groupedReceiptItems = updatedReceiptItemsPrinterGrouped[updatedParsedKey];

                        // Code to ensure we do not print blank kitchen receipts
                        if (!groupedReceiptItems || !groupedReceiptItems.length) {
                            continue;
                        }

                        var kitchenSheetData = {};
                        kitchenSheetData.locationName = transactionData.locationName || '(Location)';
                        kitchenSheetData.cashierName = transactionData.cashierName;
                        kitchenSheetData.stationName = transactionData.stationName || '(POS Station)';
                        kitchenSheetData.companyName = transactionData.companyName || '(Company)';
                        kitchenSheetData.receiptId = transactionData.receiptId;
                        kitchenSheetData.transactionDateTime = transactionData.transactionDateTime;
                        kitchenSheetData.patronName = (!transactionData.patronName && transactionData.is_third_party_preorder && transactionData.preorderDetails)
                            ? transactionData.preorderDetails.order_id : transactionData.patronName;
                        kitchenSheetData.transactionTotal = transactionData.transactionTotal;
                        kitchenSheetData.transactionTax = transactionData.transactionTax;
                        kitchenSheetData.posPrinters = locationPrinters;
                        kitchenSheetData.receiptCounter = transactionData.receiptCounter;
                        kitchenSheetData.buzzerCode = buzzerCode || transactionData.buzzerCode;
                        kitchenSheetData.printUrlArray = transactionData.printUrlArray;
                        kitchenSheetData.notes = transactionData.notes;
                        kitchenSheetData.status = transactionData.status;
                        kitchenSheetData.serviceMode = transactionData.serviceMode;

                        var labelSheetData = {};
                        labelSheetData.cashierName = transactionData.cashierName;
                        labelSheetData.receiptId = transactionData.receiptId;
                        labelSheetData.transactionDateTime = transactionData.transactionDateTime;
                        labelSheetData.patronName = transactionData.patronName;
                        labelSheetData.posPrinters = locationPrinters;
                        labelSheetData.receiptCounter = transactionData.receiptCounter;
                        labelSheetData.serviceMode = transactionData.serviceMode;

                        PrintService.printKitchenSheets(kitchenSheetData, groupedReceiptItems, updatedPosPrinter,
                            isPreorder, separateItems, {successCallback: successCallback, orderName: orderName});

                        if (transactionData.status !== 'cancelled') {
                            PrintService.printLabels(labelSheetData, groupedReceiptItems, labelPrinter, isPreorder, separateItems);
                        }
                    }
                } catch (e) {
                    // just to ensure whatever workflow is printing the kitchen sheet doesn't break
                    // because of some uncaught error
                }
            };

            var populateReceiptCounter = function (transactionResponse) {
                if (transactionResponse.receiptId || transactionResponse.receiptCounter) {
                    return;
                }

                var counter;
                if (PosStatusService.isOffline()) {
                    var posStationIds = _.map(_posStations, 'id');
                    var posStationIndex = posStationIds.sort().indexOf(shift.posStationId);

                    var receiptCounterPrefix = (posStationIndex === -1) ? 99 : (posStationIndex + 1);

                    counter = data.orderCounter % 1000;
                    counter = receiptCounterPrefix * 1000 + counter;
                } else {
                    counter = data.orderCounter % 1000;
                }

                transactionResponse.receiptCounter = counter;
            };

            var setCashierShiftForTest = function (newShift) {
                if (EnvConfig.env == 'test') {
                    shift = newShift;
                }
            };

            var startQuickChargeSession = function (isFiitGatewayEnabled, menuPeriodId) {
                if (!isFiitGatewayEnabled) {
                    return Promise.reject({
                        error: 'pos.nown.fiit.disabled'
                    });
                }

                if (!shift || !shift.cashierShiftId) {
                    return Promise.reject({
                        error: 'pos.invalid.shift'
                    });
                }

                if (!menuPeriodId) {
                    return Promise.reject({
                        error: 'pos.invalid.menu.period'
                    });
                }

                var quickChargeSessionPayload = {
                    cashierShiftId: shift.cashierShiftId,
                    menuPeriodId: menuPeriodId
                };
                return CashierShift.startQuickChargeSession(quickChargeSessionPayload).$promise.then(function (resolve) {
                    var quickChargeSession = resolve;
                    if (quickChargeSession.quickChargeItem && quickChargeSession.quickChargeItem.locationServicePeriodMenuId) {
                        _setQuickChargeSession(quickChargeSession);
                        return Promise.resolve(quickChargeSession);
                    } else {
                        return Promise.reject({
                            error: 'pos.error.general',
                            response: {error: 'error.quickChargeSession.invalidItem'}
                        });
                    }
                }, function (error) {
                    return Promise.reject({
                        error: 'pos.error.general',
                        response: error
                    });
                });
            };

            var endQuickChargeSession = function (isFiitGatewayEnabled, quickChargeSessionId) {
                if (!isFiitGatewayEnabled) {
                    return Promise.reject({
                        error: 'pos.nown.fiit.disabled'
                    });
                }

                if (!shift || !shift.cashierShiftId) {
                    return Promise.reject({
                        error: 'pos.invalid.shift'
                    });
                }

                if (!quickChargeSessionId) {
                    return Promise.reject({
                        error: 'pos.invalid.quickcharge.session'
                    });
                }

                var quickChargeSessionPayload = {
                    cashierShiftId: shift.cashierShiftId,
                    quickChargeSessionId: quickChargeSessionId
                };
                return CashierShift.endQuickChargeSession(quickChargeSessionPayload).$promise.then(function (resolve) {
                    _setQuickChargeSession(null);
                    return Promise.resolve(resolve);
                }, function (error) {
                    return Promise.reject({
                        error: 'pos.error.general',
                        response: error
                    });
                });
            };

            var _acquireReceiptCounterValue = function () {
                CashierShift.acquireReceiptCounterValue().$promise.then(
                    function (result) {
                        data.orderCounter = result.receiptCounter;
                    }
                ).catch(function () {
                    // are we offline?
                    incrementInternalOrderCounter();
                });
            };

            var searchMenuItemsByUpc = function (payload) {
                let resourceObj = CashierShift;
                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    resourceObj = Menu;
                }

                return resourceObj.lookupUpc(payload).$promise.then(function (response) {
                    if (response.upc) {
                        if (!CompanyAttributesService.hasMenuV2Enabled()) {
                            resourceObj = Locations;
                        }
                        resourceObj.processItem(response);
                    }

                    var deferred = $q.defer();
                    deferred.resolve(Controller.transformUpcLookupResponse(payload.upc, response));
                    return deferred.promise;
                }, function (error) {
                    if (PosStatusService.isOffline() || error.status <= 0 || error.status > 500) {
                        if (!payload.upc) {
                            return $q.reject(error);
                        }

                        var upc = payload.upc;
                        if (!SharedDataService.menuItemsMap[upc]) {
                            return $q.reject(error);
                        }

                        var deferred = $q.defer();
                        deferred.resolve(Controller.transformUpcLookupResponse(upc, SharedDataService.menuItemsMap[upc]));
                        return deferred.promise;
                    } else {
                        return $q.reject(error);
                    }
                });
            };

            var customerLoyaltyModalResponse;

            var setCustomerLoyaltyModalResponse = function (response) {
                customerLoyaltyModalResponse = response;
            };

            var getCustomerLoyaltyModalResponse = function () {
                return customerLoyaltyModalResponse;
            };

            /**
             * Filters out printers with a one-to-one interface configuration that do not belong to
             * the current pos station. Printers with these interface are generally only accessible
             * by a host device that they are physically connected to
             */
            function getReachablePrinters () {
                let blocklist = [PrintHelper.PRINT_INTERFACE.USB, PrintHelper.PRINT_INTERFACE.SERIAL, PrintHelper.PRINT_INTERFACE.BT];
                let allPrinters = getLocationPrinters();
                let currentStationId = SharedDataService.posStation.posStationId;
                return allPrinters.filter((printer) => blocklist.indexOf(printer.protocol) === -1 || currentStationId === printer.posStationId);
            }

            function getLocationPrinters () {
                let locationPosStations = [SharedDataService.posStation];
                locationPosStations.push(...SharedDataService.locationPosStations);

                let locationPrinters = [];
                locationPosStations.map((posStation) => {
                    locationPrinters.push(...(posStation.posPrinters || []));
                });

                // Ensures that we dont have duplicate printers
                let printersMap = {};
                locationPrinters.forEach((printer) => {
                    printersMap[printer.posPrinterId] = printer;
                });
                locationPrinters = Object.values(printersMap);

                return locationPrinters;
            }

            var loadDataForCampusStats = function () {
                if (!GatewayFiit.isEnabled()) {
                    return;
                }
                var menuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                if (!menuPeriod || !menuPeriod.menuPeriodId) {
                    return;
                }
                var reportSearch = {
                    menuPeriodId: menuPeriod.menuPeriodId,
                    locationId: shift.locationId,
                    shiftId: shift.cashierShiftId
                };

                Reports.getCampusStats(reportSearch).$promise.then((response) => {
                    if (!response.length) {
                        return;
                    }
                    data.overviewData.cashCount = response[0].cashCount;
                    data.overviewData.cardCount = response[0].cardCount;
                    data.overviewData.totalFiitDcbCount = response[0].totalFiitDcbCount;
                    data.overviewData.totalFiitMealUnitCount = response[0].totalFiitMealUnitCount;
                    data.fiitTransactionCount =
                        response[0].cashCount
                        + response[0].cardCount
                        + response[0].totalFiitDcbCount
                        + response[0].totalFiitMealUnitCount;
                });
            };

            /*
            Commented by Nick Simone 2021/Sept/14
                Here we track if a quick charge transaction
                is in progress or not to avoid doing two at
                the same time.
            */
            let quickChargeRunning = false;

            let isQuickChargeRunning = () => {
                return quickChargeRunning;
            };

            let setQuickChargeRunning = (newVal) => {
                quickChargeRunning = newVal;
            };

            return {
                data,
                shift,
                loadShift,
                startShift,
                endShift,
                quickChargeSessionStatus,
                previousEndOfShiftReports,
                loadPreviousEndOfShiftReports,
                printEndOfShiftReport,

                findLocation,
                loadPOSStations,
                loadServicePeriods,
                loadShiftMenu,

                patrons,
                ungroupedUsers,
                suspend,
                linkFiitPatron,
                linkPatron,
                transformPatron,
                loadLucovaUsers,
                loadPreorderHistory,
                loadSuspend,
                lookupSuspend,
                fetchLucovaPatron,
                fetchLucovaPatronByGiftCard,
                refreshLucovaUsers,
                searchPatron,
                getPatronPhoto,
                isGuestTransaction,
                isMobileUser,
                preorders,
                getMessaging,
                status,
                loadStatus,
                checkPrinterStatus,

                startOrder,

                loadPatronTopItems,
                getPopularItemModifiers,

                findMenuItem,
                reloadOrderMenuItems,
                setAllMenuItems,
                setCurrentMenuItems,

                fetchPatronByPatronKey,
                fetchAllPatrons,
                fetchPatronAvailableGCs,
                getPrintPendingOrders,
                selectPreorder,
                clearPreorder,
                hasSelectedPreorder,
                signupNewPatron,
                linkGuestToNewPatron,
                linkReceiptToPatron,
                emailReceipt,
                addMissingUpc,
                getTransactionResult,
                printKitchenReceipts,
                populateReceiptCounter,

                // User-Activity Logged
                openDrawer,
                cancelItemsRequestPin,
                cancelItemsAddReceipt,
                cancelItemsLogToBackend,
                reprintReceipt,
                reprintGiftReceipt,
                populateSecondaryPrinters,
                openDrawerAsPromise,
                emailAndPrintEndOfShiftReport,
                fetchNonLoyaltyMealPlans,

                populateAdjustmentDiscount,
                applyBasketDiscountAndTaxOverrides,
                createDiscountAdjustments,
                searchMenuItemsByUpc,
                loadFormattedMenuPeriods,
                addDeliveryFeeToTransaction,
                setCustomerLoyaltyModalResponse,
                getCustomerLoyaltyModalResponse,
                removeDeliveryFromTransaction,
                startQuickChargeSession,
                endQuickChargeSession,
                setCashierShiftForTest,
                fetchPatronFromFiitBackend,
                switchMenuPeriod,
                updateShiftFiitServicePeriod,
                loadDataForCampusStats,
                getScheduledPreorders,
                searchItems,
                updateShiftMenu,
                loadFormattedMenus,
                updateMenuItemAvailability,
                loadPatronOffers,

                // Quick Charge Status
                isQuickChargeRunning,
                setQuickChargeRunning,
                printNewItemsToKitchen
            };
        }
    ]);
};
