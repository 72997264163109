'use strict';

/* globals isElectron */
const TerminalFixtures = require('./fixtures/terminal.common');

module.exports = (function () {
    const BaseCardTerminal = function ({instanceToProxy = null} = {}) {
        // private variables
        let proxiedInstance = instanceToProxy;

        // instance variables
        this.config = {};

        // accessors methods
        this.getProxiedInstance = function () {
            return proxiedInstance;
        };

    };

    // BEGIN: static props and methods
    // [For]: methods or (public) variables/constants
    // [Definition]: "descriptor: {writable: false, enumarable: false, configurable: false, value: [function Declaration(){}] || string || Object || Number || Array}"
    if (!Object.getOwnPropertyDescriptor(BaseCardTerminal, 'createProxy')) {
        Object.defineProperty(BaseCardTerminal, 'createProxy', {
            value: function (instanceToProxy) {
                let proxy = new BaseCardTerminal({instanceToProxy: instanceToProxy});
                return proxy;
            },
            enumerable: false
        });
    }

    // static util methods
    if (!Object.getOwnPropertyDescriptor(BaseCardTerminal, 'extractXml')) {
        Object.defineProperty(BaseCardTerminal, 'extractXml', {
            enumerable: false,
            value: function (xml2JsonObj, depth = [], type) {
                let property = xml2JsonObj;

                for (let i = 0; i < depth.length; i++) {
                    let key = depth[i];
                    if (!property[key]) {
                        return '';
                    }

                    property = property[key];
                }

                return property[type];
            }
        });
    }
    // END: static props and methods

    const _validate = function () {
        const proxiedInstance = this.getProxiedInstance();
        let isValid = false;

        if (!proxiedInstance) {
            console.error('Unimplemented Method!');
        } else if (typeof proxiedInstance == 'object' && Object.keys(proxiedInstance).length) { // proxiedObject execute implemented method
            isValid = true;
        }

        if (!isElectron()) {
            console.log('[Usage] Running in browser! Use NownPOS application to use card terminals');
        }

        return isValid;
    };

    BaseCardTerminal.prototype.init = function (terminalConfig = {}) {
        if (!isElectron()) {
            console.log('[Usage] Running in browser! Use NownPOS application to use card terminals');
            return;
        }

        const proxiedInstance = this.getProxiedInstance();
        if (proxiedInstance != null && typeof proxiedInstance == 'object') {
            return proxiedInstance.init(terminalConfig);
        }

        if (!terminalConfig || Object.keys(terminalConfig).length == 0) {
            console.error('[init] Terminal configuration isn\'t valid');
        }

        this.config = terminalConfig;
    };
    BaseCardTerminal.prototype.creditSale = function (amountInCents = 0, extraInfo = {}) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().creditSale(amountInCents, extraInfo);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.creditVoid = function (terminalTrxId, transactionObj) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().creditVoid(terminalTrxId, transactionObj);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.creditReturn = function (amountInDollars, transactionObj) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().creditReturn(amountInDollars, transactionObj);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.debitSale = function (amountInDollars, transactionObj) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().debitSale(amountInDollars, transactionObj);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.testSale = function (amountInDollars = '1.00') {
        return this.creditSale(amountInDollars, {transactionUuid: ''});
    };
    BaseCardTerminal.prototype.autoSettle = function (forceClose = true) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().autoSettle(forceClose);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.parseResponse = function (terminalResponseStr) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().parseResponse(terminalResponseStr);
        }

        return Promise.resolve(Object.assign({}, TerminalFixtures.BASE_RESPONSE));
    };
    BaseCardTerminal.prototype.completeMultiPartTransaction = function (transactionObj, complete) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().completeMultiPartTransaction(transactionObj, complete);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.lastTransactionStatus = function (lastTender) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().lastTransactionStatus(lastTender);
        }

        return Promise.reject({});
    };
    BaseCardTerminal.prototype.isCancellableFromPos = function () {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().isCancellableFromPos();
        }

        return false;
    };
    BaseCardTerminal.prototype.cancelFromPos = function (transactionObj) {
        if (_validate.bind(this).call()) {
            return this.getProxiedInstance().cancelFromPos(transactionObj);
        }

        return Promise.reject({});
    };

    Object.freeze(BaseCardTerminal);

    return BaseCardTerminal;
})();
