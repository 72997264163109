'use strict';

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosOrderMenuCtrl', [
        '$scope',
        '$translate',
        '$modal',
        '$modalStack',
        '$timeout',
        'Security',
        'SmbPosService',
        'ProductsService',
        'SharedDataService',
        'SecondaryDisplay',
        'PosStatusService',
        'Locations',
        'PosAlertService',
        'CompanyAttributesService',
        'KioskService',
        'KioskModalService',
        'CommonOfflineCache',
        '$rootScope',
        'SolinkService',
        '$log',
        'HelpService',
        'Platform',
        'BridgedPromise',
        'TRANSACTION_STATUS',
        function (
            $scope,
            $translate,
            $modal,
            $modalStack,
            $timeout,
            Security,
            SmbPosService,
            ProductsService,
            SharedDataService,
            SecondaryDisplay,
            PosStatusService,
            Locations,
            PosAlertService,
            CompanyAttributesService,
            KioskService,
            KioskModalService,
            CommonOfflineCache,
            $rootScope,
            SolinkService,
            $log,
            HelpService,
            Platform,
            BridgedPromise,
            TRANSACTION_STATUS) {

            $scope.hasIosScanner = false;
            $scope.GRID_LOADING_PLACEHOLDER_SIZE = 14;

            if (Platform.isIosWebkit()) {
                BridgedPromise.dispatch('hasQRScanner', {}).then(({hasQRScanner}) => {
                    $scope.hasIosScanner = !!hasQRScanner;
                }).catch((error) => {
                    console.error('IOS container does not support qr scanner. Disabling feature');
                });
            }

            $scope.initIosScanner = () => {
                if ($scope.hasIosScanner) {
                    window.webkit.messageHandlers.bridge.postMessage({'type': 'initBarcodeScanner'});
                }
            };

            if (KioskService.isKiosk()) {
                $scope.$on('$destroy', () => {
                    removeKioskIdleListeners();
                    $rootScope.$broadcast('kiosk::go-home');
                });

                const removeKioskIdleListeners = () => {
                    resetKioskIdleTimer.cancel();
                    document.removeEventListener('click', watchdogInput);
                    document.removeEventListener('keydown', watchdogInput);
                    document.removeEventListener('pointerdown', watchdogInput);

                    KioskService.setTimeoutInitiated(false);
                };

                const addKioskIdleListeners = function () {
                    if (!KioskService.isKioskTimeoutInitiated() && !KioskService.timeoutModalIsDisabled()) {
                        document.addEventListener('click', watchdogInput);
                        document.addEventListener('keydown', watchdogInput);
                        document.addEventListener('pointerdown', watchdogInput);
                        resetKioskIdleTimer();

                        KioskService.setTimeoutInitiated(true);
                    }
                };

                const onTenderStatusChanged = function (event, tenderStatus, params) {
                    if (tenderStatus === TRANSACTION_STATUS.CARD_TERMINAL_PENDING) {
                        removeKioskIdleListeners();
                    } else {
                        addKioskIdleListeners();
                    }
                };

                $rootScope.$on('pos::tenderStatusChanged', onTenderStatusChanged);

                const IDLE_TIMEOUT = 30000; // maximum time an user can idle in ms
                let isTimeoutModalOpen = false;

                // Any relevant input will reset kiosk timeout timer.
                const watchdogInput = (event) => {
                    if (!isTimeoutModalOpen && (event.type == 'click' || event.key == 'Enter' || event.type == 'pointerdown')) {
                        resetKioskIdleTimer();
                    }
                };

                const resetKioskIdleTimer = _.debounce(() => {
                    isTimeoutModalOpen = true;

                    let modal = {};
                    modal['title'] = 'kiosk.are.you.still.there';
                    modal['buttonTitleOk'] = 'general.yes';
                    modal['buttonTitleCancel'] = 'smb.pos.mobile.order.cancel';
                    modal['modalCallback'] = () => {
                        isTimeoutModalOpen = false;
                        resetKioskIdleTimer();
                    };
                    modal['dismissModalCallback'] = (isAutoCancel) => {
                        isTimeoutModalOpen = false;
                        const adjustments = $scope.$parent.$parent.adjustments;
                        const transactionUuid = adjustments.transactionUuid;
                        const transactionStartTime = adjustments.transactionStartTime;
                        const transactionItems = $scope.$parent.$parent.solinkItemList;
                        const removedItems = $scope.$parent.$parent.solinkCancelledItems;
                        const confirm = !isAutoCancel; // user cancellation on timeout modal

                        try {
                            SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }

                        isTimeoutModalOpen = false;
                        removeKioskIdleListeners();
                        $scope.goHome(confirm);
                    };
                    modal['timeOut'] = 10; // seconds
                    modal['cancelBtnImg'] = 'cross';

                    KioskModalService.showModalByName('confirmation', modal);
                }, IDLE_TIMEOUT);

                $scope.orderInProgress = () => {
                    return $scope.fullReceipt.length > 0;
                };

                $scope.$on('kiosk::change-selected-category', (event, category) => {
                    $scope.selectedCategory = category;
                });

                addKioskIdleListeners();
            }

            var currentCompany = Security.getUser().company;
            var serviceLocation = $scope.shiftLocationId;
            var isInvalidUpcAlertOpen = false;
            var upcLookupModal;
            var timer;
            var archivedItemRange = {index: 0, count: 0};
            var cachedTopLevel;

            $scope.logoUrl = currentCompany.logoUrl;
            $scope.companyName = currentCompany.companyName;
            $scope.organizationName = currentCompany.organization.organizationName;
            $scope.giftCardsEnabled = CompanyAttributesService.hasGiftCardsEnabled();
            $scope.favouritePopularItemsKioskEnabled = CompanyAttributesService.enableFavouritePopularItemsKiosk();
            $scope.isMiscItemHidden = currentCompany.attributes.hide_misc_item === 'true';
            $scope.buzzerEnabled = currentCompany.attributes.buzzer_enabled === 'true';
            $scope.currentPageCategory;
            $scope.loyaltyPopularItems = {};
            $scope.locationIsInOntario = false;
            $scope.pageCategories = $scope.currentOrder.menuItems.all;
            $scope.levels = [];
            $scope.isTopLevel = true;
            $scope.archivedItemsReactive = [];
            $scope.itemGroup = {};
            $scope.menu = {
                initialized: false,
                pages: [],
                currentPage: {},
                currentPageIndex: 0
            };

            $scope.searchBar = {
                text: ''
            };

            $scope.constants = {
                menuTypes: [{
                    value: 'all',
                    name: 'smb.pos.menuType.all'
                }, {
                    value: 'recent',
                    name: 'smb.pos.menuType.recent'
                }, {
                    value: 'popular',
                    name: 'smb.pos.menuType.popular'
                }]
            };

            $scope.archivedItemAdapter = {
                adapter: {}
            };

            $scope.archivedItemDatasource = {
                get: function (index, count, success) {
                    let offset = index < 0 ? 0 : index;
                    if (index + count <= 0) {
                        return success([]);
                    }
                    $timeout(function () {
                        if ($scope.isTopLevel && cachedTopLevel && cachedTopLevel.length
                            && archivedItemRange.index === index && archivedItemRange.count === count) {
                            success(cachedTopLevel);
                        } else if ($scope.isTopLevel) {
                            let search = {
                                searchQuery: $scope.searchBar.text,
                                locationId: $scope.locationDetail.locationId,
                                offset: offset,
                                limit: 20,
                                menuPeriodId: (CommonOfflineCache.getCurrentMenuPeriod()) ? CommonOfflineCache.getCurrentMenuPeriod().menuPeriodId : undefined
                            };
                            SmbPosService.searchItems(search).$promise.then((response) => {
                                var sp = (response.posMenuItemEntries || [{entries: []}])[0];
                                var archivedItems = sp.entries.map((e) => {
                                    return {item: e};
                                });

                                archivedItemRange.index = index;
                                archivedItemRange.count = count;
                                cachedTopLevel = archivedItems;
                                success(archivedItems);
                            });
                        } else {
                            success($scope.archivedItems);
                        }
                    });
                }
            };

            $scope.isOffline = function () {
                return PosStatusService.isOffline();
            };

            $scope.truncateString = (str, num) => {
                if (str.length <= num) {
                    return str;
                }

                return str.slice(0, num) + '...';
            };

            $scope.deliveryEnabled = function () {
                return CompanyAttributesService.deliveryEnabled();
            };

            // Adds all items currently in receipt into cancelled items
            var cancelAllItems = function (toLogCancelEvent = true) {
                SmbPosService.cancelItemsAddReceipt($scope);

                if (toLogCancelEvent) {
                    SmbPosService.cancelItemsLogToBackend($scope);
                }

                $scope.cancelledItemsCount = 0;
                $scope.cancelledItemsAmount = 0.0;
                $scope.switchView('start');
                SecondaryDisplay.clearDisplay();

                // close all modals if on the kiosk.
                if (KioskService.isKiosk()) {
                    $modalStack.dismissAll('');
                }
            };

            $scope.addDelivery = function () {
                SmbPosService.addDeliveryFeeToTransaction(CompanyAttributesService.getDeliverySettings(), $scope.$parent.adjustments);
                $scope.$parent.rebuildReceipt();
            };

            $scope.goHome = function (confirm = true) {
                /* Adding a zero delay timeout to certain buttons prevents it from interacting with anything
                 * in the resulting controller (when using buttons to navigate).
                 *
                 * For example, for this goHome function (activated by back button in pos-order-menu),
                 * since the back button is in the same position as the patron search field, without zero delay timeout
                 * tapping the back button would also tap on the search field on the main view and pop up the keyboard.
                 */

                // gather transaction metadata from parent controller
                const adjustments = $scope.$parent.$parent.adjustments;
                const transactionUuid = adjustments.transactionUuid;
                const transactionStartTime = adjustments.transactionStartTime;
                const transactionItems = $scope.$parent.$parent.fullReceipt;
                const removedItems = $scope.$parent.$parent.solinkCancelledItems;
                HelpService.setQueryText('');
                $timeout(function () {
                    // Immediately go home if cart is empty or transaction is done
                    if (PosStatusService.isOffline()
                        || ((!$scope.fullReceipt || $scope.fullReceipt.length === 0 || $scope.isDone)
                            && $scope.cancelledItemsCount === 0)) {
                        // let solink know the user has cancelled their items
                        try {
                            SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                        $scope.switchView('start');
                        SecondaryDisplay.clearDisplay();
                        $modalStack.dismissAll('');
                        return;
                    }

                    if (KioskService.isKiosk()) {
                        if (confirm == true) {
                            const callbackCancelOrder = function () {

                                if (!$scope.cancelledItemsUserId) {
                                    $scope.cancelledItemsUserId = Security.getUser().userId;
                                } else {
                                    SmbPosService.cancelItemsAddReceipt($scope);
                                    $scope.switchView('start');
                                    $modalStack.dismissAll('');
                                    SecondaryDisplay.clearDisplay();
                                }

                                // let solink know the user has cancelled their items
                                try {
                                    SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                                } catch (err) {
                                    $log.error('Failed to send event to Solink', err);
                                }

                                cancelAllItems();
                            };

                            let modalParams = {
                                title: 'Cancel your order?',
                                modalCallback: callbackCancelOrder,
                                timeOut: 10,
                                confirmOnTimeout: true
                            };
                            KioskModalService.showModalByName('confirmation', modalParams);
                        } else {
                            $scope.cancelledItemsUserId = Security.getUser().userId;
                            // let solink know the user has cancelled their items
                            try {
                                SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                            } catch (err) {
                                $log.error('Failed to send event to Solink', err);
                            }

                            cancelAllItems(false);
                        }

                        return;
                    } else if ($scope.fullReceipt && $scope.fullReceipt.length) {
                        SmbPosService.cancelItemsRequestPin($scope).then(function (pinObj) {
                            // Bind user PIN to cancelled order
                            $scope.cancelledItemsUserId = pinObj.user.userId;
                            // let solink know the user has cancelled their items
                            try {
                                SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                            } catch (err) {
                                $log.error('Failed to send event to Solink', err);
                            }

                            cancelAllItems();
                        }, function (error) {
                            console.error(error);
                        });
                    } else {
                        try {
                            SolinkService.sendCancelledTransaction(transactionUuid, transactionStartTime, transactionItems, removedItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                        cancelAllItems(false);
                    }
                });
            };

            $scope.selectUpcItem = function (upc, onErrorCb, onSuccessCb) {
                if ((KioskService.isKiosk() && isInvalidUpcAlertOpen) || upc == '') {
                    return;
                }
                let payload = {
                    upc: upc,
                    serviceLocation: serviceLocation
                };
                SmbPosService.searchMenuItemsByUpc(payload).then(function (response) {
                    switch (response.type) {
                        case 'category':
                            $scope.navigateTree(response);
                            break;
                        case 'item':

                            if (onSuccessCb) {
                                onSuccessCb(response);
                            } else {
                                if (upcLookupModal) {
                                    upcLookupModal.close();
                                }
                                $scope.addMenuItem(response);
                            }
                            break;
                    }

                    if (KioskService.isKiosk() && CompanyAttributesService.goToKioskCartOnUpcScan()) {
                        $rootScope.$emit('KioskUPCScannedSuccess', {});
                    }

                }, function (error) {
                    if (KioskService.isKiosk()) {
                        var kioskModalParams = {
                            subtitle: 'kiosk.error.no-upc',
                            timeOut: 5,
                            buttonTitleOk: 'general.error.okay',
                            modalCallback: function () {
                                isInvalidUpcAlertOpen = false;
                            },
                            dismissModalCallback: function () {
                                isInvalidUpcAlertOpen = false;
                            }
                        };
                        KioskModalService.showModalByName('oops', kioskModalParams);
                        isInvalidUpcAlertOpen = true;

                        if (!CompanyAttributesService.allowUpcMissingEmailAlert()) return;

                        try {
                            let menu = CommonOfflineCache.getCurrentMenu();
                            let missingUpcPayload = {
                                upc: upc,
                                locationId: $scope.shift.location.id,
                                locationName: $scope.shift.location.name,
                                menuId: menu.menuId,
                                menuName: menu.menuName
                            };
                            SmbPosService.addMissingUpc(missingUpcPayload);
                        } catch (error) {
                            $log.error('Failed to add missing UPC to email list', error);
                        }

                        return;
                    }
                    if (onErrorCb) {
                        onErrorCb('An item matching this UPC was not found');
                    } else if (payload.upc !== '' && payload.upc.length > 8) { // standard upc is 12 digits
                        showUpcLookup(payload.upc, 'An item matching this UPC was not found');
                    }
                });
            };

            const showUpcLookup = function (upc, errMessage) {
                if (upcLookupModal) {
                    upcLookupModal.close();
                }
                upcLookupModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.upc.lookup.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'SmbPosUpcLookupCtrl',
                    windowClass: 'smb-pos',
                    resolve: {
                        upc: function () {
                            return upc;
                        },
                        errMessage: function () {
                            return errMessage;
                        },
                        selectUpcItem: function () {
                            return $scope.selectUpcItem;
                        },
                        addInventoryItem: function () {
                            return $scope.addMenuItem;
                        }
                    },
                    keyboard: false
                });
            };

            $scope.getPatronPhoto = function (patron, urlOverride = '') {
                if (!patron && !urlOverride) return;
                if (urlOverride) return SmbPosService.getPatronPhoto(urlOverride);

                let url;
                let isCompleteUrl = true;

                if (patron.photoUrl) {
                    url = patron.photoUrl;
                } else if (patron.lucovaUser) {
                    url = patron.lucovaUser.photo_url;
                    isCompleteUrl = false;
                } else if (patron.fiitPatron) {
                    url = patron.fiitPatron.imageUrl;
                } else if (patron.fiitMpsAccount) {
                    url = patron.fiitMpsAccount.photoUrl;
                }

                if (!url) return;

                return SmbPosService.getPatronPhoto(url, isCompleteUrl);
            };

            $scope.isGuestTransaction = function (user) {
                return SmbPosService.isGuestTransaction(user);
            };

            $scope.setMenuPage = function (currentPage, currentPageIndex) {
                $scope.menu.currentPage = currentPage;
                $scope.menu.currentPageIndex = currentPageIndex;

                if (currentPageIndex === -1) {
                    // Change to list page
                    resetLevel();
                } else {

                    // Clear search bar
                    $scope.searchBar.text = '';

                    // Not list page
                    $scope.crumbs.length = 0;
                    $scope.crumbs.push(currentPage);

                    SmbPosService.setCurrentMenuItems(currentPage.children, $scope.currentOrder);
                    onCurrentMenuItemsUpdated();
                }
            };

            $scope.addMiscItem = function () {
                var currentPosStation = SmbPosService.shift.posStation || {};
                var currentPosPrinters = currentPosStation.posPrinters || [];
                var firstPosSecondaryPrinter = _.findWhere(currentPosPrinters, {kitchenPrinter: true}) || {};

                $translate('smb.pos.menu.misc').then(function (miscItemName) {
                    // Tax rates are by location, so this reference is fine
                    var taxes = SmbPosService.data.taxes || [];
                    var defaultTax = taxes.find((tax) => tax.default) || {};
                    var defaultTaxRate = (defaultTax.taxRate || 0) / 100;
                    var defaultTaxRateId = defaultTax.taxRateId || undefined;

                    var miscItem = {
                        name: miscItemName,
                        taxRate: defaultTaxRate,
                        taxRateId: defaultTaxRateId,
                        children: [],
                        mealEquivalencyEnabled: true,
                        loyaltyEnabled: true,
                        printerId: firstPosSecondaryPrinter.posPrinterId
                    };
                    $scope
                        .openNumpad(miscItem, 'menu')
                        .then($scope.triggerApplyTransactionPercentageDiscount);
                });
            };

            $scope.addBuzzer = function () {
                openBuzzerPinPad().then((buzzerNumber) => {
                    $scope.$parent.setBuzzerCode(parseFloat(buzzerNumber));
                });
            };

            function openBuzzerPinPad () {
                var modalInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return $scope.$parent.buzzerCode;
                        },
                        type: function () {
                            return 'decimal';
                        }
                    },
                    backdrop: true
                });

                return modalInstance.result;
            }

            $scope.selectGiftCard = function () {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showError('error', 'Device Offline!', '', 'Gift Cards are disabled until the device comes online.');
                    return;
                }
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalGiftCard.tpl.html',
                    controller: 'GiftCardController',
                    windowClass: 'smb-pos smb-pos__gift-card-modal smb-pos__gift-card-modal--offset-right',
                    animation: false,
                    backdrop: 'static'
                });

                $scope.order.canReceiveBarcodeForItem = false;
                modalInstance.result.then(function (result) {
                    $scope.order.canReceiveBarcodeForItem = true;

                    if (result) {
                        var giftCard = {
                            name: 'Gift Card ' + result.code.replace(/\w(?=\w{4})/g, '*'),
                            taxRate: 0,
                            children: [],
                            type: 'item',
                            subtype: result.subtype,
                            upc: result.code,
                            mealEquivalencyEnabled: false,
                            loyaltyEnabled: false, // Disallow gift card from loyalty purchase
                        };
                        $scope.addCustomInventoryItem(giftCard, result.amountToReload);
                    }
                }).catch(function (error) {
                    $scope.order.canReceiveBarcodeForItem = true;
                });
            };

            // Adds gift card to cart
            $scope.addGiftCard = function () {
                var testGiftCard = {
                    name: 'Gift Card',
                    taxRate: 0,
                    children: [],
                    type: 'item',
                    subtype: 'giftcard.reload',
                    upc: '1539204791807', // testUpc,
                    mealEquivalencyEnabled: false, // not allow gift card to be purchased with loyalty?
                    loyaltyEnabled: false, // not allow gift card to be purchased with loyalty?
                };

                $scope
                    .openNumpad(testGiftCard, 'menu');
            };

            $scope.viewMenuItemsByType = function (type) {
            };

            var initializeOrUpdateMenuItems = function () {
                let pageIndex = 0;

                if (KioskService.isKiosk()) {
                    pageIndex = KioskService.getMenuPageIndex();
                }

                if ($scope.menu.initialized) {
                    onCurrentMenuItemsUpdated();
                } else if ($scope.pageCategories[pageIndex]) {
                    $scope.setMenuPage($scope.pageCategories[pageIndex], pageIndex);
                    $scope.menu.initialized = true;
                }
            };

            var onCurrentMenuItemsUpdated = function () {
                $scope.itemGroup.layout = $scope.currentOrder.menuItems.current;
            };

            var addDiscountToItem = function (coupon, item) {
                var itemPrice = item.price;
                var discount = 0;

                switch (coupon.data.discount.type) {
                    case 'absolute':
                        discount = Math.max(-itemPrice, -coupon.data.discount.value);
                        break;
                    case 'percentage':
                        discount = Math.max(-itemPrice, -coupon.data.discount.value / 100 * itemPrice);
                        break;
                }

                var discountModifier = {
                    active: true,
                    type: 'modifier',
                    locationId: item.locationId,
                    id: -2
                };
                var discountChildren = [{
                    active: true,
                    selected: true,
                    workTime: 0,
                    type: 'discount',
                    inventoryId: -1,
                    locationServicePeriodMenuId: -2, // -2 means discount?
                    locationId: item.locationId,
                    price: discount,
                    taxRate: item.taxRate,
                    name: coupon.data.name,
                    mealEquivalencyEnabled: true,
                    loyaltyEnabledd: true,
                    isFullyDiscounted: true
                }];
                discountModifier.children = discountChildren;

                item.isFullyDiscounted = true;
                item.children = item.children || [];
                item.children.push(discountModifier);

                $scope.addMenuItem(item);
            };

            var addDiscountToTransaction = function () {
                // TODO
            };

            $scope.addMenuItem = function (item) {
                // add additional call to trigger the receipt controller to apply
                // discount when an item is added
                $scope.$parent.addMenuItem(item);
                $scope.triggerApplyTransactionPercentageDiscount();
            };
            $scope.navigateTree = function (item) {
                if (!KioskService.isKiosk()) {
                    if (item && item.type && item.type === 'category'
                        && CompanyAttributesService.hasRetailMenu()) {
                        // Open new category popup here
                        showCategoryModal(item);
                        return;
                    }
                } else if (item && item.type) {
                    if (item.type === 'category') {
                        var imageUrl;
                        if (item.children && item.children.length) {
                            const firstChild = item.children.find((child) => !!child) || {};

                            if (firstChild.menuItemImages && firstChild.menuItemImages.length) {
                                imageUrl = firstChild.menuItemImages[0].imageUrl;
                            }
                        }

                        $scope.selectedCategory = {
                            name: item.name,
                            description: item.description,
                            imageUrl: imageUrl
                        };
                        $scope.$parent.selectedCategoryChanged($scope.selectedCategory);
                    } else if (item.type === 'item' && item.itemPriceEditable) {
                        $scope.openNumpad(item, 'menu', 'modal-light', item.price).then(() => {
                            $scope.back();
                        });
                        return;
                    }
                }
                // similar to `$scope.addMenuItem`, this triggers the receipt
                // controller to apply discount when an item is added. However,
                // the trigger must be passed in as a callback since the parent
                // `navigateTree` can have 2 applicable outcomes (addItem or
                // modifyItem), but 1 is synchronous but 1 is not.
                $scope.$parent.navigateTree(item, $scope.triggerApplyTransactionPercentageDiscount);
            };

            var showCategoryModal = function (item) {
                // Since price is coming in actual prices and not cents
                var categoryModal = $modal.open({
                    templateUrl: 'pos/pos-category/pos-category.tpl.html',
                    controller: 'PosCategory',
                    windowClass: 'category-modal',
                    animation: false,
                    resolve: {
                        item: function () {
                            return item;
                        },
                    },
                    backdrop: 'static'
                }, function (error) {
                });

                categoryModal.result.then(function (result) {
                    if (result) {
                        $scope.$parent.navigateTree(result, $scope.triggerApplyTransactionPercentageDiscount, false);
                    }
                });
            };

            $scope.showAllMenuItems = function () {
                $scope.setMenuPage({}, -1);
            };

            $scope.searchAction = function () {
                if ($scope.menu.currentPageIndex !== -1 && $scope.searchBar.text !== '') {
                    // Change page to search page
                    $scope.crumbs.length = 0;
                    $scope.setMenuPage({}, -1);

                    if (!$scope.$$phase) {
                        $scope.$apply();
                    }
                }

                if (timer) {
                    $timeout.cancel(timer);
                }
                let timeout = timer ? 500 : 0;
                timer = $timeout(function () {
                    let search = {
                        searchQuery: $scope.searchBar.text,
                        locationId: $scope.locationDetail.locationId,
                        menuPeriodId: (CommonOfflineCache.getCurrentMenuPeriod()) ? CommonOfflineCache.getCurrentMenuPeriod().menuPeriodId : undefined
                    };
                    SmbPosService.searchItems(search).$promise.then((response) => {
                        var sp = (response.posMenuItemEntries || [{entries: []}])[0];
                        var archivedItems = sp.entries.map((e) => {
                            return {item: e};
                        });
                        $scope.archivedItemsReactive.length = 0;
                        $scope.archivedItemsReactive.push(...archivedItems);
                    });
                }, timeout);
            };


            $scope.clearSearch = function () {
                $scope.searchBar.text = '';
                $scope.menu.currentPageIndex = 0;
            };

            var resetCurrentMenu = function (resetNavigation) {
                $scope.currentMenu.length = 0;
                $scope.currentMenu = new Array(44);

                var timestamp = new Date().valueOf();
                for (var i = 0; i < $scope.currentMenu.length; i++) {
                    $scope.currentMenu[i] = {_index: timestamp + '_' + i};
                }

                if (resetNavigation) {
                    $scope.levels = [];
                    $scope.currentPage = 0;
                }
            };

            var createCurrentMenu = function (products) {
                resetCurrentMenu();
                var timestamp = new Date().valueOf();
                _.each(products, function (product, index) {
                    if (product) {
                        var itemIndex = (product.currentCategoryId) ? product.currentCategoryIndex : product.menuOrderId - 1;

                        if (itemIndex >= 0) {
                            $scope.currentMenu[itemIndex] = product;
                        }
                        product._index = timestamp + '_' + product.locationServicePeriodMenuId;
                    }
                });
            };

            var constructArchivedItems = function () {
                $scope.archivedItems.length = 0;
                var currentMenuItemIds = [];

                _.each($scope.currentMenu, function (currentMenuItem) {
                    if (currentMenuItem.locationServicePeriodMenuId) {
                        currentMenuItemIds.push(currentMenuItem.locationServicePeriodMenuId);
                    }
                });

                var category = currentCategory();
                var archivedItems;
                if (category) {
                    currentMenuItemIds.push(category.locationServicePeriodMenuId);
                    archivedItems = _.filter(category.children, function (item) {
                        return ['item', 'category'].indexOf(item.type) > -1
                            && ['modifier_option', 'page'].indexOf(item.subtype) === -1;
                    });
                } else {
                    archivedItems = _.filter($scope.allItems, function (item) {
                        return ['item', 'category'].indexOf(item.type) > -1
                            && ['modifier_option', 'page'].indexOf(item.subtype) === -1;
                    });
                }

                archivedItems = _.sortBy(archivedItems, 'name');
                $scope.archivedItems = _.map(archivedItems, function (i) {
                    return {
                        item: i,
                        sorted: currentMenuItemIds.indexOf(i.locationServicePeriodMenuId) > -1
                    };
                });

                // Filter based on search
                if ($scope.searchBar.text) {
                    var searchTextLowerCase = $scope.searchBar.text.toLowerCase();
                    var filteredResult = [];
                    for (var i = 0; i < $scope.archivedItems.length; i++) {
                        if ($scope.archivedItems[i]) {
                            // Search first by item name, else by UPC (if exists)
                            var itemName = $scope.archivedItems[i].item.name.toLowerCase();
                            if (itemName.indexOf(searchTextLowerCase) !== -1) {
                                filteredResult.push($scope.archivedItems[i]);
                            } else if ($scope.archivedItems[i].item.upc) {
                                var itemUpc = $scope.archivedItems[i].item.upc.toLowerCase();
                                if (itemUpc.indexOf(searchTextLowerCase) !== -1) {
                                    filteredResult.push($scope.archivedItems[i]);
                                }
                            }
                        }
                    }
                    $scope.archivedItems = filteredResult;
                }
            };

            var currentCategory = $scope.currentCategory = function () {
                var depth = $scope.levels.length;
                return (depth) ? $scope.levels[depth - 1].category : undefined;
            };

            $scope.selectMenuItem = function (menuItem, index) {
                switch (menuItem.type) {
                    case 'category':
                        // Open new category popup here
                        if (CompanyAttributesService.hasRetailMenu()) {
                            showCategoryModal(menuItem);
                        } else {
                            $scope.nextLevel(menuItem);
                        }
                        break;
                    case 'item':
                        // Add to cart
                        $scope.navigateTree(menuItem);
                        break;
                }
            };

            // Navigate down a category
            $scope.nextLevel = function (category) {
                $scope.levels.push({
                    category: category,
                    page: $scope.currentPage,
                    searchText: $scope.searchBar.text
                });
                $scope.searchBar.text = '';

                $scope.isTopLevel = false;
                createCurrentMenu(category.children || []);
                constructArchivedItems();
                if ($scope.searchBar.text === '' && $scope.archivedItemAdapter.adapter.reload) {
                    $scope.archivedItemAdapter.adapter.reload();
                }
            };

            // Navigate up a category
            $scope.previousLevel = function () {
                var level = $scope.levels.pop();
                if (level) {
                    var depth = $scope.levels.length;
                    $scope.searchBar.text = level.searchText;
                    if (depth < 1) {
                        $scope.isTopLevel = true;
                        createCurrentMenu($scope.products);
                        constructArchivedItems();
                    } else {
                        var lastLevel = $scope.levels[depth - 1];
                        createCurrentMenu(lastLevel.category.children);
                        constructArchivedItems();
                    }
                    if ($scope.searchBar.text === '' && $scope.archivedItemAdapter.adapter.reload) {
                        $scope.archivedItemAdapter.adapter.reload();
                    }
                }
            };

            // Reset navigation to top level
            var resetLevel = function () {
                $scope.levels.length = 0;
                $scope.isTopLevel = true;
                createCurrentMenu($scope.products);
                constructArchivedItems();

                if ($scope.searchBar.text === '' && $scope.archivedItemAdapter.adapter.reload) {
                    $scope.archivedItemAdapter.adapter.reload();
                }
            };

            var calculateFavouriteItemsSelectedModifiers = function () {
                $scope.loyaltyPopularItems.forEach(function (item) {
                    item.children.forEach(function (modifierCategory) {
                        if (modifierCategory.selectedCount) {
                            modifierCategory.selectedCount = 0;
                        }
                        modifierCategory.children.forEach(function (modifier) {
                            if (modifier.selected) {
                                if (modifierCategory.selectedCount) {
                                    modifierCategory.selectedCount += 1;
                                } else {
                                    modifierCategory.selectedCount = 1;
                                }
                            }
                        });
                    });
                });
            };

            var populatePopularLocationItems = function () {
                $timeout(function () {
                    var itemSalesSearch = {};
                    itemSalesSearch.locationId = $scope.locationDetail.locationId;
                    // get the 3 top items
                    Locations.getTopItems(itemSalesSearch)
                        .$promise
                        .then((response) => {
                            $scope.topThreeItemsByCount = (response.posMenuItemEntries || [{entries: []}])[0].entries;
                        })
                        .catch((error) => {
                            console.log('error getting top 3 popular items');
                        });
                });
            };

            var populateLoyaltyPopularItems = function () {
                $timeout(function () {
                    var patronId = $scope.patron.patronId;
                    SmbPosService.loadPatronTopItems(patronId)
                        .then((response) => {
                            $scope.loyaltyPopularItems = response;
                            calculateFavouriteItemsSelectedModifiers();
                        })
                        .catch((error) => {
                            console.log('error getting user\'s popular orders');
                        });
                });
            };

            var isLocationIsInOntario = function () {
                $scope.locationIsInOntario = ($scope.locationDetail.region === 'ON');
            };

            $scope.$watch('filteredList', function (newValue) {
                if (newValue) {
                    SmbPosService.setCurrentMenuItems(newValue, $scope.currentOrder);

                    initializeOrUpdateMenuItems();
                }
            });

            $scope.$on('pos::open-customer-loyalty', function (event, args) {
                onCurrentMenuItemsUpdated();
            });

            $scope.$on('pos::coupon::add', function (event, type, coupon, item) {
                switch (type) {
                    case 'item':
                        addDiscountToItem(coupon, item);
                        break;
                    case 'transaction':
                        addDiscountToTransaction(coupon);

                }
            });

            $scope.$on('pos::cash-card-discount', function (event, discount) {
                $scope.$parent.addCashCardAdjustment(discount);
            });

            $scope.$on('pos::custom-item::add', function (event, item, amount) {
                $scope.$parent.addCustomInventoryItem(item, amount);
            });

            $scope.$on('pos::item::add', function (event, item) {
                // TODO: add error handling?
                $scope.addMenuItem(item);
            });

            $scope.$on('pos::loyaltyStepItem::add', function (event, itemData) {
                const {item, pointsRequired} = itemData;
                item.quantityModificationDisabled = true;
                item.discountDisabled = true;
                item.itemPriceEditable = false;
                item.detail = {quantity: 1};
                item.loyaltyStepCost = pointsRequired;
                $scope.navigateTree(item);
            });

            $rootScope.$on('kiosk::patron-added', function (event, patron) {
                $scope.currentOrder.patron = patron;
                $scope.patron = patron;
                configureKiosk();
            });

            const configureKiosk = () => {
                if (KioskService.isKiosk()) {
                    isLocationIsInOntario();

                    $scope.isLoyalty = (!!$scope.patron && !!$scope.patron.patronId);
                    if ($scope.isLoyalty) {
                        populateLoyaltyPopularItems();
                    } else {
                        populatePopularLocationItems();
                    }
                }
            };

            const init = function () {
                $scope.locationDetail = SharedDataService.location;
                $scope.menuDetails = CommonOfflineCache.getCurrentMenu();
                $scope.currentMenu = [];
                $scope.archivedItems = [];
                $scope.isMobileUser = SmbPosService.isMobileUser($scope.currentOrder.patron);

                // Load allItems and products from ProductsService
                $scope.allItems = ProductsService.allItems;
                $scope.products = ProductsService.products;

                $scope.loadingGrid = true;
                SmbPosService.reloadOrderMenuItems($scope.currentOrder)
                .then(() => {
                    initializeOrUpdateMenuItems();
                    $scope.loadingGrid = false;
                })
                .catch(() => {
                    $scope.loadingGrid = false;
                });

                configureKiosk();
            };

            init();
        }
    ]);
};
