'use strict';

const AppConstants = require('../freshideas/app-constants');

export function currentSessionService (freshideas) {

    // This service is to store session specific information and is to only store
    // data. Do not inject any dependencies!!!
    freshideas.service('CurrentSession', [
        function () {
            var obj = {
                user: null,
                company: null,
                organization: null,
                gateway: {},
                isHomebase: null,
                autoAcceptPreorders: false,
                neverShowAutoAcceptModal: false,
                isScreenPrintEnabled: false
            };

            var service = {
                setCompany: (company) => {
                    obj.company = angular.copy(company);
                },
                getCompany: () => {
                    return angular.copy(obj.company);
                },
                setUser: (user) => {
                    obj.user = angular.copy(user);
                },
                getUser: () => {
                    return angular.copy(obj.user);
                },
                setOrganization: (organization) => {
                    obj.organization = angular.copy(organization);
                },
                getOrganization: () => {
                    return angular.copy(obj.organization);
                },
                setGateway: (type, value) => {
                    obj.gateway[type] = value;
                },
                getGateway: (type) => {
                    return obj.gateway[type];
                },
                isRootCompany: () => {
                    return (obj.company.companyId === obj.organization.rootCompanyId);
                },
                isCampus: () => {
                    return (Object.keys(obj.organization || {}).length
                            && (obj.organization.type === AppConstants.organizationTypes.CAMPUS));
                },
                setIsHomebase: (value) => {
                    obj.isHomebase = value;
                },
                isHomebase: () => {
                    return obj.isHomebase;
                },
                isAutoAcceptPreorders: () => {
                    return obj.autoAcceptPreorders;
                },
                isNeverShowAutoAcceptModal: () => {
                    return obj.neverShowAutoAcceptModal;
                },
                getOrganizationAppName: () => {
                    return angular.copy(obj.organization.mobileAppName) || 'Nōwn';
                },
                isScreenPrintSession: function () {
                    return obj.isScreenPrintEnabled;
                },
                toggleSessionSettings: function (setting, isEnabled = false) {
                    let key = null;

                    switch (setting) {
                        case 'receiptScreenPrint':
                            key = 'isScreenPrintEnabled';
                        break;
                        case 'preorderAutoConfirm':
                            key = 'autoAcceptPreorders';
                        break;
                        case 'preorderAutoConfirmNever':
                            key = 'neverShowAutoAcceptModal';
                        break;
                        default:
                            // do nothing
                    }

                    if (key) {
                        obj[key] = isEnabled;
                    }
                }
            };

            return service;
        }
    ]);
}
