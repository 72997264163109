'use strict';

const moment = require('moment');
require('moment-timezone');
const currencyCodeLib = require('currency-codes');

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('PaymentProcessorSetupCtrl', [
        '$scope',
        '$timeout',
        '$sce',
        '$q',
        '$translate',
        '$state',
        'Security',
        'WizardHandler',
        'Company',
        'Locations',
        'Users',
        'Settings',
        'PosAlertService',
        'Lookup',
        'Admin',
        'Deliverect',
        'Export',
        '$modal',
        'EnvConfig',
        function ($scope,
            $timeout,
            $sce,
            $q,
            $translate,
            $state,
            Security,
            WizardHandler,
            Company,
            Locations,
            Users,
            Settings,
            PosAlertService,
            Lookup,
            Admin,
            Deliverect,
            Export,
            $modal,
            EnvConfig) {

            const TOGGLE_CATEGORIES = {
                kiosk: 'Kiosk',
                mobile: 'Mobile',
                printing: 'Printing',
                sale: 'Sale',
                security: 'Security',
                tax: 'Tax',
                rewards: 'Rewards',
                other: 'Other'
            };

            const COMPANY_ATTRIBUTES = {
                other_offline_retry_enabled: {
                    name: 'setupWizard.merchant.toggle.force_offline_retry',
                    tooltip: 'setupWizard.merchant.toggle.force_offline_retry.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: true,
                    root: true
                },
                has_mev_box: {
                    name: 'setupWizard.merchant.toggle.has_mev_box',
                    tooltip: 'setupWizard.merchant.toggle.has_mev_box.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: false
                },
                use_transaction_receipt_v1_image: {
                    name: 'setupWizard.merchant.toggle.use_transaction_receipt_v1_image',
                    tooltip: 'setupWizard.merchant.toggle.use_transaction_receipt_v1_image.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: false
                },
                retail__menu: {
                    name: 'setupWizard.merchant.toggle.retail__menu',
                    tooltip: 'setupWizard.merchant.toggle.retail__menu.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                receipt__tax_include_in_price: {
                    name: 'setupWizard.merchant.toggle.receipt__tax_include_in_price',
                    tooltip: 'setupWizard.merchant.toggle.receipt__tax_include_in_price.tooltip',
                    category: TOGGLE_CATEGORIES.tax,
                    enabled: false,
                    root: false
                },
                search_by_sku_only: {
                    name: 'setupWizard.merchant.toggle.search_by_sku_only',
                    tooltip: 'setupWizard.merchant.toggle.search_by_sku_only.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: false
                },
                other__menu_periods_enabled: {
                    name: 'setupWizard.merchant.toggle.other__menu_periods_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__menu_periods_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: false
                },
                delivery_feature_enabled: {
                    name: 'setupWizard.merchant.toggle.delivery_feature_enabled',
                    tooltip: 'setupWizard.merchant.toggle.delivery_feature_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.mobile,
                    enabled: false,
                    root: true
                },
                has_prepared_goods: {
                    name: 'setupWizard.merchant.toggle.has_prepared_goods',
                    tooltip: 'setupWizard.merchant.toggle.has_prepared_goods.tooltip',
                    category: TOGGLE_CATEGORIES.tax,
                    enabled: false,
                    root: true
                },
                kds_enabled: {
                    name: 'setupWizard.merchant.toggle.kds_enabled',
                    tooltip: 'setupWizard.merchant.toggle.kds_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                item_availability_disabled: {
                    name: 'setupWizard.merchant.toggle.item_availability_disabled',
                    tooltip: 'setupWizard.merchant.toggle.item_availability_disabled.tooltip',
                    category: TOGGLE_CATEGORIES.security,
                    enabled: false,
                    root: true
                },
                suspend_enabled: {
                    name: 'setupWizard.merchant.toggle.suspend_enabled',
                    tooltip: 'setupWizard.merchant.toggle.suspend_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.sale,
                    enabled: false,
                    root: true
                },
                receipt__restrict_open_cash_drawer: {
                    name: 'setupWizard.merchant.toggle.receipt__restrict_open_cash_drawer',
                    tooltip: 'setupWizard.merchant.toggle.receipt__restrict_open_cash_drawer.tooltip',
                    category: TOGGLE_CATEGORIES.sale,
                    enabled: false,
                    root: true
                },
                gift_cards_enabled: {
                    name: 'setupWizard.merchant.toggle.gift_cards_enabled',
                    tooltip: 'setupWizard.merchant.toggle.gift_cards_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.sale,
                    enabled: false,
                    root: true
                },
                digital_gift_card_enabled: {
                    name: 'setupWizard.merchant.toggle.digital_gift_card_enabled',
                    tooltip: 'setupWizard.merchant.toggle.digital_gift_card_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.mobile,
                    enabled: false,
                    root: true
                },
                use_transaction_receipt_v2: {
                    name: 'setupWizard.merchant.toggle.use_transaction_receipt_v2',
                    tooltip: 'setupWizard.merchant.toggle.use_transaction_receipt_v2.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: false
                },
                receipt__qrcode_print: {
                    name: 'setupWizard.merchant.toggle.receipt__qrcode_print',
                    tooltip: 'setupWizard.merchant.toggle.receipt__qrcode_print.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: true
                },
                receipt__has_gift_receipt: {
                    name: 'setupWizard.merchant.toggle.receipt__has_gift_receipt',
                    tooltip: 'setupWizard.merchant.toggle.receipt__has_gift_receipt.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: true
                },
                receipt__partial_cut_enabled: {
                    name: 'setupWizard.merchant.toggle.receipt__partial_cut_enabled.label',
                    tooltip: 'setupWizard.merchant.toggle.receipt__partial_cut_enabled.description',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: true
                },
                other__exchange_enabled: {
                    name: 'setupWizard.merchant.toggle.other__exchange_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__exchange_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.sale,
                    enabled: false,
                    root: true
                },
                other__exchange_price_limit_enabled: {
                    name: 'setupWizard.merchant.toggle.other__exchange_price_limit_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__exchange_price_limit_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.sale,
                    enabled: false,
                    root: true
                },
                other__change_tax_enabled: {
                    name: 'setupWizard.merchant.toggle.other__change_tax_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__change_tax_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.tax,
                    enabled: false,
                    root: true
                },
                sevenshifts_enabled: {
                    name: 'setupWizard.merchant.toggle.sevenshifts_enabled',
                    tooltip: 'setupWizard.merchant.toggle.sevenshifts_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                other__disable_products_for_child_companies: {
                    name: 'setupWizard.merchant.toggle.other__disable_products_for_child_companies',
                    tooltip: 'setupWizard.merchant.toggle.other__disable_products_for_child_companies.tooltip',
                    category: TOGGLE_CATEGORIES.security,
                    enabled: false,
                    root: true
                },
                other__preorder_autoconfirm_enabled: {
                    name: 'setupWizard.merchant.toggle.other__preorder_autoconfirm_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__preorder_autoconfirm_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.mobile,
                    enabled: false,
                    root: true
                },
                kiosk_enabled: {
                    name: 'setupWizard.merchant.toggle.kiosk_enabled',
                    tooltip: 'setupWizard.merchant.toggle.kiosk_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.kiosk,
                    enabled: false,
                    root: true
                },
                show_scan_app_on_kiosk: {
                    name: 'setupWizard.merchant.toggle.show_scan_app_on_kiosk',
                    tooltip: 'setupWizard.merchant.toggle.show_scan_app_on_kiosk.tooltip',
                    category: TOGGLE_CATEGORIES.kiosk,
                    enabled: true,
                    root: true
                },
                go_to_kiosk_cart_on_upc_scan: {
                    name: 'setupWizard.merchant.toggle.go_to_kiosk_cart_on_upc_scan',
                    tooltip: 'setupWizard.merchant.toggle.go_to_kiosk_cart_on_upc_scan.tooltip',
                    category: TOGGLE_CATEGORIES.kiosk,
                    enabled: false,
                    root: true
                },
                enable_favourite_popular_items_kiosk: {
                    name: 'setupWizard.merchant.toggle.enable_favourite_popular_items_kiosk',
                    tooltip: 'setupWizard.merchant.toggle.enable_favourite_popular_items_kiosk.tooltip',
                    category: TOGGLE_CATEGORIES.kiosk,
                    enabled: true,
                    root: true
                },
                quicker_charge_enabled: {
                    name: 'setupWizard.merchant.toggle.quicker_charge_enabled',
                    tooltip: 'setupWizard.merchant.toggle.quicker_charge_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                screen_print_enabled: {
                    name: 'setupWizard.merchant.toggle.receipt__screen_print',
                    tooltip: 'setupWizard.merchant.toggle.receipt__screen_print.tooltip',
                    category: TOGGLE_CATEGORIES.printing,
                    enabled: false,
                    root: false
                },
                allow_birthday_rewards: {
                    name: 'setupWizard.merchant.toggle.birthday_rewards',
                    tooltip: 'setupWizard.merchant.toggle.birthday_rewards.tooltip',
                    category: TOGGLE_CATEGORIES.rewards,
                    enabled: false,
                    root: true
                },
                recent_stats_receipt_counter: {
                    name: 'setupWizard.merchant.toggle.receipt_counter',
                    tooltip: 'setupWizard.merchant.toggle.receipt_counter.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: false
                },
                expo_screen_enabled: {
                    name: 'setupWizard.merchant.toggle.expo_screen_enabled',
                    tooltip: 'setupWizard.merchant.toggle.expo_screen_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: true,
                },
                other__foreign_gift_cards_enabled: {
                    name: 'setupWizard.merchant.toggle.other__foreign_gift_cards_enabled',
                    tooltip: 'setupWizard.merchant.toggle.other__foreign_gift_cards_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                loyalty_steps_enabled: {
                    name: 'setupWizard.merchant.toggle.loyalty_steps_enabled',
                    tooltip: 'setupWizard.merchant.toggle.loyalty_steps_enabled.tooltip',
                    category: TOGGLE_CATEGORIES.rewards,
                    enabled: false,
                    root: true
                },
                discount_loyalty_step_modifiers: {
                    name: 'setupWizard.merchant.toggle.discount_loyalty_step_modifiers',
                    tooltip: 'setupWizard.merchant.toggle.discount_loyalty_step_modifiers.tooltip',
                    category: TOGGLE_CATEGORIES.rewards,
                    enabled: false,
                    root: true
                },
                allow_menu_caching: {
                    name: 'setupWizard.merchant.toggle.allow_menu_caching',
                    tooltip: 'setupWizard.merchant.toggle.allow_menu_caching.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
                allow_reactive_patron_lookup: {
                    name: 'setupWizard.merchant.toggle.allow_reactive_patron_lookup',
                    tooltip: 'setupWizard.merchant.toggle.allow_reactive_patron_lookup.tooltip',
                    category: TOGGLE_CATEGORIES.security,
                    enabled: false,
                    root: true
                },
                cashcard_meal_plans_enabled: {
                    name: 'setupWizard.merchant.toggle.cash_card',
                    tooltip: 'setupWizard.merchant.toggle.cash_card.tooltip',
                    category: TOGGLE_CATEGORIES.other,
                    enabled: false,
                    root: true
                },
            };

            $scope.companyAttributesCopy = COMPANY_ATTRIBUTES;
            $scope.merchantSearch = {
                searchString: ''
            };
            $scope.checkSetupPermission();

            $scope.now = new Date();

            $scope.wizardHandler = WizardHandler;
            $scope.currentUser = Security.getUser();

            $scope.allMobileApps = [];

            $scope.canEnter = function (pageName) {
                switch (pageName) {
                    case 'businessInfo':
                        return $scope.isComplete.businessInfo() || $scope.isComplete.basicInfo() || $scope.pagesViewed['business-info'];
                    case 'adminUser':
                        return $scope.isComplete.adminUser() || $scope.isComplete.businessInfo() || $scope.pagesViewed['admin-user'];
                    case 'companyAttributes':
                        return $scope.isComplete.adminUser() || $scope.pagesViewed['company-attributes'];
                    case 'review':
                        return $scope.isComplete.basicInfo() && $scope.isComplete.adminUser() && $scope.isComplete.businessInfo();
                }
            };

            $scope.setPage = function (page, skipRedirect) {
                if ($scope.isNewMerchant && $scope.wizardHandler.wizard()) {
                    var steps = $scope.wizardHandler.wizard().getEnabledSteps();
                    var nextStep = _.findWhere(steps, {title: page}) || {};
                    if (!nextStep.canenter) {
                        return;
                    }
                }

                $scope.page = page;
                $scope.addPageViewed(page);
                $scope.$broadcast('page-changed');
                if (!skipRedirect) {
                    $timeout(function () {
                        if (WizardHandler && WizardHandler.wizard()) {
                            WizardHandler.wizard().goTo(page);
                        }
                    }, 0);
                }
            };
            $scope.pagesViewed = {};
            $scope.addPageViewed = function (page) {
                $scope.pagesViewed[page] = true;
            };
            $scope.$on('wizard:stepChanged', function (event, args) {
                var step = args.step;
                var title = step.title;

                if (event.currentScope.isNewMerchant) {
                    $scope.setPage(title, true);
                }
            });

            $scope.addToAnOrganization = false;

            $scope.addNewOrganization = function () {
                $scope.addToAnOrganization = false;
            };

            $scope.assignToCurrentOrganization = function () {
                var params = {};
                params.requestedPermission = 'settings';
                params.callback = function (pinResponse) {
                    $scope.addToAnOrganization = true;
                };
                params.errorCallback = function (error) {
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            PosAlertService.showAlertByName('manager-pin-invalid', {
                                title: 'general.error.tiles.setup.manager-pin.ttl'
                            });
                        } else {
                            PosAlertService.showAlertByName('manager-pin-invalid', {
                                title: 'general.error.tiles.setup.manager-pin.ttl',
                                message: exception.message || ''
                            });
                        }
                    }
                    $scope.addToAnOrganization = false;
                };
                params.verifyAllUserPinsForCompany = true;
                params.forceCheckIfManagerLogin = true;
                params.message = 'general.popup.manager-pin.ttl';
                $scope.$emit('PincodeAuthentication:Required', params);
            };

            /* $scope.merchant = {
              "name": "Forget Me Not",
              "id": "A1234567",
              "token": "ABCDEFGH",
              "dateEnrolled": "2018/03/01",
              "monthlyFee": 60,
              "mobileFeature": {
                "mobileOrder": false,
                "loyalty": false
              },
              "businessAddress": {
                "street": "487 Adelaide Street West",
                "city": "Toronto",
                "region": "ON",
                "zip": "M5V 1T4"
              },
              "owner": {
                "firstName": "John",
                "lastName": "Doe",
                "phone": "4164007587",
                "email": "john@forgetmenot.com"
              },
              "adminUser": {
                "username": "forgetmenot",
                "firstName": "John",
                "lastName": "Doe",
                "email": "tonywh.chen@gmail.com"
              }
            }; */

            $scope.initializeMerchant = function () {
                return {
                    mobileFeature: {
                        loyalty: true
                    },
                    businessAddress: {},
                    operatingAddress: {},
                    dateEnrolled: new Date(),
                    owner: {},
                    adminUser: {},
                };
            };

            $scope.toggleAlphaPay = function (merchant) {
                let paymentProcessor = merchant.paymentProcessor = (!merchant.paymentProcessor) ? {} : merchant.paymentProcessor;

                if (!paymentProcessor.id) {
                    paymentProcessor.id = 'ALPHAPAY';
                    paymentProcessor.isEnabled = false;
                }

                paymentProcessor.isEnabled = !paymentProcessor.isEnabled;
            };

            $scope.toggleMobileFeature = function (featureName) {
                $scope.merchant.mobileFeature[featureName] = !$scope.merchant.mobileFeature[featureName];
            };

            $scope.toggleLocationMobileAppVisibility = function (merchant) {
                let location = merchant.location;
                if (!location) {
                    return;
                }
                location.mobileAppEnabled = !location.mobileAppEnabled;
            };

            $scope.formatFullAddress = function (address) {
                var fullAddress = '';

                if (address) {
                    fullAddress = '<div class="text-phrase">' + address.street + '</div>'
                        + '<div class="text-phrase">' + address.city + ', ' + address.region + '</div>'
                        + '<div class="text-phrase">' + address.zip + '</div>'
                        + '<div class="text-phrase">' + address.country + '</div>';
                }

                return $sce.trustAsHtml(fullAddress);
            };

            $scope.createMerchant = function () {
                $scope.createMerchantCompany();
            };

            var merchantCreationProgress = {};
            var resetMerchantCreationProgress = function () {
                merchantCreationProgress = {
                    company: undefined,
                    location: undefined,
                    loyalty: undefined,
                    servicePeriod: undefined,
                    adminUser: undefined,
                    managerUserRole: undefined,
                    cashierUserRole: undefined,
                    kdsUserRole: undefined,
                    stationUserRole: undefined,
                    kioskUserRole: undefined,
                    accountantUserRole: undefined,
                };
            };

            $scope.createMerchantCompany = function () {
                var country = $scope.constants.countries.find((country) => country.name == $scope.merchant.businessAddress.country);

                var fields = [];
                var ecommercePaymentProcessors = {};

                const canUpdatePaymentProcessorCredentials = ($scope.isNewMerchant && !$scope.addToAnOrganization)
                    || ($scope.merchant.organization);

                if (canUpdatePaymentProcessorCredentials && $scope.merchant.gateway) {
                    if ($scope.merchant.gateway.id == 'realex') {
                        fields = [
                            {name: 'sub_account', value: $scope.merchant.gateway.subAccount},
                            {name: 'shared_secret', value: $scope.merchant.gateway.sharedSecret},
                            {name: 'merchant_id', value: $scope.merchant.gateway.merchantId},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'paysafe') {
                        fields = [
                            {name: 'account_id', value: $scope.merchant.gateway.accountId},
                            {name: 'username', value: $scope.merchant.gateway.userName},
                            {name: 'password', value: $scope.merchant.gateway.password},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'stripe') {
                        fields = [
                            {name: 'connected_stripe_account', value: $scope.merchant.gateway.accountId},
                            {name: 'percentage', value: $scope.merchant.gateway.percentage},
                            {name: 'base_cents', value: $scope.merchant.gateway.baseCost},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency', value: $scope.merchant.gateway.currency},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'moneris') {
                        fields = [
                            {name: 'user_name', value: $scope.merchant.gateway.userName},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'payeezy') {
                        fields = [
                            {name: 'api_url', value: $scope.merchant.gateway.apiUrl},
                            {name: 'api_key', value: $scope.merchant.gateway.apiKey},
                            {name: 'api_secret', value: $scope.merchant.gateway.apiSecret},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'test') {
                        fields = [
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    }

                    ecommercePaymentProcessors[$scope.merchant.gateway.id] = {
                        type: $scope.merchant.gateway.actualFieldName,
                        fields: fields
                    };
                }

                let paymentProcessors = {};
                if ($scope.merchant.paymentProcessor) {
                    let fields = [];
                    let paymentProcessor = $scope.merchant.paymentProcessor;

                    if (paymentProcessor.id == 'ALPHAPAY') {
                        fields = [
                            {name: 'isEnabled', value: paymentProcessor.isEnabled},
                            {name: 'partner_code', value: paymentProcessor.partnerCode},
                            {name: 'credential_code', value: paymentProcessor.credentialCode},
                        ];
                    }

                    paymentProcessors[$scope.merchant.paymentProcessor.id] = {
                        type: $scope.merchant.paymentProcessor.id,
                        fields: fields,
                    };
                }

                var company = {
                    companyName: $scope.merchant.name,
                    notes: '',
                    ecommercePaymentProcessors: ecommercePaymentProcessors,
                    businessAddress: $scope.merchant.businessAddress,
                    owner: $scope.merchant.owner,
                    manager: $scope.merchant.manager,
                    hasPreorder: !!$scope.merchant.mobileFeature.mobileOrder,
                    hasLoyalty: true, // !!$scope.merchant.mobileFeature.loyalty,
                    monthlyFee: $scope.merchant.monthlyFee,
                    minimumDenomination: 5,
                    timezone: $scope.merchant.timezone,
                    locale: (country) ? country.locale : $scope.constants.defaultLocale,
                    quickBooksJournalSettings: $scope.merchant.quickBooksJournalSettings,
                    adpDepartmentId: $scope.merchant.adpDepartmentId,
                    paymentProcessors: paymentProcessors,
                    organization: $scope.merchant.organization,
                    fsp: null,
                    fsp_logo: null
                };

                if ($scope.addToAnOrganization) {
                    if (!$scope.parent || !$scope.parent.selectedParentCompany || !$scope.parent.selectedParentCompany.id
                        || !$scope.parent.selectedParentCompany.organizationId) {
                        PosAlertService.showAlertByName('general-error', {
                            'title': 'setupWizard.merchant.basicInfo.parent.company.invalid.ttl',
                            'message': 'setupWizard.merchant.basicInfo.parent.company.invalid.msg'
                        });

                        return;
                    }

                    company.parentCompanyId = $scope.parent.selectedParentCompany.id;
                    company.organizationId = $scope.parent.selectedParentCompany.organizationId;
                } else {
                    if (!$scope.organizationMobileApp || !$scope.organizationMobileApp.app
                        || !$scope.organizationMobileApp.app.id) {
                        PosAlertService.showAlertByName('general-error', {
                            'title': 'setupWizard.merchant.basicInfo.mobile.app.invalid.ttl',
                            'message': 'setupWizard.merchant.basicInfo.mobile.app.invalid.msg'
                        });

                        return;
                    }

                    company.organization = company.organization || {};
                    company.organization.mobileAppId = $scope.organizationMobileApp.app.id;
                    if ($scope.merchant.organization && $scope.merchant.organization.type === 'CAMPUS') {
                        company.fsp = $scope.merchant.fsp.name;
                        company.fsp_logo = $scope.merchant.fsp.fsp_logo;
                    }
                }

                if ($scope.merchant.dateEnrolled) {
                    company.dateEnrolled = moment($scope.merchant.dateEnrolled).format('YYYY/MM/DD');
                }

                var createCompanyPromise = (merchantCreationProgress.company) ?
                    Promise.resolve(merchantCreationProgress.company) :
                    Company.add({}, company).$promise;

                $scope.isCreatingMerchant = true;

                return createCompanyPromise.then(function (response) {
                    merchantCreationProgress.company = response;

                    var locationPromise = $scope.createMerchantLocation(response);
                    var adminUserPromise = $scope.createMerchantAdminUser(response);
                    if ($scope.addToAnOrganization) {
                        return $q.all([locationPromise,
                            adminUserPromise]);
                    } else {
                        var managerUserRolePromise = createManagerUserRole(response);
                        var cashierUserRolePromise = createCashierUserRole(response);
                        var kdsUserRolePromise = createKdsUserRole(response);
                        var stationUserRolePromise = createStationUserRole(response);
                        var kioskUserRolePromise = createKioskUserRole(response);
                        var accountantUserRolePromise = createAccountantUserRole(response);

                        return $q.all([locationPromise,
                            adminUserPromise,
                            managerUserRolePromise,
                            cashierUserRolePromise,
                            kdsUserRolePromise,
                            stationUserRolePromise,
                            kioskUserRolePromise,
                            accountantUserRolePromise]);
                    }
                }).then(function (responses) {
                    $scope.showEditor = false;
                    $scope.setPage('review');
                    $scope.loadMerchants();
                }).catch(handleError).then(function () {
                    $scope.isCreatingMerchant = false;
                }).finally(function () {
                    $scope.addNewOrganization();
                });
            };

            $scope.createMerchantLocation = function (company) {
                var location = {
                    companyId: company.companyId,
                    locationName: $scope.merchant.name,
                    street: $scope.merchant.operatingAddress.street,
                    city: $scope.merchant.operatingAddress.city,
                    zip: $scope.merchant.operatingAddress.zip,
                    latitude: $scope.merchant.operatingAddress.latitude,
                    longitude: $scope.merchant.operatingAddress.longitude,
                    region: $scope.merchant.operatingAddress.region
                };

                var createLocationPromise = (merchantCreationProgress.location) ?
                    Promise.resolve(merchantCreationProgress.location) :
                    Locations.add({}, location).$promise;

                return createLocationPromise.then(function (location) {
                    merchantCreationProgress.location = location;

                    var loyaltyMealPlanPromise = (merchantCreationProgress.loyalty) ?
                        Promise.resolve(merchantCreationProgress.loyalty) :
                        Company.createLoyaltyMealPlan({}, location, function (loyalty) {
                            merchantCreationProgress.loyalty = loyalty;
                        }).$promise;


                    var defaultServicePeriodPromise;
                    if (merchantCreationProgress.servicePeriods) {
                        defaultServicePeriodPromise = Promise.resolve(merchantCreationProgress.servicePeriods);
                    } else {
                        var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                        var servicePeriods = [];

                        _.each(days, function (day, dayNum) {
                            var mealEquivalentDayMap = {};
                            var serviceDays = [];
                            var servicePeriod = {
                                code: dayNum,
                                description: day,
                                startTime: '7:00 am',
                                endTime: '11:00 pm',
                                companyId: company.companyId,
                                serviceDays: serviceDays,
                                mealEquivalentDayMap: mealEquivalentDayMap
                            };

                            mealEquivalentDayMap[day] = {
                                active: true,
                                allowedDollarAmount: 0.01,
                                allowedPerCycle: 100000, // max $1000 point redemptoin
                                enabled: true,
                                startTime: '7:00 AM',
                                endTime: '11:00 PM'
                            };

                            serviceDays.push({
                                day: day,
                                enabled: true
                            });

                            servicePeriods.push(servicePeriod);
                        });

                        defaultServicePeriodPromise = Settings.addServicePeriods({}, servicePeriods, function (servicePeriods) {
                            merchantCreationProgress.servicePeriods = servicePeriods;

                            return Locations.createProductPages({}, location).$promise;
                        }).$promise;
                    }

                    return Promise.all([loyaltyMealPlanPromise, defaultServicePeriodPromise]);
                });
            };
            $scope.createMerchantAdminUser = function (company) {
                var user = {
                    username: $scope.merchant.adminUser.username,
                    email: $scope.merchant.adminUser.email,
                    firstname: $scope.merchant.adminUser.firstName,
                    lastname: $scope.merchant.adminUser.lastName,
                    companyId: company.companyId,
                    permission: 'SITEADMIN',
                    overrideRootCompanyCheck: true
                };

                var adminUserPromise = (merchantCreationProgress.adminUser) ?
                    Promise.resolve(merchantCreationProgress.adminUser) :
                    Users.add({}, user, function (adminUser) {
                        merchantCreationProgress.adminUser = adminUser;
                    }).$promise;

                return adminUserPromise;
            };

            var defaultManagerPermission = require('./defaultManagerPermission.json');
            var createManagerUserRole = function (company) {
                var userRoleObj = {
                    roleName: 'Manager',
                    companyId: company.companyId
                };

                var managerUserRolePromise = (merchantCreationProgress.managerUserRole) ?
                    Promise.resolve(merchantCreationProgress.managerUserRole) :
                    Settings.createRole({}, userRoleObj, function (managerUserRole) {
                        merchantCreationProgress.managerUserRole = managerUserRole;

                        var userRolePermissionObj = {
                            roleId: managerUserRole.roleId,
                            roleName: managerUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultManagerPermission)
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, function (response) {
                        }).$promise;
                    }).$promise;

                return managerUserRolePromise;
            };

            var defaultCashierPermission = require('./defaultCashierPermission.json');
            var createCashierUserRole = function (company) {
                var userRoleObj = {
                    roleName: 'Cashier',
                    companyId: company.companyId
                };

                var cashierUserRolePromise = (merchantCreationProgress.cashierUserRole) ?
                    Promise.resolve(merchantCreationProgress.cashierUserRole) :
                    Settings.createRole({}, userRoleObj, function (cashierUserRole) {
                        merchantCreationProgress.cashierUserRole = cashierUserRole;

                        var userRolePermissionObj = {
                            roleId: cashierUserRole.roleId,
                            roleName: cashierUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultCashierPermission)
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, function (response) {
                        }).$promise;
                    }).$promise;

                return cashierUserRolePromise;
            };

            var defaultKdsPermission = require('./defaultKdsPermission.json');
            var createKdsUserRole = function (company) {
                var userRoleObj = {
                    roleName: 'KDS',
                    companyId: company.companyId
                };

                var kdsUserRolePromise = (merchantCreationProgress.kdsUserRole) ?
                    Promise.resolve(merchantCreationProgress.kdsUserRole) :
                    Settings.createRole({}, userRoleObj, function (kdsUserRole) {
                        merchantCreationProgress.kdsUserRole = kdsUserRole;

                        var userRolePermissionObj = {
                            roleId: kdsUserRole.roleId,
                            roleName: kdsUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultKdsPermission)
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, function (response) {
                        }).$promise;
                    }).$promise;

                return kdsUserRolePromise;
            };

            var defaultStationPermission = require('./defaultStationPermission.json');
            var createStationUserRole = function (company) {
                var userRoleObj = {
                    roleName: 'Station',
                    companyId: company.companyId
                };

                var stationUserRolePromise = (merchantCreationProgress.stationUserRole) ?
                    Promise.resolve(merchantCreationProgress.stationUserRole) :
                    Settings.createRole({}, userRoleObj, function (stationUserRole) {
                        merchantCreationProgress.stationUserRole = stationUserRole;

                        var userRolePermissionObj = {
                            roleId: stationUserRole.roleId,
                            roleName: stationUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultStationPermission)
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, function (response) {
                        }).$promise;
                    }).$promise;

                return stationUserRolePromise;
            };


            var defaultKioskPermissions = require('./defaultKioskPermission.json');
            var createKioskUserRole = function (company) {
                var userRoleObj = {
                    roleName: 'Kiosk',
                    companyId: company.companyId
                };

                var kioskUserRolePromise = (merchantCreationProgress.kioskUserRole) ?
                    Promise.resolve(merchantCreationProgress.kioskUserRole) :
                    Settings.createRole({}, userRoleObj, function (kioskUserRole) {
                        merchantCreationProgress.kioskUserRole = kioskUserRole;

                        var userRolePermissionObj = {
                            roleId: kioskUserRole.roleId,
                            roleName: kioskUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultKioskPermissions)
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, function (response) {
                        }).$promise;
                    }).$promise;

                return kioskUserRolePromise;
            };

            var defaultAccountantPermissions = require('./defaultAccountantPermission.json');
            var createAccountantUserRole = (company) => {
                let userRoleObj = {
                    roleName: 'Accountant',
                    companyId: company.companyId,
                };

                let accountantUserRolePromise = merchantCreationProgress.accountantUserRole ?
                    Promise.resolve(merchantCreationProgress.accountantUserRole) :
                    Settings.createRole({}, userRoleObj, (accountantUserRole) => {
                        merchantCreationProgress.accountantUserRole = accountantUserRole;

                        let userRolePermissionObj = {
                            roleId: accountantUserRole.roleId,
                            roleName: accountantUserRole.roleName,
                            enabled: true,
                            permissions: JSON.stringify(defaultAccountantPermissions),
                        };

                        return Settings.updatePermissions({}, userRolePermissionObj, (response) => {
                        }).$promise;
                    }).$promise;

                return accountantUserRolePromise;
            };

            $scope.updateMerchant = function (toExit) {
                var fields = [];

                const canUpdatePaymentProcessorCredentials = ($scope.isNewMerchant)
                    || ($scope.merchant.organization);

                var ecommercePaymentProcessors = {};

                if (canUpdatePaymentProcessorCredentials && $scope.merchant.gateway) {
                    if ($scope.merchant.gateway.id == 'realex') {
                        fields = [
                            {name: 'sub_account', value: $scope.merchant.gateway.subAccount},
                            {name: 'shared_secret', value: $scope.merchant.gateway.sharedSecret},
                            {name: 'merchant_id', value: $scope.merchant.gateway.merchantId},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'paysafe') {
                        fields = [
                            {name: 'account_id', value: $scope.merchant.gateway.accountId},
                            {name: 'username', value: $scope.merchant.gateway.userName},
                            {name: 'password', value: $scope.merchant.gateway.password},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'stripe') {
                        fields = [
                            {name: 'connected_stripe_account', value: $scope.merchant.gateway.accountId},
                            {name: 'percentage', value: $scope.merchant.gateway.percentage},
                            {name: 'base_cents', value: $scope.merchant.gateway.baseCost},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency', value: $scope.merchant.gateway.currency},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'moneris') {
                        fields = [
                            {name: 'user_name', value: $scope.merchant.gateway.userName},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'payeezy') {
                        fields = [
                            {name: 'api_url', value: $scope.merchant.gateway.apiUrl},
                            {name: 'api_key', value: $scope.merchant.gateway.apiKey},
                            {name: 'api_secret', value: $scope.merchant.gateway.apiSecret},
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    } else if ($scope.merchant.gateway.id == 'test') {
                        fields = [
                            {name: 'token', value: $scope.merchant.gateway.token},
                            {name: 'currency_code', value: $scope.merchant.gateway.currency}
                        ];
                    }

                    ecommercePaymentProcessors[$scope.merchant.gateway.id] = {
                        type: $scope.merchant.gateway.actualFieldName,
                        fields: fields
                    };
                }

                let paymentProcessors = {};
                if ($scope.merchant.paymentProcessor) {
                    let fields = [];
                    let paymentProcessor = $scope.merchant.paymentProcessor;

                    if (paymentProcessor.id == 'ALPHAPAY') {
                        fields = [
                            {name: 'isEnabled', value: paymentProcessor.isEnabled},
                            {name: 'partner_code', value: paymentProcessor.partnerCode},
                            {name: 'credential_code', value: paymentProcessor.credentialCode},
                        ];
                    }

                    paymentProcessors[$scope.merchant.paymentProcessor.id] = {
                        type: $scope.merchant.paymentProcessor.id,
                        fields: fields,
                    };
                }

                var company = {
                    companyId: $scope.merchant.companyId,
                    companyName: $scope.merchant.name,
                    clusterId: $scope.merchant.clusterId, // saving selected clusterId
                    notes: '',
                    ecommercePaymentProcessors: ecommercePaymentProcessors,
                    attributes: $scope.merchant.attributes,
                    businessAddress: $scope.merchant.businessAddress,
                    owner: $scope.merchant.owner,
                    manager: $scope.merchant.manager,
                    hasPreorder: !!$scope.merchant.mobileFeature.mobileOrder,
                    hasLoyalty: true, // !!$scope.merchant.mobileFeature.loyalty,
                    monthlyFee: $scope.merchant.monthlyFee,
                    companyType: $scope.merchant.companyType,
                    isSetupComplete: $scope.merchant.isSetupComplete,
                    timezone: $scope.merchant.timezone,
                    organization: $scope.merchant.organization,
                    quickBooksJournalSettings: $scope.merchant.quickBooksJournalSettings,
                    accountStatusNote: $scope.merchant.accountStatusNote,
                    accountTypeNote: $scope.merchant.accountTypeNote,
                    adpDepartmentId: $scope.merchant.adpDepartmentId,
                    paymentProcessors: paymentProcessors,
                    fsp: $scope.merchant.fsp ? $scope.merchant.fsp.name : null,
                    fsp_logo: $scope.merchant.fsp ? $scope.merchant.fsp.fsp_logo : null,
                };

                if (!company.organization || !company.organization.rootCompanyId
                    || (company.companyId == company.organization.rootCompanyId)) {
                    if ($scope.organizationMobileApp && $scope.organizationMobileApp.app
                        && $scope.organizationMobileApp.app.id) {
                        company.organization = company.organization || {};
                        company.organization.mobileAppId = $scope.organizationMobileApp.app.id;
                    }
                }

                if ($scope.merchant.dateEnrolled) {
                    company.dateEnrolled = moment($scope.merchant.dateEnrolled).format('YYYY/MM/DD');
                }

                return Company.update({}, company).$promise
                    .then(updateDeliverectAccountId)
                    .then(updateSolinkClientSecret)
                    .then(updateLocation)
                    .then(updateTimezone)
                    .then(function (response) {
                        if (toExit) {
                            $scope.exitMerchantSetting();
                        }
                    })
                    .catch(handleError);
            };

            $scope.updateMerchantFromLanding = function (merchant) {
                const company = {
                    companyId: merchant.companyId,
                    companyName: merchant.name,
                    notes: '',
                    attributes: merchant.attributes,
                    businessAddress: merchant.businessAddress,
                    owner: merchant.owner,
                    manager: merchant.manager,
                    hasPreorder: !!merchant.mobileFeature.mobileOrder,
                    hasLoyalty: true, // !!merchant.mobileFeature.loyalty,
                    monthlyFee: merchant.monthlyFee,
                    companyType: merchant.companyType,
                    isSetupComplete: merchant.isSetupComplete,
                    timezone: merchant.timezone,
                    organization: merchant.organization,
                    accountStatusNote: merchant.accountStatusNote,
                    accountTypeNote: merchant.accountTypeNote,
                    fsp: $scope.merchant.fsp ? $scope.merchant.fsp.name : null,
                    fsp_logo: $scope.merchant.fsp ? $scope.merchant.fsp.fsp_logo : null,
                };
                return Company.update({}, company).$promise.catch(handleError);
            };

            $scope.solinkConnectionTestResult = '';

            $scope.testSolinkIntegration = async () => {
                const companyId = $scope.merchant.companyId;
                const locationExternalId = companyId.toString();
                // note about solink api: at the moment solink api returns 200 if company
                // clientSecret and xApiKey are correct, in other words, if we are
                // authenticated but it does not tell us if the jsonData we are passing
                // to the api has all the required and valid key-values

                // We should send jsonData as string to the api, because the test api is expecting string for this body param
                const testBookMarkJsonData =
                    `{
                        "state": "CLOSED",
                        "locationExternalId": "${locationExternalId}",
                        "type": "bookmark",
                        "subtype": "Nown Test",
                        "startTime": ${Date.now()},
                        "endTime": ${Date.now()},
                        "details": {
                        "externalId": "${locationExternalId}",
                        "status": "TEST EVENT",
                        "user": "Nown User Testing"
                        }
                    }`;
                $scope.solinkConnectionTestResult = 'Sending test event ...';
                await updateSolinkClientSecret().$promise;
                Company.testSolinkConnection({}, {jsonData: testBookMarkJsonData, companyId: companyId},
                    function (res) {
                        $scope.solinkConnectionTestResult = res.message;
                    }).$promise
                    .catch((err) => {
                        if (err.message) {
                            $scope.solinkConnectionTestResult = err.message;
                        } else if (err.data.error) {
                            $scope.solinkConnectionTestResult = err.data.error;
                        } else {
                            $scope.solinkConnectionTestResult = 'Couldn\'t send test event to Solink successfully';
                        }
                    });
            };

            $scope.openToggleLand = function () {
                $scope.updateCompanyAttributes();
                $scope.setPage('company-attributes');
            };

            $scope.clearLoginSessions = async () => {
                try {
                    const {usersLoggedIn} = await Company.clearLoginSessions({companyId: $scope.merchant.companyId, delete: false}).$promise;
                    await $modal.open({
                        templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                        controller: 'ConfirmDeleteModalCtrl',
                        resolve: {
                            message: function () {
                                return 'Are you sure?';
                            },
                            paramMap: function () {
                                return {};
                            },
                            title: function () {
                                return `Logout ALL (${usersLoggedIn}) Users`;
                            },
                            allowCancel: function () {
                                return true;
                            }
                        }
                    }).result;
                    await Company.clearLoginSessions({companyId: $scope.merchant.companyId, delete: true}).$promise;
                    PosAlertService.showAlertByName('general', {
                        title: 'Success!',
                        message: `All (${usersLoggedIn}) users will be notified to restart.`
                    });
                } catch (err) {
                    if (!err || err == 'false') return;

                    PosAlertService.showAlertByName('general', {
                        title: 'Error!',
                        message: 'There was an error, no restarts scheduled.'
                    });
                    console.error(err);
                }
            };

            $scope.updateCompanyAttributes = function () {
                // Only send the changed attributes
                let attributesPayload = {};
                if (!$scope.changedAttributes || $scope.changedAttributes.size === 0) {
                    return;
                }

                $scope.changedAttributes.forEach((key) => {
                    attributesPayload[key] = $scope.merchant.attributes[key];
                });

                let finalPayload = {};
                finalPayload[$scope.merchant.companyId] = attributesPayload;
                return Company.updateAttributes({}, finalPayload, function (response) {
                    $timeout(function () {
                        $scope.loadAttributes(response[$scope.merchant.companyId]);
                        $scope.merchant.attributes = response[$scope.merchant.companyId];
                        $scope.changedAttributes = new Set();
                    }, 0);
                }).$promise;
            };

            var handleError = function (error) {
                var data = error.data || {};
                var exception = data.exception || {};

                PosAlertService.showAlertByName('general', {
                    message: exception.message || ''
                });
            };

            var updateDeliverectAccountId = function () {
                if ($scope.merchant.deliverectAccount) {
                    const id = $scope.merchant.companyId;
                    const attributeToUpdate = {
                        [id]: {
                            'deliverect_account_id': $scope.merchant.deliverectAccount._id
                        }
                    };
                    return Company.updateAttributes({}, attributeToUpdate);
                }
            };

            var updateSolinkClientSecret = () => {
                const id = $scope.merchant.companyId;
                let attributeToUpdate;
                if ($scope.merchant.solinkClientSecret) {
                    attributeToUpdate = {
                        [id]: {
                            'solink_client_secret': $scope.merchant.solinkClientSecret
                        }
                    };
                } else {
                    attributeToUpdate = {
                        [id]: {
                            'solink_client_secret': -1
                        }
                    };
                }
                return Company.updateAttributes({}, attributeToUpdate);
            };

            var updateLocation = function () {
                var location = {
                    locationId: $scope.merchant.locationId,
                    companyId: $scope.merchant.companyId,
                    locationName: $scope.merchant.name,
                    street: $scope.merchant.operatingAddress.street,
                    city: $scope.merchant.operatingAddress.city,
                    zip: $scope.merchant.operatingAddress.zip,
                    latitude: $scope.merchant.operatingAddress.latitude,
                    longitude: $scope.merchant.operatingAddress.longitude,
                    region: $scope.merchant.operatingAddress.region
                };
                if ($scope.merchant.location) {
                    location.mobileAppEnabled = $scope.merchant.location.mobileAppEnabled;
                }

                return Locations.update({}, location).$promise;
            };

            var updateTimezone = function () {
                var timezone = $scope.merchant.timezone;
                var companyId = $scope.company.companyId;

                var query = {
                    timezone: timezone,
                    companyId: companyId
                };

                return Company.updateTimezone({}, query).$promise;
            };

            $scope.exitMerchantSetting = function (saveMerchant, saveAttributes = false) {
                if (saveMerchant) {
                    $scope.updateMerchant();
                }
                if (saveAttributes) {
                    $scope.updateCompanyAttributes();
                }
                $scope.setPage('review');
            };

            // Commented By Akash Mehta on November 20 2020
            // The reason why we create an outer object is because the dropdown
            // library kind of needs it. Also, we assign the entire search resilt object
            // rather than a particular field because it becomes easy for the library to autopopulate
            // the select field if and when we want to edit a particular company. We might need to find
            // a better alternative than this approach.
            $scope.parent = {};
            $scope.parent.selectedParentCompany = null;

            $scope.organizationMobileApp = {};
            $scope.organizationMobileApp.app = null;

            $scope.isComplete = {
                basicInfo: function () {
                    var isComplete = true;
                    const isBrandNewOrg = ($scope.isNewMerchant && !$scope.addToAnOrganization);
                    const canUpdatePaymentProcessorCredentials = isBrandNewOrg
                        || ($scope.merchant.organization && $scope.merchant.organization.rootCompanyId == $scope.merchant.companyId);

                    if (canUpdatePaymentProcessorCredentials && $scope.merchant.gateway) {
                        switch ($scope.merchant.gateway.id) {
                            case 'realex':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.subAccount && !!$scope.merchant.dateEnrolled && !!$scope.merchant.gateway.sharedSecret && !!$scope.merchant.gateway.merchantId && !!$scope.merchant.gateway.currency;
                                break;
                            case 'paysafe':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.accountId && !!$scope.merchant.gateway.userName && !!$scope.merchant.gateway.password && !!$scope.merchant.gateway.currency;
                                break;
                            case 'stripe':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.accountId && !!$scope.merchant.gateway.percentage && !!$scope.merchant.gateway.baseCost && !!$scope.merchant.gateway.currency && !!$scope.merchant.dateEnrolled;
                                break;
                            case 'moneris':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.userName && !!$scope.merchant.gateway.token && !!$scope.merchant.dateEnrolled && !!$scope.merchant.gateway.currency;
                                break;
                            case 'payeezy':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.apiUrl && !!$scope.merchant.gateway.apiKey && !!$scope.merchant.gateway.apiSecret && !!$scope.merchant.gateway.token && !!$scope.merchant.dateEnrolled && !!$scope.merchant.gateway.currency;
                                break;
                            case 'test':
                                isComplete = !!$scope.merchant.name && !!$scope.merchant.gateway.token && !!$scope.merchant.dateEnrolled && !!$scope.merchant.gateway.currency;
                                break;
                        }
                    }

                    var isMobileAppSelected = !!$scope.organizationMobileApp && !!$scope.organizationMobileApp.app
                        && !!$scope.organizationMobileApp.app.id;
                    if ($scope.addToAnOrganization) {
                        isComplete = isComplete && !!$scope.parent && !!$scope.parent.selectedParentCompany && $scope.parent.selectedParentCompany.id
                            && !!$scope.merchant.name && !!$scope.merchant.dateEnrolled;
                    } else {
                        isComplete = isComplete && !!$scope.merchant.name && !!$scope.merchant.dateEnrolled && isMobileAppSelected;
                    }

                    if (isBrandNewOrg) {
                        isComplete = isComplete && !!$scope.merchant.organization.type;
                    }

                    if ($scope.merchant.organization && $scope.merchant.organization.type === 'CAMPUS') {
                        isComplete = isComplete && !!$scope.merchant.fsp;
                    }

                    return !!(isComplete);
                },
                businessInfo: function () {
                    var businessAddress = $scope.merchant.businessAddress || {};
                    var isBusinessAddressComplete = !!businessAddress.street && !!businessAddress.city && !!businessAddress.region && !!businessAddress.zip;

                    var owner = $scope.merchant.owner || {};
                    var manager = $scope.merchant.manager || {};

                    var isOwnerComplete = !!owner.firstName && !!owner.lastName && !!owner.phone && !!owner.email;
                    var isManagerComplete = !!manager.firstName && !!manager.lastName && !!manager.phone && !!manager.email;

                    var operatingAddress = $scope.merchant.operatingAddress || {};
                    var isOperatingAddressComplete = (
                        !!operatingAddress.street
                        && !!operatingAddress.city
                        && !!operatingAddress.region
                        && !!operatingAddress.zip
                        && !!operatingAddress.latitude
                        && !!operatingAddress.longitude
                        && !!$scope.merchant.timezone);

                    return !!(isBusinessAddressComplete && isOwnerComplete && isManagerComplete && isOperatingAddressComplete);
                },
                adminUser: function () {
                    var adminUser = $scope.merchant.adminUser || {};
                    var isAdminUserComplete = !!adminUser.username && !!adminUser.firstName && !!adminUser.lastName && !!adminUser.email;
                    return !!(isAdminUserComplete);
                }
            };

            $scope.addNewMerchant = function () {
                $scope.pagesViewed = {};
                $scope.showEditor = true;
                $scope.isNewMerchant = true;
                $scope.parent = {};
                $scope.parent.selectedParentCompany = null;
                $scope.organizationMobileApp = {};
                $scope.organizationMobileApp.app = null;
                $scope.addToAnOrganization = false;
                $scope.setPage('basic-info');
                $scope.merchant = $scope.initializeMerchant();
                loadAllCompanies();
                loadAllMobileApps();
                resetMerchantCreationProgress();
            };

            $scope.isLoadingCompanies = false;
            var loadAllCompanies = function () {
                $scope.isLoadingCompanies = true;
                Lookup.companies({}, function (response) {
                    $scope.isLoadingCompanies = false;
                    $scope.allCompanies = response;
                    $scope.allCompanies = _.sortBy($scope.allCompanies, ['name']);
                }, function (error) {
                    $scope.isLoadingCompanies = false;
                });
            };

            $scope.isLoadingMobileApps = false;
            var loadAllMobileApps = function (currentMerchant) {
                $scope.isLoadingMobileApps = true;
                Lookup.mobileApps({}, function (response) {
                    $scope.allMobileApps = response;
                    $scope.allMobileApps = _.sortBy($scope.allMobileApps, ['name']);
                    if (currentMerchant) {
                        var app = $scope.allMobileApps.find((app) => app.id == currentMerchant.organization.mobileAppId);
                        if (app) {
                            $scope.organizationMobileApp.app = app;
                        }
                    }

                    $scope.isLoadingMobileApps = false;
                }, function (error) {
                    $scope.isLoadingMobileApps = false;
                });
            };

            $scope.companyAttributes = null;

            $scope.changedAttributes = new Set();

            $scope.downloadTransactionHistoryCSV = async function () {
                let csvString = '';
                const from = moment().utc().subtract(60, 'days').format('YYYYMMDD');
                const to = moment().utc().format('YYYYMMDD');
                const fileName = `transactions_from_${from}-${to}`;

                try {
                    const orgs = await Admin.getTransactionCountByOrganization().$promise;
                    csvString += 'Organization Name, Transaction Count\r\n';
                    _.forEach(orgs, (org) => {
                        csvString += org.name + ', ' + org.count + '\r\n';
                    });

                    const locs = await Admin.getTransactionCountByLocation().$promise;
                    csvString += '\r\nLocation Name, Transaction Count\r\n';
                    _.forEach(locs, (loc) => {
                        csvString += loc.name + ', ' + loc.count + '\r\n';
                    });

                    Export.downloadCSV(csvString, fileName);
                } catch (err) {
                    PosAlertService.showAlertByName('general-error', {
                        message: 'Could not retrieve transaction data.'
                    });
                }
            };

            $scope.toggleCompanyAttributeValue = function (key, attribute) {
                if (COMPANY_ATTRIBUTES[key].root && $scope.merchant.organization.rootCompanyId !== $scope.merchant.companyId) {
                    return;
                }
                if (attribute.isTextInput) {
                    $scope.merchant.attributes[key] = attribute.text.trim();
                    $scope.changedAttributes.add(key);
                } else {
                    attribute.enabled = !attribute.enabled;
                    $scope.merchant.attributes[key] = attribute.enabled.toString();
                    $scope.changedAttributes.add(key);
                }
            };

            $scope.loadMerchantSetting = function (merchant) {
                $scope.companyAttributes = COMPANY_ATTRIBUTES;
                $scope.categorizedCompanyAttributes = {};
                _.forEach(TOGGLE_CATEGORIES, (category) => {
                    $scope.categorizedCompanyAttributes[category] = {};
                });
                _.forEach($scope.companyAttributes, ((value, id) => {
                    $scope.categorizedCompanyAttributes[value.category][id] = value;
                }));

                Object.keys($scope.companyAttributes).forEach((key) => {
                    let attribute = merchant.attributes[key];
                    if ($scope.companyAttributes[key].isTextInput) {
                        $scope.companyAttributes[key].text = attribute;
                    } else {
                        $scope.companyAttributes[key].enabled = (attribute === 'true');
                    }
                });

                $scope.showEditor = true;
                $scope.isNewMerchant = false;
                $scope.merchant = merchant;
                loadAllMobileApps(merchant);
                $scope.loadAdminUser(merchant);
                loadDeliverectAccounts();
                loadSolinkClientSecret();
                loadLocation(merchant.locationId)
                    .then((location) => merchant.location = location)
                    .catch((error) => console.log(`Unable to Load location for company: ${merchant.companyName}`));

                resetMerchantCreationProgress();
            };

            function loadDeliverectAccounts () {
                $scope.deliverectAccounts = [{
                    _id: -1,
                    name: $translate.instant('general.none')
                }];

                if (EnvConfig.env !== 'production') {
                    return;
                }

                return Deliverect.getDeliverectAccounts().$promise.then((res) => {
                    $scope.deliverectAccounts.push(...res._items);

                    // if a deliverect account has been tied to this account, set it.
                    if ($scope.merchant && $scope.merchant.attributes && $scope.merchant.attributes['deliverect_account_id']) {
                        const foundAccount = $scope.deliverectAccounts.find((account) => {
                            return account._id == $scope.merchant.attributes['deliverect_account_id'];
                        });

                        $scope.merchant.deliverectAccount = foundAccount;
                    }
                });
            }

            var loadSolinkClientSecret = () => {
                $scope.merchant.solinkClientSecret = '';
                if ($scope.merchant && $scope.merchant.attributes && $scope.merchant.attributes['solink_client_secret']) {
                    if ($scope.merchant.attributes['solink_client_secret'] != -1) {
                        $scope.merchant.solinkClientSecret = $scope.merchant.attributes['solink_client_secret'];
                    } else {
                        $scope.merchant.solinkClientSecret = '';
                    }
                }
            };

            function loadLocation (locationId) {
                return Admin.getLocation({'locationId': locationId}).$promise;
            }

            $scope.loadAdminUser = function (merchant) {
                var request = {
                    companyId: merchant.companyId,
                    limit: 2
                };

                Users.listUsers(request, function (response) {
                    var adminUser = _.find(response.entries, function (user) {
                        return (
                            (user.email != 'api@lucova.com' && user.email != 'lucova.logging@lucova.com')
                            || (user.firstname !== 'API USER' && user.lastname !== 'DONT DELETE')
                        );
                    });

                    $scope.merchant.adminUser = {
                        username: adminUser.username,
                        firstName: adminUser.firstname,
                        lastName: adminUser.lastname,
                        email: adminUser.email,
                    };
                });
            };
            $scope.exitMerchantEditor = function () {
                $scope.showEditor = false;
                $scope.isNewMerchant = false;
                $scope.merchant = undefined;
                $scope.organizationMobileApp = {};
                $scope.organizationMobileApp.app = null;

                $scope.loadMerchants();
            };
            $scope.showMerchantSetting = function (merchant) {
                $scope.loadMerchantSetting(merchant || $scope.merchant);
                $scope.setPage('review');
            };

            function resetPagingOptions () {
                $scope.pagingOptions = {
                    startRecord: 0,
                    pageSize: 20,
                    currentPage: 1,
                    offset: 0,
                    length: 0
                };
            }

            $scope.pagesAvailable = function () {
                var itemLen = $scope.pagingOptions.length || $scope.merchants.length;
                var pageSize = $scope.pagingOptions.pageSize;
                return (itemLen > pageSize) ? Math.ceil(itemLen / pageSize) : 1;
            };

            $scope.pageUp = function () {
                var start = $scope.pagingOptions.startRecord;
                var newStart = start + $scope.pagingOptions.pageSize;
                if ($scope.merchants.length > newStart) {
                    $scope.pagingOptions.startRecord = newStart;
                    $scope.pagingOptions.currentPage++;
                }

                /**
                 * Load the extra pages 1 full page before to avoid any delay
                 * Can possibly clean up this logic
                 */
                var rowBeforeEnd = $scope.merchants.length - $scope.pagingOptions.startRecord;
                if (rowBeforeEnd <= ($scope.pagingOptions.pageSize * 2) && $scope.merchants.length < $scope.pagingOptions.length) {
                    $scope.loadMerchants($scope.pagingOptions.offset);
                }
            };

            $scope.pageDown = function () {
                var start = $scope.pagingOptions.startRecord;
                var newIndex = start - $scope.pagingOptions.pageSize;
                if (start > 0) {
                    if (newIndex < 0) {
                        $scope.pagingOptions.startRecord = 0;
                    } else {
                        $scope.pagingOptions.startRecord = newIndex;
                    }
                    $scope.pagingOptions.currentPage--;
                }
            };

            $scope.merchants = [];
            // adding clusterOptions to choose from in GP admin page.
            $scope.clusterList = {
                label: 'Cluster ID',
                options: ['SMB', 'BETA', 'enterprise', 'enterprise-stable', 'campus']
            };
            $scope.accountStatuses = ['Test/Deprecated', 'Demo', 'Root', 'Onboarding', 'Live'];
            $scope.accountTypes = ['Test/Deprecated', 'Churn/Lost', 'Demo/Free Trial', 'SMB', 'SME', 'Retail', 'Campus', 'Root'];

            $scope.loadMerchants = function (offset) {
                if ($scope.loading) {
                    return;
                }
                $scope.loading = true;
                var params = {
                    companyType: 'SMB',
                    ignoreProviders: true,
                    limit: 50,
                    searchString: $scope.merchantSearch.searchString
                };
                if (offset) {
                    params.offset = offset;
                } else {
                    resetPagingOptions();
                    $scope.merchants.length = 0;
                }
                Company.pagedList(params).$promise.then((response) => {
                    $scope.loading = false;
                    $scope.pagingOptions.length = response.length;
                    $scope.pagingOptions.offset = response.offset;

                    var merchants = _.map(response.entries, function (merchant) {
                        var paymentProcessor = merchant.paymentProcessors || [];
                        var paymentProcessorProperties = paymentProcessor.fields || [];

                        var merchantAccountIdObj = _.findWhere(paymentProcessorProperties, {name: 'accountId'});
                        var merchantAccountId = (merchantAccountIdObj) ? merchantAccountIdObj.value : '';

                        var businessAddress = merchant.businessAddress || {};
                        var operatingAddress = merchant.operatingAddress || {};

                        var ecommercePaymentProcessors = merchant.ecommercePaymentProcessors || {};

                        var gateway = {};
                        let companyPaymentProcessor = {};
                        if (ecommercePaymentProcessors.realex_lucova_vault) {
                            let fields = ecommercePaymentProcessors.realex_lucova_vault.fields || [];
                            let subAccountField = fields.find(function (f) {
                                return f.name == 'sub_account';
                            });
                            let sharedSecretField = fields.find(function (f) {
                                return f.name == 'shared_secret';
                            });
                            let merchantIdField = fields.find(function (f) {
                                return f.name == 'merchant_id';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency_code';
                            });

                            gateway = {
                                id: 'realex',
                                name: 'Realex',
                                actualFieldName: 'realex_lucova_vault',
                                subAccount: subAccountField ? subAccountField.value : null,
                                sharedSecret: sharedSecretField ? sharedSecretField.value : null,
                                merchantId: merchantIdField ? merchantIdField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }
                        if (ecommercePaymentProcessors.paysafe) {
                            let fields = ecommercePaymentProcessors.paysafe.fields || [];
                            let accountIdField = fields.find(function (f) {
                                return f.name == 'account_id';
                            });
                            let userNameField = fields.find(function (f) {
                                return f.name == 'username';
                            });
                            let passwordField = fields.find(function (f) {
                                return f.name == 'password';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency_code';
                            });

                            gateway = {
                                id: 'paysafe',
                                name: 'Paysafe',
                                actualFieldName: 'paysafe',
                                accountId: accountIdField ? accountIdField.value : null,
                                userName: userNameField ? userNameField.value : null,
                                password: passwordField ? passwordField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }
                        if (ecommercePaymentProcessors.stripelucovavault) {
                            let fields = ecommercePaymentProcessors.stripelucovavault.fields || [];
                            let accountIdField = fields.find(function (f) {
                                return f.name == 'connected_stripe_account';
                            });
                            let percentageField = fields.find(function (f) {
                                return f.name == 'percentage';
                            });
                            let baseCostField = fields.find(function (f) {
                                return f.name == 'base_cents';
                            });
                            let tokenField = fields.find(function (f) {
                                return f.name == 'token';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency';
                            });

                            gateway = {
                                id: 'stripe',
                                name: 'Stripe',
                                actualFieldName: 'stripelucovavault',
                                accountId: accountIdField ? accountIdField.value : null,
                                percentage: percentageField ? percentageField.value : null,
                                baseCost: baseCostField ? baseCostField.value : null,
                                token: tokenField ? tokenField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }
                        let tokenField;
                        if (ecommercePaymentProcessors.monerislucovavault) {
                            let fields = ecommercePaymentProcessors.monerislucovavault.fields || [];
                            var userNameField = fields.find(function (f) {
                                return f.name == 'user_name';
                            });
                            tokenField = fields.find(function (f) {
                                return f.name == 'token';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency_code';
                            });

                            gateway = {
                                id: 'moneris',
                                name: 'Moneris',
                                actualFieldName: 'monerislucovavault',
                                userName: userNameField ? userNameField.value : null,
                                token: tokenField ? tokenField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }
                        if (ecommercePaymentProcessors.payeezylucovavault) {
                            let fields = ecommercePaymentProcessors.payeezylucovavault.fields || [];
                            let apiUrlField = fields.find(function (f) {
                                return f.name == 'api_url';
                            });
                            let apiKeyField = fields.find(function (f) {
                                return f.name == 'api_key';
                            });
                            let apiSecretField = fields.find(function (f) {
                                return f.name == 'api_secret';
                            });
                            tokenField = fields.find(function (f) {
                                return f.name == 'token';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency_code';
                            });

                            gateway = {
                                id: 'payeezy',
                                name: 'Payeezy',
                                actualFieldName: 'payeezylucovavault',
                                apiUrl: apiUrlField ? apiUrlField.value : null,
                                apiKey: apiKeyField ? apiKeyField.value : null,
                                apiSecret: apiSecretField ? apiSecretField.value : null,
                                token: tokenField ? tokenField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }
                        if (ecommercePaymentProcessors.stripe) {
                            let fields = ecommercePaymentProcessors.stripe.fields || [];
                            tokenField = fields.find(function (f) {
                                return f.name == 'token';
                            });
                            let currencyField = fields.find(function (f) {
                                return f.name == 'currency_code';
                            });

                            gateway = {
                                id: 'test',
                                name: 'Stripe (test mode)',
                                actualFieldName: 'stripe',
                                token: tokenField ? tokenField.value : null,
                                currency: currencyField ? currencyField.value : null
                            };
                        }

                        if (paymentProcessor.ALPHAPAY) {
                            let fields = paymentProcessor.ALPHAPAY.fields || [];
                            let isEnabled = fields.find(function (field) {
                                return field.name == 'isEnabled';
                            });
                            let partnerCode = fields.find(function (field) {
                                return field.name == 'partner_code';
                            });
                            let credentialCode = fields.find(function (field) {
                                return field.name == 'credential_code';
                            });

                            companyPaymentProcessor = {
                                id: 'ALPHAPAY',
                                isEnabled: isEnabled.value || false,
                                partnerCode: partnerCode.value || null,
                                credentialCode: credentialCode.value || null,
                            };
                        }

                        var fsp = {};
                        fsp = $scope.constants.fsp.find(function (f) {
                            return f.name == merchant.fsp;
                        });
                        var result = {
                            attributes: merchant.attributes,
                            companyId: merchant.companyId,
                            clusterId: (merchant.clusterId != null) ? merchant.clusterId : 'SMB', // passing merchant.clusterId to populate in the form, null => SMB in DB initially so return 'SMB'.
                            name: merchant.companyName,
                            accountId: merchantAccountId,
                            dateEnrolled: new Date(merchant.dateEnrolled),
                            monthlyFee: merchant.monthlyFee,
                            mobileFeature: {
                                mobileOrder: merchant.hasPreorder,
                                loyalty: true // merchant.hasLoyalty,
                            },
                            businessAddress: businessAddress,
                            operatingAddress: operatingAddress,
                            isProvider: merchant.isProvider,
                            owner: merchant.owner,
                            manager: merchant.manager,
                            companyType: merchant.companyType,
                            isSetupComplete: merchant.isSetupComplete,
                            locationId: merchant.locationId,
                            timezone: merchant.timezone,
                            gateway: gateway,
                            organization: merchant.organization,
                            quickBooksJournalSettings: merchant.quickBooksJournalSettings,
                            accountStatusNote: merchant.accountStatusNote,
                            accountTypeNote: merchant.accountTypeNote,
                            adpDepartmentId: merchant.adpDepartmentId,
                            paymentProcessor: companyPaymentProcessor,
                            fsp: fsp,
                        };

                        return result;
                    });
                    $scope.merchants.push(...merchants);
                }).catch((err) => {
                    $scope.loading = false;
                });
            };

            var countryTimezones = {
                'Canada': [
                    'America/St_Johns',
                    'America/Halifax',
                    'America/Glace_Bay',
                    'America/Moncton',
                    'America/Goose_Bay',
                    'America/Blanc-Sablon',
                    'America/Toronto',
                    'America/Montreal',
                    'America/Nipigon',
                    'America/Thunder_Bay',
                    'America/Iqaluit',
                    'America/Pangnirtung',
                    'America/Resolute',
                    'America/Atikokan',
                    'America/Rankin_Inlet',
                    'America/Winnipeg',
                    'America/Rainy_River',
                    'America/Regina',
                    'America/Swift_Current',
                    'America/Edmonton',
                    'America/Cambridge_Bay',
                    'America/Yellowknife',
                    'America/Inuvik',
                    'America/Creston',
                    'America/Dawson_Creek',
                    'America/Vancouver',
                    'America/Whitehorse',
                    'America/Dawson',
                    'Canada/Newfoundland',
                    'Canada/Atlantic',
                    'Canada/Eastern',
                    'Canada/Central',
                    'Canada/Saskatchewan',
                    'Canada/Mountain',
                    'Canada/Pacific',
                    'Canada/Yukon'
                ],
                'United States': [
                    'Canada/Eastern',
                    'Canada/Central',
                    'Canada/Mountain',
                    'Canada/Pacific',
                    'America/Juneau',
                    'Pacific/Honolulu'
                ],
                'Japan': [
                    'Asia/Tokyo'
                ],
                'United Kingdom': [
                    'Europe/London'
                ]
            };

            let canadaCurrencyObject = {
                code: 'CAD',
                currency: currencyCodeLib.code('CAD').currency
            };

            let usCurrencyObject = {
                code: 'USD',
                currency: currencyCodeLib.code('USD').currency
            };

            $scope.realexCurrencyCodes = [canadaCurrencyObject];

            $scope.paysafeCurrencyCodes = [canadaCurrencyObject, usCurrencyObject];

            $scope.stripeCurrencyCodes = [canadaCurrencyObject, usCurrencyObject];

            $scope.monerisCurrencyCodes = [canadaCurrencyObject];

            $scope.payeezyCurrencyCodes = [usCurrencyObject];

            $scope.parseTimezoneOptions = function () {
                if ($scope.merchant.operatingAddress.country) {
                    var timezones = countryTimezones[$scope.merchant.operatingAddress.country];
                    $scope.timezoneOptions = _.map(timezones, function (timezone) {
                        var cityOrRegion = timezone.split('/')[1].replace(/_/g, ' ');
                        var country = $scope.merchant.operatingAddress.country;
                        var offset = moment().tz(timezone).format('Z');

                        var timezoneName = country + ' - ' + cityOrRegion + ' (GMT ' + offset + ')';

                        return {
                            timezoneString: timezone,
                            timezoneName: timezoneName
                        };
                    });
                }
            };

            $scope.clearSearchMerchants = function () {
                $scope.merchantSearch.searchString = '';
                $scope.loadMerchants();
            };

            $scope.openSettings = () => {
                $state.go('freshideas.settings');
            };

            $scope.onGatewayChange = () => {
                if ($scope.merchant.gateway.id === 'test') {
                    $scope.merchant.gateway.token = 'sk_test_Qufy0eCROHt4ma0VcmSgNOQO';
                    $scope.merchant.gateway.currency = canadaCurrencyObject.code;
                }
            };

            $scope.init = function () {
                $scope.isEditing = false;
                $scope.isNewMerchant = false;
                $scope.loadMerchants();
            };
            $scope.init();
        },
    ]);
};
