'use strict';

const BaseCardTerminal = require('./base.cardterminal');
const FreedomPayMiddleWare = require('./freedompay.win-fcc');
const TerminalFixtures = require('./fixtures/terminal.common');

module.exports = {
    TYPE: TerminalFixtures.CARD_TERMINALS,
    BaseCardTerminal: BaseCardTerminal,
    FreedomPayMiddleWare: FreedomPayMiddleWare
};
