'use strict';

const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosCtrl', [
        '$scope',
        '$timeout',
        '$modal',
        '$filter',
        '$log',
        '$translate',
        'SmbPosService',
        'LucovaWebSocket',
        'SharedDataService',
        'PosAlertService',
        'PosStatusService',
        'Locations',
        'CurrentSession',
        'CashierShift',
        'CompanyAttributesService',
        'SecondaryDisplay',
        'NownWebsocket',
        'EnvConfig',
        'CommonOfflineCache',
        'GatewayFiit',
        'Security',
        'SecurityUsers',
        'KioskService',
        'Reports',
        'MevBoxService',
        'MEV_STATUS',
        '$rootScope',
        'Platform',
        'CardTerminal',
        'PrintService',
        function (
            $scope,
            $timeout,
            $modal,
            $filter,
            $log,
            $translate,
            SmbPosService,
            LucovaWebSocket,
            SharedDataService,
            PosAlertService,
            PosStatusService,
            Locations,
            CurrentSession,
            CashierShift,
            CompanyAttributesService,
            SecondaryDisplay,
            NownWebsocket,
            EnvConfig,
            CommonOfflineCache,
            GatewayFiit,
            Security,
            SecurityUsers,
            KioskService,
            Reports,
            MevBoxService,
            MEV_STATUS,
            $rootScope,
            Platform,
            CardTerminal,
            PrintService) {

            $scope.isRootCompany = CurrentSession.isRootCompany();
            $scope.isCampus = CurrentSession.isCampus();

            $scope.mobileOrdersEnabled = Security.getUser().company.hasPreorder;
            var processingOfflineShiftTimeout;
            $scope.isOffline = PosStatusService.isOffline();
            var loginModalOpened = false;
            $scope.isFiitEnabled = GatewayFiit.isEnabled();
            $scope.fiitServicePeriods = [];

            // Pop up to prompt user to log in if POS goes back online while using offline mode.
            var openLoginFromOfflineModal = function (userObject) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/security/modalLoginFromOffline.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: [
                        '$scope',
                        'companyName',
                        function ($scope, companyName) {
                            $scope.companyName = companyName;
                            $scope.useOfflineMode = function () {
                                Security.useOfflineMode($scope.username, $scope.password).$promise.then(function () {
                                    $scope.$close();
                                }, function (error) {
                                    if (error.data.exception.appCode == 443) {
                                        $scope.authError = 'smb.pos.loginFromOfflineMode.wrongCompany.error';
                                    } else {
                                        $scope.authError = 'login.error';
                                    }
                                });
                            };
                        }],
                    resolve: {
                        companyName: function () {
                            return CurrentSession.getCompany().companyName;
                        }
                    },
                    windowClass: 'smb-pos smb-pos__login-from-offline-modal',
                    keyboard: false
                });
                loginModalOpened = true;
                modalInstance.result.then(function (resolved) {
                    loginModalOpened = false;
                }, function (dismissed) {
                    loginModalOpened = false;
                });
            };

            $scope.$watch(function () {
                return PosStatusService.isOffline();
            }, function (value) {
                $scope.isOffline = value;
                if (!$scope.isOffline && $scope.userIsLoggedIn && $scope.processingOfflineShift) {
                    processingOfflineShiftTimeout = setTimeout(function () {
                        // Make sure POS is still online before letting user reload.
                        if (!$scope.isOffline && $scope.processingOfflineShift) {
                            PosAlertService.showAlertByName('offline-shift-processing-timeout',
                                {
                                    modalCallback: function () {
                                        // Do nothing for now
                                        //                                    location.reload();
                                    }
                                });
                        }
                    }, 30000);
                } else if (!$scope.isOffline && CurrentSession.getUser() && CurrentSession.getUser().userId < 0 && !PosStatusService.userIsLoggedIn() && !loginModalOpened) {
                    SecurityUsers.get({}, function (response) {
                        PosStatusService.setLoggedIn(true);
                        // Refresh POS so all online functionality becomes available.
                        if (!PosStatusService.offlineShiftInQueue()) {
                            location.reload();
                        }
                    }, function (error) {
                        openLoginFromOfflineModal();
                    });
                }
            });

            $scope.$watch(function () {
                return PosStatusService.offlineShiftInQueue();
            }, function (offlineShiftInQueue, prvStatus) {
                if (!offlineShiftInQueue) {
                    // we only want to loadAll when we go from offline to online, and not at startup
                    if (prvStatus !== offlineShiftInQueue) loadAll();
                    $scope.processingOfflineShift = false;
                } else {
                    $scope.processingOfflineShift = true;
                }
            });

            $scope.userIsLoggedIn = PosStatusService.userIsLoggedIn();

            $scope.enhanceSecurityMeasures = CompanyAttributesService.enhanceSecurityMeasures();
            $scope.openShiftPage = 'clockIn';
            $scope.headerCompanyName = CurrentSession.getCompany().companyName;

            var overlayHideBtnTimeout;
            var navBarDoubleClickTimeout;
            const doubleClickIntervalMillis = 400;

            $scope.revealOverlayHideBtn = function () {
                // Check if click is outside the 350 ms double click tigger interval
                // require a triple click to reveal the nav icon
                if (!navBarDoubleClickTimeout
                    || Date.now() - navBarDoubleClickTimeout > doubleClickIntervalMillis) {

                    navBarDoubleClickTimeout = Date.now();
                    return;
                }

                navBarDoubleClickTimeout = undefined;
                $scope.showOverlayHideBtn = true;

                overlayHideBtnTimeout = $timeout(function () {
                    $scope.showOverlayHideBtn = false;
                }, 5000);
            };
            $scope.hideOverlay = function () {
                $scope.hideKiosk = true;
                $scope.showOverlayHideBtn = false;
            };

            var secondaryDisplaySettings = CurrentSession.getCompany().secondaryDisplaySettings;
            var appPayLabel = CurrentSession.getOrganizationAppName();
            var secondaryDisplaySlides = [];

            if (secondaryDisplaySettings) {
                secondaryDisplaySlides = secondaryDisplaySettings.slides.map((f) => {
                    return f.resourceUrl;
                });
            }

            if (KioskService.isKiosk()) {
                $scope.isKiosk = true;
                $scope.hideKiosk = false;
                $scope.showHideBtn = false;
                $scope.isKisokImageAvailable = true;
                $scope.isShiftStarted = PosStatusService.shiftStarted;

                let kioskBackgrounds = CurrentSession.getCompany().secondaryDisplaySettings.kioskBackgrounds;
                let dayIndex = moment().day(); // [0-7] (Sunday-Saturday)

                // consider first kioskBackground from list where current day is active
                let background = _.find(kioskBackgrounds, function (background) {
                    let active = (background.dayActive[dayIndex] === '1') ? true : false;

                    return active;
                });

                let kioskImageUrl = (!background) ? '' : background.resourceUrl;

                if ((!kioskImageUrl || kioskImageUrl.length === 0) && secondaryDisplaySlides.length) {
                    kioskImageUrl = secondaryDisplaySlides[0];
                } else if (!kioskImageUrl) { // if there is no Kiosk image and no customer display images
                    $scope.isKisokImageAvailable = false;
                }

                $scope.kioskBackgroundImage = 'url(' + kioskImageUrl + ')';
            }

            const clearBillingErrorListener = $rootScope.$on('system.billingError', (event, msg) => $scope.billingErrorMsg = msg);

            $scope.$on('$destroy', function () {
                /**
                 * we no longer need to clear menu cache, because its a timestamp.
                 * the timestamp is used on the server to figure out if the menu should
                 * be reloaded from DB. If reloaded the server responds with a new calculated
                 * menu and timestamp that then overwites the local cache menu and timestamp
                 * Sadiq Nov 16, 2018
                 */
                // SharedDataService.clearMenuCache();

                window.removeEventListener('cannotReadBarcode');
                clearTimeout(processingOfflineShiftTimeout);
                clearBillingErrorListener();
            });

            window.addEventListener('cannotReadBarcode', function (event) {
                PosAlertService.showAlertByName('barcode-impossible');
            });

            var format = 'MMMM Do YYYY, h:mm:ss a';
            var formatShort = 'MMMM Do, h:mm:ss a';

            $scope.view = '';
            $scope.LucovaWebSocket = LucovaWebSocket;

            $scope.currentTime;
            $scope.currentTimeShort;
            $scope.tickInterval = 1000; // ms

            function initWebsocket () {
                let {locationId, posStationId} = SmbPosService.shift;

                if (locationId && posStationId && EnvConfig.env !== 'test' && EnvConfig.env !== 'development') {
                    /**
                     * Conncet to the Nown websocket server and listen on the pos-cashier channel
                     */
                    let socketioUrl = EnvConfig.nownWsHost;
                    let socketioChannel = `pos-cashier_${locationId}_${posStationId}`;
                    NownWebsocket.connect(socketioUrl, socketioChannel);
                }
            }

            function initCustomerDisplay () {
                if (secondaryDisplaySlides) {
                    SecondaryDisplay.updateSlides(
                        secondaryDisplaySlides,
                        Date.now(),
                    );
                }

                SecondaryDisplay.updateMobileAppName(appPayLabel, CurrentSession.getCompany().hasLoyalty);
                let locale = CurrentSession.getCompany().locale;

                // The secondary display only supports 'en-US' and 'fr-CA'
                // Default to 'en-US' if a different locale is in use
                if (locale !== 'en-US' && locale !== 'fr-CA') {
                    locale = 'en-US';
                }

                SecondaryDisplay.updateLanguage(locale);
            }

            var loadShift = function () {
                return new Promise((resolve, reject) => {
                    SmbPosService.loadShift().then(function (response) {
                        initWebsocket();
                        initCustomerDisplay();
                        SmbPosService.loadPreviousEndOfShiftReports();
                        resolve();
                    }, function (error) {
                        $log.error(error);
                        reject();
                    });
                });
            };

            // Returns POS station object for given POS station ID
            $scope.getPosStationFromStationId = function (posStationId) {
                return _.find($scope.data.posStations, {
                    id: posStationId
                });
            };

            $scope.displayOpenShiftPage = function () {
                $scope.openShiftPage = 'openShift';
            };

            $scope.displayClockInPage = function () {
                $scope.openShiftPage = 'clockIn';
            };

            // Returns previous end of shift report for selected POS station
            $scope.getPreviousEndOfShiftReport = function (forLastClosedShift = false) {
                return _.find($scope.previousShifts, {
                    posStationId: (forLastClosedShift && $scope.shift) ? $scope.shift.posStation.id : $scope.newShift.posStationId
                });
            };

            $scope.printPreviousEndOfShiftReport = function (forLastClosedShift = false) {
                var previousEndOfShiftReport = $scope.getPreviousEndOfShiftReport(forLastClosedShift);
                SmbPosService.printEndOfShiftReport(
                    previousEndOfShiftReport.cashierShiftId,
                    $scope.getPosStationFromStationId(previousEndOfShiftReport.posStationId)
                );

                CommonOfflineCache.getLocationPosStations().then(function (locationPOSStations) {
                    let posStation = locationPOSStations.entries.filter(function (entry) {
                        return entry.posStationId == $scope.newShift.posStationId;
                    });
                    let terminalConfig = posStation[0].cardTerminalProperties;
                    let terminal = CardTerminal.init(terminalConfig);

                    if ((Platform.isElectron() || Platform.isIosWebkit())
                        && terminal
                        && terminalConfig.reportPrintout) {
                        PosAlertService.showAlertByName('general-alert', {
                            title: 'smb.pos.shift.endShift.terminal.printout.previous.title',
                            message: 'smb.pos.shift.endShift.terminal.printout.previous.message',
                            modalCallback: function () {
                                let reportType = 'DayCloseReport';

                                terminal.dayReport(reportType).then(function (result) {
                                    PrintService.printTerminalReport(result, posStation[0]).then(function (response) {
                                        PrintService.clearSession();
                                    }, function (err) {
                                        $log.error('terminal report printing error', err);
                                    });
                                });
                            }
                        });
                    }

                });

            };

            /**
            *** If condition 1
            ***
            ***/
            $scope.hasMenuPeriodsEnabled = function () {
                return CompanyAttributesService.hasMenuPeriodsEnabled();
            };

            $scope.hasMenuV2Enabled = function () {
                return CompanyAttributesService.hasMenuV2Enabled();
            };

            var isShiftFormDisabled = false;

            $scope.setFiitServicePeriodId = (menuPeriodId) => {
                const menuPeriod = $scope.data.menuPeriods.find((period) => period.menuPeriodId == menuPeriodId);
                if (menuPeriod && menuPeriod.servicePeriodId) {
                    $scope.showFiitServicePeriodSelector = false;
                    $scope.newShift.fiitServicePeriodId = menuPeriod.servicePeriodId;
                } else {
                    $scope.showFiitServicePeriodSelector = true;
                    $scope.newShift.fiitServicePeriodId = null;
                }
            };

            $scope.startShift = function () {
                if (isShiftFormDisabled) {
                    return;
                }

                isShiftFormDisabled = true;

                if (KioskService.isKiosk() && CompanyAttributesService.requirePinToOpenStation()) {
                    var requestedPermission = 'pos';
                    var clockedInOnly = 'false';
                    var params = {
                        callback: function () {
                            startShift();
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var exception = error.exception || {};
                                if (exception.appCode === 412) {
                                    PosAlertService.showAlertByName('pincode-fail', {}, true);
                                } else {
                                    PosAlertService.showAlertByName('oops-general', {
                                        message: exception.message || ''
                                    }, true);
                                }
                            }
                            isShiftFormDisabled = false;
                        },
                        requestedPermission: requestedPermission,
                        message: 'smb.pos.openstation.description',
                        verifyAllUserPinsForCompany: clockedInOnly
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);
                    return;
                }

                startShift();
            };

            var startShift = function () {
                // Sort service periods and take one
                let servicePeriod = $scope.data.servicePeriods.sort((a, b) => {
                    return parseInt(a.id) - parseInt(b.id);
                })[0];
                $scope.newShift.servicePeriodId = servicePeriod.id;
                $scope.newShift.timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

                SmbPosService.startShift($scope.newShift).then(function () {
                    initWebsocket();
                    initCustomerDisplay();
                    // Shift status needs to be updated in offline cache.
                    loadShiftStatus();
                    isShiftFormDisabled = false;
                    $scope.newShift = {};
                    $scope.shiftStartBalanceDisplay = '';
                    $scope.isItemAvailabilityDisabled = CompanyAttributesService.itemAvailabilityDisabled();

                    if (!$scope.isItemAvailabilityDisabled && $scope.mobileOrdersEnabled && !KioskService.isKiosk()) {
                        showMobileFeaturesAlert();
                        showItemAvailabilityIfFirstShift();
                    }

                    if (KioskService.isKiosk()) {
                        $scope.hideKiosk = false;
                    }
                }).catch(function () {
                    isShiftFormDisabled = false;
                });
            };

            var getLastShift = () => {
                if ($scope.previousShifts && $scope.previousShifts.length) {
                    // sort shifts by their endtime, with order descending
                    // so that sortedShifts array should have the most recently ended shift as first element.
                    var sortedShifts = _.sortBy($scope.previousShifts, (shift) => {
                        return -shift.end;
                    });
                    // we are only interested in grabbing the last shift of the location
                    return sortedShifts[0];
                }
            };

            $scope.searchResults = [];

            // when we get more mobile features, we can simply add them here if they have the functionality to be disabled temporarily.s
            var showMobileFeaturesAlert = () => {
                var preorderDisabled = Security.getUser().company.disablePreorderTemporary;
                var deliveryDisabled = Security.getUser().company.disableDeliveryTemporary;

                if (preorderDisabled || deliveryDisabled) {
                    PosAlertService.showAlertByName('general', {
                        title: $translate.instant('general.error.mobileFeatures.disabled.ttl', {
                            preorderDisabled: preorderDisabled,
                            deliveryDisabled: deliveryDisabled
                        }),
                        message: 'general.error.mobileFeatures.disabled.msg'
                    });
                }
            };

            var showItemAvailabilityIfFirstShift = () => {
                // Only show if this is the first station opening after midnight local time
                // & if there are items marked inactive/unavailable
                // only show if previousShifts contains shifts
                if ($scope.previousShifts && $scope.previousShifts.length) {

                    let search = {
                        locationId: $scope.shift.locationId,
                        menuPeriodId: (CommonOfflineCache.getCurrentMenuPeriod()) ? CommonOfflineCache.getCurrentMenuPeriod().menuPeriodId : undefined,
                        itemsOnly: true,
                        activeOnly: true,
                        availableOnly: false
                    };
                    SmbPosService.searchItems(search).$promise.then((response) => {
                        var sp = response.posMenuItemEntries[0];
                        var results = sp.entries.map((e) => {
                            return {item: e};
                        });

                        // filtering out category & modifier groups to return only items & modifier options
                        var filteredResults = _.filter(results, (result) => {
                            return result.item.type != 'category' && result.item.type != 'modifier';
                        });

                        $scope.searchResults.length = 0;
                        $scope.searchResults.push(...filteredResults);

                        var lastShift = getLastShift();

                        /*
                        Commented by Chris 2020/10/21 If lastShift and/or
                        lastShift.end is undefined or null, this means all
                        shifts in $scope.previousShifts null end date (likely
                        didn't close the station) In this case, we do not open
                        the item availability modal.
                        */

                        if (lastShift && lastShift.end) {
                            var previousEndTime = moment.unix(lastShift.end / 1000);
                            var midnightTime = moment().startOf('day');

                            // difference from previous end shift time to now
                            var shiftDiff = moment.duration(previousEndTime.diff(midnightTime));
                            var shiftDuration = moment.duration(shiftDiff, 'milliseconds');

                            // if midnight - previous endshift time is negative,
                            // means previous endshift time was before midnight
                            if (shiftDuration.hours() < 0 && $scope.searchResults.length > 0) {
                                openItemAvailabilityModal();
                            }
                        }
                    });
                }
            };

            var openItemAvailabilityModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalItemAvailability.tpl.html',
                    controller: 'ItemAvailabilityCtrl',
                    animation: false,
                    windowClass: 'item-avail-screen',
                    resolve: {
                        page: function () {
                            return 'firstShiftPage';
                        },
                        searchResults: function () {
                            return $scope.searchResults;
                        }
                    },
                    backdrop: true
                });
                return modalInstance.result.then(function (value) { });
            };

            $scope.editStartingBalance = function () {
                var pinPadInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return $scope.newShift.shiftStartBalance;
                        },
                        type: function () {
                            return 'currency';
                        },
                        note: function () {
                            return '';
                        }
                    },
                    backdrop: true
                });
                pinPadInstance.result.then(function (value) {
                    $scope.newShift.shiftStartBalance = parseFloat(value);
                    $scope.shiftStartBalanceDisplay = $filter('currency')(parseFloat(value));
                });
            };

            $scope.showMevboxOptions = async (toCheck) => {
                if ($scope.isMevBoxCompany) {
                    var status = await MevBoxService.getStatus();
                    return toCheck === status;
                } else {
                    return false;
                }
            };

            $scope.isCashierOptionsModalOpen = false;

            $scope.openCashierOptions = function (callBackFn, {openMenuSwitchModal = false} = {}) {
                if (!$scope.shift.started || $scope.isCashierOptionsModalOpen) {
                    return;
                }

                $scope.isCashierOptionsModalOpen = true;

                var cashierOptionsModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.cashier.options.tpl.html',
                    controller: 'PosCashierOptionsCtrl',
                    windowClass: 'mods-open-cash-drawer-modal',
                    animation: false,
                    background: 'static',
                    resolve: {
                        user: function () {
                            return Security.getUser();
                        },
                        logCashDrawerActivityFunction: function () {
                            return $scope.logCashDrawerActivity;
                        },
                        menuOptions: function () {
                            return cashierOptions;
                        },
                        cashDrawerOptions: function () {
                            return payoutMenuOptions;
                        },
                        createCashDrawerOpenEntryFunction: function () {
                            return $scope.createCashDrawerOpenEntry;
                        },
                        shift: function () {
                            return $scope.shift;
                        },
                        parentLoadAllFunction: function () {
                            return loadAll;
                        },
                        parentDisplayOpenShiftPageFunction: function () {
                            return $scope.displayOpenShiftPage;
                        },
                        parentDisplayClockInPageFunction: function () {
                            return $scope.displayClockInPage;
                        },
                        menuType: function () {
                            return 2;
                        },
                        isMevBoxCompany: function () {
                            return $scope.isMevBoxCompany;
                        },
                        optionalParams: function () {
                            return {
                                openMenuSwitchModal: !!openMenuSwitchModal
                            };
                        }
                    },
                    backdrop: 'static'
                }, function (error) { });
                return cashierOptionsModal.result.then(function (result) {
                    if (KioskService.isKiosk() && result == 'show-kiosk') {
                        $scope.hideKiosk = false;
                    }

                    if (callBackFn) {
                        callBackFn();
                    }

                    $scope.isCashierOptionsModalOpen = false;
                }, function () {
                    $scope.isCashierOptionsModalOpen = false;
                });
            };

            // Returns all pending preorders (haven't been printed yet)
            $scope.getPrintPendingOrders = function () {
                var printPendingOrders = _.filter(LucovaWebSocket.getMobileOrderUsers(), function (patron) {
                    return patron.preorder[0].status === 'print_pending';
                });

                var newMobileOrderPatrons = _.filter($scope.getMobileOrderPatrons(), function (patron) {
                    return patron.lucovaUser.preorder[0].status === 'print_pending';
                });

                $scope.newMobileOrderPatrons = newMobileOrderPatrons;
                $scope.printPendingOrders = printPendingOrders;
                return printPendingOrders;
            };

            $scope.getPatronPhoto = function (photoUrl) {
                return SmbPosService.getPatronPhoto(photoUrl);
            };

            $scope.openMobileOrderModal = function (type) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.mobileOrder.new.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'NewMobileOrderModalCtrl',
                    windowClass: 'mobile-order-screen',
                    keyboard: false,
                    resolve: {
                        shift: function () {
                            return $scope.shift;
                        },
                        currentIndex: function () {
                            return 0;
                        },
                        patrons: function () {
                            return $scope.newMobileOrderPatrons;
                        },
                        modalType: function () {
                            return type;
                        }
                    }
                });

                modalInstance.result.then(function (resolved) {
                });
            };

            $scope.openDrawer = function () {
                openDrawerNoSale($scope);
            };

            var openDrawerNoSale = function (scope) {
                if (SmbPosService.shift.posStation) {
                    // Get pincode from user to authorize opening drawer
                    var requestedPermission = 'pos:opendrawer';
                    var params = {
                        callback: function (pinObj) {
                            openPayoutModal(pinObj.user);
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var exception = error.exception || {};
                                if (exception.appCode === 412) {
                                    PosAlertService.showAlertByName('opendrawer-invalid-pin');
                                } else {
                                    PosAlertService.showAlertByName('opendrawer-fail', {
                                        message: exception.message || ''
                                    });
                                }
                            }
                        },
                        requestedPermission: requestedPermission,
                        message: 'smb.pos.opendrawer.description'
                    };

                    scope.$emit('PincodeAuthentication:Required', params);
                } else {
                    // If POS does not exist, notify the user the POS has not been initialized
                    PosAlertService.showAlertByName('cash-drawer-uninitialized');
                }
            };


            var openPayoutModal = function (userObject) {
                var modifyModal = $modal.open({
                    templateUrl: 'pos/smb/templates/pos.cashier.options.tpl.html',
                    controller: 'PosCashierOptionsCtrl',
                    windowClass: 'mods-open-cash-drawer-modal',
                    animation: false,
                    resolve: {
                        user: function () {
                            return userObject;
                        },
                        logCashDrawerActivityFunction: function () {
                            return $scope.logCashDrawerActivity;
                        },
                        menuOptions: function () {
                            return payoutMenuOptions;
                        },
                        cashDrawerOptions: function () {
                            return payoutMenuOptions;
                        },
                        createCashDrawerOpenEntryFunction: function () {
                            return $scope.createCashDrawerOpenEntry;
                        },
                        shift: function () {
                            return $scope.shift;
                        },
                        parentLoadAllFunction: function () {
                            return loadAll;
                        },
                        parentDisplayOpenShiftPageFunction: function () {
                            return $scope.displayOpenShiftPage;
                        },
                        parentDisplayClockInPageFunction: function () {
                            return $scope.displayClockInPage;
                        },
                        menuType: function () {
                            return 1;
                        },
                        isMevBoxCompany: function () {
                            return $scope.isMevBoxCompany;
                        },
                        optionalParams: function () {
                            return {};
                        }
                    },
                    backdrop: 'static'
                }, function (error) { });
                return modifyModal.result.then(function (openDrawerEntry) {

                }, function () {

                });
            };

            $scope.setCurrentOrder = function (order, {adjustments} = {}) {
                $scope.currentOrder = order;
                // Commented By Akash Mehta on Jul 26 2021
                // Adjustments is a metadata associated with every transaction which is used to track discounts and total of tenders
                // We need to maintain the metadata for held orders. Hence for any given order, If the metadata has not been passed to the function
                // delete the global adjustments object as we want to ensure that we have a fresh set of meta data for each transaction
                if (adjustments && !angular.equals(adjustments, {})) {
                    $scope.adjustments = adjustments;
                } else {
                    $scope.adjustments = null;
                }
            };

            $scope.updateOrderPatron = function (patron) {
                $scope.currentOrder.patron = patron;
            };

            $scope.isMevBoxCompany = CompanyAttributesService.hasMevBox();

            /*
                Commented By Chris Lo: 2021/Feb/12
                for the two srm options, we set them to false here. however, on init of cashier options modal,
                there is a check for the appropriate SRM status, as we want to load the status on click and not when pos.js is loaded.
            */
            var cashierOptions = [
                {type: 'endShift', value: $translate.instant('smb.pos.shift.end'), toShow: true, dataCy: 'closeStationBtn'},
                {type: 'itemAvailability', value: $translate.instant('smb.pos.cashier.options.itemAvailability.ttl'), toShow: $scope.mobileOrdersEnabled},
                {type: 'switchMenuPeriod', value: $translate.instant('smb.pos.cashier.options.menuPeriodSwitch.ttl'), toShow: $scope.hasMenuPeriodsEnabled(), dataCy: 'endShiftBtn'},
                {type: 'switchMenu', value: $translate.instant('smb.pos.cashier.options.menuSwitch.ttl'), toShow: $scope.hasMenuV2Enabled(), dataCy: 'switchMenuBtn'},
                // {type: 'driverPreview', value: $translate.instant('smb.pos.cashier.options.driverPreview.ttl'), toShow: false},
                {type: 'preorderDashboard', value: $translate.instant('smb.pos.cashier.options.preorderDashboard.ttl'), toShow: $scope.mobileOrdersEnabled},
                {type: 'editCurrentResources', value: $translate.instant('smb.pos.cashier.options.currentResourcesEdit.ttl'), toShow: true},
                {type: 'bypassSrm', value: $translate.instant('smb.pos.cashier.options.bypassSrm.ttl'), toShow: $scope.isMevBoxCompany},
                {type: 'reconnectSrm', value: $translate.instant('smb.pos.cashier.options.reconnectSrm.ttl'), toShow: false},
            ];

            /**
            **  Tightly bound with the payout modal. Please make sure it doesn't get affected
            **  when changing anything here
            **/
            var payoutMenuOptions = [
                {type: 'payout', value: $translate.instant('smb.pos.open.cash.drawer.payout.ttl'), toShow: true, dataCy: 'payoutBtn'},
                {type: 'addcash', value: $translate.instant('smb.pos.open.cash.drawer.addcash.ttl'), toShow: true, dataCy: 'addCashBtn'},
                {type: 'other', value: $translate.instant('smb.pos.open.cash.drawer.other.ttl'), toShow: true, dataCy: 'otherReasonBtn'}
            ];

            // entryDescription - object contating attributes ()
            // activity type comes from the type field in the payOutMenuOptions above
            $scope.createCashDrawerOpenEntry = function (entryDescription, requestingUserObj, cashierShiftId, activityType) {
                var cashDrawerOpenEntry = {};
                cashDrawerOpenEntry.amount = entryDescription.amount;
                cashDrawerOpenEntry.description = entryDescription.description;
                cashDrawerOpenEntry.invoiceNumber = entryDescription.invoice_no;
                cashDrawerOpenEntry.supplier = entryDescription.supplier;
                cashDrawerOpenEntry.user = requestingUserObj;
                cashDrawerOpenEntry.cashierShiftId = cashierShiftId;
                cashDrawerOpenEntry.cashDrawerActivityType = activityType;
                return cashDrawerOpenEntry;
            };

            $scope.logCashDrawerActivity = function (cashDrawerOpenEntry, callBackSuccess, callBackError) {
                CashierShift.logCashDrawerActivity(cashDrawerOpenEntry, function (reportResponse) {
                    callBackSuccess();
                }, function (error) {
                    callBackError();
                });
            };

            $scope.setSuspendedOrder = function (suspendedOrder) {
                $scope.suspendedOrder = suspendedOrder;
            };

            $scope.switchView = function (view) {
                if (KioskService.isKiosk() && view === 'start') {
                    let languageId = CurrentSession.getCompany().languageId;
                    let locale = CurrentSession.getCompany().locale;

                    if (!languageId || !locale) {
                        let defaultLanguage = SmbPosService.data.organizationLanguages.find((language) => language.defaultLanguage === true);
                        languageId = defaultLanguage.languageId;
                        locale = defaultLanguage.locale;
                    }


                    SharedDataService.preferredLanguage.languageId = languageId;
                    SharedDataService.preferredLanguage.locale = locale;

                    $translate.use(locale);
                }

                $scope.view = view;
            };

            $scope.newShift = {};

            var loadServicePeriods = function () {
                SmbPosService.loadServicePeriods($scope.newShift.locationId).then(function () {

                });
            };

            var tickTimeout;
            var tick = function () {
                var momentDateTime = moment();
                $scope.currentTime = momentDateTime.format(format);
                $scope.currentTimeShort = momentDateTime.format(formatShort);

                tickTimeout = $timeout(tick, $scope.tickInterval); // reset the timer
            };

            // $scope.getPrintPendingOrders = function () {
            //     return SmbPosService.getPrintPendingOrders();
            // };

            var monitorPrinterStatusFn;
            var monitorPrinterStatus = function () {
                SmbPosService.checkPrinterStatus();
                monitorPrinterStatusFn = $timeout(monitorPrinterStatus, 30000);
            };
            $scope.refreshPrinterStatus = function () {
                $timeout.cancel(monitorPrinterStatusFn);
                monitorPrinterStatus();
            };

            $scope.$on('$destroy', function () {
                $timeout.cancel(monitorPrinterStatusFn);
                $timeout.cancel(tickTimeout);
                $timeout.cancel(overlayHideBtnTimeout);
            });

            var loadPrinters = function () {
                if ($scope.shift.locationId) {
                    Locations.listAllPrinters({'locationId': $scope.shift.locationId})
                        .$promise.then(function (response) {
                            SharedDataService.printers = response.entries;
                        });
                }
            };

            var findAssignedPosStation = function () {
                var userId = CurrentSession.getUser().userId;
                Locations.getAssignedPosStation({userId: userId}, function (response) {
                    $scope.hasAssignedStation = !!response.posStationId;
                    if ($scope.hasAssignedStation) {
                        $scope.newShift.posStationId = response.posStationId;
                    }
                });
            };

            var loadAll = async function () {
                if (PosStatusService.offlineShiftInQueue()) {
                    return;
                }

                /* Because we removed the Location dropdown, we now run loadPosStations() and loadServicePeriods() here automatically.
                However these functions require data that is retrieved by async functions in loadShift() so we need to
                await the data retrieval before calling loadPosStations() and loadServicePeriods(). */
                await loadShift();
                $scope.newShift.locationId = $scope.data.locations.find(function (location) {
                    return location != null;
                }).id;
                findAssignedPosStation();
                loadServicePeriods();
                loadFiitServicePeriods();
            };

            var loadShiftStatus = function () {
                CashierShift.shiftStatus({}, function (response) {
                    CommonOfflineCache.saveLocationShiftStatus(response);

                    // save the FIIT service period ID here since it is part of the shift but can
                    // also be modified during the shift
                    if (response.fiitServicePeriodId) {
                        CommonOfflineCache.setCurrentFiitServicePeriodId(response.fiitServicePeriodId);
                    }
                }, function (error) { });
            };

            const loadFiitServicePeriods = () => {
                // Get the FIIT service periods to populate the open shift UI
                $scope.fiitServicePeriods = GatewayFiit.getServicePeriods();
                $rootScope.$emit('updateFiitStats');
            };

            $scope.init = function () {
                if (PosStatusService.offlineShiftInQueue()) {
                    return;
                }
                $scope.shift = SmbPosService.shift;
                $scope.previousShifts = SmbPosService.previousEndOfShiftReports;

                $scope.data = SmbPosService.data;
                loadPrinters();

                loadAll();
                tick();
            };

            $scope.init();
        }
    ]);
};
