'use strict';

const imagePrinter = require('./image-receipt');
const starPrinter = require('./star.printer');
const {htmlPrinter, screenPrinter} = require('./html.printer');

/* globals StarWebPrintBuilder */
const ReceiptFactoryWrapper = function () {
    let factoryMap = {};

    this.getFactoryList = function () {
        return Object.values(factoryMap);
    };

    this.registerFactory = function (factoryAlias, {envConfig, printerDetails = {}, type, outputFormat} = {}) {
        const redactPrinterDetails = ['printerId', 'printerUrl'];
        let printerDetailsCopy = {};
        for (const entry of Object.entries(printerDetails).filter((e) => !redactPrinterDetails.includes(e[0]))) {
            printerDetailsCopy[entry[0]] = entry[1];
        }

        if (!factoryMap[factoryAlias]) {
            switch (factoryAlias) {
                case 'imagePrinter':
                    factoryMap['imagePrinter'] = imagePrinter(envConfig, printerDetailsCopy, type, outputFormat);
                break;
                case 'starPrinter':
                    factoryMap['starPrinter'] = starPrinter(printerDetailsCopy);
                break;
                case 'htmlPrinter':
                    factoryMap['htmlPrinter'] = htmlPrinter(printerDetailsCopy);
                break;
                case 'screenPrinter':
                    factoryMap['screenPrinter'] = screenPrinter(printerDetailsCopy);
                break;
                default:
                    // do nothing
            }
        }
    };
};

// BEGIN: static props and methods
// [For]: methods or (public) variables/constants
// [Definition]: "descriptor: {writable: false, enumarable: false, configurable: false, value: [function() {}] || string || Object || Number || Array}"
if (!Object.getOwnPropertyDescriptor(ReceiptFactoryWrapper, 'factoryAlias')) {
    Object.defineProperty(ReceiptFactoryWrapper, 'factoryAlias', {
        value: {
            IMAGE_PRINTER: 'imagePrinter',
            STAR_PRINTER: 'starPrinter',
            HTML_PRINTER: 'htmlPrinter',
            SCREEN_PRINTER: 'screenPrinter'
        },
        enumerable: false
    });
}
// END: static props and methods

ReceiptFactoryWrapper.prototype.initialize = function () {
    for (const factory of this.getFactoryList()) {
        factory.initialize();
    }

    return this;
};

ReceiptFactoryWrapper.prototype.cut = function () {
    for (const factory of this.getFactoryList()) {
        factory.cut();
    }

    return this;
};

ReceiptFactoryWrapper.prototype.section = function () {
    for (const factory of this.getFactoryList()) {
        factory.section();
    }

    return this;
};

ReceiptFactoryWrapper.prototype.align = function (alignment) {
    for (const factory of this.getFactoryList()) {
        factory.align(alignment);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.title = function (elements, emphasis, styles) {
    for (const factory of this.getFactoryList()) {
        factory.title(elements, emphasis, styles);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.titleJustified = function (elements, emphasis, styles, stylesRight) {
    for (const factory of this.getFactoryList()) {
        factory.titleJustified(elements, emphasis, styles, stylesRight);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.text = function (elements, emphasis, styles) {
    for (const factory of this.getFactoryList()) {
        factory.text(elements, emphasis, styles);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.textForNewReciept = function (elements, emphasis, styles) {
    for (const factory of this.getFactoryList()) {
        factory.textForNewReciept(elements, emphasis, styles);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.textJustified = function (elements, emphasis, styles, stylesRight, filler) {
    for (const factory of this.getFactoryList()) {
        factory.textJustified(elements, emphasis, styles, stylesRight, filler);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.textJustifiedAndWrapped = function (elements, emphasis, styles, stylesRight, filler) {
    for (const factory of this.getFactoryList()) {
        factory.textJustifiedAndWrapped(elements, emphasis, styles, stylesRight, filler);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.newLine = function (count) {
    for (const factory of this.getFactoryList()) {
        factory.newLine(count);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.divider = function (thin) {
    for (const factory of this.getFactoryList()) {
        factory.divider(thin);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.output = async function (screenPrint = false) {
    for (const factory of this.getFactoryList()) {
        factory.receiptData = await factory.output(screenPrint);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.createQrCodeElement = async function (data) {
    for (const factory of this.getFactoryList()) {
        await factory.createQrCodeElement(data);
    }

    return this;
};

ReceiptFactoryWrapper.prototype.createImageElement = async function (url) {
    for (const factory of this.getFactoryList()) {
        await factory.createImageElement(url);
    }

    return this;
};

// https://www.star-m.jp/products/s_print/sdk/webprnt/manual/en/_StarWebPrintRequestElement.htm#cutpaper
ReceiptFactoryWrapper.prototype.attachCutElement = function (isPartialCutEnabled) {
    for (const factory of this.getFactoryList()) {
        let builder = new StarWebPrintBuilder();
        let cutElem = '';

        try {
            if (isPartialCutEnabled) {
                cutElem = builder.createCutPaperElement({feed: true, type: 'partial'});
            }
        } catch (error) {
            console.error(error);
        }

        if (!cutElem) {
            cutElem = builder.createCutPaperElement({feed: true});
        }

        factory.receiptData += cutElem;
    }

    return this;
};

Object.freeze(ReceiptFactoryWrapper);
module.exports = ReceiptFactoryWrapper;
