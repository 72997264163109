'use strict';

const angular = require('angular');
const moment = require('moment');

module.exports = function (freshideasReports) {
    freshideasReports.controller('EndOfShiftReport', [
        '$scope',
        '$timeout',
        '$sce',
        '$translate',
        'Reports',
        'Export',
        'Lookup',
        'Meals',
        'Security',
        'Calculate',
        'PrintService',
        'PosAlertService',
        'Locations',
        'DateRangeService',
        'USER_ROLE_TYPE',
        'SmbPosService',
        function (
            $scope,
            $timeout,
            $sce,
            $translate,
            Reports,
            Export,
            Lookup,
            Meals,
            Security,
            Calculate,
            PrintService,
            PosAlertService,
            Locations,
            DateRangeService,
            USER_ROLE_TYPE,
            SmbPosService
        ) {
            var currentUser = Security.getUser() || {};
            $scope.isManager = currentUser.roleType === USER_ROLE_TYPE.MANAGER;
            $scope.isAccountant = currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT;
            $scope.isSiteAdmin = currentUser.permission === 'SITEADMIN';
            $scope.searchDisabled = false;
            var allowRetry;
            var refreshLocations = function (companies) {
                if (companies) {
                    $scope.locations = _.flatten(_.map(companies, function (company) {
                        return company.children;
                    }), true);
                }
            };
            var refreshMealPeriods = function (locations) {
                if (locations) {
                    $scope.mealPeriods = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            return child.type === 'mealPeriod';
                        });
                    }), true);
                }
            };
            var refreshPoses = function (locations) {
                if (locations) {
                    $scope.poses = _.flatten(_.map(locations, function (location) {
                        return _.filter(location.children, function (child) {
                            child.locationName = location.name;
                            return child.type === 'pos';
                        });
                    }), true);
                    var currentPosId = $scope.reportSearch.posId;
                    var foundPos = _.find($scope.poses, function (pos) {
                        return pos.id === currentPosId;
                    });
                    if (!foundPos) {
                        $scope.reportSearch.posId = undefined;
                    }
                }
            };
            var tick = function () {
                $scope.currentDateTime = moment();
                $timeout(tick, 60 * 1000);
            };
            $scope.couponInfo = false;
            $scope.couponInfoView = function () {
                $scope.couponInfo = !$scope.couponInfo;
            };
            $timeout(tick, 60 * 1000);
            $scope.$watch('reportSearch.companyId', function (companyId) {
                if (companyId) {
                    var foundCompany = _.find($scope.companyHierarchy, function (company) {
                        return company.id === companyId;
                    });
                    if (foundCompany) {
                        refreshLocations([foundCompany]);
                    }
                } else {
                    var companyHierarchy = angular.copy($scope.companyHierarchy);
                    refreshLocations(companyHierarchy);
                }
            });
            $scope.$watch('reportSearch.locationId', function (locationId) {
                if (locationId) {
                    var foundLocation;
                    var l = _.find($scope.company.children, function (location) {
                        return location.id === locationId;
                    });
                    if (l) {
                        foundLocation = l;
                    }
                    if (foundLocation) {
                        refreshMealPeriods([foundLocation]);
                        refreshPoses([foundLocation]);
                    }
                } else {
                    refreshMealPeriods($scope.locations);
                    refreshPoses($scope.locations);
                }
            });
            $scope.opened = {};
            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };
            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1
            };
            $scope.clearFilters = function (isAdvancedSearch) {
                $scope.reportSearch = {isAdvancedSearch: !!isAdvancedSearch};
                $scope.reportSearch.fromDate = moment().startOf('week').toDate();
                $scope.reportSearch.toDate = moment().endOf('day').toDate();
                $scope.reportSearch.locationId = currentUser.company.locationId;
            };
            $scope.clearFilters();
            $scope.lookupEntity = function (type, id) {
                if (!$scope.baseSettings) {
                    return;
                }
                var entities = $scope.baseSettings[type] || [];
                var foundEntity = _.find(entities, function (entity) {
                    return entity.id === id;
                }) || {};
                return foundEntity.name || '';
            };
            $scope.lookupMealPlan = function (id) {
                if (!$scope.mealPlans) {
                    return;
                }
                var foundMealPlan = _.find($scope.mealPlans, function (mealPlan) {
                    return mealPlan.mealPlanId === id;
                }) || {};
                return foundMealPlan.name || '';
            };
            function lookupCompanyHierarchy () {
                Lookup.locationsByCompany({isAdvancedSearch: true}, function (response) {
                    $scope.company = _.findWhere(response, {id: currentUser.companyId});
                    $scope.locations = [];
                    let children = ($scope.company && $scope.company.children) ? $scope.company.children : [];

                    if (children.length) {
                        if (children.length > 1) {
                            $scope.locations.push({
                                id: null,
                                name: 'All Locations'
                            });
                        }
                        _.each(children, function (location) {
                            $scope.locations.push(location);
                        });
                        $scope.reportSearch.locationId = currentUser.company.locationId;
                    }
                });
            }
            $scope.getEndOfShiftReport = function (isAdvancedSearch) {
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    reportSearch.locationId = $scope.reportSearch.locationId;
                }
                if ($scope.reportSearch.posId) {
                    reportSearch.posStationId = $scope.reportSearch.posId;
                }
                reportSearch.employeeId = $scope.reportSearch.employeeId;
                $scope.searching = true;
                Reports.getEndOfShiftReport(reportSearch, function (response) {
                    $scope.results = response.endOfShiftSummary.shifts;
                    $scope.couponCodes = response.couponCodes;
                    var currentReportSearch = {
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate,
                        employeeId: $scope.reportSearch.employeeId,
                        companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                        locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                        posName: $scope.lookupEntity('poses', $scope.reportSearch.posId),
                        isAdvancedSearch: isAdvancedSearch
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }
                    $scope.reportSearch = $scope.reportSearch || {};
                    $scope.reportSearch.isAdvancedSearch = isAdvancedSearch;
                    $scope.initialSearch = false;
                    $scope.searching = false;
                });
            };
            $scope.getEndOfShiftReportV2 = function (isAdvancedSearch) {
                $scope.searchDisabled = true;
                clearTimeout(allowRetry);
                allowRetry = setTimeout(function () {
                    $scope.searchDisabled = false;
                }, 30000);
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                if ($scope.reportSearch.companyId) {
                    reportSearch.companyId = $scope.reportSearch.companyId;
                }
                if ($scope.reportSearch.locationId) {
                    reportSearch.locationId = $scope.reportSearch.locationId;
                }
                if ($scope.reportSearch.posId) {
                    reportSearch.posStationId = $scope.reportSearch.posId;
                }
                reportSearch.employeeId = $scope.reportSearch.employeeId;
                reportSearch.activeOnly = $scope.reportSearch.activeOnly;
                reportSearch.includeAutoCreated = $scope.reportSearch.includeAutoCreated;
                $scope.searching = true;
                Reports.getEndOfShiftReport(reportSearch, function (responses) {
                    $scope.results = [];

                    _.each(responses, function (response) {
                        return PrintService.previewEndOfShiftReport(response).then(function (result) {
                            var shift = {
                                data: response,
                                html: $sce.trustAsHtml(result),
                                start: response.start
                            };

                            $scope.results.push(shift);
                            $scope.searching = false;
                        });
                    });

                    var currentReportSearch = {
                        fromDate: $scope.reportSearch.fromDate,
                        toDate: $scope.reportSearch.toDate,
                        employeeId: $scope.reportSearch.employeeId,
                        companyName: $scope.lookupEntity('companies', $scope.reportSearch.companyId),
                        locationName: $scope.lookupEntity('locations', $scope.reportSearch.locationId),
                        posName: $scope.lookupEntity('poses', $scope.reportSearch.posId),
                        isAdvancedSearch: isAdvancedSearch,
                        activeOnly: $scope.reportSearch.activeOnly,
                        includeAutoCreated: $scope.reportSearch.includeAutoCreated
                    };
                    currentReportSearch = _.pick(currentReportSearch, function (value) {
                        return !!value;
                    });
                    if (_.isEmpty(currentReportSearch)) {
                        $scope.currentReportSearch = undefined;
                    } else {
                        $scope.currentReportSearch = currentReportSearch;
                    }
                    $scope.reportSearch = $scope.reportSearch || {};
                    $scope.reportSearch.isAdvancedSearch = isAdvancedSearch;
                    $scope.initialSearch = false;
                });
            };
            $scope.toggleActiveShiftsOnly = function () {
                $scope.reportSearch.activeOnly = !$scope.reportSearch.activeOnly;
            };
            $scope.toggleAutoCreatedShift = function () {
                $scope.reportSearch.includeAutoCreated = !$scope.reportSearch.includeAutoCreated;
            };
            $scope.saveSelectedFromDate = function () {
                var selectedFromDate = moment($scope.reportSearch.fromDate).startOf('day').valueOf();
                DateRangeService.setFromDate(selectedFromDate);
            };
            $scope.saveSelectedToDate = function () {
                var selectedToDate = moment($scope.reportSearch.toDate).endOf('day').valueOf();
                DateRangeService.setToDate(selectedToDate);
            };
            $scope.init = function () {
                var fromDate;
                var toDate;
                if (DateRangeService.getFromDate()) {
                    fromDate = moment(DateRangeService.getFromDate()).startOf('day').toDate();
                } else {
                    fromDate = moment().startOf('week').toDate();
                }
                if (DateRangeService.getToDate()) {
                    toDate = moment(DateRangeService.getToDate()).endOf('day').toDate();
                } else {
                    toDate = moment().endOf('day').toDate();
                }
                $scope.reportSearch = {
                    fromDate: fromDate,
                    toDate: toDate,
                    isAdvancedSearch: true
                };
                lookupCompanyHierarchy();
            };
            $scope.init();
            $scope.exportToPdf = function (tableId) {
                Export.tableToPdf(tableId, 'portrait');
            };
            $scope.printReportToReceiptPrinter = function (shift) {
                var location = $scope.locations[0] || {};

                Locations.getLocationPOSStations({
                    locationId: location.id,
                }, function (posStations) {

                    var posStation;
                    if (posStations && posStations.entries && posStations.entries.length == 1) {
                        posStation = posStations.entries[0];
                    } else if (SmbPosService.shift && SmbPosService.shift.posStation) {
                        posStation = SmbPosService.shift.posStation;
                    } else {
                        var title = $translate.instant('reports.endOfShift.printerSelectError.title');
                        var description = $translate.instant('reports.endOfShift.printerSelectError.description');
                        PosAlertService.showError('error', title, '', description);
                    }

                    if (posStation) {
                        PrintService.printEndOfShift(shift, posStation)
                            .then(function () {

                            })
                            .catch(function () {
                                PosAlertService.showAlertByName('printer-disconnected');
                            });
                    }
                }, function () {
                    PosAlertService.showAlertByName('printer-disconnected');
                });
            };
            $scope.initialSearch = true;
            $scope.searching = false;

            $scope.$on('$destroy', function () {
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.locationId', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.fromDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });

            $scope.$watch('reportSearch.toDate', function () {
                $scope.searchDisabled = false;
                clearTimeout(allowRetry);
            });
        }
    ]);
};
