'use strict';

const SemiIntegartedTerminals = require('./cardterminal/semi-integrated.terminals');

export function cardTerminal (freshideas) {

    freshideas.constant('TerminalEvent', {
        SUCCESS: 'SUCCESS',
        FAILED: 'FAILED',
        PRINT: 'PRINT',
        BUSY: 'BUSY',
        BATCH_EMPTY: 'BATCH_EMPTY'
    });

    freshideas.factory('CardTerminal', [
        'CardTerminalOpenEdge',
        'CardTerminalGlobalPayEast',
        'iOSCardTerminalGlobalPayEast',
        'CardTerminalCtPayments',
        'CardTerminalVantiv',
        'CardTerminalHeartland',
        'CardTerminalClover',
        'CardTerminalMonerisCore',
        'iOSCardTerminalMonerisCore',
        'CardTerminalPax',
        'iOSCardTerminalPax',
        'CardTerminalEquinoxEPOS',
        'iOSCardTerminalEquinoxEpos',
        'Platform',
        function (
            CardTerminalOpenEdge,
            CardTerminalGlobalPayEast,
            iOSCardTerminalGlobalPayEast,
            CardTerminalCtPayments,
            CardTerminalVantiv,
            CardTerminalHeartLand,
            CardTerminalClover,
            CardTerminalMonerisCore,
            iOSCardTerminalMonerisCore,
            CardTerminalPax,
            iOSCardTerminalPax,
            CardTerminalEquinoxEPOS,
            iOSCardTerminalEquinoxEpos,
            Platform
        ) {

            var isIosWebkit = function () {
                return Platform.isIosWebkit();
            };

            var isTizen = function () {
                return Platform.isTizen();
            };

            var processor = null;
            var findProcessor = function (processorType) {
                if (!processor) {
                    switch (processorType) {
                        case SemiIntegartedTerminals.TYPE.GLOBALPAY:
                            if (isIosWebkit() || isTizen()) {
                                processor = iOSCardTerminalGlobalPayEast;
                            } else {
                                processor = CardTerminalGlobalPayEast;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.OPENEDGE:
                            processor = CardTerminalOpenEdge;
                        break;
                        case SemiIntegartedTerminals.TYPE.CTPAYMENT:
                            processor = CardTerminalCtPayments;
                        break;
                        case SemiIntegartedTerminals.TYPE.VANTIV:
                            processor = CardTerminalVantiv;
                        break;
                        case SemiIntegartedTerminals.TYPE.HEARTLAND:
                            processor = CardTerminalHeartLand;
                        break;
                        case SemiIntegartedTerminals.TYPE.MONERIS:
                            if (isIosWebkit() || isTizen()) {
                                processor = iOSCardTerminalGlobalPayEast;
                            } else {
                                processor = CardTerminalGlobalPayEast;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.MONERISCORE:
                            if (isIosWebkit() || isTizen()) {
                                processor = iOSCardTerminalMonerisCore;
                            } else {
                                processor = CardTerminalMonerisCore;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.INGENICO:
                            if (isIosWebkit() || isTizen()) {
                                // return iOSCardTerminalIngenico;
                                processor = iOSCardTerminalGlobalPayEast;
                            } else {
                                // return CardTerminalIngenico;
                                processor = CardTerminalGlobalPayEast;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.CLOVER:
                        case SemiIntegartedTerminals.TYPE.CLOVER_CLOUD:
                            processor = CardTerminalClover;
                        break;
                        case SemiIntegartedTerminals.TYPE.PAX:
                            if (isIosWebkit() || isTizen()) {
                                processor = iOSCardTerminalPax;
                            } else {
                                processor = CardTerminalPax;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.EQUINOX_EPOS:
                            if (isIosWebkit() || isTizen()) {
                                processor = iOSCardTerminalEquinoxEpos;
                            } else {
                                processor = CardTerminalEquinoxEPOS;
                            }
                        break;
                        case SemiIntegartedTerminals.TYPE.FREEDOMPAY_MIDDLEWARE:
                            processor = SemiIntegartedTerminals.BaseCardTerminal.createProxy(
                                new SemiIntegartedTerminals.FreedomPayMiddleWare());
                        break;
                        default:
                            // do nothing
                    }
                }

                return processor;
            };

            var parseResponse = function (response, type) {
                var cardInterface = findProcessor(type);

                return cardInterface.parseResponse(JSON.parse(response));
            };

            var init = function (terminalConfig) {
                try {
                    var processor = findProcessor(terminalConfig.type);

                    if (processor == null) {
                        return null;
                    }
                    processor.init(terminalConfig);
                    return processor;
                } catch (e) {
                    console.error(e);
                    return null;
                }
            };

            return {
                init: init,
                parseResponse: parseResponse,
                findProcessor: findProcessor
            };
        }
    ]);
}
