'use strict';


module.exports = function (rules) {
    const Decimal = require('decimal.js').default;
    const SharedDataService = require('../../external/pos.data-service.js');
    var rule = {
        name: 'ca_on_prepared_goods',
        enableTag: 'has_prepared_goods',
        key: 'CA_ON_PREPARED_GOODS',
        canEnable: function (company, location) {
            return /* company.businessAddress.country === 'Canada' && */ (!!location && (location.province === 'ON' || location.region === 'ON'));
        },
        isEnabled: function (company, location) {
            return this.canEnable(company, location) && (company.attributes[this.enableTag] && company.attributes[this.enableTag] === 'true');
        },
        run: function (company, location, receipt) {
            if (this.isEnabled(company, location)) {
                // Commented By Akash Mehta on November 12 2020
                // All modifiers (eligible for prepared goods or not) are automatically eligible
                // for prepared goods if the main/top/root item is eligible for prepared goods tax and is not fully discounted
                // This list inclueds : modifiers, negative modifiers as well as discounts
                //
                // Commented By Akash Mehta on April 20 2021
                // If the root item is fully discounted (for e.g. when paid by a fahrenheit loyalty plan like an americano loyalty plan)
                // it should no longer be eligible for tax rule redemption
                receipt.forEach(function (listItem) {
                    if (listItem.level > 0) {
                        var rootItem = receipt.find((receiptItem) => receiptItem.index == listItem.index && receiptItem.level == 0);
                        if (rootItem.taxRules && rootItem.taxRules[rule.name]) {
                            listItem.taxRules = listItem.taxRules || {};
                            if (rootItem.isFullyDiscounted) {
                                listItem.taxRules[rule.name] = 'false';
                            } else if (rootItem.taxRules[rule.name] === 'true') {
                                listItem.taxRules[rule.name] = 'true';
                            }
                        }
                    } else if (listItem.level == 0) {
                        if (listItem.taxRules && listItem.taxRules[rule.name] === 'true' && listItem.isFullyDiscounted) {
                            listItem.taxRules[rule.name] = 'false';
                        }
                    }
                });

                _computeReceiptTaxRates(receipt);
            }
        }
    };

    var _computeReceiptTaxRates = function (receiptList) {
        var pretaxTotal = _computePrepareGoodsTotal(receiptList);

        receiptList.forEach(function (listItem) {
            var itemTaxRate = new Decimal(_computeItemTaxRate(listItem, pretaxTotal, receiptList));
            var itemPrice = new Decimal(listItem.itemPrice);
            var itemTaxAmount = itemPrice.times(itemTaxRate).toNearest(SharedDataService.baseDollar); // ensure item-level tax rounding
            var itemQuantity = new Decimal(listItem.quantity);

            listItem.taxRate = parseFloat(itemTaxRate.valueOf());
            listItem.taxAmount = parseFloat(itemTaxAmount.times(itemQuantity).valueOf());
            listItem.price = parseFloat(itemPrice.times(itemQuantity).valueOf());
            listItem.total = parseFloat((itemPrice.plus(itemTaxAmount)).times(itemQuantity).valueOf());
            listItem.itemTaxAmount = parseFloat(itemTaxAmount.valueOf());
            listItem.itemTotalPrice = parseFloat(itemPrice.plus(itemTaxAmount).valueOf());
        });
    };

    var _computePrepareGoodsTotal = function (receiptList) {
        var total = new Decimal(0);
        receiptList.forEach(function (listItem) {
            if (listItem.taxRules && listItem.taxRules[rule.name] === 'true') {
                var itemPrice = new Decimal(listItem.itemPrice);
                var itemTotalPrice = itemPrice.times(listItem.quantity);

                total = total.plus(itemTotalPrice);
            }
        });

        return total.toNumber();
    };

    var _computeItemTaxRate = function (item, pretaxTotal, receiptList) {
        var itemOwnTaxRate = item.taxRate;
        var taxRules = item.taxRules || {};
        var isRuleApplicable = false;
        if (taxRules['ca_on_prepared_goods_quantity'] === 'true') {
            if (item.quantity < 6) {
                isRuleApplicable = (itemOwnTaxRate > 0 && taxRules[rule.name] === 'true' && pretaxTotal <= 4);
            } else {
               isRuleApplicable = false;
            }
        } else {
            isRuleApplicable = (itemOwnTaxRate > 0 && taxRules[rule.name] === 'true' && pretaxTotal <= 4);
        }

        var taxRate;
        if (isRuleApplicable) {
            taxRate = 0.05;

            var taxRulesAppliedString = item.taxRulesApplied || '';
            var taxRulesApplied = taxRulesAppliedString.split(',').filter(function (taxRuleApplied) {
                return !!taxRuleApplied;
            });
            taxRulesApplied.push(rule.name);

            item.taxRulesApplied = taxRulesApplied.join(',');
        } else {
            taxRate = itemOwnTaxRate;
        }

        return taxRate;
    };

    rules.CA_ON_PREPARED_GOODS = rule;
};
