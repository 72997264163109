'use strict';
const Decimal = require('decimal.js').default;
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderTerminalRecoveryCtrl', [
        '$scope',
        '$timeout',
        'tender',
        'terminalInterface',
        'multiPartData',
        function (
            $scope,
            $timeout,
            tender,
            terminalInterface,
            multiPartData
        ) {
            $scope.tender = tender;
            $scope.isLoading = true;

            let steps = {
                initial: {
                    name: 'initial',
                    option: ''
                },
                retry: {
                    name: 'retry',
                    option: '',
                    attempt: 0,
                    maxAllowed: 5,
                    lastCharge: []
                },
                multiPart: {
                    name: 'multiPart',
                    option: '',
                    lastCharge: []
                },
            };

            $scope.setOption = function (name, option) {
                if ($scope.currentStep.option === option) {
                    $scope.currentStep.option = '';
                } else {
                    $scope.currentStep.option = option;
                }
            };

            $scope.confirmOption = function (name) {
                if (name === 'initial') {
                    switch ($scope.currentStep.option) {
                        case 'notSure':
                            fetchLastStatus();
                            break;
                        case 'no':
                            $scope.$dismiss();
                            break;
                        case 'yes':
                            // flag to indicate this is a credit card tender
                            // without terminal response, like a manul credit
                            // card workflow
                            $scope.$close({isManual: true});
                            break;
                    }

                    $scope.currentStep.option = '';
                    return;
                }

                if (name === 'retry') {
                    switch ($scope.currentStep.option) {
                        case 'reattempt':
                            fetchLastStatus();
                            break;
                        case 'exit':
                            $scope.$dismiss();
                            break;
                        case 'yes':
                            $scope.$close({
                                isManual: false,
                                result: steps.retry.lastCharge
                            });
                            break;
                        case 'initial':
                            $scope.currentStep = steps.initial;
                            break;
                    }

                    steps.retry.option = '';
                    return;
                }

                if (name === 'multiPart') {
                    switch ($scope.currentStep.option) {
                        case 'exit':
                            $scope.$close({
                                result: steps.multiPart.lastCharge,
                                completeMultiPartTrx: false
                            });
                            break;
                        case 'continue':
                            $scope.$close({
                                result: steps.multiPart.lastCharge,
                                completeMultiPartTrx: true
                            });
                            break;
                    }

                    steps.multiPart.option = '';
                    return;
                }
            };

            var fetchLastStatus = async function (firstTry) {
                steps.retry.attempt++;
                steps.retry.isLoading = true;

                if (terminalInterface) {
                    var lastChargeData;
                    try {
                        lastChargeData = await terminalInterface.lastTransactionStatus(tender);
                        if (lastChargeData) {
                            var transactionDateTime = lastChargeData[0].transactionDateTime;
                            var approvedAmount = new Decimal(lastChargeData[0].approvedAmount).toNearest(SharedDataService.baseDollar).toNumber();
                            var tipAmount = new Decimal(lastChargeData[0].tipAmount).toNearest(SharedDataService.baseDollar).toNumber();
                            var approvedAmountWithoutTip = new Decimal(approvedAmount).minus(new Decimal(tipAmount)).toNearest(SharedDataService.baseDollar).toNumber();
                            var partiallyApproved = !!lastChargeData[0].partiallyApproved;
                            var cardNumber = lastChargeData[0].cardNumber;

                            if (!multiPartData.multiPart) {
                                lastChargeData[0].transactionDateTime = transactionDateTime;
                                lastChargeData[0].approvedAmount = approvedAmountWithoutTip;
                                lastChargeData[0].tipAmount = tipAmount;
                                lastChargeData[0].partiallyApproved = partiallyApproved;
                                lastChargeData[0].cardNumber = cardNumber;

                                steps.retry.lastCharge.length = 0;
                                Array.prototype.push.apply(steps.retry.lastCharge, lastChargeData);
                            } else {
                                lastChargeData[0].transactionDateTime = transactionDateTime;
                                lastChargeData[0].approvedAmount = approvedAmountWithoutTip;
                                lastChargeData[0].tipAmount = tipAmount;
                                lastChargeData[0].partiallyApproved = partiallyApproved;
                                lastChargeData[0].cardNumber = cardNumber;

                                steps.multiPart.message = multiPartData.notifyUser;
                                steps.multiPart.lastCharge.length = 0;
                                Array.prototype.push.apply(steps.multiPart.lastCharge, lastChargeData);
                            }

                        }
                    } catch (e) {
                        console.log(e);
                    }

                    steps.retry.isLoading = false;
                    if (!lastChargeData && steps.retry.attempt <= steps.retry.maxAllowed) {
                        fetchLastStatus(firstTry);
                    } else {
                        $scope.isLoading = false;

                        if (firstTry && !lastChargeData) {
                            $scope.currentStep = steps.initial;
                        } else if (multiPartData.multiPart) {
                            $scope.currentStep = steps.multiPart;
                        } else {
                            $scope.currentStep = steps.retry;
                        }

                        return;
                    }
                }

                $scope.isLoading = false;
                $scope.currentStep = steps.initial;
            };

            $scope.init = function () {
                fetchLastStatus(true);
            };
            $scope.init();
        }]
    );
};
