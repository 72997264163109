'use strict';

const BASE_RESPONSE = {
    paymentProcessor: 'BaseCardTerminal',

    terminalId: '',
    transactionId: '',
    transactionDateTime: new Date().toLocaleString(),
    transactionAmount: 0,
    totalAmount: 0,
    approvedAmount: 0,

    transactionType: '',
    attributeTranId: '',
    cardType: '',
    cardName: '',
    maskedCardNumber: 'xxxx xxxx xxxx xxxx',
    cardNumber: 'xxxx xxxx xxxx xxxx',
    success: false,
    batchNum: -1,

    isCredit: true,
    isDebit: false,
    debitAccountType: '',

    tipAmount: 0,
    cvmResult: '',
    emvAid: '',
    emvTvr: '',
    emvTsi: '',
    emvAppLabel: '',
    emvAppPreferredName: '',
    emvCryptogram: '',
    emvCryptogramType: '',

    hostResponseCode: '',
    statusLine: '',
    cardBalance: null,
    demoMode: false,
    formFactor: null,
    showCustomerSignatureLine: false,

    customerLanguage: 'en',
    merchantId: '',
    referenceNumber: '',
    cardEntryMethod: '',
    authorizationNumber: '',
    transactionSequenceNum: '',
    verifiedByPin: false,

    // error checking purpose
    ResponseCode: '',
    AppStatusCode: '',

    partiallyApproved: false,
    balanceDue: 0,
    // receipt print out text supplied by terminal
    customerCopy: [],
    merchantCopy: [],

    // autoSettle data
    batchStatus: 2, // 0 – balanced, 1 – out of balance, 2 – unknown
    hostCount: 0,
    hostNet: 0,
    terminalCount: 0,
    terminalNet: 0,

    // terminal reports printout text
    printOutText: '',
    isBusy: false
};

const CARD_TERMINALS = {
    MANUAL: 'manual',
    GLOBALPAY: 'globalpay',
    OPENEDGE: 'openedge',
    CTPAYMENT: 'ctpayment',
    VANTIV: 'vantiv',
    HEARTLAND: 'heartland',
    MONERIS: 'moneris',
    MONERISCORE: 'moneriscore',
    INGENICO: 'ingenico',
    CLOVER: 'clover',
    CLOVER_CLOUD: 'clover_cloud',
    PAX: 'pax',
    EQUINOX_EPOS: 'equinox-epos',
    FREEDOMPAY_MIDDLEWARE: 'freedomPay_win-fcc'
};

module.exports = {
    BASE_RESPONSE: BASE_RESPONSE,
    CARD_TERMINALS: CARD_TERMINALS
};
