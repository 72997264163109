'use strict';

const moment = require('moment');

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductMenuV2Ctrl', [
        '$scope',
        '$modal',
        '$timeout',
        '$filter',
        '$translate',
        'Security',
        'PosAlertService',
        'ProductsService',
        'SmbPosService',
        'Locations',
        'CurrentSession',
        'CustomReceiptService',
        'DaysConstant',
        'CompanyAttributesService',
        'Menu',
        'MenuService',
        'CommonOfflineCache',
        'GatewayFiit',
        'SharedDataService',
        'EnvConfig',
        'Export',
        function (
            $scope,
            $modal,
            $timeout,
            $filter,
            $translate,
            Security,
            PosAlertService,
            ProductsService,
            SmbPosService,
            Locations,
            CurrentSession,
            CustomReceiptService,
            DaysConstant,
            CompanyAttributesService,
            Menu,
            MenuService,
            CommonOfflineCache,
            GatewayFiit,
            SharedDataService,
            EnvConfig,
            Export) {

            var currentCompany = Security.getUser().company;
            $scope.giftCardsEnabled = CompanyAttributesService.hasGiftCardsEnabled();
            $scope.isMiscItemHidden = currentCompany.attributes.hide_misc_item === 'true';

            const PAGE_TYPE = Object.freeze({
                REGULAR: 'regular',
                ARCHIVE: 'archive',
                MASTER_PRODUCT_LIST: 'masterProductList'
            });

            $scope.days = [
                {
                    day: DaysConstant.MONDAY,
                    label: 'Mon',
                    enabled: false
                },
                {
                    day: DaysConstant.TUESDAY,
                    label: 'Tues',
                    enabled: false
                },
                {
                    day: DaysConstant.WEDNESDAY,
                    label: 'Wed',
                    enabled: false
                },
                {
                    day: DaysConstant.THURSDAY,
                    label: 'Thurs',
                    enabled: false
                },
                {
                    day: DaysConstant.FRIDAY,
                    label: 'Fri',
                    enabled: false
                }, {
                    day: DaysConstant.SATURDAY,
                    label: 'Sat',
                    enabled: false
                }, {
                    day: DaysConstant.SUNDAY,
                    label: 'Sun',
                    enabled: false
                }
            ];

            $scope.buzzerEnabled = currentCompany.attributes.buzzer_enabled === 'true';

            $scope.hasMenuPeriodsEnabled = function () {
                return CompanyAttributesService.hasMenuPeriodsEnabled();
            };

            $scope.isFiitEnabled = GatewayFiit.isEnabled();

            $scope.isLayoutPure = true;
            $scope.searchText = {
                text: ''
            };

            $scope.menuPeriodItemSearchText = {
                text: ''
            };

            $scope.quickChargeItemSearchText = {
                text: '',
                isSearching: true
            };

            $scope.menus = {
                selected: {},
                all: []
            };

            $scope.checkIfMasterMenu = function () {
                return !!ProductsService.isMasterMenu;
            };

            $scope.hasAdminRights = function () {
                var privilege = Security.getUser().permission;
                return privilege === 'FULLADMIN' || privilege === 'SITEADMIN';
            };

            const loadMenus = () => {
                let getMenuPromise;
                const companyId = CurrentSession.getCompany().companyId;

                if ($scope.isRootCompany) {
                    getMenuPromise = MenuService.loadAvailableMenusByOrganization();
                } else {
                    getMenuPromise = MenuService.loadAvailableMenusByCompany(companyId);
                }

                getMenuPromise.then((menus) => {
                    let defaultMenuIdex = menus.findIndex(function (menu) {
                        return menu.defaultMenu === true;
                    });
                    var from = menus.splice(defaultMenuIdex, 1)[0];
                    // insert deafult menu at 0th index
                    menus.splice(0, 0, from);
                    $scope.menus.all = menus;
                    $scope.menus.selected = $scope.menus.all.find((menu) => {
                        return menu.associatedCompanyIds.find((id) => id === companyId);
                    });
                    ProductsService.isMasterMenu = $scope.menus.selected.defaultMenu;
                    SharedDataService.setAllMenus(menus);

                    loadProducts(companyId);
                });
            };

            $scope.switchMenu = function (selectedOption) {
                var companyId;
                if (selectedOption.menuId) {
                    if (selectedOption.defaultMenu) {
                        companyId = CurrentSession.getOrganization().rootCompanyId;
                    } else {
                        companyId = selectedOption.associatedCompanyIds[0];
                    }

                    ProductsService.isMasterMenu = selectedOption.defaultMenu;
                }
                loadProducts(companyId);
            };

            $scope.renameMenuPage = function (index) {
                var modalInstance = $modal.open({
                    templateUrl: 'products/templates/product.rename.prompt.tpl.html',
                    controller: 'ProductRenamePromptCtrl',
                    windowClass: 'modal-50 products2',
                    animation: false,
                    resolve: {
                        selectedMenu: function () {
                            return $scope.menus.selected;
                        },
                        currentPageName: function () {
                            return $scope.menus.selected['pageName' + index];
                        }
                    }
                });

                modalInstance.result.then(function (response) {
                    if (response.action === 'save') {
                        // Set default name if left empty
                        // TODO: Replace with translate
                        if (response.pageName === '') {
                            response.pageName = 'Page ' + (index + 1);
                        }

                        MenuService.updatePageName($scope.menus.selected.menuId, index, response.pageName)
                        .then(() => {
                            $scope.menus.selected['pageName' + index] = response.pageName;
                        })
                        .catch((error) => {
                            PosAlertService.showAlertByName('general-error', {
                                message: error.data.error,
                            });
                        });
                    }
                }, function (error) {
                    // Do nothing
                });
            };

            var menuItemTranslation = {
                itemId: '',
                languageId: '',
                itemName: '',
                displayName: '',
                description: '',
            };

            function _resetMenuItemTranslation () {
                menuItemTranslation = {
                    itemId: '',
                    languageId: '',
                    itemName: '',
                    displayName: '',
                    description: '',
                };
            }

            $scope.editPageOrder = function () {
                let modalInstance = $modal.open({
                    templateUrl: 'products/templates/product.page.order.prompt.tpl.html',
                    controller: 'ProductPageOrderPromptCtrl',
                    windowClass: 'modal-50 products3',
                    animation: false,
                    resolve: {
                        selectedMenu: function () {
                            return $scope.menus.selected;
                        }
                    }
                });
                modalInstance.result.then(function (response) {
                    if (response.action === 'save') {
                        let menuPagesOrder = [];
                        let reorderPageNames = [];
                        let payload = {
                            menuPagesOrder: menuPagesOrder,
                            reorderPageNames: reorderPageNames
                        };

                        _.each(response.newPageOrder, function (page, index) {
                            _resetMenuItemTranslation();

                            menuItemTranslation['itemId'] = ProductsService.products[index].id;
                            menuItemTranslation['languageId'] = CurrentSession.getCompany().languageId;

                            if (page != Object.keys(response.currentPageOrder)[index]) {
                                let pageNameObj = {};

                                menuItemTranslation['itemName'] = page;
                                menuItemTranslation['displayName'] = response.currentPageOrder[page];

                                pageNameObj.menuId = $scope.menus.selected.menuId;
                                pageNameObj.pageIndex = index;
                                pageNameObj.pageName = response.currentPageOrder[page];

                                menuPagesOrder.push(menuItemTranslation);
                                reorderPageNames.push(pageNameObj);
                            }
                        });

                        Menu.updatePageOrder(payload).$promise.then(function () {
                            loadMenus(); // reloading UI on demand
                        });
                    }
                });
            };

            var loadProducts = function (companyId) {
                ProductsService.setSelectedMenuId($scope.menus.selected.menuId);
                var locationPromise = ProductsService.loadLocations(companyId, true);
                $scope.isMenuLoading = true;
                locationPromise
                    .then(ProductsService.loadProducts)
                    .then(function () {
                        $scope.locationDetail = ProductsService.locationDetail;

                        // only reset to first page if user is currently in at least a level deep.
                        // e.g. if user is currently in a category page within page 0.
                        if (currentCategory()) {
                            let id = currentCategory().locationServicePeriodMenuId;
                            let currentPageChildren = $scope.products[$scope.currentPage].children;

                            // find the current updated category
                            let updatedCategory = _.find(currentPageChildren, (child) => {
                                return child.locationServicePeriodMenuId === id;
                            });

                            // if found, replace the category in $scope.levels with the updatedCategory.
                            if (updatedCategory) {
                                $scope.levels[$scope.levels.length - 1].category = updatedCategory;
                            }
                        } else {
                            $scope.setMenuPage(0);
                        }
                        $scope.isMenuLoading = false;
                        $scope.isMenuLoaded = true;
                    });
            };

            var resetCurrentMenu = function (menuLength) {
                var menuRowCount = Math.ceil((menuLength + 2) / 3);
                menuLength = (menuRowCount + 1) * 3 - 1;

                $scope.currentMenu.length = 0;
                $scope.currentMenu = new Array(menuLength);

                var timestamp = new Date().valueOf();
                for (var i = 0; i < $scope.currentMenu.length; i++) {
                    $scope.currentMenu[i] = {_index: timestamp + '_' + i};
                }
            };
            var refreshCurrentMenu = function () {
                var currentLevels = angular.copy($scope.levels);
                $scope.levels = [];

                var oldCurrentPage;
                if ($scope.isShowingArchive || $scope.isShowingMasterProductList) {
                    var isTopLevel = true;
                    oldCurrentPage = $scope.currentPage;
                    var oldCurrentPageType = $scope.currentPageType;

                    $scope.showArchivePage();

                    _.each(currentLevels, async function (level) {
                        if (isTopLevel && level.page !== -1) {
                            if (level.category) {
                                $scope.nextLevel(level.category);
                            } else {
                                $scope.showArchivePage();
                            }
                        } else {
                            var search = {
                                servicePeriodMenuIds: level.category.locationServicePeriodMenuId,
                                menuId: $scope.menus.selected.menuId,
                                itemsOnly: true
                            };
                            let items = await findItem(search);
                            var category = _.find(items, function (item) {
                                return item.locationServicePeriodMenuId === level.category.locationServicePeriodMenuId;
                            });
                            if (category) {
                                $scope.currentPage = -1;
                                $scope.nextLevel(category);
                            } else {
                                $scope.showArchivePage();
                            }
                        }
                        isTopLevel = false;
                    });

                    $scope.currentPage = oldCurrentPage;
                    $scope.currentPageType = oldCurrentPageType;
                } else {
                    oldCurrentPage = $scope.currentPage;

                    createCurrentMenu($scope.products[$scope.currentPage].children);

                    _.each(currentLevels, function (level) {
                        $scope.nextLevel(level.category);
                    });

                    $scope.currentPage = oldCurrentPage;
                }
            };
            var createCurrentMenu = function (products) {
                var maxProduct = _.max(products, function (product) {
                    return product.currentCategoryIndex;
                }) || {};
                var maxProductCategoryIndex = maxProduct.currentCategoryIndex || 0;
                resetCurrentMenu(maxProductCategoryIndex);

                var timestamp = new Date().valueOf();
                _.each(products, function (product, index) {
                    if (product) {
                        var itemIndex = (product.currentCategoryId)? product.currentCategoryIndex : product.menuOrderId - 1;

                        if (itemIndex >= 0) {
                            $scope.currentMenu[itemIndex] = product;

                            var parentCategory = currentCategory() || _.findWhere($scope.products, {
                                locationServicePeriodMenuId: product.currentCategoryId
                            });
                            if (parentCategory) {
                                product._parent = parentCategory.locationServicePeriodMenuId; // parentCategory;
                            } else {
                                product._parent = undefined;
                            }
                        }
                        product._index = timestamp + '_' + product.locationServicePeriodMenuId;
                    }
                });
            };

            function findItem (param) {
                return new Promise(async (resolve, reject) => {
                    try {
                        let {posMenuItemEntries} = await Menu.itemSearch(param).$promise;
                        let items = (posMenuItemEntries && posMenuItemEntries.length) ? posMenuItemEntries[0].entries : [];
                        resolve(items);
                    } catch (error) {
                        console.log(error);
                        resolve([]);
                    }
                });
            }

            $scope.setCurrentMenu = function (menu) {
                createCurrentMenu(menu);
            };
            $scope.selectMenuItem = async function (menuItem) {
                if (!menuItem || !menuItem.type) {
                    return;
                }

                // ensure that no changes are pending
                if (!$scope.isLayoutPure) {
                    // show save or discard modal
                    return showSavePrompt();
                }

                $scope.isShowingArchive = ($scope.currentPageType === PAGE_TYPE.ARCHIVE);
                $scope.isShowingMasterProductList = ($scope.currentPageType === PAGE_TYPE.MASTER_PRODUCT_LIST);

                switch (menuItem.type) {
                    case 'category':
                        $scope.nextLevel(menuItem);
                        break;
                    case 'item': {
                        var search = {
                            servicePeriodMenuIds: menuItem.locationServicePeriodMenuId,
                            menuId: $scope.menus.selected.menuId,
                            itemsOnly: true
                        };
                        let items = await findItem(search);

                        let foundItem = _.find(items, {
                            locationServicePeriodMenuId: menuItem.locationServicePeriodMenuId
                        });

                        $scope.editItem(foundItem).then((itemEdited) => {
                            $scope.addActivity('edit', itemEdited);
                            refreshCurrentMenu();

                            if ($scope.isShowingArchive && $scope.isTopLevel) {
                                var topVisibleItem = $scope.archivedItemAdapter.topVisible;
                                var topVisibleItemId = topVisibleItem.item.locationServicePeriodMenuId;

                                var foundArchivedItem = _.find($scope.archivedItems, function (i) {
                                    return i.item.locationServicePeriodMenuId === topVisibleItemId;
                                });

                                if (foundArchivedItem) {
                                    var index = $scope.archivedItems.indexOf(foundArchivedItem);
                                    $scope.archivedItemAdapter.reload(index + 1);
                                } else {
                                    $scope.archivedItemAdapter.reload();
                                }
                            }
                        });
                        return;
                    }
                }
            };

            function showSavePrompt () {
                var modalInstance = $modal.open({
                    templateUrl: 'products/templates/product.save.prompt.tpl.html',
                    controller: 'ProductSavePromptCtrl',
                    windowClass: 'modal-50 products2',
                    animation: false,
                    // backdrop: 'static'
                });

                modalInstance.result.then(function (response) {
                    if (response.action === 'discard') {
                        $scope.revertLayout();
                    } else if (response.action === 'save') {
                        $scope.saveLayout();
                    }
                }, function (error) {
                    console.error(error);
                });
            }

            $scope.findMenuItemByUpc = function (upc) {
                if (!upc) {
                    return;
                }

                ProductsService.searchMenuItemsByUpc(upc).then(function (response) {
                    $scope.selectMenuItem(response);
                }, function (error) {
                    if ($scope.isRootCompany && error && error.errorCode == 0) {
                        // $scope.levels.length = 0;
                        // $scope.isTopLevel = true;
                        $scope.currentPage = 0;

                        $scope.createItem(-1, upc);
                    } else {
                        var message = 'general.error.oops.msg';
                        if (error && error.errorCode == 1) {
                            message = 'smb.pos.exchange.item.scan.cannot.process';
                        } else if (error && error.errorCode == -1) {
                            message = 'general.error.offline.functionality.disabled';
                        }

                        PosAlertService.showAlertByName('general-error', {
                            message: message,
                            title: 'smb.pos.exchange.item.search.failed'
                        });
                    }
                });
            };

            $scope.onItemUpcCodeScanned = function (upc) {
                if (!$scope.isModalActive()) {
                    $timeout(function () {
                        $scope.findMenuItemByUpc(upc);
                    }, 0);
                }
            };

            var modalPromise;
            const setModal = (config) => {
                if (modalPromise && !modalPromise.isPending) {
                    return Promise.reject();
                }

                var modalInstance = $modal.open(config, function (error) {
                    unsetModal();
                });
                modalPromise = modalInstance.result;

                return modalPromise;
            };
            const unsetModal = () => {
                modalPromise = undefined;
            };

            const openCreateCategoryModal = function (index, targetCategory, parentItemId, menus, refreshProducts) {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.category.tpl.html',
                    controller: 'ProductCategoryCtrl',
                    windowClass: 'modal-40 products2 modal--pull-right',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        index: function () {
                            return index;
                        },
                        targetCategory: function () {
                            return targetCategory;
                        },
                        menus: function () {
                            return menus;
                        },
                        parentItemId: function () {
                            return parentItemId;
                        },
                        refreshProducts: function () {
                            return refreshProducts;
                        }
                    }
                }).then(function (itemSaved) {
                    unsetModal();
                    return itemSaved;
                }).catch(function (error) {
                    unsetModal();
                });
            };

            const openEditCategoryModal = function (category, menus, parentItemId, refreshProducts) {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.category.tpl.html',
                    controller: 'ProductCategoryCtrl',
                    windowClass: 'modal-50 products2 modal--pull-right',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        category: function () {
                            return category;
                        },
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        menus: function () {
                            return menus;
                        },
                        parentItemId: function () {
                            return parentItemId;
                        },
                        isNew: function () {
                            return false;
                        },
                        refreshProducts: function () {
                            return refreshProducts;
                        }
                    }
                }).then(function (itemSaved) {
                    unsetModal();
                    return itemSaved;
                }).catch(function () {
                    unsetModal();
                });
            };

            const openCreateItemModal = function (newItem, targetCategory, menus, refreshProducts) {
                var index = newItem.index;
                var upc = newItem.upc;

                return setModal({
                    templateUrl: 'products/menuV2/templates/product.item.tpl.html',
                    controller: 'ProductItemCtrl',
                    windowClass: 'modal-80 products2 modal--pull-right',
                    animation: true,
                    backdrop: 'static',
                    resolve: {
                        index: function () {
                            return index;
                        },
                        upc: function () {
                            return upc;
                        },
                        targetCategory: function () {
                            return targetCategory;
                        },
                        isRootCompany: function () {
                            return $scope.isRootCompany;
                        },
                        isCampus: function () {
                            return $scope.isCampus;
                        },
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        menus: function () {
                            return menus;
                        },
                        isNew: function () {
                            return true;
                        },
                        refreshProducts: function () {
                            return refreshProducts;
                        }
                    }
                }).then(function (itemSaved) {
                    unsetModal();
                    return itemSaved;
                }).catch(function (error) {
                    unsetModal();
                });
            };

            const openEditItemModal = function (menuItem, targetCategory, menus, refreshProducts) {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.item.tpl.html',
                    controller: 'ProductItemCtrl',
                    windowClass: 'modal-80 products2 modal--pull-right',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        item: function () {
                            return menuItem;
                        },
                        targetCategory: function () {
                            return targetCategory;
                        },
                        isRootCompany: function () {
                            return $scope.isRootCompany;
                        },
                        isCampus: function () {
                            return $scope.isCampus;
                        },
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        menus: function () {
                            return menus;
                        },
                        isNew: function () {
                            return false;
                        },
                        refreshProducts: function () {
                            return refreshProducts;
                        }
                    }
                }).then(function (itemSaved) {
                    unsetModal();
                    return itemSaved;
                }).catch(function () {
                    unsetModal();
                });
            };

            $scope.hideMasterProductList = () => {
                // return to first page of items page.
                $scope.currentPageType = PAGE_TYPE.REGULAR;
                loadMenus();
                $scope.setMenuPage(0);

                // clear the search param.
                $scope.masterProductListSearch.text = '';
            };

            // editing category/items on the master product list.
            const openMasterProductEditModal = (menuItem) => {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.master.edit.tpl.html',
                    controller: 'MasterEditCtrl',
                    windowClass: 'modal-80 products2 modal--pull-right master-product__edit-item-modal',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        item: () => {
                            return menuItem;
                        },
                        organizationLanguages: () => {
                            return $scope.organizationLanguages;
                        },
                        menus: () => {
                            return $scope.menus;
                        }
                    }
                }).then((itemSaved) => {
                    unsetModal();
                }).catch(() => {
                    unsetModal();
                });
            };

            $scope.createCategory = function (index) {
                // ensure that no changes are pending
                if (!$scope.isLayoutPure) {
                    // show save or discard modal
                    return showSavePrompt();
                }

                if (isNaN(index)) {
                    index = findEmptyMenuIndex();
                }

                if ($scope.currentPage === -1) {
                    index = -1;
                }

                var targetCategory = currentCategory() || $scope.products[$scope.currentPage];

                // this is necessary in order to associate the item to the appropriate parent.
                // see /associations endpoint for more details.
                // create the category directly on the first page if in the 'all items' or 'master product list' page.
                let parentItemId = $scope.currentPage === -1 ? $scope.products[0].locationServicePeriodMenuId : $scope.products[$scope.currentPage].locationServicePeriodMenuId;
                return openCreateCategoryModal(index, targetCategory, parentItemId, $scope.menus, loadProducts).then(function (categorySaved) {
                    if (!categorySaved) { // dismiss
                        return;
                    }

                    refreshCurrentMenu();

                    if ($scope.isShowingArchive) {
                        if ($scope.archivedItemAdapter.reload) {
                            $scope.archivedItemAdapter.reload();
                        }
                    }
                });
            };

            $scope.createItem = function (index, upc) {
                // ensure that no changes are pending
                if (!$scope.isLayoutPure) {
                    // show save or discard modal
                    return showSavePrompt();
                }

                if (isNaN(index) || index < 0) {
                    index = findEmptyMenuIndex();
                }

                var newItem = {
                    index: index
                };

                if (upc) {
                    newItem.upc = upc;
                }

                var targetCategory = currentCategory() || $scope.products[$scope.currentPage];
                return openCreateItemModal(newItem, targetCategory, $scope.menus, loadProducts).then(function (itemSaved) {
                    if (!itemSaved) { // dismiss
                        return;
                    }

                    var category = currentCategory();
                    if (category) {
                        createCurrentMenu(category.children);
                    } else {
                        createCurrentMenu($scope.products);
                    }

                    if ($scope.isShowingArchive) {
                        if ($scope.archivedItemAdapter.reload) {
                            $scope.archivedItemAdapter.reload();
                        }
                    }

                    refreshCurrentMenu();
                });
            };

            $scope.editCategory = async (category) => {
                let search = {
                    servicePeriodMenuIds: category.locationServicePeriodMenuId,
                    menuId: $scope.menus.selected.menuId
                };

                let categories = await findItem(search);
                let foundCategory = _.find(categories, {
                    locationServicePeriodMenuId: category.locationServicePeriodMenuId
                });

                // this is necessary in order to associate the item to the appropriate parent.
                // see /associations endpoint for more details.
                let parentItemId = $scope.currentPage === -1 ? null : $scope.products[$scope.currentPage].locationServicePeriodMenuId;
                return openEditCategoryModal(foundCategory, $scope.menus, parentItemId, loadProducts).then(function (categorySaved) {
                    if (!categorySaved) { // dismiss
                        return;
                    }

                    if (categorySaved.deleted) {
                        $scope.previousLevel();
                    } else {
                        $scope.addActivity('edit', categorySaved);

                        var categoryToUpdate = currentCategory();
                        categoryToUpdate.name = categorySaved.name;
                        categoryToUpdate.description = categorySaved.description;
                    }

                    if ($scope.isShowingArchive) {
                        constructArchivedItems();
                    }
                });
            };

            $scope.editItem = function (item) {
                var targetCategory = currentCategory() || $scope.products[$scope.currentPage];
                return openEditItemModal(item, targetCategory, $scope.menus, loadProducts).then(function (itemSaved) {
                    if (!itemSaved) { // dismiss
                        return;
                    }

                    $scope.addActivity('edit', itemSaved);

                    var category = currentCategory();
                    if (category) {
                        createCurrentMenu(category.children);
                    } else {
                        createCurrentMenu($scope.products);
                    }

                    if ($scope.isShowingArchive) {
                        constructArchivedItems();
                    }
                });
            };

            $scope.editItemOnMasterProductList = async (item) => {
                // need to perform a fresh reload of the item with lite=false from searchMasterProductList
                // as the item at the MPL table only contains lite data.

                const search = {
                    searchQuery: '',
                    servicePeriodMenuIds: item.locationServicePeriodMenuId,
                    locationId: ProductsService.locationDetail.locationId,
                    offset: 0,
                    limit: 30
                };
                const response = await Menu.searchMasterProductList(search).$promise;

                // if no response or no item in response array
                if (!response.length || !response[0]) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'product.masterProductList.error.ttl',
                        message: 'product.masterProductList.error.desc'
                    });
                    return;
                }

                const itemToEdit = response[0];
                return openMasterProductEditModal(itemToEdit).then(() => {
                    $scope.searchMasterProductList();
                });
            };

            $scope.openCreateEditMenuModal = (page, isNew = true) => {
                return setModal({
                    templateUrl: 'products/menuV2/templates/product.menu.create.tpl.html',
                    controller: 'ProductMenuCreateCtrl',
                    windowClass: 'modal-60 products3',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        menus: () => {
                            return $scope.menus;
                        },
                        currentPage: () => {
                            return page;
                        },
                        isNew: () => {
                            return isNew;
                        }
                    }
                }).then((res) => {
                    // load menus if a new menu was created.
                    if (res) {
                        loadMenus();
                    }
                    unsetModal();
                }).catch(() => {
                    unsetModal();
                });
            };

            $scope.alertReservedItemNumpad = function () {
                PosAlertService.showError('success', 'Reserved', '', 'This block is reserved for the numpad key.');
            };


            $scope.alertReservedBuzzer = function () {
                PosAlertService.showError('success', 'Reserved', '', 'This block is reserved for the buzzer key.');
            };

            $scope.alertReservedItemGiftCard = function () {
                PosAlertService.showError('success', 'Reserved', '', 'This block is reserved for the gift card button.');
            };

            var findEmptyMenuIndex = function () {
                var i = 0;
                for (i; i < $scope.currentMenu.length; i++) {
                    var menuItem = $scope.currentMenu[i] || {};

                    if (!menuItem.locationServicePeriodMenuId) {
                        return i;
                    }
                }

                return -1;
            };

            $scope.moveMenuItemTo = function (fromItem, newIndex) {
                if (newIndex < 0) {
                    $scope.isLayoutPure = false;
                    $scope.unsortMenuItem(fromItem);
                } else {
                    var newMenuItemIndex = newIndex;
                    var newMenuItemSlot = $scope.currentMenu[newMenuItemIndex];
                    $scope.isLayoutPure = false;

                    if (newMenuItemSlot && newMenuItemSlot.locationServicePeriodMenuId) {
                        $scope.swapMenuItems(fromItem, newMenuItemSlot);
                    } else {
                        var oldFromProductCategoryId, oldFromProductCategoryIndex;

                        if (!fromItem.currentCategoryId) {
                            fromItem.menuOrderId = newMenuItemIndex + 1;
                        } else {
                            var category = currentCategory() || $scope.products[$scope.currentPage];

                            if (!validateDuplicatedCategoryChildren(category, fromItem)) {
                                return;
                            }

                            oldFromProductCategoryId = fromItem.currentCategoryId;
                            oldFromProductCategoryIndex = fromItem.currentCategoryIndex;

                            fromItem.currentCategoryId = category.locationServicePeriodMenuId;
                            fromItem.currentCategoryIndex = newMenuItemIndex;
                        }

                        var category2 = currentCategory();

                        // refreshes UI right away
                        if (category2) {
                            createCurrentMenu(category2.children);
                        } else {
                            var oldCategoryId = oldFromProductCategoryId;
                            var newCategoryId = $scope.products[$scope.currentPage].locationServicePeriodMenuId;
                            var hasCategoryChanged = (oldCategoryId != newCategoryId);

                            if (hasCategoryChanged) {
                                var oldCategory = _.findWhere($scope.products, {locationServicePeriodMenuId: oldCategoryId});
                                var newCategory = _.findWhere($scope.products, {locationServicePeriodMenuId: newCategoryId});

                                var oldCategoryArrayIndex = oldCategory.children.indexOf(fromItem);
                                if (oldCategoryArrayIndex > -1) {
                                    oldCategory.children.splice(oldCategoryArrayIndex, 1);
                                }

                                newCategory.children.push(fromItem);
                                fromItem._parent = newCategory.locationServicePeriodMenuId;
                            }

                            createCurrentMenu($scope.products[$scope.currentPage].children);
                        }

                        recordMenuItemsToMove(fromItem,
                            oldFromProductCategoryId,
                            oldFromProductCategoryIndex,
                            fromItem.currentCategoryId,
                            fromItem.currentCategoryIndex);
                    }
                }
            };
            $scope.swapMenuItems = function (fromItem, targetItem, fromItemCategory, targetItemCategory) {
                if (!fromItem.currentCategoryId) {
                    var o = fromItem.menuOrderId;
                    fromItem.menuOrderId = targetItem.menuOrderId;
                    targetItem.menuOrderId = o;

                    var fromProduct = _.find($scope.products, {locationServicePeriodMenuId: fromItem.locationServicePeriodMenuId});
                    fromProduct.menuOrderId = fromItem.menuOrderId;

                    var targetProduct = _.find($scope.products, {locationServicePeriodMenuId: targetItem.locationServicePeriodMenuId});
                    targetProduct.menuOrderId = targetItem.menuOrderId;

                    createCurrentMenu($scope.products);
                } else {
                    var category = currentCategory() || $scope.products[$scope.currentPage];

                    var targetItemParent = _.find($scope.products, {locationServicePeriodMenuId: targetItem._parent});
                    var fromItemParent = _.find($scope.products, {locationServicePeriodMenuId: fromItem._parent});

                    if (!validateDuplicatedCategoryChildren(targetItemParent, fromItem)) {
                        return;
                    }

                    if (!validateDuplicatedCategoryChildren(fromItemParent, targetItem)) {
                        return;
                    }

                    var oldFromCategoryId = fromItem.currentCategoryId;
                    var oldFromCategoryIndex = fromItem.currentCategoryIndex;
                    var oldFromCategory = _.find($scope.products, {locationServicePeriodMenuId: oldFromCategoryId});
                    var oldFromCategoryArrayIndex = oldFromCategory.children.indexOf(fromItem);

                    var oldTargetCategoryId = targetItem.currentCategoryId;
                    var oldTargetCategoryIndex = targetItem.currentCategoryIndex;
                    var oldTargetCategory = _.find($scope.products, {locationServicePeriodMenuId: oldTargetCategoryId});
                    var oldTargetCategoryArrayIndex = oldTargetCategory.children.indexOf(targetItem);

                    oldFromCategory.children.splice(oldFromCategoryArrayIndex, 1, targetItem);
                    targetItem._parent = oldFromCategoryId;
                    oldTargetCategory.children.splice(oldTargetCategoryArrayIndex, 1, fromItem);
                    fromItem._parent = oldTargetCategoryId;

                    fromItem.currentCategoryId = oldTargetCategoryId;
                    fromItem.currentCategoryIndex = oldTargetCategoryIndex;
                    targetItem.currentCategoryId = oldFromCategoryId;
                    targetItem.currentCategoryIndex = oldFromCategoryIndex;

                    createCurrentMenu(category.children);

                    recordMenuItemsToMove(fromItem,
                        targetItem.currentCategoryId,
                        targetItem.currentCategoryIndex,
                        fromItem.currentCategoryId,
                        fromItem.currentCategoryIndex);

                    recordMenuItemsToMove(targetItem,
                        fromItem.currentCategoryId,
                        fromItem.currentCategoryIndex,
                        targetItem.currentCategoryId,
                        targetItem.currentCategoryIndex);
                }
            };


            var menuItemsToMove = [];
            var recordMenuItemsToMove = function (menuItem, oldCategoryId, oldCategoryIndex, newCategoryId, newCategoryIndex) {
                var menuItemToMove = _.findWhere(menuItemsToMove, {
                    locationServicePeriodMenuId: menuItem.locationServicePeriodMenuId
                });

                if (!menuItemToMove) {
                    menuItemToMove = {
                        locationServicePeriodMenuId: menuItem.locationServicePeriodMenuId,
                        servicePeriodIds: menuItem.servicePeriodIds,
                        menuOrderId: menuItem.menuOrderId,
                        forceTopLevel: menuItem.forceTopLevel,
                        oldCategoryId: oldCategoryId,
                        oldCategoryIndex: oldCategoryIndex,
                        currentCategoryId: newCategoryId,
                        currentCategoryIndex: newCategoryIndex,
                        companyId: menuItem.companyId
                    };

                    menuItemsToMove.push(menuItemToMove);
                }

                menuItemToMove.currentCategoryId = newCategoryId;
                menuItemToMove.currentCategoryIndex = newCategoryIndex;
            };


            var menuItemsToUnsort = [];
            $scope.unsortMenuItem = function (menuItem) {
                if (!menuItem.currentCategoryId) {
                    menuItem.menuOrderId = -1;
                } else {
                    menuItem.currentCategoryIndex = -1;
                }

                menuItemsToUnsort.push(menuItem);

                var category = currentCategory();

                // refreshes UI right away
                if (category) {
                    createCurrentMenu(category.children);
                } else {
                    createCurrentMenu($scope.products[$scope.currentPage].children);
                }
            };
            $scope.moveArchivedMenuItemTo = function (menuItem, newIndex) {

                $scope.isLayoutPure = false;

                var category = currentCategory() || $scope.products[$scope.currentPage];

                if (validateDuplicatedCategoryChildren(category, menuItem)) {
                    menuItem.currentCategoryIndex = newIndex;
                    menuItem.currentCategoryId = category.locationServicePeriodMenuId;

                    category.children.push(menuItem);
                    createCurrentMenu(category.children);

                    recordMenuItemsToMove(menuItem, undefined, undefined, menuItem.currentCategoryId, menuItem.currentCategoryIndex);
                }
            };
            var validateDuplicatedCategoryChildren = function (category, child) {
                if (child.currentCategoryId === category.locationServicePeriodMenuId) {
                    return true;
                }

                var isValid = !_.findWhere(category.children, {locationServicePeriodMenuId: child.locationServicePeriodMenuId});

                if (!isValid) {
                    if (category.currentCategoryId) {
                        PosAlertService.showAlertByName('duplicated-item-in-category');
                    } else {
                        PosAlertService.showAlertByName('duplicated-item-on-page');
                    }
                }

                return isValid;
            };

            $scope.revertLayout = function () {
                $scope.products = ProductsService.products;
                loadMenus();

                $scope.isLayoutPure = true;
                menuItemsToMove.length = 0;
                menuItemsToUnsort.length = 0;
            };

            // new function to save the layout: MenuService.addAssociations()
            $scope.saveLayout = function () {
                if ($scope.isLayoutPure || !acquireLock()) {
                    return;
                }

                $scope.savingLayout = true;
                var items = menuItemsToMove;
                var itemsToUnsort = menuItemsToLiteItems(menuItemsToUnsort);

                var allItemsToUpdate = items.concat(itemsToUnsort);
                allItemsToUpdate.forEach((item) => {
                    const parentMap = {};
                    const childMap = {};
                    if (item.oldCategoryId) {
                        parentMap[item.oldCategoryId] = -1;
                    }
                    if (item.currentCategoryId) {
                        parentMap[item.currentCategoryId] = item.currentCategoryIndex;
                    }

                    item.parentMap = parentMap;
                    item.childMap = childMap;
                });

                MenuService.addAssociations({
                    itemToSave: allItemsToUpdate,
                    menuId: ProductsService.getSelectedMenuId()
                }).then(function () {
                    unlockButtons();
                    $scope.isLayoutPure = true;
                }).finally(() => {
                    $scope.savingLayout = false;
                });

                menuItemsToMove.length = 0;
                menuItemsToUnsort.length = 0;
            };

            // returns a list of menu items with only the required
            // fields to rearange the order
            function menuItemsToLiteItems (items) {
                var itemsCopy = angular.copy(items);
                return itemsCopy.filter(function (item) {
                    return (item.companyId && item.locationServicePeriodMenuId);
                }).map(function (item) {
                    return {
                        locationServicePeriodMenuId: item.locationServicePeriodMenuId,
                        companyId: item.companyId,
                        servicePeriodIds: item.servicePeriodIds,
                        menuOrderId: item.menuOrderId,
                        currentCategoryId: item.currentCategoryId,
                        currentCategoryIndex: item.currentCategoryIndex,
                        forceTopLevel: item.forceTopLevel
                    };
                });
            }

            var hasActiveRequest = false;
            var timeout;

            function acquireLock () {
                if (hasActiveRequest) {
                    return false;
                }
                hasActiveRequest = true;
                timeout = setTimeout(function () {
                    timeout = undefined;
                    hasActiveRequest = false;
                }, 10000);

                return true;
            }

            function unlockButtons () {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = undefined;
                }
                hasActiveRequest = false;
            }

            $scope.selectCategory = function (category) {
            };

            $scope.levels = [];
            $scope.isTopLevel = true;
            $scope.nextLevel = function (category) {
                $scope.levels.push({
                    category: category,
                    page: $scope.currentPage
                });
                $scope.isTopLevel = false;

                if ($scope.currentPage < 0) {
                    $scope.currentPage = 0;
                }

                $scope.setCurrentMenu(category.children || []);
                $scope.clearActivity();
            };
            $scope.previousLevel = function () {
                var level = $scope.levels.pop();
                if (level) {
                    var depth = $scope.levels.length;
                    if (depth < 1) {
                        $scope.isTopLevel = true;

                        createCurrentMenu($scope.products);
                    } else {
                        var lastLevel = $scope.levels[depth - 1];
                        createCurrentMenu(lastLevel.category.children);
                    }

                    if (level.page < 0) {
                        $scope.currentPageType === 'archive' ? $scope.showArchivePage() : $scope.showMasterProductList();
                    } else {
                        $scope.setMenuPage(level.page);
                    }

                    $scope.clearActivity();
                }
            };
            var currentCategory = $scope.currentCategory = function () {
                var depth = $scope.levels.length;

                return (depth)? $scope.levels[depth - 1].category : undefined;
            };
            $scope.parentPage = function () {
                var depth = $scope.levels.length;

                return (depth)? $scope.levels[depth - 1].page : undefined;
            };

            $scope.activities = [];
            $scope.addActivity = function (type, product) {
                if (!product) {
                    return;
                }

                $scope.activities.push({
                    type: type,
                    product: product,
                    timestamp: new Date()
                });
            };
            $scope.clearActivity = function () {
                $scope.activities.length = 0;
            };

            $scope.draggingStatus = {
                isDragging: 0
            };

            $scope.changePageOnItemMove = function (index, updateArchiveStatus) {
                var isTopLevel = currentCategory();

                if (!isTopLevel) {
                    if (index >= 0) {
                        $scope.currentPage = index;
                        $scope.currentPageType = PAGE_TYPE.REGULAR;

                        var page = $scope.products[index] || {};
                        var pageChildren = page.children || [];

                        createCurrentMenu(pageChildren);

                        if (updateArchiveStatus) {
                            $scope.isShowingArchive = false;
                        }
                    } else {
                        $scope.currentPageType = PAGE_TYPE.ARCHIVE;
                        $scope.isShowingArchive = true;
                    }
                } else {
                    if (index >= 0) {
                        if (updateArchiveStatus) {
                            $scope.isShowingArchive = false;
                        }
                    } else {
                        $scope.currentPageType = PAGE_TYPE.ARCHIVE;
                        $scope.isShowingArchive = true;
                    }
                }
            };

            $scope.setMenuPage = function (index, updateArchiveStatus) {
                if (index >= 0) {
                    $scope.isTopLevel = true;
                    $scope.levels.length = 0;
                    // Empty search bar
                    $scope.searchText.text = '';

                    $scope.currentPage = index;
                    $scope.currentPageType = PAGE_TYPE.REGULAR;

                    var page = $scope.products[index] || {};
                    var pageChildren = page.children || [];

                    // $scope.isMasterMenu = !page._inherited;

                    createCurrentMenu(pageChildren);

                    if (updateArchiveStatus) {
                        $scope.isShowingArchive = false;
                    }
                } else {
                    $scope.currentPageType = PAGE_TYPE.ARCHIVE;
                    $scope.isShowingArchive = true;
                }
            };
            $scope.showArchivePage = function () {
                $scope.currentPage = -1;
                $scope.currentPageType = PAGE_TYPE.ARCHIVE;
                $scope.isShowingArchive = true;

                constructArchivedItems();
            };

            $scope.showMasterProductList = () => {
                $scope.masterProductList = [];
                // we need to change limit to 30 after infinite scroll is implemented.
                const search = {
                    searchQuery: '',
                    locationId: ProductsService.locationDetail.locationId,
                    offset: 0,
                    limit: 100,
                    liteVersion: true
                };
                Menu.searchMasterProductList(search).$promise.then((response) => {
                    $scope.masterProductList = response.map((e) => {
                        return {item: e};
                    });
                });

                $scope.currentPageType = PAGE_TYPE.MASTER_PRODUCT_LIST;
            };

            $scope.masterProductListSearch = {
                text: ''
            };

            $scope.searchMasterProductList = () => {
                $scope.masterProductList = [];

                if (timer) {
                    $timeout.cancel(timer);
                }
                // we need to change limit to 30 after infinite scroll is implemented.
                let timeout = timer ? 500 : 0;
                timer = $timeout(() => {
                    const search = {
                        searchQuery: $scope.masterProductListSearch.text,
                        locationId: ProductsService.locationDetail.locationId,
                        offset: 0,
                        limit: 30,
                        liteVersion: true
                    };
                    Menu.searchMasterProductList(search).$promise.then((response) => {
                        $scope.masterProductList = response.map((e) => {
                            return {item: e};
                        });
                    });
                }, timeout);
            };

            $scope.manageMenuPeriods = function () {
                $scope.isMenuPeriodList = true;
                $scope.isMenuPeriod = false;
                $scope.isNewMenuPeriod = true;
                $scope.fiitServicePeriods = GatewayFiit.getServicePeriods();
            };

            $scope.selectedMenuPeriod = {};

            $scope.addNewMenuPeriod = function () {
                if (!$scope.menus || !$scope.menus.selected || !$scope.menus.selected.menuId) {
                    PosAlertService.showAlertByName('general-error', {
                        message: 'Invalid menu selected. Cannot add a schedule !'
                    });
                    return;
                }

                $scope.isNewMenuPeriod = true;
                $scope.isMenuPeriodList = false;
                $scope.isMenuPeriod = true;
                $scope.allItemsSelected = false;
                $scope.selectedMenuPeriod = {
                    menuPeriodId: -1,
                    menuId: (!$scope.menuFilter) ? $scope.menus.selected.menuId : $scope.menuFilter.menuId,
                    days: [],
                    description: '',
                    assignedItems: []
                };
                setDays($scope.selectedMenuPeriod.days);
                initMenuPeriodTime($scope.selectedMenuPeriod, true);
                if ($scope.menuPeriodItemAdapter.reload) {
                    $scope.menuPeriodItemAdapter.reload();
                }
            };

            $scope.toggleItemCard = function (item) {
                if (!item.children || !item.children.length) {
                    return;
                }

                item.cardExpanded = !item.cardExpanded;

                if (item.children && item.children.length) {
                    item.children.forEach((child) => child.toShow = item.cardExpanded);
                }
            };

            // @param force - prevents mulitple error modals while recursing
            var _processItemForMenuPeriod = async function (item, isAssigned, parentItems, force) {

                if (item.children && item.children.length) {
                    if (!isAssigned && !force) {
                        force = true;
                        const modalInstance = PosAlertService.showAlertByName('general-alert', {
                            title: 'products.menu.schedule.alert.modifier.linked.ttl',
                            message: 'products.menu.schedule.alert.modifier.linked.msg',
                            modalCallback: function () {
                                _processItemForMenuPeriod(item, isAssigned, parentItems, force);
                            }
                        });
                        await modalInstance.result;
                    }

                    for (const child of item.children) {
                        $scope.assignItemToPeriod(child, isAssigned, [], force);
                    }
                }

                var existingIndex = $scope.selectedMenuPeriod.assignedItems.indexOf(item.locationServicePeriodMenuId);
                if (existingIndex > -1) {
                    $scope.selectedMenuPeriod.assignedItems.splice(existingIndex, 1);
                }

                if (isAssigned) {
                    $scope.selectedMenuPeriod.assignedItems.push(item.locationServicePeriodMenuId);
                    if (parentItems && parentItems.length) {
                        parentItems.forEach((parent) => {
                            var existingParentIndex = $scope.selectedMenuPeriod.assignedItems.indexOf(parent.locationServicePeriodMenuId);
                            if (existingParentIndex > -1) {
                                $scope.selectedMenuPeriod.assignedItems.splice(existingParentIndex, 1);
                            }
                            $scope.selectedMenuPeriod.assignedItems.push(parent.locationServicePeriodMenuId);
                        });
                    }
                }
            };

            $scope.assignItemToPeriod = function (item, isAssigned, parentItems = [], force = false) {
                if (!$scope.selectedMenuPeriod || !$scope.selectedMenuPeriod.assignedItems) {
                    return;
                }

                if ($scope.selectedMenuPeriod.quickChargeEnabled
                    && (item.locationServicePeriodMenuId == $scope.selectedMenuPeriod.quickChargeItemId)) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'products.menu.schedule.invalid.required.quickcharge.ttl',
                        message: 'products.menu.schedule.invalid.required.quickcharge.msg'
                    });
                    return;
                }

                isAssigned = !!isAssigned;
                _processItemForMenuPeriod(item, isAssigned, parentItems, force);
            };

            $scope.setQuickChargeItem = function (item) {
                if (!$scope.selectedMenuPeriod || !$scope.selectedMenuPeriod.assignedItems) {
                    return;
                }

                var existingIndex = $scope.selectedMenuPeriod.assignedItems.indexOf(item.locationServicePeriodMenuId);
                if (existingIndex <= -1) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'products.menu.schedule.invalid.quickcharge.ttl',
                        message: 'products.menu.schedule.invalid.quickcharge.msg'
                    });
                    return;
                }

                $scope.selectedMenuPeriod.quickChargeEnabled = true;
                $scope.selectedMenuPeriod.quickChargeItemId = item.locationServicePeriodMenuId;
                $scope.quickChargeItemSearchText.isSearching = false;
                $scope.quickChargeItemSearchText.text = item.name;
            };

            $scope.editMenuPeriod = function (menuPeriod) {
                $scope.isNewMenuPeriod = false;
                $scope.isMenuPeriodList = false;
                $scope.isMenuPeriod = true;
                $scope.allItemsSelected = false;
                setDays(menuPeriod.days || []);
                initMenuPeriodTime(menuPeriod, true);

                Locations.getMenuPeriod({companyId: CurrentSession.getCompany().companyId, menuPeriodId: menuPeriod.menuPeriodId}, function (response) {
                    $scope.selectedMenuPeriod = response;

                    if ($scope.selectedMenuPeriod.quickChargeItemId) {
                        $scope.quickChargeItemSearchText.text = $scope.selectedMenuPeriod.quickChargeItemName;
                        $scope.quickChargeItemSearchText.isSearching = false;
                    } else {
                        $scope.quickChargeItemSearchText.text = '';
                        $scope.quickChargeItemSearchText.isSearching = true;
                    }

                    if ($scope.menuPeriodItemAdapter.reload) {
                        $scope.menuPeriodItemAdapter.reload();
                    }
                }, function (error) {
                    PosAlertService.showAlertByName('general-error');
                });
            };

            var populateTimestamps = function (schedule) {
                try {
                    for (var type in $scope.timestamps) {
                        if (!$scope.timestamps[type].newTimestamp) {
                                continue;
                        }
                        /*
                            Commented by: Harish Reddy on June 28, 2022.
                            'tmpTime' should take '.timePeriod' (not '.timestampPeriod' which sets [AM/PM] to current time [AM/PM])
                            from 'timestamps[type]' object to generate time data using 'moment.js'.
                            @property '.startTime' is supplied the time data from 'newDate' by calling 'moment(Y, M, d, h, m, s=0, ms=0);'.
                            'newDate' returns time data and depends on timestamps[type].timeInHrsMins & timestamps[type].timePeriod;
                        */
                        var tmpTime = moment($scope.timestamps[type].timeInHrsMins + ' ' + $scope.timestamps[type].timePeriod, ['hh:mm A']);
                        var tmpDate = moment($scope.timestamps[type].newTimestamp);
                        var newDate = moment({
                            y: tmpDate.year(),
                            M: tmpDate.month(),
                            d: tmpDate.date(),
                            h: tmpTime.hour(),
                            m: tmpTime.minute(),
                            s: 0,
                            ms: 0
                        }).valueOf();
                        var originalTimestampWithoutSeconds = moment($scope.timestamps[type].originalTimestamp).seconds(0).milliseconds(0).valueOf();
                        var isEdited = (newDate == originalTimestampWithoutSeconds) ? false : true;

                        if (type == 'startTime') {
                            schedule.originalStartTime = schedule.startTime;
                            schedule.startTime = newDate;
                            schedule.isStartTimeEdited = isEdited;
                        } else if (type == 'endTime') {
                            schedule.originalEndTime = schedule.endTime;
                            schedule.endTime = newDate;
                            schedule.isEndTimeEdited = isEdited;
                        }
                    }
                } catch (error) {
                    PosAlertService.showAlertByName('invalid-date-range', {
                        message: 'Invalid '+ $scope.timestamps[type].errorLabel
                    });
                    return false;
                }

                return true;
            };

            var isSavingSchedule = false;
            $scope.saveMenuPeriod = function () {
                const cacheQCS = CommonOfflineCache.getQuickChargeSession();

                if (!$scope.selectedMenuPeriod || isSavingSchedule) {
                    return;
                }

                if ($scope.selectedMenuPeriod.quickChargeEnabled && !$scope.selectedMenuPeriod.quickChargeItemId) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'products.menu.schedule.invalid.selection.required.quickcharge.ttl',
                        message: 'products.menu.schedule.invalid.selection.required.quickcharge.msg'
                    });
                    return;
                } else if ($scope.selectedMenuPeriod.quickChargeEnabled && $scope.selectedMenuPeriod.quickChargeItemId
                    && cacheQCS) {
                    // Commented By Harish Reddy, August 22, 2022
                    // Whenever change is made to any menu period, Quick charge session needs to be reloaded to take
                    // updated item. So, we end session and then cashiers can start the session
                    SmbPosService.quickChargeSessionStatus.active = false;
                    SmbPosService.endQuickChargeSession(GatewayFiit.isEnabled(), cacheQCS.quickChargeSessionId);
                }

                $scope.days.forEach((dayObj) => {
                    var existingIndex = $scope.selectedMenuPeriod.days.indexOf(dayObj.day);
                    if (existingIndex > -1) {
                        $scope.selectedMenuPeriod.days.splice(existingIndex, 1);
                    }
                    if (dayObj.enabled) {
                        $scope.selectedMenuPeriod.days.push(dayObj.day);
                    }
                });

                if (!populateTimestamps($scope.selectedMenuPeriod) || !isMenuPeriodValid($scope.selectedMenuPeriod)) {
                    return;
                }

                isSavingSchedule = true;
                var menuPeriodObj = {
                    menuPeriodId: $scope.selectedMenuPeriod.menuPeriodId,
                    menuId: $scope.selectedMenuPeriod.menuId,
                    days: $scope.selectedMenuPeriod.days || [],
                    assignedItems: [...(new Set($scope.selectedMenuPeriod.assignedItems))] || [],
                    description: $scope.selectedMenuPeriod.description,
                    quickChargeEnabled: !!$scope.selectedMenuPeriod.quickChargeEnabled,
                    quickChargeItemId: $scope.selectedMenuPeriod.quickChargeItemId,
                    startTimeStr: moment($scope.selectedMenuPeriod.startTime).format('HH:mm'),
                    endTimeStr: moment($scope.selectedMenuPeriod.endTime).format('HH:mm'),
                    servicePeriodId: $scope.selectedMenuPeriod.servicePeriodId
                };

                if ($scope.selectedMenuPeriod.menuPeriodId <= 0) {
                    delete menuPeriodObj.menuPeriodId;
                    Locations.addMenuPeriod(menuPeriodObj, function (response) {
                        isSavingSchedule = false;
                        ProductsService.updateMenuMobile(ProductsService.locationDetail.locationId);
                        $scope.goBackToMenuPeriodList();
                    }, function (error) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'Error while Storing data'
                        });
                    });
                    isSavingSchedule = false;
                } else {
                    Locations.updateMenuPeriod(menuPeriodObj, function (response) {
                        isSavingSchedule = false;
                        ProductsService.updateMenuMobile(ProductsService.locationDetail.locationId);
                        $scope.goBackToMenuPeriodList();
                    }, function (error) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'Error while Storing data'
                        });
                    });
                    isSavingSchedule = false;
                }


            };

            var setDays = function (enabledDays = []) {
                var enabledDaysLocal = enabledDays.map((day) => day.toLowerCase());
                for (var dayObj of $scope.days) {
                    if (enabledDaysLocal.includes(dayObj.day.toLowerCase())) {
                        dayObj.enabled = true;
                    } else {
                        dayObj.enabled = false;
                    }
                }
            };

            var isMenuPeriodValid = function (menuPeriod, toShowAlerts = true) {
                if (!menuPeriod.description) {
                    if (toShowAlerts) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            title: 'No Period Name',
                            message: 'Please enter a period name to continue'
                        });
                    }
                    return false;
                }

                if ($scope.isFiitEnabled && !menuPeriod.servicePeriodId) {
                    if (toShowAlerts) {
                        PosAlertService.showAlertByName('general-error', {
                            title: 'No Service Period Associated',
                            message: 'Please associate a service period to continue'
                        });
                    }
                    return false;
                }

                if (!menuPeriod.startTime) {
                    if (toShowAlerts) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'Invalid Period Start Time'
                        });
                    }
                    return false;
                }

                if (!menuPeriod.endTime) {
                    if (toShowAlerts) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: 'Invalid Period End Time'
                        });
                    }
                    return false;
                }

                if (menuPeriod.endTime < menuPeriod.startTime) {
                    if (toShowAlerts) {
                        PosAlertService.showAlertByName('invalid-date-range', {
                            message: '"Period End Time" must be later than "Period Start Time"'
                        });
                    }
                    return false;
                }

                return true;
            };

            $scope.timestamps = {};
            var initMenuPeriodTime = function (menuPeriod) {
                var menuPeriodStartTime = {};
                /*
                    Commented by Harish Reddy on June 28, 2022.
                    @property 'inputMoment' sets time data to model;
                    'menuPeriodStartTime.inputMoment' supplies the time data, if it's already available or
                    gets current time from moment() (moment.js);
                */
                var startTimeMoment = (menuPeriod.startTimeStr) ? moment(menuPeriod.startTimeStr, 'HH:mm') : moment();

                menuPeriodStartTime.originalTimestamp = menuPeriod.startTime || Date.now();
                menuPeriodStartTime.newTimestamp = angular.copy(menuPeriodStartTime.originalTimestamp);
                menuPeriodStartTime.timeInHrsMins = $filter('date')(menuPeriodStartTime.newTimestamp, 'hh:mm');
                menuPeriodStartTime.timestampPeriod = $filter('date')(menuPeriodStartTime.newTimestamp, 'a');
                menuPeriodStartTime.inputMoment = startTimeMoment;
                menuPeriodStartTime.errorLabel = 'Start Time';
                menuPeriodStartTime.label = 'Start Time';
                $scope.timestamps['startTime'] = menuPeriodStartTime;

                var menuPeriodEndTime = {};
                /*
                    Commented by Harish Reddy on June 28, 2022.
                    @property 'inputMoment' sets time data to model;
                    'menuPeriodEndTime.inputMoment' supplies the time data, if it's already available or
                    gets current time from moment() (moment.js);
                */
                var endTimeMoment = (menuPeriod.endTimeStr) ? moment(menuPeriod.endTimeStr, 'HH:mm') : moment();

                menuPeriodEndTime.originalTimestamp = menuPeriod.endTime || Date.now();
                menuPeriodEndTime.newTimestamp = angular.copy(menuPeriodEndTime.originalTimestamp);
                menuPeriodEndTime.timeInHrsMins = $filter('date')(menuPeriodEndTime.newTimestamp, 'hh:mm');
                menuPeriodEndTime.timestampPeriod = $filter('date')(menuPeriodEndTime.newTimestamp, 'a');
                menuPeriodEndTime.inputMoment = endTimeMoment;
                menuPeriodEndTime.errorLabel = 'End Time';
                menuPeriodEndTime.label = 'End Time';
                $scope.timestamps['endTime'] = menuPeriodEndTime;
            };

            $scope.goBackToItems = function () {
                $scope.isMenuPeriodList = false;
                $scope.isMenuPeriod = false;
                resetMenuPeriodPage();
            };

            var resetMenuPeriodPage = function () {
                $scope.selectedMenuPeriod = {};
                setDays([]);
                if ($scope.menuPeriodAdapter.reload) {
                    $scope.menuPeriodAdapter.reload();
                }
            };

            $scope.goBackToMenuPeriodList = function () {
                $scope.isMenuPeriodList = true;
                $scope.isMenuPeriod = false;
                $scope.menuPeriodItemSearchText.text = '';
                resetMenuPeriodPage();
            };

            $scope.archivedItems = [];

            var constructArchivedItems = function () {
                $scope.archivedItems.length = 0;

                var currentMenuItemIds = [];

                _.each($scope.currentMenu, function (currentMenuItem) {
                    if (currentMenuItem.locationServicePeriodMenuId) {
                        currentMenuItemIds.push(currentMenuItem.locationServicePeriodMenuId);
                    }
                });

                var category = currentCategory();
                if (category) {
                    currentMenuItemIds.push(category.locationServicePeriodMenuId);
                }

                var archivedItems = _.filter($scope.allItems, function (item) {
                    return ['item', 'category'].indexOf(item.type) > -1
                        && ['modifier_option', 'page'].indexOf(item.subtype) === -1;
                });
                archivedItems = _.sortBy(archivedItems, 'name');

                $scope.archivedItems = _.map(archivedItems, function (i) {
                    return {
                        item: i,
                        sorted: currentMenuItemIds.indexOf(i.locationServicePeriodMenuId) > -1
                    };
                });

                if ($scope.searchText.text) {
                    var searchTextLowerCase = $scope.searchText.text.toLowerCase();
                    var filteredResult = [];
                    for (var i = 0; i < $scope.archivedItems.length; i++) {
                        if ($scope.archivedItems[i]) {
                            // Search item name
                            var itemName = $scope.archivedItems[i].item.name.toLowerCase();
                            if (itemName.indexOf(searchTextLowerCase) !== -1) {
                                filteredResult.push($scope.archivedItems[i]);
                            // Search item UPC (if exists)
                            } else if ($scope.archivedItems[i].item.upc) {
                                var itemUpc = $scope.archivedItems[i].item.upc.toLowerCase();
                                if (itemUpc.indexOf(searchTextLowerCase) !== -1) {
                                    filteredResult.push($scope.archivedItems[i]);
                                }
                            }
                        }
                    }
                    $scope.archivedItems = filteredResult;
                }
            };

            $scope.searchAction = function () {
                if ($scope.currentPage !== -1 && $scope.searchText.text !== '') {
                    // Change page to search page
                    $scope.showArchivePage();
                }

                if ($scope.archivedItemAdapter.reload) {
                    $scope.archivedItemAdapter.reload();
                }
            };

            $scope.searchMenuPeriodItem = function () {
                if ($scope.menuPeriodItemAdapter.reload) {
                    $scope.menuPeriodItemAdapter.reload();
                }
            };

            $scope.searchQuickChargeItem = function () {
                if ($scope.quickChargeItemSearchText.isSearching && $scope.quickChargeItemAdapter.reload) {
                    $scope.quickChargeItemAdapter.reload();
                }
            };

            $scope.clearSearch = function () {
                $scope.searchText.text = '';
                $scope.archivedItemAdapter.reload();
            };

            $scope.clearMenuItemSearch = function () {
                $scope.menuPeriodItemSearchText.text = '';

                if ($scope.menuPeriodItemAdapter.reload) {
                    $scope.menuPeriodItemAdapter.reload();
                }
            };

            $scope.clearQuickChargeItemSearch = function () {
                $timeout(function () {
                    delete $scope.selectedMenuPeriod.quickChargeItemId;
                    $scope.quickChargeItemSearchText.text = '';
                    $scope.quickChargeItemSearchText.isSearching = true;
                }, 0);

                if ($scope.quickChargeItemAdapter.reload) {
                    $scope.quickChargeItemAdapter.reload();
                }
            };

            $scope.archivedItemRange = {index: 0, count: 0};
            $scope.archivedItemAdapter = {};

            $scope.menuPeriodItemRange = {index: 0, count: 0};
            $scope.menuPeriodItemAdapter = {};

            $scope.quickChargeItemRange = {index: 0, count: 0};
            $scope.quickChargeItemAdapter = {};

            $scope.menuPeriodRange = {index: 0, count: 0};
            $scope.menuPeriodAdapter = {};

            var timer;
            $scope.archivedItemDatasource = {
                get: function (index, count, success) {
                    let offset = index < 0 ? 0 : index;
                    if (index + count <= 0) {
                        return success([]);
                    }
                    if (timer) {
                        $timeout.cancel(timer);
                    }
                    let timeout = timer ? 500 : 0;
                    timer = $timeout(function () {
                        let search = {
                            searchQuery: $scope.searchText.text,
                            locationId: ProductsService.locationDetail.locationId,
                            menuId: (!$scope.menuFilter) ? $scope.menus.selected.menuId : $scope.menuFilter.menuId,
                            offset: offset,
                            limit: 300
                        };

                        Menu.itemSearch(search).$promise.then((response) => {
                            var sp = (response.posMenuItemEntries || [{entries: []}])[0];
                            var archivedItems = sp.entries.map((e) => {
                                return {item: e};
                            });

                            $scope.archivedItemRange.index = index;
                            $scope.archivedItemRange.count = count;
                            success(archivedItems);
                        });
                    }, timeout);
                }
            };

            var menuPeriodItemDataTimer;
            $scope.menuPeriodItemDataSource = {
                get: function (index, count, success) {
                    let offset = index < 0 ? 0 : index;
                    if (index + count <= 0) {
                        return success([]);
                    }
                    if (menuPeriodItemDataTimer) {
                        $timeout.cancel(menuPeriodItemDataTimer);
                    }
                    let timeout = menuPeriodItemDataTimer ? 500 : 0;
                    menuPeriodItemDataTimer = $timeout(function () {
                        let search = {
                            searchQuery: $scope.menuPeriodItemSearchText.text,
                            locationId: ProductsService.locationDetail.locationId,
                            offset: offset,
                            forMenuPeriodViewOnly: true,
                            menuId: (!$scope.menuFilter) ? $scope.menus.selected.menuId : $scope.menuFilter.menuId,
                            limit: 300,
                            menuPeriodOptional: true,
                            itemsOnly: true
                        };

                        Menu.itemSearch(search).$promise.then((response) => {
                            var items = (response.posMenuItemEntries || [{entries: []}])[0];
                            var flattenedArchivedItems = items.entries
                                                            .map((e) => {
                                                                var itemName = e.name.replace(' ', '_').toLowerCase();
                                                                return {
                                                                    item: e,
                                                                    dataCy: e.type + '_' + itemName
                                                                };
                                                            });
                            $scope.menuPeriodItemRange.index = index;
                            $scope.menuPeriodItemRange.count = count;
                            if (success) {
                                success(flattenedArchivedItems);
                            }
                        });
                    }, timeout);
                }
            };

            $scope.generateDataCy = function (item) {
                var itemName = item.name.replace(' ', '_').toLowerCase();
                return item.type + '_' + itemName;
            };

            var quickChargeItemDataTimer;
            $scope.quickChargeItemDataSource = {
                get: function (index, count, success) {
                    let offset = index < 0 ? 0 : index;
                    if (index + count <= 0) {
                        return success([]);
                    }
                    if (quickChargeItemDataTimer) {
                        $timeout.cancel(quickChargeItemDataTimer);
                    }
                    let timeout = quickChargeItemDataTimer ? 500 : 0;
                    quickChargeItemDataTimer = $timeout(function () {
                        let search = {
                            searchQuery: $scope.quickChargeItemSearchText.text,
                            locationId: ProductsService.locationDetail.locationId,
                            offset: offset,
                            limit: 300,
                            menuId: (!$scope.menuFilter) ? $scope.menus.selected.menuId : $scope.menuFilter.menuId,
                            menuPeriodOptional: true,
                            itemsOnly: true
                        };

                        Menu.itemSearch(search).$promise.then((response) => {
                            var items = (response.posMenuItemEntries || [{entries: []}])[0];
                            // Commented By Akash Mehta on November 19 2020
                            // The reason why we are filtering results on the frontend is because
                            // the required endpoint is already doing a lot of work on the backend,
                            // We tried adding the filter on the backend, but it ended up breaking
                            // other stuff as this endpoint is widely used throughout the POS.
                            var flattenedArchivedItems = items.entries
                                                .filter((e) => !e.subtype || e.subtype != 'modifier_option')
                                                .map((e) => {
                                                    var itemName = e.name.replace(' ', '_').toLowerCase();
                                                    return {
                                                        item: e,
                                                        dataCy: itemName
                                                    };
                                                });
                            $scope.quickChargeItemRange.index = index;
                            $scope.quickChargeItemRange.count = count;
                            if (success) {
                                success(flattenedArchivedItems);
                            }
                        });
                    }, timeout);
                }
            };

            $scope.convertTimeStampToTime = function (timestamp) {
                let locationDateTime = new Date(timestamp).toLocaleString('en-US', {timeZone: currentCompany.timezone});
                let localTimeStamp = Date.parse(locationDateTime);

                return moment(localTimeStamp).format('hh:mm A');
            };

            $scope.getActiveDays = function (enabledDays = []) {
                var daysLabelArr = [];
                var enabledDaysLocal = enabledDays.map((day) => day.toLowerCase());
                for (var dayObj of $scope.days) {
                    if (enabledDaysLocal.includes(dayObj.day.toLowerCase())) {
                        daysLabelArr.push(dayObj.label);
                    }
                }

                return daysLabelArr.join(',');
            };

            var menuPeriodDataTimer;
            $scope.menuPeriodDataSource = {
                get: function (index, count, success) {
                    let offset = index < 0 ? 0 : index;
                    if (index + count <= 0) {
                        return success([]);
                    }
                    if (menuPeriodDataTimer) {
                        $timeout.cancel(menuPeriodDataTimer);
                    }
                    let timeout = menuPeriodDataTimer ? 500 : 0;
                    menuPeriodDataTimer = $timeout(function () {
                        let search = {
                            searchQuery: $scope.menuPeriodItemSearchText.text,
                            companyId: CurrentSession.getCompany().companyId,
                            offset: offset,
                            limit: 300,
                        };
                        Locations.getMenuPeriods(search).$promise.then((response) => {
                            var sp = response || [];
                            let filteredMenuPeriods = [];
                            var menuPeriods = sp.map((e) => {
                                return {period: e};
                            });

                            $scope.menuPeriodDataSource.menuPeriods = response;

                            if (!$scope.menuFilter) {
                                $scope.menuFilter = $scope.menus.selected; // by this call a menu would have selected or assigned
                            }

                            filteredMenuPeriods = menuPeriods.filter((item) => item.period.menuId == $scope.menuFilter.menuId);

                            $scope.menuPeriodRange.index = index;
                            $scope.menuPeriodRange.count = count;
                            success(filteredMenuPeriods);
                        });
                    }, timeout);
                },
                menuPeriods: {}
            };

            $scope.filterMenuPeriods = function (menu) {
                $scope.menuFilter = menu;

                if ($scope.menuPeriodAdapter.reload) {
                    $scope.menuPeriodAdapter.reload();
                }
            };

            $scope.isMenuExporting = false;
            $scope.exportProducts = () => {
                if ($scope.isIosWebkit || $scope.isElectron) {
                    return;
                }

                let modalInstance = $modal.open({
                    templateUrl: 'products/menuV2/templates/product.menu.export.tpl.html',
                    controller: 'ProductMenuExportCtrl',
                    windowClass: 'modal-30 products3',
                    animation: false,
                    resolve: {
                        EnvConfig: function () {
                            return EnvConfig;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    if (result.action == 'export') {
                        $scope.isMenuExporting = true;
                        let selectedMenu = $scope.menus.selected;
                        result.params.menuId = selectedMenu.menuId;

                        let queryString = jQuery.param(result.params);
                        let downloadUrl = result.resourceUrl + '?' + queryString;
                        let fileName = [selectedMenu.menuName, currentCompany.locale, currentCompany.companyName].join('-');

                        Export.generateAndDownload(downloadUrl, fileName).then(function () {
                            $scope.isMenuExporting = false;
                        }, function () {
                            $scope.isMenuExporting = false;
                            PosAlertService.showAlertByName('general-alert', {
                                title: $translate.instant('product.menu.export.error'),
                                message: null,
                                buttonType: 'ok'
                            });
                        });
                    }
                });
            };

            $scope.currentMenu = [];
            $scope.crumbs = [];
            $scope.currentPage = 0;

            $scope.init = function () {
                $scope.products = ProductsService.products;
                loadMenus();
            };

            $scope.init();
        }]);
};
