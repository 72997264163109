'use strict';
const angular = require('angular');
const Redemption = require('../../external/loyalty/redemption.js');
const Decimal = require('decimal.js').default;

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderLucovaCtrl', [
        '$scope',
        '$modal',
        '$modalInstance',
        '$filter',
        '$translate',
        '$timeout',
        '$log',
        'CurrentSession',
        'adjustments',
        'qrCode',
        'SmbPosService',
        'FiitMealPlanCalculationService',
        'CashierShift',
        'Security',
        'GuestLabelService',
        'patron',
        'giftCard',
        'outstandingBalance',
        'selectedPaymentMethod',
        'splitTender',
        'parentAdjustments',
        'GatewayFiit',
        'PromiseRetry',
        'PosBuilderService',
        'KioskService',
        'PosStatusService',
        'PosAlertService',
        'fiitMpsUtil',
        'tenderType',
        'Lucova',
        'TransactionService',
        'CompanyAttributesService',
        'PAYMENT_METHODS',
        'KioskModalService',
        'Platform',
        'BridgedPromise',
        'SharedDataService',
        function (
            $scope,
            $modal,
            $modalInstance,
            $filter,
            $translate,
            $timeout,
            $log,
            CurrentSession,
            adjustments,
            qrCode,
            SmbPosService,
            FiitMealPlanCalculationService,
            CashierShift,
            Security,
            GuestLabelService,
            patron,
            giftCard,
            outstandingBalance,
            selectedPaymentMethod,
            splitTender,
            parentAdjustments,
            GatewayFiit,
            PromiseRetry,
            PosBuilderService,
            KioskService,
            PosStatusService,
            PosAlertService,
            fiitMpsUtil,
            tenderType,
            Lucova,
            TransactionService,
            CompanyAttributesService,
            PAYMENT_METHODS,
            KioskModalService,
            Platform,
            BridgedPromise,
            SharedDataService) {

            $scope.hasIosScanner = false;
            const currencyCode = SharedDataService.currencyCode;
            const allowForeignGiftCards = CompanyAttributesService.allowForeignGiftCards();
            const loyaltyStepsEnabled = CompanyAttributesService.loyaltyStepsEnabled();

            if (Platform.isIosWebkit()) {
                BridgedPromise.dispatch('hasQRScanner', {}).then(({hasQRScanner}) => {
                    $scope.hasIosScanner = !!hasQRScanner;
                }).catch((error) => {
                    console.error('IOS container does not support qr scanner. Disabling feature');
                });
            }

            $scope.initIosScanner = () => {
                if (!$scope.hasIosScanner) {
                    return;
                }
                window.webkit.messageHandlers.bridge.postMessage({'type': 'initBarcodeScanner'});
            };

            $scope.PAYMENT_METHODS = PAYMENT_METHODS;

            $scope.kioskUseGiftCard = function () {
                $scope.setAdjustment('useGiftCard', true);
                $scope.applyPatron();
            };

            var selectedTenderType = angular.copy(tenderType);

            $scope.kioskTotalAmountToCharge = KioskService.totalAmountToCharge();
            $scope.selectedPaymentMethod = selectedPaymentMethod;
            $scope.selectedPatron = (patron && !patron.guest) ? patron : undefined;
            $scope.error;
            $scope.giftCard = (giftCard && giftCard.id) ? giftCard : undefined;
            $scope.scanning = false;
            $scope.location = {
                name: CurrentSession.getCompany().companyName || ''
            };
            var loyaltyRedemptionRules = CurrentSession.getCompany().organization.settings.loyaltyRedemptionRules;
            var loyaltyRedemptionPerDollar = CurrentSession.getCompany().organization.settings.loyaltyRedemptionPerDollar;

            const STEPS = $scope.STEPS = {
                MAIN: 'main',
                FIITMPS: 'fiitmps'
            };
            $scope.currentStep = STEPS.MAIN;

            $scope.dcbMealPlans = [];

            $scope.paymentAvailable = {
                loyalty: false, // this should be true only when loyalty is enabled
                mobilePay: true, // this should always be true
                giftCard: false, // this should be true only when gift card is enabled
                fiitmps: false // this should be true only when fiitmps gateway is enabled
            };
            var currentCompany = CurrentSession.getCompany();

            if (currentCompany.hasLoyalty && !loyaltyStepsEnabled) {
                $scope.paymentAvailable.loyalty = true; // allow old loyalty redemption
            }

            $scope.giftCardsEnabled = CompanyAttributesService.hasGiftCardsEnabled();

            if ($scope.giftCardsEnabled) {
                $scope.paymentAvailable.giftCard = true;
            }

            $scope.adjustments = adjustments;

            $scope.gateway = {
                fiitMps: GatewayFiit.isEnabled()
            };

            $scope.canDisplayGiftCard = function () {
                return ($scope.giftCardsEnabled && !$scope.adjustments.mobilePay && $scope.giftCard && $scope.giftCard.currentBalanceCents > 0);
            };

            $scope.canRedeemPoints = function () {
                var patron = $scope.selectedPatron;

                return (!GatewayFiit.isEnabled() && patron && patron.loyalty && Redemption.getPointsRedeemable(outstandingBalance, patron.loyalty.points, loyaltyRedemptionRules, loyaltyRedemptionPerDollar) > 0
                    && (adjustments.usePoints || (!adjustments.usePoints && !parentAdjustments.dollarDiscount && !parentAdjustments.percentDiscount)));
            };

            $scope.hasMobilePay = function () {
                var patron = $scope.selectedPatron;
                return (patron && patron.lucovaUser && !$scope.adjustments.useGiftCard && patron.isNearby !== false && !splitTender);
            };

            $scope.canUseDCB = function (mealPlan) {
                return mealPlan && mealPlan.enabled && !mealPlan.expired && mealPlan.currentDcbBalance > 0;
            };
            $scope.calculateAdjustments = function () {
                $scope.adjustments.usePoints = (adjustments.usePoints && $scope.canRedeemPoints()) ? adjustments.usePoints : false;
                $scope.adjustments.mobilePay = (adjustments.mobilePay && $scope.hasMobilePay()) ? adjustments.mobilePay : false;
                $scope.adjustments.useGiftCard = (adjustments.useGiftCard && $scope.canDisplayGiftCard()) ? adjustments.useGiftCard : false;

                if (!$scope.hasMobilePay() && $scope.canDisplayGiftCard() && !giftCard) {
                    $scope.adjustments.useGiftCard = true;
                }
            };

            var adjustmentOptions = ['mobilePay', 'useGiftCard', 'useFiitMps'];
            $scope.toggleAdjustment = function (targetAdjustment) {
                var currentTargetAdjustmentValue = $scope.adjustments[targetAdjustment];
                $scope.setAdjustment(targetAdjustment, !currentTargetAdjustmentValue);
            };
            $scope.setAdjustment = function (targetAdjustment, newValue) {
                for (var adjustmentOption of adjustmentOptions) {
                    $scope.adjustments[adjustmentOption] = false;
                }

                $scope.adjustments[targetAdjustment] = newValue;
            };

            $scope.kioskRedeemRewards = function () {
                $scope.adjustments.usePoints = true;
                $scope.applyPatron();
            };

            $scope.tryShowFiitStep = function () {
                if ($scope.adjustments.useFiitMps) {
                    if (selectedTenderType && selectedTenderType.value === 'gateway') {
                        fiitMpsUtil.process({
                            adjustments: $scope.adjustments,
                            giftCard: $scope.giftCard,
                            patron: $scope.selectedPatron
                        });

                        $scope.updateEstimate();
                    }
                } else {
                    fiitMpsUtil.unprocess();
                }
            };

            var populateModalResponseForFiitTender = function (result, skipTenderInput = true) {
                try {
                    var currentTender = fiitMpsUtil.fetchCurrentTender() || {};
                    var nownTransactionPayload = fiitMpsUtil.fetchTenderAmounts();
                    let fiitPatronId = ($scope.selectedPatron.fiitMpsAccount) ? $scope.selectedPatron.fiitMpsAccount.patronId : null;
                    TransactionService.processFiitTransactionMockResponse(nownTransactionPayload,
                        $scope.fiitCalculationResponse, fiitPatronId, currentTender);
                    result.fiitMps = {
                        skipTenderInput: skipTenderInput,
                        useMealEquivalency: !!useMealEquivalency,
                        resetCurrentTender: false // This is to ensure that when processing this response, the parent controller does not reset this info
                    };

                    $modalInstance.close(result);
                } catch (ex) {
                     PosAlertService.showAlertByName('general-error', {
                        'title': 'general.error.oops.ttl',
                        'message': 'patron.popup.tender.general.error.msg'
                    });
                    $log.error(ex);
                }
            };

            $scope.selectDCBplan = (selectedMealPlan) => {
                // filter the DCB plans that are already selected
                const selectedDCBplans = $scope.dcbMealPlans.filter((mealPlan) => {
                    return mealPlan.useDCB;
                });
                // store the current value of the DCB plan we are trying to select/deselect
                const isMealPlanSelected = selectedMealPlan.useDCB;
                // disable all the already selected DCB plans to just allow one DCB plan to be selected in the next step
                selectedDCBplans.forEach((mealPlan) => mealPlan.useDCB = false);
                // set the value of the DCB plan that we are selecting/deselecting to the opposite of its initial value
                selectedMealPlan.useDCB = !isMealPlanSelected;
            };

            $scope.applyPatron = function (params = {}) {
                /**
                 * Its required to copy over the gift card to the lucovaUser
                 * because this will be used later to generate the tenders
                 * sent to the lucova-payment-server
                 */
                if ($scope.adjustments.useGiftCard && $scope.giftCard && $scope.selectedPatron && $scope.selectedPatron.lucovaUser) {
                    $scope.selectedPatron.lucovaUser.nownGiftCards = [$scope.giftCard];
                }

                var result = {
                    adjustments: $scope.adjustments,
                    giftCard: $scope.giftCard,
                    patron: $scope.selectedPatron
                };

                if (params.action == 'cancel') {
                    $scope.$close();
                    return;
                }

                if (adjustments.useFiitMps && selectedTenderType && selectedTenderType.value === 'gateway') {
                    if ($scope.sidebar.remainingBalance) {
                            // if there is remaining balance, its a kiosk & Split tender isn't enabled, we show a kiosk error modal.
                            if (KioskService.isKiosk()) {
                                if (CompanyAttributesService.canKioskSplitTender()) {
                                    populateModalResponseForFiitTender(result);
                                } else {
                                    let kioskModalParams = {
                                        subtitle: 'kiosk.error.remaining-balance',
                                        timeOut: 5,
                                        buttonTitleOk: 'general.error.okay',
                                    };

                                    KioskModalService.showModalByName('oops', kioskModalParams);
                                }

                                return;
                            }

                            PosAlertService.showAlertByName('general-alert', {
                                'title': 'patron.popup.tender.remainingBalance.popup.title',
                                'message': $translate.instant('patron.popup.tender.remainingBalance.popup.msg', {
                                        remainingBalance: $scope.sidebar.remainingBalance
                                }),
                                'modalCallback': function () {
                                    populateModalResponseForFiitTender(result);
                                }
                            });
                    } else {
                        populateModalResponseForFiitTender(result);
                    }
                } else {
                    $modalInstance.close(result);
                }
            };

            $scope.reset = function () {
                $scope.selectedPatron = undefined;
                $scope.error = undefined;
                $scope.giftCard = undefined;
                $scope.scanning = false;

                // // Add check for tenderType once bluetooth checkin is merged
                if (GatewayFiit.isEnabled()) {
                    $scope.resetPatronModal();
                }
            };

            $scope.close = function () {
                $modalInstance.close();
            };
            $scope.dismiss = function (toResetPatronModal, payload) {
                if (toResetPatronModal) {
                    $scope.resetPatronModal(true);
                }

                if (selectedTenderType && selectedTenderType.value === 'gateway' && GatewayFiit.isEnabled()) {
                    fiitMpsUtil.setSplitTender(false);
                    $scope.setAdjustment('useFiitMps', false);
                }

                $modalInstance.dismiss(payload);
            };

            var combineDCBPlanBalances = function (a, b) {
                return {'remainingServicePeriodDcb': (new Decimal(a.remainingServicePeriodDcb).plus(new Decimal(b.remainingServicePeriodDcb))).toDecimalPlaces(2).toNumber()};
            };

            var combineMealPlanBalances = function (a, b) {
                return {'remainingServicePeriodMeals': a.remainingServicePeriodMeals + b.remainingServicePeriodMeals};
            };

            const _isMealRulesApplied = function (plans, type, guest = false) {
                let isRuleApplied = false;

                for (let plan of plans) {
                    if (plan.guestPlan == guest && plan.mealPlanType == type) {
                        isRuleApplied = plan.unavailablityReasons.ruleApplied;
                    }

                    if (isRuleApplied) {
                        break;
                    }
                }

                return isRuleApplied;
            };

            var calculateAvailableBalances = function (plans = []) {
                if (!plans) {
                    return;
                }

                var dcbPlans = plans.filter((plan) => plan.mealPlanType === 'DCB' && plan.isAvailable == true);
                var mealPlans = plans.filter((plan) => plan.mealPlanType === 'MEAL' && plan.isAvailable == true && !plan.guestPlan);
                var guestMealPlans = plans.filter((plan) => plan.mealPlanType === 'MEAL' && plan.isAvailable == true && plan.guestPlan);
                // var mealPlans = plans.filter((plan) => plan.mealPlanType === 'MEAL' && plan.isAvailable == true);

                $scope.settings.dcb.availableBalance = dcbPlans.reduce(combineDCBPlanBalances, {remainingServicePeriodDcb: 0}).remainingServicePeriodDcb || 0;
                $scope.settings.mealCard.availableBalance = mealPlans.reduce(combineMealPlanBalances, {remainingServicePeriodMeals: 0}).remainingServicePeriodMeals || 0;
                $scope.settings.guestMealCard.availableBalance = guestMealPlans.reduce(combineMealPlanBalances, {remainingServicePeriodMeals: 0}).remainingServicePeriodMeals || 0;

                $scope.settings.dcb.isMealRulesUsed = _isMealRulesApplied(plans, 'DCB');
                $scope.settings.mealCard.isMealRulesUsed = _isMealRulesApplied(plans, 'MEAL');
                $scope.settings.guestMealCard.isMealRulesUsed = _isMealRulesApplied(plans, 'MEAL', true);
            };

            $scope.canUseMealUnits = false;
            var isOverrideAllowed = false;

            $scope.openIdCheckModal = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modal.id.check.tpl.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'PatronIdCheckModalCtrl',
                    windowClass: 'id-check-modal',
                    keyboard: false,
                    resolve: {
                        idChecked: function () {
                            return $scope.idChecked;
                        },
                        selectedPatron: function () {
                            return $scope.selectedPatron;
                        }
                    }
                });

                modalInstance.result.then(function (resolved) {
                    $scope.idChecked = resolved.idChecked;

                    if (!$scope.idChecked) {
                        $modalInstance.close();
                    }
                });
            };

            var findHighestBalanceCard = (giftCards) => {
                if (!giftCards.length) {
                    return;
                }

                // sort by balance, with highest on top.
                var sortedCards = _.sortBy(giftCards, (giftCard) => {
                    return -giftCard.currentBalanceCents;
                });

                // grab only the highest balance card
                return sortedCards[0];
            };

            var sumGiftCardBalance = (giftCards) => {
                return giftCards.reduce((prev, cur) => {
                    return prev + cur.currentBalanceCents;
                }, 0);
            };

            // This method is desgined to fetch a card number of an unknown type. This is to allow
            // scanning any phsyical card directly on the tender screen without having to select the
            // card type first. This function will attempt to fetch FIIT meal cards and merchant
            // gift cards (if either is enabled) and returns the first one that returns a result.
            // TODO: things to consider in the future:
            // - resolve gift/meal card number conflict
            // - more secure offline handling
            var fetchAnyCard = async function (cardNumber) {
                if (!cardNumber) {
                    return;
                }

                $scope.error = undefined;
                var notFoundError = undefined;

                if ($scope.paymentAvailable.giftCard) {
                    try {

                        var params = {
                            currencyId: CurrentSession.getCompany().baseCurrencyId,
                            cashierShiftId: SmbPosService.shift.cashierShiftId
                        };

                        // cardNumber is used to differentiate digital gift cards from physical gift cards.
                        // digital gift cards should be in the following format:
                        // e.g. 'gc:12345:1a2b3c45d6789e12f3g123456'
                        // where the first set of numbers (12345) is the gift card id in nown,
                        // and the second set of numbers (1a2b3c45d6789e12f3g123456) is the gift card id in the payments server
                        if (cardNumber.indexOf('gc:') !== -1) {
                            $scope.isDigitalGiftCard = true;
                            // search by id if digital gift card
                            params.giftCardId = cardNumber.slice(3, cardNumber.indexOf(':', 3));
                            params.code = null;
                        } else {
                            // search by code if physical gift card
                            params.id = null;
                            params.code = cardNumber;
                        }

                        var giftCardPromise = await CashierShift.lookupGiftCard(params).$promise;

                        $scope.scanning = false;
                        if (giftCardPromise && giftCardPromise.id) {
                            if (!allowForeignGiftCards && giftCardPromise.currencyCode !== currencyCode) {
                                if (!KioskService.isKiosk()) {
                                    PosAlertService.showAlertByName('general', {
                                        title: 'general.error.no-foreign-gift-card.ttl',
                                        message: $translate.instant('general.error.no-foreign-gift-card.desc', {
                                            currencyCode: giftCardPromise.currencyCode
                                        })
                                    });
                                    $modalInstance.dismiss();
                                } else {
                                    $scope.error = true;
                                    return;
                                }
                            }
                            $scope.giftCard = giftCardPromise;
                            // if it is a digital gift card,
                            // fetch giftcard information from payments server
                            if ($scope.isDigitalGiftCard) {
                                let index = cardNumber.indexOf(':', 3);
                                if (Lucova.isConnected()) {
                                    Lucova.manager().getGiftCardById({
                                        id: cardNumber.slice(index + 1)
                                    }, function (response) {
                                        // payments server may return success = false
                                        // if gift card id cannot be found
                                        if (response.success) {
                                            $scope.selectedPatron = {
                                                email: response.card.recipient_email,
                                                fullName: response.card.recipient_name
                                            };

                                            if (!KioskService.isKiosk()) {
                                                // setting idChecked to false by default.
                                                // if cashier selects 'back' in the patron loyalty screen,
                                                // id would need to be rechecked due to possibility that they scan another giftcard
                                                $scope.idChecked = false;
                                                $scope.openIdCheckModal();
                                            } else {
                                                $scope.error = true;
                                            }
                                        } else {
                                            PosAlertService.showAlertByName('general', {
                                                title: 'general.error.no-digital-gift-card.ttl',
                                                message: 'general.error.no-digital-gift-card.desc'
                                            });
                                            $modalInstance.dismiss();
                                        }
                                    });
                                }
                            }

                            $scope.calculateAdjustments();

                            return;
                        } else {
                            notFoundError = notFoundError || {};
                            notFoundError.giftCard = true;
                        }
                    } catch (error) {
                        $scope.scanning = false;
                        notFoundError = notFoundError || {};
                        notFoundError.giftCard = true;

                        if (error && error.data && error.data.exception && error.data.exception.appCode == 444) {
                            notFoundError.foreignGiftCard = true;
                        }
                    }
                }

                if ($scope.gateway.fiitMps) {
                    notFoundError = notFoundError || {};
                    fetchPatronDetailsFromFiitBackend(undefined, cardNumber, notFoundError);
                }

                $scope.error = notFoundError;
            };

            const _selectGiftCardToCharge = function (giftCards) {

                // displaying number of available gift cards and
                // total balance of these cards on frontend.
                $scope.availableGiftCards = giftCards;
                $scope.totalAvailableGiftCardsBalance = sumGiftCardBalance(giftCards);

                // take the highest balance card first.
                // unlike the mobile app, the POS cannot use all gift card balance at the same time.
                // therefore, we must use cards one by one.
                // this is something we need to look into when the transactions workflow is going to be rewritten.
                let highestBalanceCard = findHighestBalanceCard(giftCards);

                if (highestBalanceCard && highestBalanceCard.id) {
                    $scope.giftCard = highestBalanceCard;
                    $scope.error = undefined;
                } else {
                    $scope.error = {
                        giftCard: true
                    };
                }
                $scope.calculateAdjustments();
                return {
                    success: !$scope.error
                };
            };

            /*
                Commented by Chris L (November 06 2020)
                please do not make a change to this function and the request.
                this is to ensure backwards compatibility as physical gift cards
                before the deployment of digital gift card feature
                do not contain an id.
                After deploying the digital gift card feature, the behavior is as followed:
                1. For digital gift cards, you only get the id and not the code.
                2. For physical gift cards, you will get the code and possibily the id as well.
            */
            var fetchGiftCards = function (lucovaGiftCards) {
                if (!lucovaGiftCards || !lucovaGiftCards.length) {
                    return;
                }
                // Ideally we should send one request to get all the gift cards but since we do not expect a
                // customer to have more than 2-3 giftcards it is acceptable here to send a request per gift card
                var giftCardPromises = [];
                lucovaGiftCards.forEach((lucovaGiftCard) => {
                    giftCardPromises.push(
                        CashierShift.lookupGiftCard({
                            giftCardId: lucovaGiftCard.id,
                            code: lucovaGiftCard.code,
                            currencyId: CurrentSession.getCompany().baseCurrencyId,
                            cashierShiftId: SmbPosService.shift.cashierShiftId
                        }).$promise
                    );
                });

                return Promise.all(giftCardPromises).then(_selectGiftCardToCharge, function (error) {
                    $scope.error = {
                        giftCard: true
                    };

                    if (error && error.data && error.data.exception && error.data.exception.appCode == 444) {
                        $scope.error.foreignGiftCard = true;
                    }

                    return {
                        success: false,
                        error: error
                    };
                });
            };

            var linkPatron = async function (patron, nearby = true) {
                var patronObj = {};

                if (patron.lucovaUser) {
                    patronObj.lucovaUser = angular.copy(patron.lucovaUser);
                }

                patronObj.isNearby = nearby;
                if (!nearby) {
                    $scope.adjustments.mobilePay = false;
                }


                if ($scope.gateway.fiitMps && patronObj.lucovaUser && patronObj.lucovaUser.fiit) {
                    fetchPatronDetailsFromFiitBackend(patronObj.lucovaUser.fiit.patron_id,
                        patronObj.lucovaUser.fiit.patron_key, undefined, patronObj);
                } else {
                    try {
                        let fiitPatron = await SmbPosService.linkFiitPatron(patronObj.lucovaUser);
                        patronObj.fiitPatron = fiitPatron;
                        patronObj = SmbPosService.transformPatron(patronObj);
                        $scope.selectedPatron = patronObj;
                        $scope.calculateAdjustments();
                    } catch (error) {
                        // do nothing
                    }
                }
            };

            var giftCardFromLucovaPatron = function (patron) {
                if (!patron) {
                    return;
                }
                let lucovaUser = patron.lucovaUser;
                let lucovaGiftCards = (lucovaUser.nown && lucovaUser.nown.nown_gift_cards) ? lucovaUser.nown.nown_gift_cards : [];

                // ensuring backwards compatibility as older versions of the mobile apps may send nown_gift_nums instead of
                // nown_gift_cards
                let lucovaGiftNums = (lucovaUser.nown && lucovaUser.nown.nown_gift_nums) ? lucovaUser.nown.nown_gift_nums : [];
                _.each(lucovaGiftNums, (num) => {
                    let giftCard = {
                        code: num
                    };
                    lucovaGiftCards.push(giftCard);
                });

                fetchGiftCards(lucovaGiftCards);
            };

            $scope.checkId = () => {
                var requestedPermission = 'pos:id_authentication';
                var params = {
                    callback: function (pinObj) {
                        $scope.idChecked = true;
                    },
                    errorCallback: function (error) {
                        if (error) {
                            PosAlertService.showAlertByName('giftcard-auth-invalid-pin');
                        }
                    },
                    requestedPermission: requestedPermission,
                    message: 'smb.pos.giftcard.auth.description'
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            };

            /**
            ** Commented by Akash Mehta
            ** We need to relook through the entire flow below.
            ** Currently, nown fiir & gift cards won't be able to work
            ** together,
            **/
            $scope.didReceiveQrCode = function (qrCode) {
                if (PosStatusService.isOffline()) {
                    PosAlertService.showAlertByName('general', {
                        title: 'general.error.offline.ttl',
                        message: 'pos.giftcard.offline.description'
                    });
                    return;
                }
                $scope.scanning = true;

                SmbPosService.fetchLucovaPatron(qrCode).then((patrons) => {
                    onLucovaPatronFetched(patrons[0]);
                }).catch((error) => {
                    SmbPosService.fetchLucovaPatronByGiftCard(qrCode, $scope.gateway.fiitMps).then((patrons) => {
                        /**
                         * A patron lookup by this method should not be able to pay
                         * with mobile, so we set nearby to false below
                         */
                        onLucovaPatronFetched(patrons[0], false);
                    }).catch((error) => {
                        fetchAnyCard(qrCode);
                    });
                });
            };

            var onLucovaPatronFetched = function (patron, nearby = true) {
                $scope.error = undefined;
                $scope.scanning = false;

                linkPatron(patron);
                giftCardFromLucovaPatron(patron);
            };

            var checkIfFiitMpsExists = function () {
                if ($scope.selectedPatron
                    && $scope.selectedPatron.fiitMpsAccount
                    && !$scope.selectedPatron.fiitMpsAccount.error
                    && selectedTenderType
                    && selectedTenderType.value === 'gateway') {

                    $scope.setAdjustment('useFiitMps', true);
                    return true;
                }
                return false;
            };

            $scope.setCurrentStep = function (step) {
                $scope.currentStep = step;
            };

            /* For the wizard-like FIITMPS workflow */
            $scope.isPaymentReady = true;
            $scope.setPaymentReady = function (value) {
                $scope.isPaymentReady = value;
            };

            $scope.transaction = fiitMpsUtil.fetchTenderAmounts();


            var updateAdjustedAmountCallBack = function (valueToSet, mealPlanId) {

                /**
                *** Commented By Akash Mehta
                *** The reason we have to do this is because if we pass the meal Plan object
                *** in the callback, it looses the original reference and any modifcations made
                *** are not present on the original meal plan in the scope.mealPlans variable
                ***/
                var mealPlan = $scope.mealPlans.find((plan) => plan.mealPlanId == mealPlanId);

                /**
                *** Commented By Akash Mehta
                *** This check is for a specific reason.
                *** The FiitMealPlanCalculationService uses this modification type field to prioritize a meal plan.
                *** If the type is 'OVERRIDE', we allow users to use upto the total balance of the meal,
                *** else we only allow the user to use upto the service period balance of the meal plan.
                ***/
                if (isOverrideAllowed) {
                    mealPlan.modificationType = FiitMealPlanCalculationService.OVERRIDE;
                } else {
                    mealPlan.modificationType = FiitMealPlanCalculationService.MODIFIED;
                }
                mealPlan.requestedUnits = valueToSet;
                $scope.updateTenderAmount(valueToSet, mealPlan.mealPlanType);
            };

            $scope.overrideMealPlanRequiredUnits = function (value, mealPlanId) {
                var overridedMealPlanId = parseInt(mealPlanId);
                for (var mealPlan of $scope.mealPlans) {
                    if (mealPlan.mealPlanId != overridedMealPlanId) {
                        delete mealPlan.modificationType;
                    } else {
                        var servicePeriodBalance = (mealPlan.mealPlanType == 'MEAL') ? mealPlan.remainingServicePeriodMeals : mealPlan.remainingServicePeriodDcb;
                        var totalBalance = (mealPlan.mealPlanType == 'MEAL') ? mealPlan.currentMealPlanBalance : mealPlan.currentDcbBalance;

                        var isGreaterThanServicePeriodBalance = new Decimal(value).greaterThan(servicePeriodBalance);
                        var isGreaterThanTotalBalance = new Decimal(value).greaterThan(totalBalance);
                        if (isGreaterThanServicePeriodBalance) {
                            if (isOverrideAllowed && !isGreaterThanTotalBalance) {
                                PosAlertService.showAlertByName('general-alert', {
                                    'title': 'patron.popup.tender.balance.override.title',
                                    'message': $translate.instant('patron.popup.tender.balance.override.msg', {
                                        servicePeriodBalance: (mealPlan.mealPlanType == 'MEAL') ? servicePeriodBalance + ' Unit(s)' : $filter('currency')(servicePeriodBalance),
                                        overrideValue: (mealPlan.mealPlanType == 'MEAL') ? value + ' Unit(s)' : $filter('currency')(value)
                                    }),
                                    'modalCallback': function () {
                                        updateAdjustedAmountCallBack(value, overridedMealPlanId);
                                    },
                                    'dismissModalCallback': function () {
                                        updateAdjustedAmountCallBack(servicePeriodBalance, overridedMealPlanId);
                                    }
                                });
                            } else {
                                var balanceToApply = (isOverrideAllowed && isGreaterThanTotalBalance) ? totalBalance : servicePeriodBalance;
                                var title = (isOverrideAllowed && isGreaterThanTotalBalance) ?
                                    'patron.popup.tender.totalbalance.insufficient.title' : 'patron.popup.tender.spbalance.insufficient.title';
                                var message = (isOverrideAllowed && isGreaterThanTotalBalance) ?
                                    'patron.popup.tender.totalbalance.insufficient.msg' : 'patron.popup.tender.spbalance.insufficient.msg';
                                PosAlertService.showAlertByName('general-alert', {
                                    'title': title,
                                    'buttonType': 'ok',
                                    'message': $translate.instant(message, {
                                        balance: (mealPlan.mealPlanType == 'MEAL') ? balanceToApply + ' Unit(s)' : $filter('currency')(balanceToApply)
                                    }),
                                    'modalCallback': function () {
                                        updateAdjustedAmountCallBack(balanceToApply, overridedMealPlanId);
                                    }
                                });
                            }
                        } else {
                            updateAdjustedAmountCallBack(value, overridedMealPlanId);
                        }
                    }
                }
            };

            var useMealEquivalency = false;
            $scope.updateTenderAmount = function (value, type, useMealPlanPriority = false) {
                $scope.currentTender = fiitMpsUtil.fetchCurrentTender() || {};
                $scope.settings.resetButton.show = true;
                if (type && type === 'DCB') {
                    $scope.currentTender.amount = value;
                }

                if (type && type === 'MEAL') {
                    if (value && new Decimal(value).toNumber() > 0 ) {
                        useMealEquivalency = true;
                    } else {
                        useMealEquivalency = false;
                    }
                }

                setToUseFiitMealPlanPriority(useMealPlanPriority);

                $scope.updateEstimate();
            };

            $scope.requestedAmounts = {
                dcbAmount: 0,
                mealAmount: 0,
                guestMealAmount: 0
            };

            $scope.toAnimate = {
                dcb: false,
                mealCard: false,
                guestMealCard: false
            };

            $scope.sidebar = {
                mealUnitsCount: 0,
                mealUnitsAmount: 0,
                taxableDCBAmount: 0,
                nonTaxableDCBAmount: 0,
                totalTenders: 0,
                remainingBalance: 0,
                taxes: 0,
                discounts: 0,
                total: 0
            };

            var updateSideBar = function (calculationResponse) {
                // // Reinitializing the sidebar attributes
                $scope.sidebar.taxableDCBAmount = new Decimal(0);
                $scope.sidebar.nonTaxableDCBAmount = new Decimal(0);
                $scope.sidebar.totalTenders = new Decimal(0);


                // // Setting the values of straightforward mappings
                $scope.sidebar.mealUnitsCount = calculationResponse.approvedMeal;
                $scope.sidebar.remainingBalance = calculationResponse.outstandingAmount;
                $scope.sidebar.taxes = calculationResponse.payments.taxAmount;
                $scope.sidebar.discounts = calculationResponse.tenderAmounts.totalDiscount;
                $scope.sidebar.subtotalTaxable = calculationResponse.tenderAmounts.subtotalTaxable;
                $scope.sidebar.subtotalNonTaxable = calculationResponse.tenderAmounts.subtotalNonTaxable;

                // / Calculating the new total based on the mapped values above
                $scope.sidebar.total = $scope.sidebar.subtotalTaxable + $scope.sidebar.subtotalNonTaxable + $scope.sidebar.taxes;

                // // Calculating the tenders section on the sidebar
                var nonTaxableDCBPayments = calculationResponse.payments.dcbUnits.filter((payment) => !payment.taxable);
                var taxableDCBPayments = calculationResponse.payments.dcbUnits.filter((payment) => payment.taxable);

                for (var nonTaxPayment of nonTaxableDCBPayments) {
                    $scope.sidebar.nonTaxableDCBAmount = $scope.sidebar.nonTaxableDCBAmount.plus(nonTaxPayment.unit);
                }

                for (var taxPayment of taxableDCBPayments) {
                    $scope.sidebar.taxableDCBAmount = $scope.sidebar.taxableDCBAmount.plus(taxPayment.unit);
                }

                $scope.sidebar.mealUnitsAmount = new Decimal(calculationResponse.approvedMealEqAmount || 0);
                for (var mealPayment of calculationResponse.payments.mealUnits) {
                    $scope.sidebar.mealUnitsAmount = $scope.sidebar.mealUnitsAmount.plus(mealPayment.dollarValue);
                }


                $scope.sidebar.totalTenders = $scope.sidebar.mealUnitsAmount
                                            .plus($scope.sidebar.nonTaxableDCBAmount)
                                            .plus($scope.sidebar.taxableDCBAmount);

                $scope.sidebar.mealUnitsAmount = $scope.sidebar.mealUnitsAmount.toNumber();
                $scope.sidebar.taxableDCBAmount = $scope.sidebar.taxableDCBAmount.toNumber();
                $scope.sidebar.nonTaxableDCBAmount = $scope.sidebar.nonTaxableDCBAmount.toNumber();
                $scope.sidebar.totalTenders = $scope.sidebar.totalTenders.toNumber();
            };

            $scope.updateEstimate = function () {
                if (!$scope.selectedPatron || !$scope.selectedPatron.fiitMpsAccount || $scope.patronStatusError()) {
                    return;
                }

                $scope.currentTender.estimate = $scope.currentTender.estimate || {};
                $scope.currentTender.estimate.loading = true;
                $scope.fiitCalculationResponse = {};
                $scope.transaction = fiitMpsUtil.fetchTenderAmounts();

                $scope.canUseMealUnits = FiitMealPlanCalculationService.isReceiptmealUnitDeductionEligible($scope.transaction.receiptItems);

                $scope.setPaymentReady(false);
                TransactionService.runFiitTransactionMock($scope.transaction, $scope.selectedPatron, useMealEquivalency,
                    parentAdjustments, !!useFiitMealPlanPriority)
                .then(function (data) {
                    // RESETS to false as animation doesn't replay if already true
                    $timeout(function () {
                        if ($scope.toAnimate.dcb || $scope.toAnimate.mealCard || $scope.toAnimate.guestMealCard) {
                            $scope.toAnimate.dcb = false;
                            $scope.toAnimate.mealCard = false;
                            $scope.toAnimate.guestMealCard = false;
                        }
                    }, 0);
                    $scope.tenderAmounts = fiitMpsUtil.fetchTenderAmounts();

                    // Checks previous stored amounts to decide whether or not to animate auto calc flash
                    var tempDcbAmount = $scope.requestedAmounts.dcbAmount;
                    var tempMealAmount = $scope.requestedAmounts.mealAmount;
                    var tempGuestMealAmount = $scope.requestedAmounts.guestMealAmount;
                    var totalDcb = returnTotalUnits(data.payments.dcbUnits);
                    var totalMeal = returnTotalUnits(data.payments.mealUnits);
                    totalMeal += returnTotalUnits(data.payments.mealUnitsUsedForMealEq);
                    var totalGuestMeal = returnTotalUnits(data.payments.mealUnits, true);
                    totalGuestMeal += returnTotalUnits(data.payments.mealUnitsUsedForMealEq, true);

                    $scope.fiitCalculationResponse = data;

                    $timeout(function () {
                        if (tempDcbAmount != totalDcb) {
                            $scope.toAnimate.dcb = true;
                        }
                        if (tempMealAmount != totalMeal) {
                            $scope.toAnimate.mealCard = true;
                        }

                        if (tempGuestMealAmount != totalGuestMeal) {
                            $scope.toAnimate.guestMealCard = true;
                        }

                        $scope.requestedAmounts.dcbAmount = totalDcb;
                        $scope.requestedAmounts.mealAmount = totalMeal;
                        $scope.requestedAmounts.guestMealAmount = totalGuestMeal;
                        updateSideBar(data);
                    }, 0);

                    mapPaymentsToPlans(data.payments.dcbUnits);
                    mapPaymentsToPlans(data.payments.mealUnits);
                    mapPaymentsToPlans(data.payments.mealUnitsUsedForMealEq);

                    delete parentAdjustments.mealEquivalencyAdjustment;


                    $scope.setPaymentReady(true);
                }).catch(function (error) {
                    PosAlertService.showAlertByName('oops-general', {
                        message: error.message
                    });
                    $scope.setPaymentReady(false);
                    delete parentAdjustments.mealEquivalencyAdjustment;
                });
            };

            /*
            ** Commenting out this function for now Incase we need it back. But please remove this before production
            ** push if still commented out
            var isPaymentApproved = function (data) {
                return data.approvedAmount || data.approvedDCBAmount || data.approvedMeal || data.approvedMealEqAmount;
            }; */

            $scope.getPlanCountAsPerAvailability = function (type, isPlanAvailable) {
                if ($scope.mealPlans) {
                    var result = [];
                    switch (type) {
                        case 'GUEST':
                            result = $filter('filter')($scope.mealPlans, {mealPlanType: 'MEAL', guestPlan: true, isAvailable: isPlanAvailable});
                            break;
                        default:
                            result = $filter('filter')($scope.mealPlans, {mealPlanType: type, isAvailable: isPlanAvailable, guestPlan: false});
                            break;
                    }

                    return result.length;
                }
            };

            $scope.hasNoPlansUnavailable = function (type) {
                if ($scope.mealPlans) {
                    var result = $filter('filter')($scope.mealPlans, {mealPlanType: type, isAvailable: false});
                    return result.length === 0;
                }
            };

            $scope.toggleCardExpanded = function (plan) {
                $scope.settings[plan].isCardExpanded = !$scope.settings[plan].isCardExpanded;
                $scope.settings[plan].isUnavailableExpanded = false;
                for (let card in $scope.settings) {
                    if (plan != card && plan !== 'sidebar') {
                        $scope.settings[card].isCardExpanded = false;
                    }
                }
            };

            $scope.toggleUnavailableExpanded = function (plan) {
                $scope.settings[plan].isUnavailableExpanded = !$scope.settings[plan].isUnavailableExpanded;
            };

            // plan settings
            $scope.settings = {
                dcb: {
                    isCardExpanded: false,
                    isUnavailableExpanded: false,
                    isMealRulesUsed: false,
                    availableBalance: 0
                },
                mealCard: {
                    isCardExpanded: false,
                    isUnavailableExpanded: false,
                    isMealRulesUsed: false,
                    availableBalance: 0
                },
                guestMealCard: {
                    isCardExpanded: false,
                    isUnavailableExpanded: false,
                    isMealRulesUsed: false,
                    availableBalance: 0
                },
                sidebar: {
                    isCardExpanded: false,
                },
                resetButton: {
                    show: false
                }
            };

            $scope.returnPlansUsed = function (type) {
                if ($scope.mealPlans) {
                    var filtered = $scope.mealPlans.filter((mealPlan) => {
                        switch (type) {
                            case 'GUEST':
                                return (mealPlan.mealPlanType === 'MEAL' && mealPlan.isAvailable && mealPlan.guestPlan && mealPlan.requestedUnits > 0);
                            default:
                                return (mealPlan.mealPlanType === type && mealPlan.isAvailable && !mealPlan.guestPlan && mealPlan.requestedUnits > 0);
                        }
                    });
                    if (filtered.length === 0) {
                        return '';
                    } else {
                        var nameArr = [];
                        filtered.sort((acc, curr) => {
                            return acc.priority > curr.priority;
                        });
                        filtered.forEach((mealPlan) => {
                            nameArr.push(mealPlan.name);
                        });
                        return nameArr.join(', ');
                    }
                }
            };

            $scope.highestPriorityPlan = function (type) {
                if ($scope.mealPlans) {
                    var filtered = $scope.mealPlans.filter((mealPlan) => {
                        switch (type) {
                            case 'GUEST':
                                return (mealPlan.mealPlanType === 'MEAL' && mealPlan.isAvailable && mealPlan.guestPlan);
                            default:
                                return (mealPlan.mealPlanType === type && mealPlan.isAvailable && !mealPlan.guestPlan);
                        }
                    });
                    if (filtered.length === 0) {
                        return '';
                    } else {
                        var result = filtered.reduce((acc, curr) => {
                            if (acc.priority < curr.priority) {
                                return acc;
                            } else {
                                return curr;
                            }
                        });
                        return result.name || '';
                    }
                }
            };

            var returnTotalUnits = function (approvedTenderUnits, isGuestPlan) {
                var result = 0;
                for (let mealPlan of $scope.mealPlans) {
                    var mealPlanId = mealPlan.mealPlanId;

                    for (let approvedTenderUnit of approvedTenderUnits) {
                        if (mealPlanId === approvedTenderUnit.id && mealPlan.guestPlan == !!isGuestPlan) {
                            var amount = new Decimal(approvedTenderUnit.equivalentMealUnits || approvedTenderUnit.unit).toNumber();
                            result = result + amount;
                        }
                    }
                }
                return result;
            };

            var mapPaymentsToPlans = function (approvedTenderUnits) {
                for (let mealPlan of $scope.mealPlans) {
                    var mealPlanId = mealPlan.mealPlanId;
                    mealPlan.requestedUnits = 0;

                    for (let approvedTenderUnit of approvedTenderUnits) {
                        if (mealPlanId === approvedTenderUnit.id) {
                            $timeout(function () {
                                var amount = new Decimal(approvedTenderUnit.equivalentMealUnits || approvedTenderUnit.unit).toNumber();
                                mealPlan.requestedUnits = mealPlan.requestedUnits + amount;
                            }, 0);
                        }
                    }
                }
            };

            var useFiitMealPlanPriority = true;

            var setToUseFiitMealPlanPriority = function (toUseFiitPriority) {
                useFiitMealPlanPriority = !!toUseFiitPriority;
            };

            $scope.resetPatronModal = function (skipUpdateEstimate = false) {
                setToUseFiitMealPlanPriority(true);
                useMealEquivalency = false;

                _.each($scope.mealPlans, function (mealPlan) {
                    delete mealPlan.requestedUnits;
                    delete mealPlan.modificationType;
                });

                if (!skipUpdateEstimate && $scope.selectedPatron && GatewayFiit.isEnabled()) {
                    $scope.updateEstimate();
                }
                $scope.settings.resetButton.show = false;
            };

            // If remaining balance === 0;
            // Case 1: The selected plan has enough to cover the whole transaction total.
            // All other applied tenders/plan on the popup screen (Meal units, any other dcb) will become 0.
            // Case 2: The selected plan cannot cover the whole transaction total.
            // Apply whatever amount that can be covered with the selected plan and spit out a new remaining balance.

            // If remaining balance > 0;
            // Pressing the green arrow against a plan will trigger the get balance algorithm which will attempt to apply the remaining balance to that particular plan.
            // Case 1: The selected plan has enough to cover the remaining balance.
            // Remaining balance will now become 0.
            // Case 2: The selected plan cannot cover the entire remaining balance.
            // In this case, just apply whatever amount that can be covered with the selected plan and spit out a new remaining balance.


            // CASE 1: The meal plan you want to auto populate has a zero value in the grey box against the plan on the UI.
            // In this case, zero out all other populated tenders,
            // calculate the applicable meal units for the selected plan and grade out the auto populate button for the particular meal plan.

            // CASE 2: The meal plan you want to auto populate for has a non zero value filled in the grey box against it.
            // In this case, the green auto populate button will always be graded out for that particular meal plan.

            const _updateRequestedUnits = function (selectedMealPlan) {
                let remainingBalance = new Decimal($scope.sidebar.remainingBalance);

                if (!selectedMealPlan) {
                    return;
                }

                switch (selectedMealPlan.mealPlanType) {
                    case 'DCB':
                        if (!remainingBalance.equals(0)) {
                            // auto populate should be adding remainingBalance to requestedUnits
                            // instead of replacing requestedUnits with remainingBalance
                            selectedMealPlan.requestedUnits = new Decimal(selectedMealPlan.requestedUnits).plus(remainingBalance).toNumber();
                            /**
                            *** Commented By Akash Mehta
                            *** This step is for a specific reason.
                            *** The FiitMealPlanCalculationService uses this modification type field to prioritize a meal plan.
                            *** If the type is 'OVERRIDE', we allow users to use upto the total balance of the meal,
                            *** else we only allow the user to use upto the service period balance of the meal plan.
                            ***/
                            selectedMealPlan.modificationType = FiitMealPlanCalculationService.AUTOCALCULATE;
                        } else {
                            selectedMealPlan.requestedUnits = new Decimal($scope.transaction.totalSales).toNumber();
                            /**
                            *** Commented By Akash Mehta
                            *** This step is for a specific reason.
                            *** The FiitMealPlanCalculationService uses this modification type field to prioritize a meal plan.
                            *** If the type is 'OVERRIDE', we allow users to use upto the total balance of the meal,
                            *** else we only allow the user to use upto the service period balance of the meal plan.
                            ***/
                            selectedMealPlan.modificationType = FiitMealPlanCalculationService.AUTOCALCULATE;

                            for (var patronMealPlan of $scope.mealPlans) {
                                if (patronMealPlan.mealPlanId !== selectedMealPlan.mealPlanId) {
                                    delete patronMealPlan.modificationType;
                                }
                            }
                        }
                    break;
                    case 'MEAL':
                        if (selectedMealPlan.requestedUnits) {
                            return;
                        } else {
                            selectedMealPlan.requestedUnits = new Decimal(selectedMealPlan.remainingServicePeriodMeals).toNumber();
                            /**
                            *** Commented By Akash Mehta
                            *** This step is for a specific reason.
                            *** The FiitMealPlanCalculationService uses this modification type field to prioritize a meal plan.
                            *** If the type is 'OVERRIDE', we allow users to use upto the total balance of the meal,
                            *** else we only allow the user to use upto the service period balance of the meal plan.
                            ***/
                            selectedMealPlan.modificationType = FiitMealPlanCalculationService.AUTOCALCULATE;

                            for (var availablePatronMealPlan of $scope.mealPlans) {
                                if (availablePatronMealPlan.mealPlanId !== selectedMealPlan.mealPlanId) {
                                    delete availablePatronMealPlan.modificationType;
                                }
                            }
                        }
                    break;
                    default:
                }
            };

            $scope.autoCalculate = function (selectedMealPlan = {}, type = '', useMealPlanPriority = false) {
                // use all available meal plans by type
                if (selectedMealPlan && !Object.keys(selectedMealPlan).length && type) {
                    let totalAvailableUnits = 0;

                    _.each($scope.mealPlans, function (mealPlan) {
                        if (mealPlan.mealPlanType == type) {
                            _updateRequestedUnits(mealPlan);
                            totalAvailableUnits += mealPlan.requestedUnits;
                        }
                    });

                    selectedMealPlan = {
                        requestedUnits: totalAvailableUnits,
                        mealPlanType: type
                    };
                } else {
                    _updateRequestedUnits(selectedMealPlan);
                }

                $scope.updateTenderAmount(selectedMealPlan.requestedUnits, selectedMealPlan.mealPlanType, useMealPlanPriority);
            };

            $scope.patronStatusError = function () {
                if (!$scope.selectedPatron || !$scope.selectedPatron.fiitMpsAccount || $scope.selectedPatron.fiitMpsAccount.status != 0) {
                    return true;
                } else {
                    return false;
                }
            };

            $scope.unavailableReason = function (mealPlan) {
                // 'inactive' needs to be added when backend is able to send this information

                if (mealPlan.unavailablityReasons) {
                     if (mealPlan.unavailablityReasons.disabled) {
                        return 'patron.popup.tender.unavailableWarning.disabled';

                    } else if (!mealPlan.unavailablityReasons.disabled && !mealPlan.unavailablityReasons.active) {
                        return 'patron.popup.tender.unavailableWarning.inactive';
                    } else if (!mealPlan.unavailablityReasons.disabled && mealPlan.unavailablityReasons.expired) {
                        return 'patron.popup.tender.unavailableWarning.expired';
                    } else if (!mealPlan.unavailablityReasons.disabled && !mealPlan.unavailablityReasons.expired
                        && mealPlan.unavailablityReasons.unavailableAtLocation) {
                        return 'patron.popup.tender.unavailableWarning.not.available';
                    } else if (!mealPlan.unavailablityReasons.disabled && !mealPlan.unavailablityReasons.expired
                        && !mealPlan.unavailablityReasons.unavailableAtLocation && mealPlan.unavailablityReasons.noBalance) {
                        return 'patron.popup.tender.unavailableWarning.no.balance';
                    } else if (!mealPlan.unavailablityReasons.disabled && !mealPlan.unavailablityReasons.expired
                        && !mealPlan.unavailablityReasons.unavailableAtLocation && !mealPlan.unavailablityReasons.noBalance
                        && mealPlan.unavailablityReasons.noPriority) {
                        return 'patron.popup.tender.unavailableWarning.noPriority';
                    }
                }

                return 'patron.popup.tender.unavailableWarning';
            };

            $scope.init = function () {
                if (!checkIfFiitMpsExists() && $scope.selectedPatron && $scope.giftCardsEnabled) {
                    if ($scope.selectedPatron.lucovaUser) {
                        giftCardFromLucovaPatron($scope.selectedPatron);
                    } else if ($scope.selectedPatron.fiitPatron) { // loaded patron is from NownPOS
                        let patron = $scope.selectedPatron.fiitPatron;
                        $scope.scanning = true;

                        SmbPosService.fetchPatronAvailableGCs(patron).then(function (result) {
                            _selectGiftCardToCharge(result);
                            $scope.scanning = false;
                        }).catch(function (error) {
                            $scope.scanning = false;
                        });
                    }
                }

                if (qrCode) {
                    $scope.didReceiveQrCode(qrCode);
                }

                /**
                 * Commented by Akash Mehta
                 * This block of code has been added to fetch updated Patron data from FIIT whenver possible
                 */
                if ($scope.selectedPatron && GatewayFiit.isEnabled() && tenderType.value != 'giftcard') {
                    if ($scope.selectedPatron && $scope.selectedPatron.fiitMpsAccount && $scope.selectedPatron.fiitMpsAccount.patronKey) {
                        $scope.scanning = true;
                        fetchAnyCard($scope.selectedPatron.fiitMpsAccount.patronKey);
                    }
                }
            };

            /** Commented By Akash Mehta ion June 28 2020
            ** We had two seperate pieces of code introducing two different workflows
            ** for searching a fiit patron on the fiit backend. The below function
            ** unifies both of the codes to make things more cleaner
            **/
            var fetchPatronDetailsFromFiitBackend = async function (patronId, patronKey, notFoundError = {}, patronObj = {}) {
                if ($scope.gateway.fiitMps) {
                    $scope.scanning = true;

                    SmbPosService.fetchPatronFromFiitBackend(patronId, patronKey, notFoundError, patronObj).then(function (resolve) {
                        /** Commented By Akash Mehta
                        ** The below check has been added because of a new workflow added at the end of the init function
                        ** We want to get the fresh patron info from fiit backend. But, the line below is updating the entire
                        ** patronObj thus removing all other data which is required for bluetooth checkin. So now if there is no
                        ** patron, we are going to assign the entire response to selected patron else Just going to update
                        ** the required data here
                        **/
                        if (!$scope.selectedPatron) {
                            $scope.selectedPatron = patronObj;
                        }

                        if (patronObj.fiitMpsAccount.photoUrl) {
                            $scope.selectedPatron.photoUrl = patronObj.fiitMpsAccount.photoUrl;
                        }

                        GuestLabelService.setGuestLabel(patronObj.fullName);

                        $scope.selectedPatron.fiitMpsAccount = patronObj.fiitMpsAccount;
                        $scope.selectedPatron.fullName = patronObj.fullName;
                        $scope.adjustments.mobilePay = false;
                        $scope.mealPlans = patronObj.fiitMpsAccount.mealPlans || [];
                        isOverrideAllowed = patronObj.fiitMpsAccount.fiitLocationOverrideAllowed;
                        $scope.currentTender = fiitMpsUtil.fetchCurrentTender() || {};

                        $scope.mealPlans.forEach((plan) => plan.guestPlan = !!plan.guestPlan);
                        $scope.hasGuestMealPlans = !!($scope.mealPlans.filter((plan) => plan.mealPlanType === 'MEAL' && plan.guestPlan).length);

                        calculateAvailableBalances($scope.mealPlans);

                        checkIfFiitMpsExists();
                        $scope.tryShowFiitStep();
                        $scope.scanning = false;
                    }).catch(async function (error) {
                        const alertShown = error.fiitMpsAccount && error.fiitMpsAccount.alertShown;
                        if (CompanyAttributesService.allowSavingMealCards() && !PosStatusService.isOffline() && !alertShown) {
                            await $modal.open({
                                templateUrl: 'pos/smb/templates/pos.save.mealcard.tpl.html',
                                animation: false,
                                backdrop: 'static',
                                controller: 'SaveMealCardCtrl',
                                windowClass: 'smb-pos__checkin',
                                resolve: {
                                    qrcode: () => patronKey,
                                    isQuickCharge: () => false,
                                    cashierShiftId: () => SmbPosService.shift.cashierShiftId
                                },
                                keyboard: false
                            }).result.then((savedMealCard) => {
                                SmbPosService.data.overviewData.totalFiitSavedMealCardCount++;
                                $scope.dismiss(false, savedMealCard);
                            }).catch(() => {
                                // noop
                            });
                        }
                        $scope.scanning = false;
                        $scope.$apply();
                    });
                }
            };

            $scope.init();
            $scope.calculateAdjustments();
        }
    ]);
};
