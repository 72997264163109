'use strict';


(function () {
    var PosCtrl = (function () {
        // const SharedDataService = require('./pos.data-service.js');
        // const SharedFunctionService = require('../external/pos.meal-plan-calculations.js');
        const SharedDataService = require('./pos.data-service.js');
        const moment = require('moment');
        const lodash = require('lodash');
        const _ = require('underscore');
        const Decimal = require('decimal.js').default;

        var PosCtrl = function () {
        };

        function buildItems (item, level = 0, index, included = false, alreadyAttachedToParent = false) {
            var effectiveItemPrice = new Decimal(item.price).toNearest(SharedDataService.baseDollar).toNumber();
            var originalEffectiveItemPrice = item.originalPrice || item.price;

            var itemName = item.name;

            if (level === 0) {
                var toReplaceItemPrice = isModifierReplacingItemPrice(item);

                if (toReplaceItemPrice) {
                    effectiveItemPrice = 0;
                    originalEffectiveItemPrice = 0;
                }
                var attachingOptions = isModifierAttachingName(item);

                var attachmentPrice = 0;
                var attachmentName = '';

                let count = 0;

                for (let option of attachingOptions) {
                    // assume same tax
                    if (count !== 0) {
                        attachmentName += ', ';
                    }
                    attachmentPrice += option.price;
                    attachmentName += option.name;

                    option.subtype = 'attachment';
                    count++;
                }

                if (attachmentPrice) {
                    effectiveItemPrice = new Decimal(effectiveItemPrice).plus(attachmentPrice).toNearest(SharedDataService.baseDollar).toNumber();
                    originalEffectiveItemPrice = new Decimal(originalEffectiveItemPrice).plus(attachmentPrice).toNearest(SharedDataService.baseDollar).toNumber();
                }

                if (attachmentName) {
                    itemName = itemName + ' (' + attachmentName + ')';
                }
            }

            if (included || alreadyAttachedToParent) {
                effectiveItemPrice = 0;
                originalEffectiveItemPrice = 0;
            }

            var quantity = item.quantity || 1;
            quantity = (item.quantity == 0 && item.level !== 0) ? 0 : quantity;

            // ensure item level tax rounding
            var taxAmount = 0;
            if (item.taxRate) {
                taxAmount = new Decimal(effectiveItemPrice).times(new Decimal(item.taxRate)).toNearest(SharedDataService.baseDollar).toNumber();
            }

            var unitTaxAmount = new Decimal(taxAmount).toNearest(SharedDataService.baseDollar);
            var unitPrice = new Decimal(effectiveItemPrice).toNearest(SharedDataService.baseDollar);
            var unitTotal = unitTaxAmount.plus(unitPrice).toNearest(SharedDataService.baseDollar);

            var overallTaxAmount = new Decimal(taxAmount).times(new Decimal(quantity)).toNearest(SharedDataService.baseDollar);
            var overallPrice = new Decimal(effectiveItemPrice).times(new Decimal(quantity)).toNearest(SharedDataService.baseDollar);
            var overallTotal = overallTaxAmount.plus(overallPrice).toNearest(SharedDataService.baseDollar);

            var arr = [];

            // Note: `item` maps to "menu item", where `price` means the
            // unit price of an item, whereas `newItem` maps to a "receipt item",
            // where `itemPrice` means the unit price of an item, and `price` means
            // the unit price multiplied by quantity.
            var newItem = {
                'quantity': quantity,
                'locationServicePeriodMenuId': lodash.cloneDeep(item.locationServicePeriodMenuId),
                'name': itemName,
                'upc': lodash.cloneDeep(item.upc),
                'taxRate': item.taxRate,
                'taxAmount': overallTaxAmount.toNumber(),
                'taxRateId': item.taxRateId,
                'price': overallPrice.toNumber(),
                'total': overallTotal.toNumber(),
                'itemTaxAmount': unitTaxAmount.toNumber(),
                'itemPrice': unitPrice.toNumber(),
                'itemTotalPrice': unitTotal.toNumber(),
                'itemOriginalPrice': originalEffectiveItemPrice,
                'mealPlanAmount': (lodash.cloneDeep(item.mealPlanCount)) * quantity,
                'mealPlanEligible': !!item.mealPlanEligible,
                'mealEquivalencyEnabled': lodash.cloneDeep(item.mealEquivalencyEnabled),
                'loyaltyEnabled': item.loyaltyEnabled,
                'mealPlanCount': lodash.cloneDeep(item.mealPlanCount),
                'printerId': lodash.cloneDeep(item.printerId),
                'included': included,
                'level': level,
                'has_mods': (item.children && item.children.length > 0),
                'index': index,
                'isFullyDiscounted': item.isFullyDiscounted,
                'taxRules': item.taxRules || {},
                'type': item.type,
                'subtype': item.subtype,
                'detail': item.detail,
                'discount': item.discount,
                'notes': item.notes || '',
                'visualDiscount': item.visualDiscount || {},
                'visualPrice': item.visualPrice || {},
                'multiplier': item.multiplier,
                'multiplierInWords': item.multiplierInWords,
                'kdsEnabled': item.kdsEnabled,
                'preCalculatedTender': item.preCalculatedTender,
                'printoutType': item.printoutType,
                'parentItemId': item.parentItemId,
                'quantityPrintedToKitchen': item.quantityPrintedToKitchen || 0,
                'updatedSinceLastKitchenPrint': item.updatedSinceLastKitchenPrint,
                'createdSinceLastKitchenPrint': item.createdSinceLastKitchenPrint,
                'solinkItemTimes': item.solinkItemTimes || [],
                'loyaltyStepCost': item.loyaltyStepCost
            };

            if (!item.parentItemId) {
                delete item.parentItemId;
            }

            if (item._uuid) {
                newItem._uuid = item._uuid;
            }

            if (item._timestamp) {
                newItem._timestamp = item._timestamp;
            }

            if (item.labelledDiscount) {
                newItem.labelledDiscount = item.labelledDiscount;
            }

            if (!(item.subtype === 'discount' || item.subtype === 'loyalty') || effectiveItemPrice != 0) {
                arr.push(newItem);
            }

            var decimalCurrentBundlePriceCents = new Decimal(effectiveItemPrice).times(100).toNearest(SharedDataService.baseCent);

            var decimalOriginalBundlePriceCents = new Decimal(0);
            if (!(item.subtype === 'discount' || item.subtype === 'loyalty')) {
                decimalOriginalBundlePriceCents = new Decimal(originalEffectiveItemPrice).times(100).toNearest(SharedDataService.baseCent);
            }

            if (item.children && item.children.length > 0) {

                // FIND ALL MODIFIER GROUPS
                _.each(item.children, function (group) {

                    if (!group) {
                        return;
                    }

                    if (group.type === 'modifier') {

                        var includes = group.includes || 0;

                        var modifierAttached = group._attached;
                        delete group._attached;

                        // FIND ALL MODIFIER ITEMS
                        if (group.children && group.children.length > 0) {
                            _.each(group.children, function (el) {
                                if (el.selected) {
                                    if (item.loyaltyEnabled && !(el.subtype === 'discount' || el.subtype === 'loyalty')) {
                                        el.loyaltyEnabled = true;
                                    }

                                    var childIncluded = included || (includes > 0);
                                    var subArr = buildItems(el, level + 1, index, childIncluded, modifierAttached);
                                    arr = arr.concat(subArr);

                                    var subBundlePrices = calculateItemListPricesInCents(subArr);
                                    decimalCurrentBundlePriceCents = decimalCurrentBundlePriceCents.plus(subBundlePrices.current).toNearest(SharedDataService.baseCent);
                                    decimalOriginalBundlePriceCents = decimalOriginalBundlePriceCents.plus(subBundlePrices.original).toNearest(SharedDataService.baseCent);

                                    includes--;
                                }
                            });
                        }
                    } else {
                        // assumes it is never an item because we alternate
                    }
                });
            }

            newItem.data = newItem.data || {};
            newItem.data.currentBundlePrice = decimalCurrentBundlePriceCents.dividedBy(100).toNearest(SharedDataService.baseDollar).toNumber();
            newItem.data.originalBundlePrice = decimalOriginalBundlePriceCents.dividedBy(100).toNearest(SharedDataService.baseDollar).toNumber();

            return arr;
        }

        function calculateItemListPricesInCents (itemList) {
            var currentTotal = new Decimal(0);
            var originalTotal = new Decimal(0);

            _.each(itemList, function (item) {
                var currentItemPrice = new Decimal(item.itemPrice).toNearest(SharedDataService.baseDollar);
                var originalItemPrice = new Decimal(item.itemOriginalPrice).toNearest(SharedDataService.baseDollar);

                currentTotal = currentTotal.plus(currentItemPrice);

                if (item.subtype !== 'discount') {
                    originalTotal = originalTotal.plus(originalItemPrice);
                }
            });

            return {
                current: currentTotal.times(100).toNearest(SharedDataService.baseCent),
                original: originalTotal.times(100).toNearest(SharedDataService.baseCent)
            };
        }

        function isModifierReplacingItemPrice (itemToCheck) {
            var priceReplacedBy = itemToCheck.priceReplacedBy;
            if (!priceReplacedBy) {
                return false;
            }

            var priceOverride = _.findWhere(itemToCheck.children, {
                locationServicePeriodMenuId: priceReplacedBy.locationServicePeriodMenuId
            });

            if (priceOverride && priceOverride.children) {
                var isPriceOverrideInEffect = _.any(priceOverride.children, function (modifierOption) {
                    return modifierOption.selected;
                });

                return isPriceOverrideInEffect;
            }

            return false;
        }

        function isModifierAttachingName (itemToCheck) {
            var modifiersAttachingName = _.where(itemToCheck.children, {
                nameAttachedToParent: true
            });

            modifiersAttachingName = modifiersAttachingName || [];

            var options = [];
            _.each(modifiersAttachingName, function (modifier) {
                modifier._attached = false;

                if (modifier.children) {
                    var selectedOption = _.find(modifier.children, function (option) {
                        return option.selected;
                    });

                    if (selectedOption) {
                        options.push(selectedOption);
                        modifier._attached = true;
                    }
                }
            });

            return options;
        }

        var getDeliveryFee = function (deliverySettings, subtotal) {
            // Other non calculation values to be sent to backend
            deliverySettings = deliverySettings || {};
            var transactionSubtotal = new Decimal(subtotal || 0);
            var finalDeliveryFee = new Decimal(0);
            if (deliverySettings.delivery_enabled) {
                var deliveryFeeSetting = new Decimal(deliverySettings.delivery_fee || 0);
                var smallOrderDeliveryFeeSetting = new Decimal(deliverySettings.small_order_delivery_fee || 0);
                var smallOrderThresholdSetting = new Decimal(deliverySettings.small_order_threshold || 0);
                if (transactionSubtotal.greaterThanOrEqualTo(smallOrderThresholdSetting)) {
                    finalDeliveryFee = deliveryFeeSetting;
                } else {
                    finalDeliveryFee = smallOrderDeliveryFeeSetting;
                }
            }

            return finalDeliveryFee.toNearest(SharedDataService.baseDollar).toNumber();
        };

        PosCtrl.prototype.createDeliveryItem = function (deliverySettings, transactionSubtotal, overridenDeliveryFee, kitchenPrinter, defaultTax, itemName, itemIndex) {
            var deliveryItemPrice = new Decimal(0);
            if (overridenDeliveryFee != null) {
                deliveryItemPrice = new Decimal(overridenDeliveryFee).toNearest(SharedDataService.baseDollar);
            } else {
                deliveryItemPrice = new Decimal(getDeliveryFee(deliverySettings, transactionSubtotal));
            }

            defaultTax = defaultTax || {};
            kitchenPrinter = kitchenPrinter || {};
            var defaultTaxRate = 0;
            var defaultTaxRateId = undefined;

            if (deliverySettings.delivery_taxable) {
                defaultTaxRate = defaultTax.taxRate;
                defaultTaxRateId = defaultTax.id || undefined;
            }

            var deliveryItem = {
                locationServicePeriodMenuId: -9998,
                name: itemName || 'Delivery Fee',
                taxRate: defaultTaxRate,
                taxRateId: defaultTaxRateId,
                children: [],
                mealEquivalencyEnabled: true,
                loyaltyEnabled: true,
                printerId: kitchenPrinter.posPrinterId
            };

            if (itemIndex != null) {
                deliveryItem.index = itemIndex;
                deliveryItem.level = 0;
            }
            if (SharedDataService.taxIncludedInPrice) {
                var decimalPreTaxPrice = deliveryItemPrice
                    .dividedBy(1 + defaultTaxRate)
                    .toNearest(SharedDataService.baseDollar);

                deliveryItem.price = decimalPreTaxPrice.toNumber();
            } else {
                deliveryItem.price = deliveryItemPrice.toNumber();
            }

            return deliveryItem;
        };

        PosCtrl.prototype.areSameUPC = function (a, b) {
            var areSameUpc = (a.upc === b.upc);
            var areSameItemTaxRate = (a.taxRate === b.taxRate);
            var areSameItemPrices = (a.price === b.price);
            var areSameItemTaxAmount = (a.taxRate === b.taxRate);
            var areSameMealPlanAmount = (a.mealPlanCount === b.mealPlanCount);
            // TODO: add more checks here
            var areSameDiscountStatus = a.isFullyDiscounted === b.isFullyDiscounted;
            return (areSameUpc && areSameItemTaxRate && areSameItemPrices && areSameItemTaxAmount && areSameMealPlanAmount && areSameDiscountStatus);
        };

        PosCtrl.prototype.areSameMenuItems = function (a, b) {
            var areSameId = (a.locationServicePeriodMenuId === b.locationServicePeriodMenuId);
            var areSameItemTaxRate = (a.taxRate === b.taxRate);
            var areSameItemPrices = (a.price === b.price);
            var areSameItemTaxAmount = (a.taxRate === b.taxRate);
            var areSameMealPlanAmount = (a.mealPlanCount === b.mealPlanCount);

            // For now, do not merge any item with modifier for simplicity
            var aNonDiscountChildren = _.find(a.children, function (child) {
                return child.subtype !== 'discount';
            }) || [];
            var bNonDiscountChildren = _.find(a.children, function (child) {
                return child.subtype !== 'discount';
            }) || b.children || [];
            var areSameChildren = (aNonDiscountChildren.length === 0 && bNonDiscountChildren.length === 0);
            var areSameDiscountStatus = a.isFullyDiscounted === b.isFullyDiscounted;

            return (areSameId && areSameItemTaxRate && areSameItemPrices && areSameItemTaxAmount && areSameMealPlanAmount && areSameChildren && areSameDiscountStatus);
        };

        PosCtrl.prototype.filterSearch = function (input, searchString) {

            var val = searchString.toLowerCase();

            return input ? _.filter(input, function (child) {
                return child['name'].toLowerCase().indexOf(val.toLowerCase()) !== -1;
            }) : null;
        };

        PosCtrl.prototype.getCampaignDiscountFromBackEnd = function (lucovaUser) {

            var retVal = {};

            var campaignDiscounts = [];

            if (lucovaUser) {
                campaignDiscounts = lucovaUser.discounts;
            }

            var percentCampaignDiscounts = [];
            var dollarCampaignDiscounts = [];

            for (var i = 0; i < campaignDiscounts.length; i++) {

                if (campaignDiscounts[i].amount_cents === null
                    || campaignDiscounts[i].amount_cents === undefined) {
                    percentCampaignDiscounts.push(campaignDiscounts[i]);
                } else {
                    dollarCampaignDiscounts.push(campaignDiscounts[i]);
                }
            }

            percentCampaignDiscounts.sort(function (a, b) {
                return b.amount_percent - a.amount_percent;
            });

            dollarCampaignDiscounts.sort(function (a, b) {
                return b.amount_cents - a.amount_cents;
            });

            retVal.sortedDollarCampaignDiscounts = dollarCampaignDiscounts.sort(function (a, b) {
                return b.amount_cents - a.amount_cents;
            });

            retVal.sortedPercentCampaignDiscounts = percentCampaignDiscounts.sort(function (a, b) {
                return b.amount_percent - a.amount_percent;
            });

            retVal.percentCampaignDiscounts = percentCampaignDiscounts;
            retVal.dollarCampaignDiscounts = dollarCampaignDiscounts;

            return retVal;
        };

        PosCtrl.prototype.getMealEquivalentDay = function (servicePeriod) {
            var day;

            if (moment().day() === 0) {
                day = servicePeriod.mealEquivalentDayMap.sunday;
            } else if (moment().day() === 1) {
                day = servicePeriod.mealEquivalentDayMap.monday;
            } else if (moment().day() === 2) {
                day = servicePeriod.mealEquivalentDayMap.tuesday;
            } else if (moment().day() === 3) {
                day = servicePeriod.mealEquivalentDayMap.wednesday;
            } else if (moment().day() === 4) {
                day = servicePeriod.mealEquivalentDayMap.thursday;
            } else if (moment().day() === 5) {
                day = servicePeriod.mealEquivalentDayMap.friday;
            } else if (moment().day() === 6) {
                day = servicePeriod.mealEquivalentDayMap.saturday;
            }

            return day;
        };

        PosCtrl.prototype.copyCalculations = function (payments, currentOrderBalance) {
            currentOrderBalance.totalMeals = lodash.cloneDeep(payments.totalMeals);
            currentOrderBalance.subTotalAmount = lodash.cloneDeep(payments.subTotalAmount);
            currentOrderBalance.totalAmount = lodash.cloneDeep(payments.totalAmount);
            currentOrderBalance.taxAmount = lodash.cloneDeep(payments.taxAmount);
            currentOrderBalance.totalDiscount = lodash.cloneDeep(payments.totalDiscount);
            if (payments.cashCard && payments.cashCard > 0) {
                currentOrderBalance.cashCard = lodash.cloneDeep(payments.cashCard);
                currentOrderBalance.totalAmount = currentOrderBalance.totalAmount - currentOrderBalance.cashCard;
            }
        };

        PosCtrl.prototype.buildItem = function (item, level = 0, index, included = false) {
            return buildItems(item, level, index, included);
        };

        PosCtrl.prototype.rebuildReceipt = function (fullReceipt) {
            var arr = [];

            if (fullReceipt && fullReceipt.length > 0) {
                _.each(fullReceipt, function (item, index) {
                    var itemArr = buildItems(item, 0, index);
                    arr = arr.concat(itemArr);
                });
            }
            return arr;
        };

        PosCtrl.prototype.rebuildReceiptForPreorder = function (preorderItemsArr) {
            if (!preorderItemsArr || !Array.isArray(preorderItemsArr)) {
                return [];
            }

            var arr = [];

            preorderItemsArr.forEach((item) => {
                if (item.level == undefined || item.index == undefined) {
                    throw new Error(`Invalid level or index for item : ${JSON.stringify(item)}`);
                }

                var itemArr = buildItems(item, item.level, item.index);
                arr = arr.concat(itemArr);
            });

            return arr;
        };

        // NOTE: very similar concept as SharedFunctionService.calculateServicePeriodAvailableBalances.
        // Should consider use that instead since this class seems to be dealing more with building/merging
        // items rather than transaction balances
        PosCtrl.prototype.calculateAvailableBalances = function (available) {
            var tempBalances = {
                mealPlanCount: 0,
                availableCredit: 0,
                dcbBalance: 0
            };
            // TODO
            _.each(available, function (plan) {
                if (plan.mealPlanType === 'MEAL') {
                    tempBalances.mealPlanCount += plan.remainingServicePeriodMeals;
                } else if (plan.mealPlanType === 'DCB') {
                    tempBalances.dcbBalance += plan.remainingServicePeriodDcb;
                } else if (plan.mealPlanType === 'ICB') {
                    tempBalances.availableCredit += plan.remainingServicePeriodCharge;
                }
            });
            return tempBalances;
        };

        PosCtrl.prototype.transformUpcLookupResponse = function (upcCode, response) {
            let toReturn = response;
            switch (response.type) {
                case 'item':
                    var quantity = 1;
                    var total = Number(quantity) * response.price;
                    var taxAmount = 0;
                    var itemTaxAmount = 0;
                    var totalItemAmount = response.price;

                    if (response.taxRate) {
                        taxAmount = total * response.taxRate;
                        itemTaxAmount = response.price * response.taxRate;
                        totalItemAmount += itemTaxAmount;
                    }

                    var resultUpcItem = {
                        'locationServicePeriodMenuId': response.locationServicePeriodMenuId,
                        'quantity': quantity,
                        'name': response.name,
                        'taxAmount': taxAmount,
                        'price': response.price,
                        'total': total + taxAmount,
                        'upc': upcCode,
                        'type': response.type,
                        'itemPrice': response.price,
                        'itemTaxAmount': itemTaxAmount,
                        'itemTotalPrice': totalItemAmount,
                        'taxRate': response.taxRate,
                        'taxRateId': response.taxRateId,
                        'taxRules': response.taxRules,
                        'mealEquivalencyEnabled': response.mealEquivalencyEnabled,
                        'mealPlanAmount': response.mealPlanIndicator ? 1 : 0,
                        'mealPlanCount': ((response.mealPlanIndicator) ? Number(quantity) : 0),
                        'defaultDiscountMatrix': response.defaultDiscountMatrix,
                        'printoutType': response.printoutType,
                        'children': response.children
                    };

                    toReturn = resultUpcItem;
                    break;
            }

            return toReturn;
        };

        return PosCtrl;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = PosCtrl;
    } else {
        window.PosCtrl = PosCtrl;
    }
})();
