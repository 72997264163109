'use strict';

const Decimal = require('decimal.js').default;
const SharedFunctionService = require('../../external/pos.meal-plan-calculations.js');

export default angular.module('freshideas.services.company.attributes', []).factory('CompanyAttributesService', [
    'CurrentSession',
    'Company',
    function (
        CurrentSession,
        Company
    ) {
        const enhanceSecurityMeasures = () => {
            return CurrentSession.getCompany().attributes.other__enhance_security_measures === 'true';
        };

        const requestTransactionPin = () => {
            return CurrentSession.getCompany().attributes.other__transaction_pincode_security === 'true';
        };

        const enableTransactionLevelOpenDiscount = () => {
            return CurrentSession.getCompany().attributes.discounts__enable_transaction_level_open === 'true';
        };

        const enableItemLevelOpenDiscount = () => {
            return CurrentSession.getCompany().attributes.discounts__enable_item_level_open === 'true';
        };

        const enablePinOnLogout = () => {
            return CurrentSession.getCompany().attributes.other__enable_pin_on_logout === 'true';
        };

        const hasRetailMenu = () => {
            return CurrentSession.getCompany().attributes.retail__menu === 'true';
        };

        const hasEOSReportPrint = () => {
            return CurrentSession.getCompany().attributes.receipt__print_eos_report === 'true';
        };

        const partialCutEnabled = () => {
            return CurrentSession.getCompany().attributes.receipt__partial_cut_enabled === 'true';
        };

        const hasQRCodePrint = () => {
            return CurrentSession.getCompany().attributes.receipt__qrcode_print === 'true';
        };

        const hasExchangeEnabled = () => {
            return CurrentSession.getCompany().attributes.other__exchange_enabled === 'true';
        };

        const hasTaxPopupEnabled = () => {
            return CurrentSession.getCompany().attributes.other__change_tax_enabled === 'true';
        };

        const hasExchangePriceLimitEnabled = () => {
            return CurrentSession.getCompany().attributes.other__exchange_price_limit_enabled === 'true';
        };

        const getReceiptFooter = () => {
            return CurrentSession.getCompany().attributes.receipt_footer;
        };

        const toHideProductsPage = () => {
            return CurrentSession.getCompany().attributes.other__disable_products_for_child_companies === 'true';
        };

        const hasKDSEnabled = () => {
            return CurrentSession.getCompany().attributes.kds_enabled === 'true';
        };

        const toHideCustomersPage = () => {
            return CurrentSession.getCompany().attributes.hide_customers === 'true';
        };

        const hasTicketPrePrint = () => {
            return CurrentSession.getCompany().attributes.receipt__ticket_pre_print === 'true';
        };

        const includePatronNameBottom = () => {
            return CurrentSession.getCompany().attributes.receipt__include_patron_name_bottom === 'true';
        };

        const includePatronPhoneQR = () => {
            return CurrentSession.getCompany().attributes.receipt__include_patron_phone_qr === 'true';
        };

        const hasMenuPeriodsEnabled = () => {
            return CurrentSession.getCompany().attributes.other__menu_periods_enabled === 'true';
        };

        const deliveryEnabled = () => {
            return CurrentSession.getCompany().attributes.delivery_enabled === 'true';
        };

        const deliveryFeatureEnabled = () => {
            return CurrentSession.getCompany().attributes.delivery_feature_enabled === 'true';
        };

        const getDeliveryZoneRadiusInKms = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_zone_radius_kms || 0);
        };

        const getDeliveryFee = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_fee || 0);
        };

        const getSmallOrderFee = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_small_order_fee || 0);
        };

        const getSmallOrderThreshold = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_small_order_threshold || 0);
        };

        const getLargeOrderFee = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_large_order_fee || 0);
        };

        const getLargeOrderThreshold = () => {
            return new Decimal(CurrentSession.getCompany().attributes.delivery_large_order_threshold || 0);
        };

        const getPreClockInChecklist = () => {
            return JSON.parse(CurrentSession.getCompany().attributes.delivery_pre_clockin_checklist || '[]');
        };

        const deliveryTaxable = () => {
            return CurrentSession.getCompany().attributes.delivery_taxable === 'true';
        };

        const getDeliveryFixedLocations = () => {
            if (CurrentSession.getCompany().attributes.delivery_fixed_locations_enabled === 'true') {
                return JSON.parse(CurrentSession.getCompany().attributes.delivery_fixed_locations || '[]');
            }
            return JSON.parse('[]');
        };

        const hasExpoScreenEnabled = () => {
            return CurrentSession.getCompany().attributes.expo_screen_enabled === 'true';
        };

        const getDeliverySettings = () => {
            return SharedFunctionService.generateDeliverySettingsObject(
                deliveryEnabled(),
                getDeliveryZoneRadiusInKms(),
                getDeliveryFee(),
                getSmallOrderFee(),
                getSmallOrderThreshold(),
                getLargeOrderFee(),
                getLargeOrderThreshold(),
                getPreClockInChecklist(),
                deliveryTaxable(),
                getDeliveryFixedLocations()
            );
        };

        const hasPreorderAutoConfirm = () => {
            return CurrentSession.getCompany().attributes.other__preorder_autoconfirm_enabled === 'true';
        };

        const hasGiftCardsEnabled = () => {
            return CurrentSession.getCompany().attributes.gift_cards_enabled === 'true';
        };

        const hasOtherTenderEnabled = () => {
            return CurrentSession.getCompany().attributes.other__enable_other_tender_option === 'true';
        };

        const getCustomReceiptText = () => {
            if (
                CurrentSession.getCompany().localAttributes &&
                CurrentSession.getCompany().localAttributes.receipt__custom_text
            ) {
                return CurrentSession.getCompany().localAttributes.receipt__custom_text;
            }
        };

        const hasMenuV2Enabled = () => {
            return CurrentSession.getCompany().attributes.menu_v2_enabled === 'true';
        };

        const hasMevBox = () => {
            return CurrentSession.getCompany().attributes.has_mev_box === 'true';
        };

        const getSolinkClientSecret = () => {
            return CurrentSession.getCompany().attributes.solink_client_secret;
        };

        const hasCashcardMealPlansEnabled = () => {
            return CurrentSession.getCompany().attributes.cashcard_meal_plans_enabled === 'true';
        };

        const goToKioskCartOnUpcScan = () => {
            return CurrentSession.getCompany().attributes.go_to_kiosk_cart_on_upc_scan === 'true';
        };

        const enableFavouritePopularItemsKiosk = () => {
            return CurrentSession.getCompany().attributes.enable_favourite_popular_items_kiosk === 'true';
        };

        const kitchenPrintTextSize = () => {
            return CurrentSession.getCompany().attributes.kitchen_print_text_size;
        };

        /*
            Commented by Nick Simone 2022/02/23
            This attribute is default OFF and temporary. We are hoping to implement custom
            email templates for merchants in the future and remove this attribute entirely.
            */
        const hasKioskEmailsEnabled = () => {
            return CurrentSession.getCompany().attributes.kiosk_emails_enabled === 'true';
        };

        const getReceiptTaxRegistrationDetails = () => {
            return CurrentSession.getCompany().attributes.receipt_tax_registration_details;
        };

        const hasServiceModeSelectorEnabled = () => {
            return CurrentSession.getCompany().attributes.enable_service_mode_selector === 'true';
        };

        const itemAvailabilityDisabled = () => {
            return CurrentSession.getCompany().attributes.item_availability_disabled === 'true';
        };

        const hasSoundEnabled = () => {
            return CurrentSession.getCompany().attributes.other__pos_alert_sounds === 'true';
        };

        const hasQuickerChargeEnabled = () => {
            return CurrentSession.getCompany().attributes.quicker_charge_enabled === 'true';
        };

        const getKioskImageUrl = () => {
            return CurrentSession.getCompany().attributes.kiosk_display_image_url;
        };

        const disableDCBQuickcharge = () => {
            return CurrentSession.getCompany().attributes.disable_dcb_quickcharge === 'true';
        };

        const enableDCBQuickchargeConfirmation = () => {
            return CurrentSession.getCompany().attributes.enable_dcb_quickcharge_confirmation === 'true';
        };

        // should only apply to kiosk
        const requirePinToOpenStation = () => {
            return CurrentSession.getCompany().attributes.require_pin_to_open_station === 'true';
        };

        const hasKioskEnabled = () => {
            return CurrentSession.getCompany().attributes.kiosk_enabled === 'true';
        };

        const showScanAppOnKiosk = () => {
            return CurrentSession.getCompany().attributes.show_scan_app_on_kiosk === 'true';
        };

        const hasKioskLanguageSelection = () => {
            return CurrentSession.getCompany().attributes.kiosk_language_selection_enabled === 'true';
        };

        const hasOfflineForceRetry = () => {
            return CurrentSession.getCompany().attributes.other_offline_retry_enabled === 'true';
        };

        const hasPrintCardTerminalResponse = () => {
            return CurrentSession.getCompany().attributes.receipt__print_card_terminal_response === 'true';
        };

        const isScreenPrintEnabled = () => {
            return CurrentSession.getCompany().attributes.screen_print_enabled === 'true' || CurrentSession.isScreenPrintSession();
        };

        const allowBirthdayRewards = () => {
            return CurrentSession.getCompany().attributes.allow_birthday_rewards === 'true';
        };

        const hasRecentStatsReceiptCounter = () => {
            return CurrentSession.getCompany().attributes.recent_stats_receipt_counter === 'true';
        };

        const isKioskCreditCardDisabled = () => {
            return CurrentSession.getCompany().attributes.kiosk__creditcard_option_disabled === 'true';
        };

        const isKioskGiftCardDisabled = () => {
            return CurrentSession.getCompany().attributes.kiosk__giftcard_option_disabled === 'true';
        };

        const allowKioskTipping = () => {
            return CurrentSession.getCompany().attributes.allow_kiosk_tipping === 'true';
        };

        const canKioskSplitTender = function () {
            return CurrentSession.getCompany().attributes.kiosk__split_tender === 'true';
        };

        const hasCashierTipPayoutDisabled = () => {
            return CurrentSession.getCompany().attributes.disable_cashier_tip_payout === 'true';
        };

        const allowSavingMealCards = () => {
            return CurrentSession.getCompany().attributes.allow_saving_meal_cards === 'true';
        };

        const isPGCDiscountable = () => {
            return CurrentSession.getCompany().attributes.physical_giftcard__discountable === 'true';
        };

        const allowForeignGiftCards = () => {
            return CurrentSession.getCompany().attributes.other__foreign_gift_cards_enabled === 'true';
        };

        const getDefaultKioskMenuPage = () => {
            return CurrentSession.getCompany().attributes.kiosk__default_menu_page_index;
        };

        const loyaltyStepsEnabled = () => {
            return CurrentSession.getCompany().attributes.loyalty_steps_enabled === 'true';
        };

        const includeCCTipsInTipsPayout = () => {
            return CurrentSession.getCompany().attributes.cashier_tip_payout__include_cc_tips === 'true';
        };

        const allowUpcMissingEmailAlert = () => {
            return CurrentSession.getCompany().attributes.send_upc_missing_email_alert === 'true';
        };

        const allowReactivePatronLookup = () => {
            return CurrentSession.getCompany().attributes.allow_reactive_patron_lookup === 'true';
        };

        const getPreorderAutoFulfillInterval = function () {
            return CurrentSession.getCompany().attributes.preorder__auto_fulfill_interval || 300000; // in millisec (Default: 5 mins)
        };

        const updateCompanyAttributes = function (attributesToUpdate, resolve, reject) {
            const company = CurrentSession.getCompany();
            const payload = {};
            payload[company.companyId] = attributesToUpdate;

            return Company.updateAttributes({}, payload, function (response) {
                if (resolve) {
                    resolve(response);
                }
            }, function (error) {
                if (reject) {
                    reject(error);
                }
            });
        };

        return {
            enhanceSecurityMeasures,
            enableTransactionLevelOpenDiscount,
            enableItemLevelOpenDiscount,
            enablePinOnLogout,
            hasRetailMenu,
            hasEOSReportPrint,
            hasQRCodePrint,
            hasExchangeEnabled,
            hasTaxPopupEnabled,
            hasExchangePriceLimitEnabled,
            getReceiptFooter,
            toHideProductsPage,
            hasKDSEnabled,
            toHideCustomersPage,
            hasTicketPrePrint,
            hasMenuPeriodsEnabled,
            deliveryEnabled,
            deliveryFeatureEnabled,
            getDeliverySettings,
            hasPreorderAutoConfirm,
            hasGiftCardsEnabled,
            hasOtherTenderEnabled,
            getCustomReceiptText,
            hasMenuV2Enabled,
            requestTransactionPin,
            hasMevBox,
            getSolinkClientSecret,
            hasCashcardMealPlansEnabled,
            goToKioskCartOnUpcScan,
            enableFavouritePopularItemsKiosk,
            kitchenPrintTextSize,
            hasKioskEmailsEnabled,
            getReceiptTaxRegistrationDetails,
            hasServiceModeSelectorEnabled,
            itemAvailabilityDisabled,
            hasSoundEnabled,
            hasQuickerChargeEnabled,
            getKioskImageUrl,
            requirePinToOpenStation,
            hasKioskEnabled,
            hasKioskLanguageSelection,
            includePatronNameBottom,
            includePatronPhoneQR,
            hasOfflineForceRetry,
            isScreenPrintEnabled,
            disableDCBQuickcharge,
            enableDCBQuickchargeConfirmation,
            hasPrintCardTerminalResponse,
            allowBirthdayRewards,
            hasRecentStatsReceiptCounter,
            showScanAppOnKiosk,
            hasCashierTipPayoutDisabled,
            isKioskCreditCardDisabled,
            isKioskGiftCardDisabled,
            allowKioskTipping,
            canKioskSplitTender,
            allowSavingMealCards,
            isPGCDiscountable,
            hasExpoScreenEnabled,
            allowForeignGiftCards,
            getDefaultKioskMenuPage,
            partialCutEnabled,
            loyaltyStepsEnabled,
            includeCCTipsInTipsPayout,
            allowUpcMissingEmailAlert,
            allowReactivePatronLookup,
            getPreorderAutoFulfillInterval,
            updateCompanyAttributes
        };
    },
]);
